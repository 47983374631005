import { FlowServiceKeywords, QMFlow, EligibilityType } from './types';
import { initFlowConfig } from './utils';
import { FLOW_90_SELECT_SERVICE } from './Flow90';
import appConfigs from '../utils/configs';

/** PREMERA Trizetto (copay) */
export const FLOW_28_B2B_COPAY_TRIZETTO_PREMERA = 28; // TriZetto

export const ServiceKeywords: FlowServiceKeywords = {
  trizetto: {
    psychotherapy: 'premerabh',
    therapyCouples: 'premerabhcouples',
    psychiatry: 'premerabhpsychiatry',
  },
  manual: {
    psychotherapy: 'premeramanualflowkeyword',
    therapyCouples: 'premerabhmanualcouplestest',
    psychiatry: 'premerabhmanualpsychiatrytest',
  },
};

const flow: QMFlow = {
  flowId: FLOW_28_B2B_COPAY_TRIZETTO_PREMERA,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    isTeen: true,
    serviceKeywords: ServiceKeywords.trizetto,
    serviceKeywordsManual: ServiceKeywords.manual,
    isTrizetto: true,
    maximumCost: {
      psychotherapy: 121.44,
      psychiatry: 181.72,
    },
    eligibilityType: EligibilityType.trizetto,
  },
  steps: [
    {
      id: 1,
      analyticsId: 56,
      category: 'oneFormEligibility',
      isGroupIdRequired: false,
      optionalOrganization: true,
      buttonTarget: 2,
      skipText: 'Continue without insurance',
      skipRedirectToFlow: FLOW_90_SELECT_SERVICE,
    },
    {
      id: 2,
      prompt: 'Validate Voucher',
      analyticsId: 58,
      category: 'validateVoucher',
      response_category_id: 8,
      heading1: null,
      heading2: "You're ready to begin.",
      heading3:
        'To get you the best care possible, we need to ask you a few questions that will help us find the right provider for your needs.',
      behavioralHealth: true,
      buttonText: 'Next',
      hideBackButton: true,
      supportEmail: appConfigs.supportEmailLinks.partnersSupport,
      linkToFAQ: 'https://www.talkspace.com/faq/bh',
    },
  ],
};

export default flow;
