import { HomePageState } from '../Components/HomePage/types';
import { states } from '../Helpers/locales';
import getParamByName from '../utils/queryString';
// This import in particular needs user interaction, therefore, "safe" to be a cycle
// eslint-disable-next-line import/no-cycle
import { getClientFlow, getClientFlowConfig } from './index';
// eslint-disable-next-line import/no-cycle
import { FLOW_138_B2B_VOUCHER_GENERIC_V2 } from './Flow138';
// eslint-disable-next-line import/no-cycle
import { FLOW_7_B2B_COPAY_GENERIC } from './Flow7';
import { OneFormEligibilityStep, QMFlow, UpdateStepObj } from './types';
import {
  allRegistrationPagesSteps,
  ineligiblePromo,
  initFlowConfig,
  registrationStepsValidation,
  skipIfExperiment,
  skipToStepIfDoesntMeetAvailabilityPreferencesRequirements,
} from './utils';
import { QM_COPY } from '../utils/qmCopy';

const OFFER_ID = 112;
const INITIAL_OFFER_STEP_ID = 23;
const MATCHES_STEP_ID = 17;

const getRegistrationStepIDByFlowID = (state: HomePageState, answer?: UpdateStepObj): number => {
  const { organizationFlowID, partnerFlowID } = state;
  let { qmPartnerCode, cpPartnerCode } = state;
  let accessCodeType = getParamByName('accessCodeType') as AccessCodeType | null;

  if (
    !(accessCodeType || qmPartnerCode || cpPartnerCode) &&
    (answer?.accessCodeType || answer?.accessCode)
  ) {
    accessCodeType = answer.accessCodeType || null;
    // Attempt to find the cpPartnerCode first
    if (accessCodeType && accessCodeType === 'cpPartnerCode') {
      cpPartnerCode = answer.accessCode || '';
    }
    // Default to qmPartnerCode
    if (!cpPartnerCode) {
      qmPartnerCode = answer.accessCode || '';
    }
  }
  const flowID = (organizationFlowID || partnerFlowID) as number;
  let flow = flowID && getClientFlow(flowID);
  if (cpPartnerCode || accessCodeType === 'cpPartnerCode') {
    flow = getClientFlow(FLOW_7_B2B_COPAY_GENERIC);
  }
  if (qmPartnerCode || accessCodeType === 'qmPartnerCode') {
    flow = getClientFlow(FLOW_138_B2B_VOUCHER_GENERIC_V2);
  }
  if (flow) {
    const registrationStepIndexes = flow.steps
      .map((step) => registrationStepsValidation.findIndex((validationFun) => validationFun(step)))
      .filter((index) => index > -1);

    if (registrationStepIndexes.length > 0) {
      return INITIAL_OFFER_STEP_ID + registrationStepIndexes[0];
    }
  }
  return INITIAL_OFFER_STEP_ID;
};

const skipEligibilityForDTE = (_state: HomePageState, answer?: UpdateStepObj) => {
  const { organizationFlowID } = answer || {};
  // If we successfully received a qmFlowID and the target flow has an eligibility type, show form
  if (
    organizationFlowID &&
    getClientFlowConfig(organizationFlowID)?.eligibilityType !== undefined
  ) {
    return undefined;
  }
  return MATCHES_STEP_ID; // Matches step
};

// Omitting category for readability
const ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG: Omit<OneFormEligibilityStep, 'id' | 'category'> = {
  analyticsId: 54,
  response_category_id: 9,
  // nextQuestionActions: [goToMatchesOrRegistration],
  ...ineligiblePromo,
};

/** Service Specific Funnels: Psychiatry */
export const FLOW_102_ELIGIBILITY_QUESTIONS_PSYCHIATRY = 102;

const flow: QMFlow = {
  flowId: FLOW_102_ELIGIBILITY_QUESTIONS_PSYCHIATRY,
  flowConfig: {
    ...initFlowConfig,
    serviceType: 'psychiatry',
    isB2B: false,
    shouldHideServiceSelection: true,
    version: 3,
  },
  steps: [
    {
      id: 1,
      prompt: 'What led you to seek help?',
      analyticsId: 54,
      response_category_id: 2,
      category: 'presentingProblems',
      internalTarget: 135,
      selfServe: {
        field: 'fieldsOfExpertise',
        type: Array,
      },
      heading1: null,
      heading2: "To begin, tell us why you're looking for help today.",
      progressCurrent: 1,
      progressMax: 8,
      tip: 'findTheRightMatch',
    },
    {
      id: 135,
      category: 'memberAvailability',
      prompt: 'Select your preferred times for therapy',
      heading2: 'Select your preferred times for therapy',
      skipQuestionActions: [
        skipToStepIfDoesntMeetAvailabilityPreferencesRequirements(2),
        skipIfExperiment({
          experimentName: 'clientAvailabilityPreferencesExperiment',
          variant: 'control',
          nextStepId: 2,
          shouldTrack: true,
        }),
      ],
      buttonTarget: 2,
      progressCurrent: 1.5,
      progressMax: 8,
    },
    {
      id: 2,
      prompt: 'Have you ever been prescribed medication to treat a mental health condition?',
      analyticsId: 93,
      response_category_id: 7,
      category: 'select1',
      heading2: 'Have you ever been prescribed medication to treat a mental health condition?',
      buttons: [
        {
          text: 'Yes',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 'Yes',
        },
        {
          text: 'No',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 'No',
        },
        {
          text: "I'm not sure",
          externalTarget: null,
          internalTarget: 11,
          answerValue: undefined,
        },
      ],
      tip: 'noteControlledSubstances',
      progressCurrent: 2,
      progressMax: 8,
    },
    // matching questions
    {
      id: 11,
      prompt: 'My gender is',
      analyticsId: 62,
      response_category_id: 4,
      category: 'select1',
      heading2: 'What gender do you identify with?',
      buttons: [
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 1,
        },
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 2,
        },
        {
          text: 'Transgender male',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 3,
        },
        {
          text: 'Transgender female',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 4,
        },
        {
          text: 'Gender queer',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 5,
        },
        {
          text: 'Gender variant',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 6,
        },
        {
          text: 'Other',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 7,
        },
        {
          text: 'Non-binary',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 8,
        },
      ],
      skipText: 'Skip',
      skipExternalTarget: null,
      skipInternalTarget: 12,
      progressCurrent: 3,
      progressMax: 8,
    },
    {
      id: 12,
      prompt: 'Preferred provider gender',
      analyticsId: 57,
      response_category_id: 3,
      category: 'select1',
      heading2: 'What gender would you prefer in a provider?',
      selfServe: {
        field: 'therapistGender',
      },
      buttons: [
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 13,
          answerValue: 1,
        },
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 13,
          answerValue: 2,
        },
        {
          text: "I'm not sure yet",
          externalTarget: null,
          internalTarget: 13,
          answerValue: undefined,
        },
      ],
      progressCurrent: 4,
      progressMax: 8,
    },
    {
      id: 13,
      prompt: 'What is your age?',
      analyticsId: 61,
      response_category_id: 5,
      category: 'dateOfBirth',
      heading2: "What's your date of birth?",
      targets: {
        under13: 'https://helpnow.talkspace.com/under-13',
        under18GDPR: 42,
        teen: 23,
        adult: 14,
      },
      progressCurrent: 5,
      progressMax: 8,
    },
    {
      id: 42,
      category: 'notAvailableInYourRegion',
    },
    {
      id: 14,
      prompt: QM_COPY.state.prompt,
      analyticsId: 63,
      response_category_id: 6,
      category: 'dropdown',
      heading2: QM_COPY.state.title,
      heading3: QM_COPY.state.subtitle,
      selectPlaceholder: QM_COPY.state.placeholder,
      selectOptions: states,
      buttonText: QM_COPY.state.continue,
      buttonTarget: 15,
      skipExternalTarget: null,
      progressCurrent: 6,
      progressMax: 8,
    },
    {
      id: 15,
      category: 'dispatcherInQM',
      initialDispatcherStep: 'organizationName',
      internalTarget: 16,
      progressCurrent: 7,
      progressMax: 8,
      nextQuestionActions: [skipEligibilityForDTE],
    },
    {
      id: 16,
      category: 'oneFormEligibility',
      progressCurrent: 8,
      progressMax: 8,
      buttonTarget: MATCHES_STEP_ID,
      ...ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG,
    },
    {
      id: MATCHES_STEP_ID,
      prompt: 'Matches',
      category: 'matches1',
      buttonTarget: 20,
      zeroMatchTarget: 20,
      matchOverlay: true,
      canMatchOverlayBeSkipped: true,
      showCustomerRegistrationModalWithoutPayment: true,
    },
    {
      id: 20,
      category: 'oneFormEligibility',
      noCoverageButtonTarget: INITIAL_OFFER_STEP_ID,
      buttonTarget: INITIAL_OFFER_STEP_ID,
      nextQuestionActions: [getRegistrationStepIDByFlowID],
      disableOrganization: true,
      ...ONE_FORM_ELIGIBILITY_DEFAULT_STEP_CONFIG,
    },
    { id: 22, category: 'registerWithoutPayment' },
    ...allRegistrationPagesSteps({
      initialStepID: INITIAL_OFFER_STEP_ID,
      offersPageSettings: {
        offerID: OFFER_ID,
        previousStepsUntilMatches: 2,
      },
    }),
  ],
};

export default flow;
