import {
  ExtraHuge,
  Large,
  Small,
  Tiny,
  View,
  HeartShield,
  TouchableView,
  CaretRight,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { useHistory } from 'react-router-dom';
import { ReactElement } from 'react';
import styled from '../../core/styled';
import { OneFormEligibilityStep, UpdateStepObj } from '../../Flows/types';
import { trackEvent } from '../../utils/analytics/events';
import { useRecoveredSessionActions } from '@/hooks/recoveredSessionContext';
import switchFlowHelper from '../../Helpers/switchFlowHelper';

const Wrapper = styled(View)<{ isMobile }>(({ isMobile }) => {
  return {
    alignItems: 'center',
    maxWidth: 430,
    marginTop: isMobile ? 20 : 100,
  };
});

const InsuranceInfoBanner = styled(View)<{ isMobile }>(({ theme: { colors }, isMobile }) => {
  return {
    borderRadius: 12,
    width: '100%',
    maxWidth: isMobile ? 335 : 430,
    height: 62,
    background: colors.permaGreyed,
    marginBottom: 16,
    border: `solid 1px ${colors.permaLondonGray}`,
  };
});

const DiscountBanner = styled(View)(({ theme: { colors } }) => {
  return {
    borderRadius: 8,
    padding: 8,
    marginTop: 8,
    background: colors.aquaSqueeze,
    color: colors.permaTalkspaceDarkGreen,
    width: 202,
    boxSizing: 'content-box',
  };
});

const MenuLinks = ({ items }) => {
  const { colors } = useEmotionTheme();
  return (
    <View style={{ borderTop: `1px ${colors.periwinkleGrey} solid`, marginTop: 20, width: '100%' }}>
      {items.map(({ key, Content, onPress }) => (
        <TouchableView
          row
          justify="space-between"
          key={key}
          dataQa={key}
          style={{
            textAlign: 'left',
            padding: 20,
            borderBottom: `1px ${colors.periwinkleGrey} solid`,
          }}
          onPress={onPress}
          align="center"
        >
          <View style={{ flex: 1 }}>
            <Content />
          </View>

          <CaretRight style={{ marginLeft: 24 }} />
        </TouchableView>
      ))}
    </View>
  );
};

interface Props {
  insuranceInfo: {
    payerName: string;
    fullName: string;
    dateOfBirth: string;
    memberID: string;
  };
  step: OneFormEligibilityStep;
  handleReturnPress: (args: { isManualRequest?: boolean }) => void;
  updateStep: (
    internalTarget: string | number | null | undefined,
    objectArg?: UpdateStepObj
  ) => void;
  setIsCouldNotBeVerified: (isNotVerified: boolean) => void;
}

interface MenuItems {
  key: string;
  shouldHide?: boolean;
  Content: () => ReactElement;
  onPress: () => void;
}

const OneFormEligibilityNoCoverage = ({
  insuranceInfo,
  step,
  handleReturnPress,
  updateStep,
  setIsCouldNotBeVerified,
}: Props) => {
  const { updateAndSave } = useRecoveredSessionActions();
  const history = useHistory();

  const { isMobile } = useWindowWidthState();
  const { payerName, fullName, dateOfBirth, memberID } = insuranceInfo;
  const items: MenuItems[] = [
    {
      key: 'continueWithoutInsurancePress',
      shouldHide: ![step?.skipRedirectToFlow, step?.noCoverageButtonTarget].some(Boolean),
      Content: () => (
        <>
          <Large>Continue without insurance</Large>
          <DiscountBanner>
            <Small variant="smallBolderGreen">You’re eligible for a discount!</Small>
          </DiscountBanner>
        </>
      ),
      onPress: () => {
        trackEvent('No Coverage Options', {
          optionType: 'Continue without insurance',
          source: 'Could not verify',
        });

        if (step?.skipRedirectToFlow) {
          setIsCouldNotBeVerified(false);
          switchFlowHelper(step.skipRedirectToFlow, history, updateAndSave);
        } else if (step?.noCoverageButtonTarget) {
          updateStep(step.noCoverageButtonTarget);
        }
      },
    },
    {
      key: 'checkInformationCorrectAndResubmitPress',
      Content: () => <Large>Check that my information is correct and resubmit</Large>,
      onPress: () => {
        trackEvent('No Coverage Options', {
          optionType: 'Check that my information is correct and resubmit',
          source: 'Could not verify',
        });
        handleReturnPress({});
      },
    },
    {
      key: 'checkIfHaveCoveragePress',
      Content: () => (
        <Large>
          See if I have coverage through my employer, organization, or Employee Assistance Program
          (EAP)
        </Large>
      ),
      onPress: () => {
        trackEvent('No Coverage Options', {
          optionType: 'See if I have coverage through my employer, organization, or EAP',
          source: 'Could not verify',
        });
        updateStep(16);
      },
    },
    {
      key: 'surePlanCoversTalkspacePress',
      Content: () => <Large>I’m sure my plan covers Talkspace</Large>,
      onPress: () => {
        trackEvent('No Coverage Options', {
          optionType: 'I’m sure my plan covers Talkspace',
          source: 'Could not verify',
        });
        handleReturnPress({ isManualRequest: true });
      },
    },
  ];

  const filteredItems = items.filter((value) => {
    if (
      (step?.skipRedirectToFlow || step?.noCoverageHideEAP) &&
      value.key === 'checkIfHaveCoveragePress'
    ) {
      return false;
    }
    if (step?.noCoverageHideOutOfPocket && value.key === 'continueWithoutInsurancePress') {
      return false;
    }
    return true;
  });

  return (
    <Wrapper isMobile={isMobile}>
      <InsuranceInfoBanner justify="center" isMobile={isMobile}>
        {payerName && (
          <View row justify="center">
            <HeartShield />
            <Small style={{ marginLeft: 8 }} variant="smallBolderGrey">
              {payerName}
            </Small>
          </View>
        )}
        <View style={{ marginTop: 4 }}>
          <Tiny>
            {fullName}, {dateOfBirth}, {memberID}
          </Tiny>
        </View>
      </InsuranceInfoBanner>
      <ExtraHuge style={{ marginBottom: 12 }}>
        We couldn’t verify your coverage for Talkspace
      </ExtraHuge>
      <Large>
        If you typed everything correctly, then it appears that Talkspace may not be covered by your
        plan. Don't worry, we'll help you explore other options.
      </Large>
      <MenuLinks items={filteredItems} />
    </Wrapper>
  );
};

export default OneFormEligibilityNoCoverage;
