import { FlowServiceKeywords, QMFlow, EligibilityType } from './types';
import { initFlowConfig } from './utils';
import { FLOW_90_SELECT_SERVICE } from './Flow90';
import appConfigs from '../utils/configs';

export const FLOW_126_B2B_BH_TRIZETTO_PROVIDENCE = 126;

export const ServiceKeywords: FlowServiceKeywords = {
  trizetto: {
    psychotherapy: 'providencebh',
    psychiatry: 'providencepsych',
  },
  manual: {
    psychotherapy: 'providencemanualbh',
    psychiatry: 'providencemanualpsych',
  },
};

const flow: QMFlow = {
  flowId: FLOW_126_B2B_BH_TRIZETTO_PROVIDENCE,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    serviceKeywords: ServiceKeywords.trizetto,
    eligibilityType: EligibilityType.trizetto,
    isTeen: true,
    serviceKeywordsManual: ServiceKeywords.manual,
    isTrizetto: true,
    maximumCost: {
      psychotherapy: 128,
      psychiatry: 208,
    },
  },
  steps: [
    {
      id: 1,
      analyticsId: 56,
      category: 'oneFormEligibility',
      isGroupIdRequired: false,
      optionalOrganization: true,
      buttonTarget: 2,
      skipText: 'Continue without insurance',
      skipRedirectToFlow: FLOW_90_SELECT_SERVICE,
    },
    {
      id: 2,
      prompt: 'Validate Voucher',
      analyticsId: 58,
      category: 'validateVoucher',
      response_category_id: 8,
      heading1: null,
      heading2: "You're ready to begin.",
      heading3:
        'To get you the best care possible, we need to ask you a few questions that will help us find the right provider for your needs.',
      behavioralHealth: true,
      buttonText: 'Next',
      hideBackButton: true,
      supportEmail: appConfigs.supportEmailLinks.partnersSupport,
      linkToFAQ: 'https://www.talkspace.com/faq/bh',
    },
  ],
};

export default flow;
