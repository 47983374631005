import s from '@emotion/styled';
import { CreateStyled, ExtendEmotionTheme } from '@talkspace/react-toolkit';
import { theme } from '../../Elements';

// export interface EmotionStyle extends CSS.Properties<string | number> {}
export type EmotionStyle = object;

const { fonts, headings, buttons, links, breakpoints, mq } = theme;

interface BaseTheme {
  fonts: typeof fonts;
  headings: typeof headings;
  buttons: typeof buttons;
  links: typeof links;
  breakpoints: typeof breakpoints;
  mq: typeof mq;
}

export type Theme = ExtendEmotionTheme<BaseTheme>;

// Using @emotion/styled version of CreateStyled instead of the toolkit version
// because we are overriding `colors`.
const styled = s as CreateStyled<Theme>;

export default styled;
