import { SelectDynamicStep } from '../Flows/types';
import { DynamicValueHomePageState, DynamicValueTranslators } from '../Components/HomePage/types';

export const CALLBACK_FIELDS: Array<keyof SelectDynamicStep> = [
  'buttons',
  'heading1',
  'heading2',
  'heading3',
  // 'selfServe', // selfServe field is called in the mapSelfServeFields function
];

export const resolveField = (
  step: SelectDynamicStep,
  fieldName: keyof SelectDynamicStep,
  homePageState?: DynamicValueHomePageState,
  i18nTranslators?: DynamicValueTranslators
) => {
  const value = step[fieldName];
  return value && typeof value === 'function' ? value(homePageState, i18nTranslators) : value;
};

const isCallback = (step: SelectDynamicStep, fieldName: keyof SelectDynamicStep) => {
  const value = step[fieldName];
  return value && typeof value === 'function';
};

export const getHasAnyCallbacks = (step) =>
  step.category === 'selectDynamic' &&
  CALLBACK_FIELDS.map((fieldName) => isCallback(step, fieldName)).some(Boolean);
