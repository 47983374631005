/**
 * If a user is unable to select an insurance provider with whom we have determined
 * they have coverage, they have the option of uploading photographic documentation
 * of their insurance coverage. If they elect to do that, this is the last screen
 * that is shown as part of that process. We prompt them for an email at which we
 * can reach out to them and allow them to perform the final submit here.
 */
import { useEffect, useState } from 'react';
import { checkEmailValidity } from 'ts-frontend/helpers';
import {
  AnimatedError,
  Button,
  Label,
  SparklingCheckMark,
  TSInput,
  View,
  useEmotionTheme,
  useUniqueID,
} from '@talkspace/react-toolkit';
import Title from '@/Components/Title';
import Subtitle from '@/Components/Subtitle';
import { useManualSubmit } from './hooks';
import { ManualSubmitProps } from './types';

const ManualSubmit = ({
  updateStep,
  service,
  step,
  eligibilityFiles,
  quickEligibilityInfo,
  setManualVerificationEmail,
}: ManualSubmitProps) => {
  const [email, setEmail] = useState<string>('');
  const [confirmEmail, setConfirmEmail] = useState<string>('');
  const canSubmit = checkEmailValidity(email) && email === confirmEmail;
  const emailInputID = useUniqueID('emailInputID');
  const confirmEmailInputID = useUniqueID('confirmEmailInputID');
  const emailError =
    email && !checkEmailValidity(email) ? 'Please enter a valid email address' : undefined;
  const confirmEmailError =
    confirmEmail && email !== confirmEmail
      ? 'The email addresses you entered don’t match'
      : undefined;
  const { colors } = useEmotionTheme();

  const { error, isSubmitting, success, onSubmit } = useManualSubmit({
    ...eligibilityFiles,
    memberID: quickEligibilityInfo.memberID,
    state: quickEligibilityInfo.state,
    email:
      email && confirmEmail && email === confirmEmail && !emailError && !confirmEmailError
        ? email
        : '',
    firstName: quickEligibilityInfo.firstName,
    lastName: quickEligibilityInfo.lastName,
    dob: quickEligibilityInfo.dob,
    partnerID: quickEligibilityInfo?.insurancePayer?.partnerID || undefined,
    serviceType: service,
  });

  useEffect(() => {
    if (success) {
      setManualVerificationEmail(email);
      updateStep(step.id);
    }
  }, [success, email, step.id, setManualVerificationEmail, updateStep]);

  return (
    <View align="center">
      <SparklingCheckMark />
      <Title
        style={{
          marginTop: 24,
        }}
      >
        We’ll assign an agent to help you
      </Title>
      <Subtitle>
        A member of our team will be following up with you via email, please provide us with an
        email address where you can be reached.
      </Subtitle>
      <View style={{ marginTop: 24, width: '100%' }}>
        <Label htmlFor={emailInputID}>Email</Label>
        <TSInput
          id={emailInputID}
          ariaDescribedBy={emailError || undefined}
          onChange={(value) => {
            setEmail(value);
          }}
          value={email}
          placeholder="Enter email"
          style={{ fontSize: 16, borderColor: emailError && colors.red }}
          shouldFocus={!!emailError}
          dataQa="emailInput"
        />
        <AnimatedError id={emailError} shouldAnimate={Boolean(emailError)}>
          {emailError}
        </AnimatedError>
        <Label htmlFor={confirmEmailInputID} style={{ marginTop: 16 }}>
          Confirm email
        </Label>
        <TSInput
          id={confirmEmailInputID}
          ariaDescribedBy={confirmEmailError || undefined}
          onChange={setConfirmEmail}
          value={confirmEmail}
          placeholder="Enter email"
          style={{ fontSize: 16, borderColor: confirmEmailError && colors.red }}
          shouldFocus={!!(confirmEmailError && !emailError)}
          dataQa="confirmNewEmailInput"
        />
        <AnimatedError id={confirmEmailError} shouldAnimate={Boolean(confirmEmailError)}>
          {confirmEmailError}
        </AnimatedError>
      </View>
      <Button
        dataQa="submitButton"
        onPress={async () => {
          await onSubmit();
        }}
        text="Submit"
        disabled={!canSubmit}
        isLoading={isSubmitting}
        style={{ width: '100%', marginTop: 40 }}
      />
      {error && (
        <AnimatedError id={error} shouldAnimate>
          {error}
        </AnimatedError>
      )}
    </View>
  );
};

export default ManualSubmit;
