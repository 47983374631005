/**
 * Flow: 97
 * Description: Teen Therapy
 *
 * Notes:
 *
 * An experimental version of this flow exists (108). If changes are made to this flow, those
 * same changes must also be applied to the other experimental flow.
 */
import { QMFlow } from './types';
import { initFlowConfig } from './utils';
// eslint-disable-next-line import/no-cycle
import flow132 from './Flow132';

export const FLOW_97_TEEN_THERAPY = 97;

const flow: QMFlow = {
  steps: flow132.steps,
  flowId: FLOW_97_TEEN_THERAPY,
  flowConfig: {
    ...initFlowConfig,
    ...flow132.flowConfig,
    disabled: true,
  },
};

export default flow;
