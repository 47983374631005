/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import { trackEvent } from '@/utils/analytics/eventTracker';
import { UseOnContinueOptions } from '../types';

export const useOnContinue = ({
  shouldDisplayModal,
  setShowMatchOverlay,
  handleOnPressSecureYourMatch,
}: UseOnContinueOptions) =>
  useCallback(() => {
    if (shouldDisplayModal) {
      setShowMatchOverlay(true);
      trackEvent('Great Work CTA Click', {
        showMatchOverlay: true,
      });
    } else {
      handleOnPressSecureYourMatch();
      trackEvent('Great Work CTA Click', {
        showMatchOverlay: false,
      });
    }
  }, [shouldDisplayModal, setShowMatchOverlay, handleOnPressSecureYourMatch]);
