import { View, Image, Tiny, Link, useEmotionTheme } from '@talkspace/react-toolkit';

import styled from '@/core/styled';
import voiceLineIcon from './voiceLineIcon.png';

const Styled = {
  HelpPillContainer: styled(View)(() => {
    return {
      backgroundColor: '#F2F5FB',
      borderRadius: 10,
      height: 42,
      flexDirection: 'row',
      alignItems: 'center',
      gap: 11,
      justifyContent: 'flex-start',
    };
  }),
  VoiceLineIconContainer: styled(View)({
    marginLeft: 9,
    marginBottom: 2,
  }),
};

const HelpPill = () => {
  const { colors } = useEmotionTheme();
  return (
    <Styled.HelpPillContainer>
      <Styled.VoiceLineIconContainer>
        <Image source={voiceLineIcon} alt="call center assistant" width={22} height={24} />
      </Styled.VoiceLineIconContainer>
      <Tiny style={{ color: colors.grey950 }}>
        Need help signing up? Call
        <Link href="tel:8888500986">
          <Tiny variant="tinyBoldGreen" inline>
            (888) 850-0986
          </Tiny>
        </Link>
      </Tiny>
    </Styled.HelpPillContainer>
  );
};

export default HelpPill;
