import { View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

interface StickyProps {
  stickHeader?: boolean;
}

const StickyFooter = styled(View)<StickyProps>(({ theme: { window, colors }, stickHeader }) => {
  const { isMobile } = window;
  return {
    position: 'sticky',
    top: isMobile ? 0 : -22,
    marginLeft: isMobile ? 0 : -22,
    marginRight: isMobile ? 0 : -22,
    backgroundColor: colors.white,
    boxShadow: stickHeader ? '0 1px 0 0 rgba(0,0,0,0.06)' : 'none',
    zIndex: 3,
    width: isMobile ? 335 : 430,
  };
});

export default StickyFooter;
