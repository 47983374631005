import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { ExtraHuge, FadeOut, Large, Spinner, View } from '@talkspace/react-toolkit';
import { ClientMatchPresentingProblem, InsuranceEligibilityPayer } from 'ts-frontend/types';
import { getPresentingProblemIdsByExpertiseNames } from 'ts-frontend/helpers/presentingProblems';
import { trackEvent } from '../../utils/analytics/events';
import { Match, PROVIDER_GENDERS } from '../HomePage/types';
import MatchTab from './MatchTab';
import { ExternalTarget, InternalTarget, MatchesStep, UpdateStepObj } from '../../Flows/types';
import styled from '../../core/styled';
import useWindowWidth from '../../hooks/useWindowWidth';
import MeetYourMatchesHeader from './MeetYourMatchesHeader';

const MATCHES_ADDEND = 3;
const FOOTER_HEIGHT = 72;
const LAZY_LOAD_TIME_MS = 15;
const LAZY_LOAD_BOTTOM_OFFSET = 20;

const MATCHES_VIEW_STYLE = {
  maxWidth: 875,
  width: '100%',
  alignSelf: 'center',
  padding: 0,
  paddingRight: 4,
  display: 'block',
};

interface MatchesProps {
  clientMatchPresentingProblems: ClientMatchPresentingProblem[];
  flowId: number;
  matches: Match[];
  step: MatchesStep;
  loadingMatches: boolean;
  removeGenderFilter: () => void;
  updateStep: (target: InternalTarget | ExternalTarget, updateStepObj: UpdateStepObj) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  exactMatch?: boolean;
  clientCountry?: string;
  clientMatchGenderPreference?: string;
  insurancePayer?: InsuranceEligibilityPayer;
}

enum MatchesType {
  FILTERED = 'filtered',
  UNFILTERED = 'unfiltered',
}

const BoldSpan = styled.span({ fontWeight: 'bold' });

const OtherMatchesTitle = styled(Large)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    marginTop: isMobile ? 38 : 40,
    marginBottom: 29,
    paddingLeft: 20,
    paddingRight: 10,
    textAlign: 'left',
  };
});

const NoMatchesHeader = styled(ExtraHuge)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    alignSelf: 'center',
    maxWidth: isMobile ? 329 : 585,
    marginTop: 30,
    marginBottom: 49,
  };
});

const Separator = styled(View)(({ theme: { colors } }) => {
  return {
    width: '60%',
    borderTop: `1px solid ${colors.permaLinkWaterGrey}`,
  };
});

const LoadingMatchesSpinner = () => (
  <View justify="center" flex={1} row style={{ paddingBottom: 30 }}>
    <Spinner />
    <Large variant="largeDarkGrey">Loading more matches</Large>
  </View>
);

const Matches: FunctionComponent<MatchesProps> = ({
  clientMatchPresentingProblems,
  flowId,
  step,
  removeGenderFilter,
  updateStep,
  matches,
  loadingMatches = false,
  clientMatchGenderPreference = PROVIDER_GENDERS.ANY,
  clientCountry = undefined,
  insurancePayer = undefined,
}) => {
  const { isMobile } = useWindowWidth();
  const [filteredMatchesEndIndex, setFilteredMatchesEndIndex] = useState<number>(MATCHES_ADDEND);
  const [restOfMatchesEndIndex, setRestOfMatchesEndIndex] = useState<number>(MATCHES_ADDEND);
  const [filteredMatches, setFilteredMatches] = useState<Match[]>([]);
  const [restOfMatches, setRestOfMatches] = useState<Match[]>([]);
  const [filteredMatchesToShow, setFilteredMatchesToShow] = useState<Match[]>([]);
  const [restOfMatchesToShow, setRestOfMatchesToShow] = useState<Match[]>([]);
  const [isLazyLoadingFilteredMatches, setIsLazyLoadingFilteredMatches] = useState<boolean>(false);
  const [isLazyLoadingRestOfMatches, setIsLazyLoadingRestOfMatches] = useState<boolean>(false);
  const matchesRef = useRef<HTMLDivElement>(null);

  const footerOffset = isMobile ? 180 : 150;

  const isExpertiseMatch = (
    expertise: string[],
    presentingProblems: (string | number | null)[]
  ) => {
    if (!expertise.length) return false;
    const matchPresentingProblemIds = getPresentingProblemIdsByExpertiseNames(expertise);
    return presentingProblems.some((p) => matchPresentingProblemIds.includes(p));
  };

  const getNewMatchesEndIndex = (newLength: number): number => {
    if (newLength < MATCHES_ADDEND) return newLength;
    return MATCHES_ADDEND;
  };

  const lazyLoad = (matchesLength: number, matchesEndIndex: number, matchesType: MatchesType) => {
    matchesType === MatchesType.FILTERED
      ? setIsLazyLoadingFilteredMatches(true)
      : setIsLazyLoadingRestOfMatches(true);
    setTimeout(() => {
      const newAddend =
        matchesLength - matchesEndIndex < MATCHES_ADDEND
          ? matchesLength
          : MATCHES_ADDEND + matchesEndIndex;

      if (matchesType === MatchesType.FILTERED) {
        setFilteredMatchesEndIndex(newAddend);
        setIsLazyLoadingFilteredMatches(false);
      } else {
        setRestOfMatchesEndIndex(newAddend);
        setIsLazyLoadingRestOfMatches(false);
      }
    }, LAZY_LOAD_TIME_MS);
  };

  useEffect(() => {
    const tempFilteredMatches: Match[] = [];
    const tempRestOfMatches: Match[] = [];
    matchesRef.current?.scrollTo({ top: 0 });
    if (!loadingMatches) {
      const presentingProblems = clientMatchPresentingProblems.map((p) => Number(p.id));
      matches.forEach((match) => {
        const expertiseMatch = isExpertiseMatch(match.allFieldsOfExpertise, presentingProblems);
        const genderMatch =
          clientMatchGenderPreference === PROVIDER_GENDERS.ANY ||
          clientMatchGenderPreference === String(match.gender);

        if (!presentingProblems.length && genderMatch) {
          tempFilteredMatches.push(match);
        } else if (presentingProblems.length && expertiseMatch) {
          genderMatch ? tempFilteredMatches.push(match) : tempRestOfMatches.push(match);
        } else {
          tempRestOfMatches.push(match);
        }
      });
      const newFilteredMatchesEndIndex = getNewMatchesEndIndex(tempFilteredMatches.length);
      const newRestOfMatchesEndIndex = getNewMatchesEndIndex(tempRestOfMatches.length);
      setFilteredMatches(tempFilteredMatches);
      setRestOfMatches(tempRestOfMatches);
      setFilteredMatchesEndIndex(newFilteredMatchesEndIndex);
      setRestOfMatchesEndIndex(newRestOfMatchesEndIndex);
    }
  }, [loadingMatches, matches, clientMatchGenderPreference, clientMatchPresentingProblems]);

  useEffect(
    () => setFilteredMatchesToShow(filteredMatches.slice(0, filteredMatchesEndIndex)),
    [filteredMatchesEndIndex, filteredMatches, setFilteredMatches]
  );

  useEffect(
    () => setRestOfMatchesToShow(restOfMatches.slice(0, restOfMatchesEndIndex)),
    [restOfMatchesEndIndex, restOfMatches, setRestOfMatchesToShow]
  );

  useEffect(() => {
    const therapistMatchesUnfiltered = matches.map((match) => match.id).join(', ');
    trackEvent('Meet Matched Therapists', {
      'Therapist ID': therapistMatchesUnfiltered,
      'Funnel Name': 'QuickMatch',
    });
  }, [matches]);

  const handleScroll = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const isAtBottom = scrollHeight - Math.abs(scrollTop) <= clientHeight + LAZY_LOAD_BOTTOM_OFFSET;
    // Check if scrolled to bottom of (filtered or rest of) matches
    if (
      isAtBottom &&
      filteredMatchesToShow.length < filteredMatches.length &&
      !isLazyLoadingFilteredMatches
    ) {
      lazyLoad(filteredMatches.length, filteredMatchesEndIndex, MatchesType.FILTERED);
    } else if (
      isAtBottom &&
      filteredMatchesToShow.length === filteredMatches.length &&
      restOfMatchesEndIndex < restOfMatches.length &&
      !isLazyLoadingRestOfMatches
    ) {
      lazyLoad(restOfMatches.length, restOfMatchesEndIndex, MatchesType.UNFILTERED);
    }
  };

  // Don't show other matches components until client has finished lazy loading or 0 filtered results
  const showRestOfScreen =
    filteredMatchesToShow.length === filteredMatches.length || filteredMatches.length === 0;

  return (
    <>
      <View
        align="center"
        onScroll={handleScroll}
        style={{
          height: window.innerHeight - footerOffset,
          overflowY: 'scroll',
          // Negative margin bottom prevents over-scrolling on footer/header
          marginBottom: -FOOTER_HEIGHT,
          overscrollBehavior: 'none',
          width: '100%',
        }}
        ref={matchesRef}
      >
        {filteredMatches.length > 0 && (
          <MeetYourMatchesHeader
            removeGenderFilter={removeGenderFilter}
            clientMatchGenderPreference={clientMatchGenderPreference}
            filteredMatchesDifference={restOfMatches.length}
            filteredMatchesLength={filteredMatches.length}
            isMobile={isMobile}
            usingInNetworkMatches={!!insurancePayer?.keyword}
            hideGenderToolTip={clientMatchPresentingProblems.length > 0}
          />
        )}
        {filteredMatches.length === 0 ? (
          <NoMatchesHeader as="h1" isMobile={isMobile}>
            It looks like no providers meeting your criteria are accepting new clients right now.
          </NoMatchesHeader>
        ) : (
          <View style={{ ...MATCHES_VIEW_STYLE, marginBottom: 26 }}>
            {filteredMatchesToShow.map((filteredMatch) => (
              <MatchTab
                key={filteredMatch.id}
                step={step}
                updateStep={updateStep}
                match={filteredMatch}
                flowId={flowId}
                clientCountry={clientCountry}
                presentingProblems={clientMatchPresentingProblems}
              />
            ))}
            {isLazyLoadingFilteredMatches && <LoadingMatchesSpinner />}
          </View>
        )}
        {showRestOfScreen && restOfMatches.length > 0 ? (
          <>
            <Separator />
            <View
              style={{
                ...MATCHES_VIEW_STYLE,
                paddingBottom: 15,
              }}
            >
              <OtherMatchesTitle as="h2" variant="largeDarkGrey" isMobile={isMobile}>
                <BoldSpan>{`Explore ${restOfMatches.length} other providers `}</BoldSpan>
                who might meet your needs.
              </OtherMatchesTitle>
              {restOfMatchesToShow.map((match) => (
                <MatchTab
                  key={match.id}
                  step={step}
                  updateStep={updateStep}
                  match={match}
                  flowId={flowId}
                  clientCountry={clientCountry}
                  presentingProblems={clientMatchPresentingProblems}
                />
              ))}
              {isLazyLoadingRestOfMatches && <LoadingMatchesSpinner />}
            </View>
          </>
        ) : null}
      </View>
      <FadeOut topOffset={-27} />
    </>
  );
};

export default Matches;
