import { states, countries } from '@talkspace/configs';
// qm-web expects these to be in a slightly different format then in the configs

const statesFormatted = Object.entries(states).map(([value, label]) => {
  return { value, label };
});

const countriesFormatted = Object.entries(countries)
  .map(([value, label]) => {
    return { value, label };
  })
  .filter(({ value }) => value !== 'US');

export { statesFormatted as states, countriesFormatted as countries };
