import { FunctionComponent } from 'react';
import Svg, { Path, G } from 'svgs';
import { useEmotionTheme } from '@talkspace/react-toolkit';
import { EmotionStyle } from '@/core/styled';

// TODO: move to react-toolkit
const Rx: FunctionComponent<{
  color?: string;
  height?: number;
  width?: number;
  style?: EmotionStyle;
}> = ({ color, width = 68, height = 68, style, ...otherProps }) => {
  const titleText = 'Prescription Rx';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      viewBox="0 0 68 68"
      fill="none"
      style={style}
      {...otherProps}
    >
      <G fillRule="evenodd" clipRule="evenodd">
        <Path
          d="M34.2 52.2c9.941 0 18-8.059 18-18s-8.059-18-18-18c-9.94 0-18 8.059-18 18s8.06 18 18 18z"
          fill={color || colors.patensBlue}
        />
        <Path
          d="M53.945 45.6c3.777-6.541 3.944-14.176 1.12-20.597A22.697 22.697 0 0045.6 14.455c-10.905-6.296-24.85-2.56-31.145 8.345-6.296 10.905-2.56 24.85 8.345 31.145a22.719 22.719 0 0016.428 2.494c5.984-1.355 11.408-5.106 14.717-10.839z"
          stroke={colors.permaTropicalRainForest}
          strokeWidth={3.6}
          strokeLinecap="round"
          strokeDasharray={133.2}
        />
        <Path
          d="M35.745 22.328c-1.08-.885-2.55-1.328-4.407-1.328H27l-.179.007a2.4 2.4 0 00-2.22 2.393v16.26l.007.156a1.62 1.62 0 003.232-.156v-6.672h1.392l6.383 7.65-4.782 5.62a.6.6 0 00.472.989l2.63-.065a.36.36 0 00.268-.13l3.537-4.274 3.532 4.273a.36.36 0 00.27.13l2.636.066a.6.6 0 00.473-.988l-4.902-5.782 4.357-5.032a.6.6 0 00-.454-.993H40.9a.36.36 0 00-.28.135l-2.879 3.59-4.652-5.319.333-.066a5.483 5.483 0 002.324-1.132c1.49-1.21 2.235-2.765 2.235-4.666 0-1.9-.745-3.456-2.235-4.666zm-7.905 1.75h3.336l.306.006c1.096.043 1.9.322 2.416.836.561.562.842 1.253.842 2.074 0 .82-.28 1.512-.842 2.074-.562.561-1.47.842-2.722.842H27.84v-5.832z"
          fill={colors.permaTropicalRainForest}
        />
      </G>
    </Svg>
  );
};
export default Rx;
