import { SessionModality, VideoCreditOffer, Currency } from 'ts-frontend/types';

import {
  getVisitTherapistSchedulerTrackingData,
  mixpanelSessionModalities,
  ScheduledBy,
} from 'ts-analytics/mixpanel/helpers';
import apiWrapper from '../../core/api/apiWrapper';

import {
  AskModalityPreference,
  EventProperties,
  EventValueTypes,
  Platforms,
  SelectModalityPreferenceParams,
} from './trackerTypes';

import {
  trackEvent as internalTracker,
  setTrackerUserID,
  setAlias,
  setSuperProperty,
  setPeople,
} from './eventTracker';

import ReactFrameService from '../reactFrame/ReactFrameService';

export { VWO } from './eventTracker';

export const getPlatform = (): Platforms => {
  if (ReactFrameService.isAndroid()) return 'Android';
  if (ReactFrameService.isiOS()) return 'iOS';
  return 'web';
};

export const trackEvent = async <T extends keyof EventValueTypes>(
  eventName: T,
  eventProperties: EventValueTypes[T]
) => {
  await new Promise<void>((resolve) =>
    internalTracker(eventName, eventProperties as EventProperties, ['mixpanel'], resolve)
  );
};

export const trackGTMEvent = async <T extends keyof EventValueTypes>(
  eventName: T,
  eventProperties: EventValueTypes[T]
) => {
  await new Promise<void>((resolve) =>
    internalTracker(eventName, eventProperties as EventProperties, ['GTM'], resolve)
  );
};

export const alias = (newID?: string, existingID?: string) => {
  setAlias(newID, existingID, ['mixpanel']);
};

export const identify = (userID: string | number) => {
  setTrackerUserID(userID);
};

export const setFlowSuperProperty = (flowId: string) => {
  setSuperProperty(
    {
      Platform: getPlatform(),
      'Page Name': 'Quickmatch Home',
      'Flow ID': flowId,
    },
    ['mixpanel']
  );
};

interface UpdateCoverageEventProps {
  providerAvailable: boolean;
  hasRemainingSessions: boolean;
}

export const trackUpdateCoverageEvent = (
  roomID: number,
  props?: Partial<UpdateCoverageEventProps>
) => {
  const { providerAvailable, hasRemainingSessions } = props || {};
  return new Promise<void>((resolve) =>
    internalTracker(
      'Confirm Update Coverage',
      {
        roomID,
        'Provider Available': !!providerAvailable,
        'Has Remaining Sessions': !!hasRemainingSessions,
      },
      ['mixpanel'],
      resolve
    )
  );
};

// NOOP trackers for stepWizard
export const trackWizardEvent = (action: string, eventCategory: string, eventProperties = {}) => {};
export const trackWizardExitEvent = (wizardName: string = '', clientID: number | null = null) => {};

export const setPeopleLogin = (userID: number) => {
  setPeople({
    'Last Login Platform': 'web',
    'Uses Web': true,
    'Talkspace User ID': userID,
  });
};

export const trackVisitTherapistScheduler = (therapistID: number) => {
  trackEvent(
    'Visit Therapist Scheduler',
    getVisitTherapistSchedulerTrackingData('QuickMatch', therapistID)
  );
};

export const trackSelectScheduleModality = (therapistID: number, roomID?: number) => {
  trackEvent('Select Schedule Modality', {
    scheduledBy: ScheduledBy.client,
    therapistID,
  });
};

export const trackSelectScheduleDuration = (
  modality: SessionModality,
  selectScheduleDurationMinutes?: number,
  roomID?: number,
  creditType?: VideoCreditOffer['type'],
  planID?: number
) => {
  trackEvent('Select Schedule Duration', {
    modality: mixpanelSessionModalities[modality],
    selectScheduleDurationMinutes,
    scheduledBy: ScheduledBy.client,
  });
};

// client-web mocks
export const trackContinueToCheckout = (
  eventCategory: string,
  planDisplayName: string,
  price: number,
  billingCycle: number,
  discountPercent: number,
  experiment?: object,
  experimentID?: string
) => {};

export const trackPlanExpendEvent = (
  eventCategory: string,
  offerID: number,
  planDisplayName: string
) => {};

export const trackPlanSelectedEvent = (
  eventCategory: string,
  planDisplayName: string,
  price: number,
  billingCycle: number,
  discountPercent: number,
  experiment?: object,
  experimentID?: string
) => {};

export const trackPurchaseEvent = (
  roomID: number,
  planID: number,
  offerID: number,
  requestedOfferID: number,
  eventCategory: string,
  currency: string,
  discountPercent: number,
  revenue: number,
  price: number,
  planDisplayName: string,
  billingCycle: number,
  isTestUser: boolean,
  couponCode?: string,
  partner?: string,
  experiment?: object,
  experimentID?: string,
  isFirstPurchase = false,
  successful = true,
  errors?: string[]
) => {};

export const trackSeeAvailableOffersEvent = (
  eventCategory: string,
  offerID: number,
  requestedOfferID: number,
  currency: Currency,
  planPrices: string[],
  topMostPlanPrice: string,
  Successful: boolean,
  errorMessage?: string,
  experimentID?: string,
  experiment?: object
) => {};

export const trackTalkspacePageView = (
  pageName: string,
  funnelName: string,
  eventProperties = {},
  anonymousUser = false
) => {};

export const trackVirtualPageView = (url: string) => {};

export const trackOpenCancellationPolicy = () => {
  trackEvent('Open Cancellation Policy', {
    'Funnel Name': 'B2B QuickMatch',
  });
};

export const trackAsyncSessionInfoNextClick = () => {
  trackEvent('Async Session Info Next Click', { sessionID: apiWrapper.currentUserID() });
};

export const trackAsyncSessionInfoCancellationPolicyClick = () => {
  trackEvent('Async Session Info Cancellation Policy Click', {
    sessionID: apiWrapper.currentUserID(),
  });
};

export const trackAskModalityPreferenceView = ({ preferenceOptions }: AskModalityPreference) => {
  trackEvent('Ask Modality Preference', { preferenceOptions });
};

export const trackSelectModalityPreference = (params: SelectModalityPreferenceParams) => {
  trackEvent('Select Modality Preference', params);
  if (!params.isLoggedInUser) trackGTMEvent('Selected Plan', {});
};

export const trackAsyncConfirmSessionClick = () => {
  trackEvent('Async Session Info Confirm Session Click', { sessionID: apiWrapper.currentUserID() });
};
