import apiWrapper from '../core/api/apiWrapper';
import appConfigs from '../utils/configs';

const { publicEndpoint } = appConfigs.endpoints;

type AdminConfig = 'stripe_link';

export const getPublicAdminOptionByName = (name: AdminConfig) =>
  apiWrapper.get(`${publicEndpoint}public/v1/get-admin-config-value?key=${name}`);

export const getPublicAdminFlagValue = async (flagName: AdminConfig): Promise<boolean> =>
  getPublicAdminOptionByName(flagName);

export const getUseStripeLinkFlagValue = async (): Promise<boolean> =>
  getPublicAdminFlagValue('stripe_link');
