import {
  Button,
  ExtraHuge,
  Standard,
  View,
  Modal,
  useWindowWidthState,
  useUniqueID,
} from '@talkspace/react-toolkit';

interface PageOverlayProps {
  title: string;
  content: string | JSX.Element;
  isVisible: boolean;
  onDismiss: () => void;
}

const PageOverlay = ({ title, content, isVisible, onDismiss }: PageOverlayProps) => {
  const { isMobile } = useWindowWidthState();
  const modalTitleId = useUniqueID('modalTitleId');
  const modalContentId = useUniqueID('modalContentId');
  return (
    <Modal
      titleID={modalTitleId}
      isVisible={isVisible}
      underlayStyle={{
        alignItems: 'flex-end',
        overflow: 'hidden',
      }}
      dialogStyle={
        isMobile ? { width: '100%' } : { position: 'absolute', width: 500, height: '100%' }
      }
    >
      <View
        style={{
          backgroundColor: '#FFF',
          width: '100%',
          ...(isMobile
            ? { borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 20 }
            : { borderRadius: 10, padding: 80 }),
        }}
      >
        <ExtraHuge
          as="h1"
          id={modalTitleId}
          style={{
            textAlign: 'center',
            marginBottom: 30,
            marginLeft: 30,
            marginRight: 30,
            ...(isMobile ? { marginTop: 50 } : { marginTop: -10 }),
          }}
        >
          {title}
        </ExtraHuge>

        <Standard
          id={modalContentId}
          variant="standardDarkGrey"
          style={{ textAlign: 'center', marginBottom: 20 }}
        >
          {content}
        </Standard>

        <Button
          aria-describedby={modalContentId}
          style={{ marginTop: 25, width: '100%' }}
          onPress={onDismiss}
          dataQa="holidayMessageDismiss"
        >
          Okay
        </Button>
      </View>
    </Modal>
  );
};

export default PageOverlay;
