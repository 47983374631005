import { useEffect, useState } from 'react';
import useQueryPublicAdminConfigJson from './useQueryPublicAdminConfigJson';

const useMemberIDHint = (partnerID) => {
  const [memberIDHint, setMemberIDHint] = useState();
  const { data: memberIDHintsByPartner, isLoading: isLoadingMemberIDHints } =
    useQueryPublicAdminConfigJson('member_id_hints', true);

  useEffect(() => {
    let hint;
    if (!isLoadingMemberIDHints && memberIDHintsByPartner) {
      const memberIDHints = memberIDHintsByPartner[partnerID || ''];
      hint = memberIDHints?.find((h) => h.isPrimary)?.hint;
    }
    setMemberIDHint(hint);
  }, [isLoadingMemberIDHints, memberIDHintsByPartner, partnerID]);

  return memberIDHint;
};

export default useMemberIDHint;
