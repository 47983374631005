import {
  ImplicitBusinessHoursByDay,
  InsuranceEligibilityPayer,
  SurveyResult,
} from 'ts-frontend/types';
import { OptionType } from '@talkspace/react-toolkit';
import apiWrapper from '../core/api/apiWrapper';
import apiHelper, { apiHelperV4 } from './apiHelper';
import appConfigs from '../utils/configs';
import { AccountType, RoomType, PlanInfo } from '../Components/HomePage/types';
import { FlowConfig } from '@/Flows';

const QMEndpointWithAPIPath = apiHelper();
const QMEndpointWithAPIPathV4 = apiHelperV4();
const { TSEndpoint, publicEndpoint } = appConfigs.endpoints;

/**
 * Validate if params are objects
 *
 * @param objects
 * @return {undefined}
 */
const validateObjects = (...objects) => {
  if (objects.filter((type) => type && typeof type !== 'object').length) {
    throw new Error('Bad params');
  }
};

const removeStepIdsFlowIds = (data) => {
  validateObjects(data);
  let { quickmatchResponses } = data;
  if (quickmatchResponses) {
    quickmatchResponses = quickmatchResponses.map((response) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { stepId, flowId, ...responseContent } = response;
      return responseContent;
    });
    const processedData = { ...data, quickmatchResponses };
    return processedData;
  }
  return data;
};

const removeUserData = (data) => {
  const params = removeStepIdsFlowIds(data);
  const userDataProps = [
    'nickname',
    'password',
    'timezone',
    'attribution',
    'registerUrl',
    'redirectFrom',
  ];
  userDataProps.forEach((item) => {
    if (params[item] !== undefined) {
      delete params[item];
    }
  });
  return params;
};

export const getOffersAccessToken = (): Promise<OffersAccessToken> =>
  apiWrapper.get(`${QMEndpointWithAPIPath}session/access-offers`);

export const getSetupIntent = async ({
  planID,
  currency,
}: {
  planID?: number;
  currency?: string;
}) => {
  const data = await getOffersAccessToken();
  const { token } = data;

  const res = await apiWrapper.post(
    `${TSEndpoint}v2/payments/setup-intent`,
    { planID, currency },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

/**
 * Get Match Therapists
 *
 * @param data
 * @param config
 * @return {*}
 */
export const getMatchTherapists = (data = {}) =>
  apiWrapper.post(`${QMEndpointWithAPIPath}matches`, removeStepIdsFlowIds(data));

interface ImplicitBusinessHoursResponse {
  [key: string]: ImplicitBusinessHoursByDay;
}

/**
 * Get Match Therapists
 *
 * @param data
 * @param config
 * @return {*}
 */
export const getTherapistImplicitBusinessHours = async (
  data: {
    therapistIDs?: string;
    offset?: number;
  } = {}
): Promise<ImplicitBusinessHoursResponse> =>
  apiWrapper.get(
    `${QMEndpointWithAPIPath}implicitBusinessHours?therapistIDs=${data.therapistIDs}&offset=${data.offset}`,
    {}
  );

export const getAdminConfigValue = (key) =>
  apiWrapper.get(`${QMEndpointWithAPIPath}getAdminConfigOption?key=${key}`);

export const getPublicAdminConfigValue = (key: string) =>
  apiWrapper.get(`${publicEndpoint}public/v1/get-admin-config-value?key=${key}`);

export const getPublicAdminConfigJsonValue = (key: string, json?: boolean) =>
  apiWrapper.get(
    `${publicEndpoint}public/v1/get-admin-config-value?key=${key}${json ? '&json=true' : ''}`
  );

/**
 * Update login
 * (deprecated)
 * @param data
 * @param config
 * @return {Promise<*>}
 */
export const updateLogin = (userID, data, config = {}) =>
  apiWrapper.post(`${TSEndpoint}v2/registration/user/${userID}/basic-details`, data, config);

/**
 * Update login with phone data and sms marketing agreement
 * @param data
 * @param config
 * @return {Promise<*>}
 */
export const updateLoginWithPhone = (userID, data, config = {}) =>
  apiWrapper.post(`${TSEndpoint}v4/registration/user/${userID}/basic-details`, data, config);

/**
 * Update login
 *
 * @param data
 * @param config
 * @return {Promise<*>}
 */
export const updateLoginCognito = (userID, data, config = {}) =>
  apiWrapper.post(`${TSEndpoint}v3/registration/user/${userID}/basic-details`, data, config);

/**
 * Register user with voucher
 *
 * @param data
 * @param config
 * @return {Promise<*>}
 */
export const registerUserWithVoucher = (data = {}) =>
  apiWrapper.post(`${QMEndpointWithAPIPath}registerWithVoucher`, removeStepIdsFlowIds(data));

/**
 * Register cognito user with voucher
 *
 * @param data
 * @param config
 * @return {Promise<*>}
 */
export const registerUserWithVoucherCognito = (data = {}) =>
  apiWrapper.post(`${QMEndpointWithAPIPathV4}registerWithVoucher`, removeStepIdsFlowIds(data));

/**
 * Register user with copay voucher
 *
 * @param data
 * @param config
 * @return {Promise<*>}
 */
export const registerUserWithCoPayVoucher = (data = {}) =>
  apiWrapper.post(`${QMEndpointWithAPIPath}registerWithCoPayVoucher`, removeStepIdsFlowIds(data));

/**
 * Register cognito user with copay voucher
 *
 * @param data
 * @param config
 * @return {Promise<*>}
 */
export const registerUserWithCoPayVoucherCognito = (data = {}) =>
  apiWrapper.post(`${QMEndpointWithAPIPathV4}registerWithCoPayVoucher`, removeStepIdsFlowIds(data));

/**
 * Register user with payment
 *
 * @param data
 * @param config
 * @return {Promise<*>}
 */
export const registerUserWithPayment = (data = {}) =>
  apiWrapper.post(`${QMEndpointWithAPIPath}processPaymentAndRegister`, removeStepIdsFlowIds(data));

/**
 * Register cognito user with payment
 *
 * @param data
 * @param config
 * @return {Promise<*>}
 */
export const registerUserWithPaymentCognito = (data = {}) =>
  apiWrapper.post(
    `${QMEndpointWithAPIPathV4}processPaymentAndRegister`,
    removeStepIdsFlowIds(data)
  );

/**
 * Register user without payment
 *
 * @param data
 * @param config
 * @return {Promise<*>}
 */
export const registerUserWithoutPayment = (data = {}) =>
  apiWrapper.post(`${QMEndpointWithAPIPath}registerWithoutPayment`, removeStepIdsFlowIds(data));

/**
 * Register cognito user without payment
 *
 * @param data
 * @param config
 * @return {Promise<*>}
 */
export const registerUserWithoutPaymentCognito = (data = {}) =>
  apiWrapper.post(`${QMEndpointWithAPIPathV4}registerWithoutPayment`, removeStepIdsFlowIds(data));

/**
 * Register user with BH Copay
 *
 * @param data
 * @return {Promise<*>}
 */
export const registerUserWithBHCopay = (data = {}) =>
  apiWrapper.post(`${QMEndpointWithAPIPath}registerWithCopay`, removeStepIdsFlowIds(data));

/**
 * Register cognito user with BH Copay
 *
 * @param data
 * @return {Promise<*>}
 */
export const registerUserWithBHCopayCognito = (data = {}) =>
  apiWrapper.post(`${QMEndpointWithAPIPathV4}registerWithCopay`, removeStepIdsFlowIds(data));

/**
 * get registered user info needed to pre-fill account activation form
 *
 * @param userId
 * @return {Promise<*>}
 */
export const getRegisteredUserAccountActivationInfo = (userID: number) =>
  apiWrapper.get(`${QMEndpointWithAPIPathV4}registeredUser/${userID}`).then((res) => res.data);

export const createRoom = (data = {}) =>
  apiWrapper.post(`${QMEndpointWithAPIPath}room/createRoom`, removeUserData(data));

export const saveSession = ({ qmState, stepId, flowId, flowVersion = 0 }) =>
  apiWrapper.post(`${QMEndpointWithAPIPath}session/set`, {
    qmState,
    qmFlowID: flowId,
    qmFlowStep: stepId,
    qmFlowVersion: flowVersion,
  });

export interface DispatcherGoToResponse {
  goto: 'NOT_VERIFIED' | 'EMAIL_VERIFICATION' | 'QM_FLOW';
  params: {
    qmFlowID?: number;
    accessCode?: string;
    accessCodeType?: AccessCodeType;
    isMismatchingService?: boolean;
    allowedModalities?: Array<AllowedModality>;
    totalSessions?: number;
    accountType?: AccountType | null;
  };
}

export const checkEligibility = (data: {
  email: string;
  organization: string;
  serviceType: string;
}) =>
  apiWrapper.post(`${QMEndpointWithAPIPathV4}dispatcher/check-eligibility`, {
    email: data.email,
    organization: data.organization.replace(/[^a-z0-9A-Z]/gim, '').toLowerCase(),
    serviceType: data.serviceType,
  }) as Promise<DispatcherGoToResponse>;

export const verifyEmailCode = (data: { pinCode: number }) =>
  apiWrapper.post(`${QMEndpointWithAPIPath}dispatcher/verify-email-code`, {
    pinCode: data.pinCode,
  }) as Promise<DispatcherGoToResponse>;

export const resendCode = (data: { email: string }) =>
  apiWrapper.post(`${QMEndpointWithAPIPath}dispatcher/resend-code`, {
    email: data.email,
  }) as Promise<{}>;

export const validateKeyword = (data: { email: string; keyword: string; serviceType: string }) =>
  apiWrapper.post(`${QMEndpointWithAPIPathV4}dispatcher/keyword`, {
    email: data.email,
    keyword: data.keyword,
    serviceType: data.serviceType,
  }) as Promise<DispatcherGoToResponse>;

export const getRedirectToB2CFlowID = () =>
  apiWrapper
    .get(`${QMEndpointWithAPIPath}get-quickmatch-config?key=qm_redirect_b2c_flow_id`)
    .then((data) => data as number);

interface OffersAccessToken {
  token: string;
  tltMinutes: number;
}

export const putAnonymousUserDetails = ({
  email,
  phone,
  consent,
  isGDPR,
  consentGDPRMarketing,
  ignoreIfNotNull,
}: {
  email?: string;
  phone?: string;
  consent?: boolean;
  isGDPR?: boolean;
  consentGDPRMarketing?: boolean;
  ignoreIfNotNull?: boolean;
}): Promise<boolean> =>
  apiWrapper
    .put(`${QMEndpointWithAPIPath}session/user`, {
      email,
      phoneNumber: phone,
      consent,
      isGDPR,
      consentGDPRMarketing,
      ignoreIfNotNull,
    })
    .then(() => true)
    .catch(() => false);

export const getSurveyResults = (
  surveyID: number
): Promise<{ error?: string; results: SurveyResult[] }> =>
  apiWrapper.get(`${QMEndpointWithAPIPath}surveys/${surveyID}/results`);

interface AccessCodeByLocationRequest {
  email: string;
  latitude: number;
  longitude: number;
  flowId: number;
}

interface AccessCodeByZipCodeRequest {
  email: string | undefined;
  zipCode: string;
  flowId: number;
  metadata?: Record<string, any>;
}

interface AccessCodeByLocationResponse {
  accessCode: string;
  accessCodeType: AccessCodeType;
  qmFlowID: number;
  accountType: AccountType;
  allowedModalities: Array<AllowedModality>;
  isMismatchingService?: boolean;
  isMultiSession?: boolean;
  totalSessions: number;
}

interface GetFlowResponse {
  flowID: number | null;
  flowConfig?: FlowConfig;
}

export interface QuickmatchRegistrationErrorResponse {
  response?: {
    data?: {
      errors?: QuickmatchRegistrationError[];
      error?: QuickmatchRegistrationError;
    };
  };
}

interface QuickmatchRegistrationError {
  code: string;
  message: string;
}

export const getAccessCodeByLocation = ({
  email,
  latitude,
  longitude,
  flowId,
}: AccessCodeByLocationRequest): Promise<AccessCodeByLocationResponse> =>
  apiWrapper.post(`${QMEndpointWithAPIPath}accessCodeBy/location`, {
    email,
    latitude,
    longitude,
    flowId,
  });

export const getAccessCodeByZipCode = ({
  email,
  zipCode,
  flowId,
  metadata,
}: AccessCodeByZipCodeRequest): Promise<AccessCodeByLocationResponse> =>
  apiWrapper.post(`${QMEndpointWithAPIPathV4}accessCodeBy/zipCode`, {
    email,
    zipCode,
    flowId,
    metadata,
  });

export const validateKeywordMetadata = ({
  email,
  keyword,
  flowId,
}: {
  email: string;
  keyword: string;
  flowId: number;
}) =>
  apiWrapper.post(`${QMEndpointWithAPIPath}accessCodeBy/keywordMetadata`, {
    email,
    keyword,
    flowId,
  });

export const getInsurancePayers = (): Promise<InsuranceEligibilityPayer[]> =>
  apiWrapper.get(`${QMEndpointWithAPIPath}insurance-payers`);

export const getFlowConfigByKeyword = (keyword: string): Promise<GetFlowResponse> =>
  apiWrapper.get(`${QMEndpointWithAPIPath}get-keyword-flow/${keyword}`).catch(() => {
    return { flowID: null, flowConfig: null };
  });

export const getFlowConfigByFlowID = (flowID: number): Promise<FlowConfig> =>
  apiWrapper.get(`${QMEndpointWithAPIPathV4}getFlowConfig/${flowID}`).catch(() => {
    return { maximumCost: null };
  });
export const getLocalSchoolList = (): Promise<Array<OptionType>> => apiWrapper.get(`/schools.json`);

interface ProviderRematchAvailabilityPayload {
  clientState: string | null;
  clientCountry: string;
  insurancePayerID?: number;
  roomType: RoomType;
  isB2B?: boolean;
  sessionID?: string;
  clientUserID?: number;
}
export const checkProviderRematchAvailability = (
  therapistID: number,
  payload: ProviderRematchAvailabilityPayload
): Promise<{ available: boolean }> =>
  apiWrapper.post(`${QMEndpointWithAPIPath}can-rematch`, {
    therapistID,
    ...payload,
  });
interface PostLanguagesForMatchingPayload {
  clientState?: string;
  clientCountry: string;
  roomType: string;
  planInfo: PlanInfo;
  clientAge?: number;
  clientDateOfBirth?: string;
}

export interface MatchingLanguage {
  id: number;
  name: string;
}
interface PostLanguagesForMatchingResponse {
  languages: MatchingLanguage[];
}

export const postLanguagesForMatching = (
  payload: PostLanguagesForMatchingPayload
): Promise<PostLanguagesForMatchingResponse> =>
  apiWrapper.post(`${QMEndpointWithAPIPathV4}languages-for-matching`, payload);

export interface SecondaryInsuranceMetadata {
  secondaryMemberID: string;
  secondaryPayerID: string;
}
export interface SaveSecondaryInsuranceParams {
  email?: string | null;
  keyword?: string | null;
  metadata: SecondaryInsuranceMetadata;
  accessCode?: string | null;
}

export const saveSecondaryInsurance = async (params: SaveSecondaryInsuranceParams): Promise<void> =>
  apiWrapper.post(`${apiHelperV4()}saveSecondaryInsurance`, params);
