import { QMFlow } from './types';
import {
  initFlowConfig,
  skipIfExperiment,
  skipToStepIfDoesntMeetAvailabilityPreferencesRequirements,
} from './utils';

export const FLOW_133_ISRAEL = 133;

const flow: QMFlow = {
  flowId: FLOW_133_ISRAEL,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    isTeen: true,
    version: 3,
  },
  steps: [
    {
      id: 1,
      prompt: 'What led you to seek help?',
      analyticsId: 54,
      response_category_id: 2,
      category: 'presentingProblems',
      internalTarget: 135,
      selfServe: {
        field: 'fieldsOfExpertise',
        type: Array,
      },
      heading1: null,
      heading2: '?מה גרם לכם לבקש סיוע',
      progressCurrent: 1,
      progressMax: 11,
      tip: 'findTheRightMatch',
    },
    {
      id: 135,
      category: 'memberAvailability',
      prompt: 'Select your preferred times for therapy',
      heading2: 'Select your preferred times for therapy',
      skipQuestionActions: [
        skipToStepIfDoesntMeetAvailabilityPreferencesRequirements(2),
        skipIfExperiment({
          experimentName: 'clientAvailabilityPreferencesExperiment',
          variant: 'control',
          nextStepId: 2,
          shouldTrack: true,
        }),
      ],
      buttonTarget: 2,
      progressCurrent: 1.5,
      progressMax: 11,
    },
    {
      id: 2,
      prompt: 'What is your age?',
      analyticsId: 61,
      response_category_id: 5,
      category: 'dateOfBirth',
      heading2: '?מה תאריך הלידה שלך',
      heading3: 'אנחנו שואלים כדי לספק לכם את השירות הטוב ביותר לגיל המתאים',
      hideBackButton: true,
      targets: {
        adult: 3,
        teen: 3,
        under13: 'https://helpnow.talkspace.com/under-13',
        under18GDPR: 42,
      },
    },
    {
      id: 42,
      category: 'notAvailableInYourRegion',
    },
    {
      id: 3,
      category: 'registerWithVoucher1',
      response_category_id: 9,
    },
  ],
};

export default flow;
