/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import { trackEvent } from '@/utils/analytics/events';
import { UseOnOrganizationPressOptions } from '../types';

export const useOnOrganizationPress = ({ updateStep, step }: UseOnOrganizationPressOptions) =>
  useCallback(() => {
    trackEvent('Select Insurance Provider', {
      'Insurance Provider': 'N/A',
      selection: 'Organizations',
    });

    updateStep(step.dispatcherStepTarget, {
      b2bForkResult: 'b2b',
      insurancePayer: undefined,
      insuranceEligibility: undefined,
      partnerFlowID: undefined,
      manualFlowID: undefined,
    });
  }, [updateStep, step]);
