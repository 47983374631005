import { FlowServiceKeywords, QMFlow, EligibilityType } from './types';
import { initFlowConfig } from './utils';
import { FLOW_90_SELECT_SERVICE } from './Flow90';
import appConfigs from '../utils/configs';

/** Aetna Trizetto (BH) similar to 59 */
export const FLOW_70_B2B_BH_TRIZETTO_AETNA = 70;

export const ServiceKeywords: FlowServiceKeywords = {
  trizetto: {
    psychotherapy: 'aetnabhtherapywithvideotest',
    psychiatry: 'aetnabhpsychiatrywithvideotest',
    therapyCouples: 'aetnabhcouples',
  },
  manual: {
    psychotherapy: 'aetnabhmanualtherapytest',
    psychiatry: 'aetnabhmanualpsychiatrytest',
    therapyCouples: 'aetnamanualbhcouples',
  },
};

const flow: QMFlow = {
  flowId: FLOW_70_B2B_BH_TRIZETTO_AETNA,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    isTeen: true,
    serviceKeywords: ServiceKeywords.trizetto,
    serviceKeywordsManual: ServiceKeywords.manual,
    isTrizetto: true,
    maximumCost: {
      psychotherapy: 179.99,
      psychiatry: 184.15,
    },
    eligibilityType: EligibilityType.trizetto,
  },
  steps: [
    {
      id: 1,
      analyticsId: 56,
      category: 'oneFormEligibility',
      response_category_id: 9,
      isGroupIdRequired: false,
      optionalOrganization: true,
      buttonTarget: 2,
      skipText: 'Continue without insurance',
      skipRedirectToFlow: FLOW_90_SELECT_SERVICE,
    },
    {
      id: 2,
      prompt: 'Validate Voucher',
      analyticsId: 58,
      category: 'validateVoucher',
      response_category_id: 8,
      heading1: null,
      heading2: "You're ready to begin.",
      heading3:
        'To get you the best care possible, we need to ask you a few questions that will help us find the right provider for your needs.',
      behavioralHealth: true,
      buttonText: 'Next',
      hideBackButton: true,
      supportEmail: appConfigs.supportEmailLinks.aetnaSupport,
      linkToFAQ: 'https://www.talkspace.com/faq/aetna',
    },
  ],
};

export default flow;
