/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import { SessionModality } from 'ts-frontend/types';
import { UseDefaultModalitySetterOptions } from '../types';

export const useDefaultModalitySetter = ({
  allowedModalities,
  availableModalities,
  userSelectedLiveSession,
  removeMessagingNudge,
  setModality,
}: UseDefaultModalitySetterOptions) => {
  useEffect(() => {
    if (!allowedModalities || !allowedModalities.length) {
      return;
    }

    let defaultModality: SessionModality | undefined;

    if (userSelectedLiveSession || removeMessagingNudge) {
      defaultModality = allowedModalities.find(
        ({ name: modalityName }) =>
          modalityName !== 'messaging' && availableModalities?.[modalityName]
      )?.name;
    } else {
      defaultModality = allowedModalities.find((currModality) => currModality.isDefault)?.name;
    }

    if (defaultModality) {
      setModality(defaultModality as SessionModality);
    }
  }, [
    allowedModalities,
    availableModalities,
    userSelectedLiveSession,
    removeMessagingNudge,
    setModality,
  ]);
};
