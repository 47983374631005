import styled from '@emotion/styled';
import { Small, Link, useEmotionTheme } from '@talkspace/react-toolkit';
import { isTeensFlow } from '../../Helpers/flowsHelper';
import AlertBox from '../AlertBox';

const StyledAlertBox = styled(AlertBox)({
  width: '100%',
  maxWidth: 335,
  alignSelf: 'center',
});

const DateOfBirthUnderageAlert = ({ flowId }: { flowId: number }) => {
  const { colors } = useEmotionTheme();
  return (
    <StyledAlertBox dataQa="underageError" style={{ marginTop: 0, marginBottom: 20, fontSize: 14 }}>
      Talkspace cannot provide service to individuals under the age of 13 at this time. If you or
      anyone you know are in a crisis or may be in danger, please use
      <Link
        dataQa="helpResourcesLink"
        style={{ textDecoration: 'none' }}
        roundedFocusStyle
        target="_blank"
        primaryColor={colors.permaErrorRed}
        href={`https://helpnow.talkspace.com/under-${isTeensFlow(flowId) ? '13' : '18'}`}
      >
        <Small variant="smallBolderGrey" inline style={{ color: colors.permaErrorRed }}>
          these resources
        </Small>
      </Link>
      to get immediate help.
    </StyledAlertBox>
  );
};

export default DateOfBirthUnderageAlert;
