import { Dispatch, SetStateAction, useEffect } from 'react';
import moment from 'moment-timezone';
import { sleep } from 'ts-frontend/helpers';
import {
  SESSION_STORAGE_MEMBER_DETAILS_KEY,
  getSessionStorageValuesFromJson,
} from 'ts-frontend/utils/sessionStorageService';
import {
  getPhoneNumberFromSessionStorage,
  getEmailFromSessionStorage,
  getCustomerInformationFromSessionStorage,
  getMarketingConsentFromSessionStorage,
} from '@/utils/registrationHelpers';
import { identify, trackEvent, VWO, trackGTMEvent, getPlatform } from '@/utils/analytics/events';
import { redirectToAccountActivation, RegistrationErrors } from '@/Helpers/registrationHelpers';
import {
  createRoom,
  registerUserWithBHCopay,
  registerUserWithBHCopayCognito,
} from '@/Helpers/apiService';
import { RegisterWithBHCopayProps } from './types';

// TODO: @Eric replace once reusable registration function has been added to registrationHelpers
interface RegisterWithBHCopayHandlerParams {
  token?: string;
  stepProps: RegisterWithBHCopayProps;
  setRequestPending: Dispatch<SetStateAction<boolean>>;
  setCheckoutErrorMessage: Dispatch<SetStateAction<string>>;
  finalFlowID: number;
  email: string;
  memberAvailabilityVariant: 'control' | 'treatment' | 'treatment-with-skip';
  collectReferralSourceOnSignUp?: boolean;
}

const registerWithBHCopayHandler = async ({
  token,
  stepProps,
  setRequestPending,
  setCheckoutErrorMessage,
  finalFlowID,
  email,
  memberAvailabilityVariant,
  collectReferralSourceOnSignUp,
}: RegisterWithBHCopayHandlerParams) => {
  const {
    country: phoneNumberCountryCodeFromSessionStorage,
    nationalNumber: phoneNumberFromSessionStorage,
  } = getPhoneNumberFromSessionStorage();
  const customerInformation = getCustomerInformationFromSessionStorage();
  const consent = getMarketingConsentFromSessionStorage();

  const { preRegisterBookingID } = getSessionStorageValuesFromJson(
    SESSION_STORAGE_MEMBER_DETAILS_KEY,
    ['preRegisterBookingID']
  );

  const params = {
    appointment: stepProps.appointment,
    email,
    therapistId: stepProps.providerId,
    updateCoverageRoomID: stepProps.updateCoverageRoomID,
    timezone: moment.tz.guess(),
    tokenizedCreditCard: token,
    attribution: {
      referrerUrl: window.location.href,
      registrationUrl: window.location.href,
    },
    quickmatchResponses: stepProps.responses.filter(
      (quickmatchResponse) =>
        quickmatchResponse.payfirst_step_prompt || quickmatchResponse.response_category_id
    ),
    presentingProblems: stepProps.clientMatchPresentingProblems,
    funnelVariation: stepProps.funnelVariation,
    preRegisterBookingID,
    insuranceCode: stepProps.insuranceCode || '',
    voucherCode: stepProps.cpPartnerCode,
    roomType: stepProps.roomType,
    clientDateOfBirth: stepProps.clientDateOfBirth,
    trizettoRequest: stepProps.trizettoRequest,
    gender: stepProps.clientGender || undefined,
    isPendingMatch: !!stepProps.isNoMatches,
    phoneNumberCountryCode: phoneNumberCountryCodeFromSessionStorage,
    phoneNumber: phoneNumberFromSessionStorage,
    flowID: finalFlowID,
    customerInformation,
    memberAvailability: stepProps.memberAvailability,
    memberAvailabilityVariant,
  };

  try {
    if (stepProps.isLoggedInUser) {
      const { phoneNumberCountryCode, phoneNumber, ...otherParams } = params;
      const createRoomResponse = await createRoom(otherParams);
      const { roomID, oldRoomHasSessions, sessionsCanceled, sessionsTransferred, therapistID } =
        createRoomResponse;
      stepProps.setRoomID(roomID);
      stepProps.setCreateRoomReturnProps({
        oldRoomHasSessions,
        sessionsCanceled,
        sessionsTransferred,
        therapistID,
      });
    } else {
      let userID;
      let authToken;
      let updateCredentialsJWTToken;
      if (stepProps.cognitoActive) {
        ({
          user: { id: userID, updateCredentialsJWTToken },
          authToken,
        } = await registerUserWithBHCopayCognito(params));
      } else {
        const registrationResponse = await registerUserWithBHCopay(params);
        ({
          user: { id: userID, updateCredentialsJWTToken },
        } = registrationResponse);
      }
      if (!updateCredentialsJWTToken || !userID) {
        setCheckoutErrorMessage(RegistrationErrors.generalError);
      }
      stepProps.setQMFlowDone();
      sessionStorage.clear();

      identify(userID);

      trackEvent('User Referral Source', {
        referralSource: stepProps.referralSource,
      });

      // GTM QM Payment Event
      trackGTMEvent('qmPaymentComplete', {
        result: 'success',
        platform: getPlatform(),
        tspl: params.roomType === 'psychiatryRoom' ? 2 : 1,
      });

      VWO.trackPurchaseGoal();
      VWO.trackQMPurchaseGoal();

      await sleep(1000);

      redirectToAccountActivation({
        authToken,
        updateCredentialsJWTToken,
        userID,
        isNoMatches: !!stepProps.isNoMatches,
        collectReferralSourceOnSignUp,
        smsConsent: consent,
      });
    }
  } catch (err) {
    const errors =
      err && err.response && err.response.data && err.response.data.errors
        ? err.response.data.errors
        : '';

    if (!Array.isArray(errors) || !errors.length || errors[0].code === 'paramsError') {
      setCheckoutErrorMessage(RegistrationErrors.generalError);
      setRequestPending(false);
      return;
    }
    // parse errors and set checkout error message (in the case of multiple errors will be set to the last one in the list)
    errors.forEach(({ code, message } = {}) => {
      if (code === 'serverError') {
        setCheckoutErrorMessage(message);
      }

      if (code === 'paymentError') {
        setCheckoutErrorMessage(message || 'Payment error');
      }
    });

    trackEvent('Failed to Purchase', {
      'Flow ID': finalFlowID,
      'Funnel Name': 'B2B QuickMatch',
      'Voucher Code': stepProps.cpPartnerCode || '',
      isFirstPurchase: true,
      isTestUser: true,
      Successful: false,
      'Error Codes': errors,
      type: 'B2B',
      'Pending Match': !!stepProps.isNoMatches,
      'User ID': undefined,
    });
  }
  setRequestPending(false);
};

const getCopayInDollars = (copayCents?: number) => (copayCents || 0) / 100;

export const getCopayCost = (copayCents: number, maxCost: number): number =>
  Number(Math.min(getCopayInDollars(copayCents), maxCost).toFixed(2));

export const useEmailFromSessionStorage = (setEmail: Dispatch<SetStateAction<string>>) => {
  useEffect(() => {
    const email = getEmailFromSessionStorage();
    setEmail(email);
  }, [setEmail]);
};

export default registerWithBHCopayHandler;
