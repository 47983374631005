import { useState, VoidFunctionComponent } from 'react';
import { Trans } from '@talkspace/i18n';
import {
  View,
  Button,
  Large,
  ExtraHuge,
  useWindowWidthState,
  useEmotionTheme,
  useContainerFocus,
  useUniqueID,
  ProfileCircle,
  NotepadCircle,
  HeadCircle,
} from '@talkspace/react-toolkit';
import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import useStringTranslation from '@talkspace/i18n/hooks/useStringTranslation';
import styled from '@/core/styled/index';
import MatchOverlay from '../MatchOverlay';
import { useBulletOneText, useOnContinue, useShouldDisplayModal } from './hooks';
import { ThanksForSharingProps } from './types';

const ICON_SIZE = 56;

const Styled = {
  Button: styled(Button)(({ theme: { colors } }) => {
    return {
      transition: 'all 200ms ease-in-out',
      '&:not([disabled]):hover': {
        backgroundColor: colors.permaTropicalRainForest,
      },
    };
  }),
  ListIcon: ({ icon: Icon }: { icon: React.FC<{ width?: number; height?: number }> }) => (
    <View>
      <Icon width={ICON_SIZE} height={ICON_SIZE} />
    </View>
  ),
  ListWrapper: styled(View)({
    alignItems: 'center',
    flexDirection: 'row',
    width: 300,
    height: 100,
  }),
  ListTextWrapper: styled(Large)({
    marginLeft: 24,
    textAlign: 'start',
  }),
};

const ThanksForSharing: VoidFunctionComponent<ThanksForSharingProps> = ({
  step,
  insurancePayer,
  updateStep,
  isNoMatches,
  setBlurParentContent,
  handleSetIsMatchesOverlayDismissed,
  isLoggedInUser,
  b2bForkResult,
  isMatchesOverlayDismissed,
  isB2BOutOfNetwork,
  isCouldNotBeVerified,
}) => {
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const { containerRef } = useContainerFocus();
  const titleId = useUniqueID('titleId');

  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');

  const [showMatchOverlay, setShowMatchOverlay] = useState(false);

  const handleOnPressSecureYourMatch = async () => {
    updateStep(step.zeroMatchTarget, {
      isNoMatches: true,
    });
  };

  const bulletOneText = useBulletOneText({
    b2bForkResult,
    isB2BOutOfNetwork,
    isCouldNotBeVerified,
  });

  const shouldDisplayModal = useShouldDisplayModal({
    step,
    isLoggedInUser,
    b2bForkResult,
    isB2BOutOfNetwork,
    isCouldNotBeVerified,
    isMatchesOverlayDismissed,
    insurancePayer,
  });

  const onContinue = useOnContinue({
    shouldDisplayModal,
    setShowMatchOverlay,
    handleOnPressSecureYourMatch,
  });

  const continueButtonText = useStringTranslation(
    'Continue',
    'quickmatch.common',
    'matchesStep.continueButtonText'
  );
  return (
    <View
      tabIndex={-1}
      align="center"
      ref={containerRef}
      aria-labelledby={titleId}
      style={{
        outline: 'none',
        textAlign: 'center',
        width: '100%',
        paddingTop: 48,
        ...(isMobile && { alignSelf: 'center' }),
      }}
    >
      <ExtraHuge id={titleId} as="h1">
        <Trans t={tQuickmatchCommon} i18nKey="matchesStep.thanksForSharingTitleText">
          You're off to a great start
        </Trans>
      </ExtraHuge>
      <Large style={{ width: 275, marginTop: 8 }}>
        <Trans t={tQuickmatchCommon} i18nKey="matchesStep.thanksForSharingSubtitleText">
          Only a few more steps before you can book a session:
        </Trans>
      </Large>
      <Styled.ListWrapper style={{ marginTop: 32 }}>
        <Styled.ListIcon icon={ProfileCircle} />
        <Styled.ListTextWrapper style={{ marginBottom: 15 }}>
          {bulletOneText}
        </Styled.ListTextWrapper>
      </Styled.ListWrapper>
      <Styled.ListWrapper>
        <Styled.ListIcon icon={NotepadCircle} />
        <Styled.ListTextWrapper>
          <Trans t={tQuickmatchCommon} i18nKey="matchesStep.remainingStepMedicalHistoryText">
            Answer some questions about your medical history and health
          </Trans>
        </Styled.ListTextWrapper>
      </Styled.ListWrapper>
      <Styled.ListWrapper>
        <Styled.ListIcon icon={HeadCircle} />
        <Styled.ListTextWrapper>
          <Trans t={tQuickmatchCommon} i18nKey="matchesStep.remainingStepWeTakeItFromThereText">
            We'll take it from there and find you a therapist that meets your needs
          </Trans>
        </Styled.ListTextWrapper>
      </Styled.ListWrapper>

      <Styled.Button
        dataQa="noMatchesSecureYourMatchButton"
        style={{ marginTop: 40, width: 335, fontWeight: 700 }}
        onPress={onContinue}
        text={continueButtonText}
        primaryColor={colors.green}
        roundedFocusStyle
      />

      {showMatchOverlay && !isMatchesOverlayDismissed && (
        <MatchOverlay
          campaignType={step.matchOverlayCampaignType}
          canMatchOverlayBeSkipped={!!step.canMatchOverlayBeSkipped}
          isNoMatches={isNoMatches}
          setBlurParentContent={setBlurParentContent}
          handleSetIsMatchesOverlayDismissed={handleSetIsMatchesOverlayDismissed}
          onDismiss={handleOnPressSecureYourMatch}
        />
      )}
    </View>
  );
};

export default ThanksForSharing;
