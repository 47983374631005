import Svg, { Path } from 'svgs';

function SeparatingThreeDots() {
  const title = 'three dots';
  return (
    <Svg width={38} height={18} viewBox="0 0 38 18" fill="none" title={title} aria-label={title}>
      <Path
        d="M19 3.396a1 1 0 011 1v.541a1 1 0 11-2 0v-.541a1 1 0 011-1zm0 3.791a1 1 0 011 1v1.084a1 1 0 11-2 0V8.187a1 1 0 011-1zm0 4.334a1 1 0 011 1v1.083a1 1 0 11-2 0v-1.083a1 1 0 011-1z"
        fill="#8A98AF"
      />
    </Svg>
  );
}

export default SeparatingThreeDots;
