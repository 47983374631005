import { FunctionComponent } from 'react';
import {
  COLORS,
  Button,
  Tiny,
  Huge,
  TextInputAuth,
  View,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { useInputChange } from '../../hooks/useInputChange';
import MagnifyingGlassTextBox from '../assets/MagnifyingGlassTextBox';

export interface EligibilityCodeProps {
  isError: boolean;
  orgName: string;
  onSubmit: (string) => void;
  isLoading: boolean;
}

const EligibilityCode: FunctionComponent<EligibilityCodeProps> = ({
  isError,
  orgName,
  onSubmit,
  isLoading,
}) => {
  const [input, handleInputChange] = useInputChange();
  const { isMobile } = useWindowWidthState();

  const accessCodeEmpty = input.length === 0;

  const onSubmitEditing = () => {
    if (accessCodeEmpty) return;
    onSubmit(input);
  };
  return (
    <View style={{ maxWidth: isMobile ? 320 : undefined }}>
      <Huge style={{ marginBottom: 48 }}>
        Enter the EAP code or keyword provided by your organization.
      </Huge>
      <TextInputAuth
        autoFocus
        wrapperStyle={{ width: '100%', borderColor: COLORS.periwinkleGrey }}
        inputStyle={{ width: '100%' }}
        placeholder="Enter Code"
        onChange={handleInputChange}
        value={input}
        onSubmitEditing={onSubmitEditing}
        errorMessage={
          <>
            {"We don't recognize this code. If you don't have a code, contact "}
            <Tiny inline style={{ fontWeight: orgName ? 700 : 400, color: COLORS.torchRed }}>
              {orgName || 'your organization'}
            </Tiny>
            {' for assistance.'}
          </>
        }
        shouldDisplayError={isError}
        errorStyle={{ textAlign: 'left' }}
      />
      {!accessCodeEmpty && (
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          style={{ marginTop: 41, backgroundColor: COLORS.green, width: '100%' }}
          onPress={() => {
            onSubmit(input);
          }}
        >
          Next
        </Button>
      )}
      <MagnifyingGlassTextBox
        style={{ marginTop: 40, alignSelf: 'center', width: '100%' }}
        text="Talk to your HR or benefits manager about how to retrieve your keyword or access code."
        headerText="Where do I find my code?"
      />
    </View>
  );
};

export default EligibilityCode;
