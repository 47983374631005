import {
  ZipcodeSearch,
  Button,
  ExtraHuge,
  Large,
  RHFInput,
  Small,
  spacing,
  SpacingView,
  View,
} from '@talkspace/react-toolkit';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { upsertSession } from 'ts-frontend/utils';
import { isGDPRCountry, storeGDPRProps } from 'ts-frontend/helpers/gdpr';
import { useHistory, useLocation } from '@/core/routerLib';
import styled from '@/core/styled';
import { getFormValuesFromSessionStorage } from '../../OneFormEligibility/util';
import { ageErrorCopy } from '../../../Helpers/ageHelper';
import DateOfBirthUnderageAlert from '../../DateOfBirthUnderageAlert';
import { getAccessCodeByZipCode } from '../../../Helpers/apiService';
import { useHandleServiceSelection } from '../ServiceSelection/hooks';
import { trackEvent } from '../../../utils/analytics/events';
import { DEFAULT_FLOWS, TeenZipcodeEligibilityStep, UpdateStep } from '../../../Flows';
import switchFlowHelper from '../../../Helpers/switchFlowHelper';
import teenZipcodeEligibilitySchema, {
  TeenZipcodeEligibilityFields,
  TeenZipcodeEligibilityYupContext,
} from './teenZipcodeEligibilitySchema';
import { RecoveredField } from '../../OneFormEligibility/types';
import { useHomePageActions } from '../../HomePage';

interface TeenZipcodeEligibilityProps {
  flowId: number;
  step: TeenZipcodeEligibilityStep;
  updateStep: UpdateStep;
  setClientAge: (clientAge: number) => void;
  setRecoveredField: <T extends RecoveredField>(field: T, data: RecoveredFields[T]) => void;
  isCreateRoomFlow: boolean;
  isReactivationFlow: boolean;
  isUpdateCoverageFlow: boolean;
  isMBHIneligibilityFlow: boolean;
  updateAndSave: (state: Partial<RecoveredSessionState>) => void;
}

const StyledContainer = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      height: '100%',
      width: '100%',
      margin: 'auto',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxWidth: 375,
      minHeight: isMobile ? 'calc(100vh - 260px)' : undefined,
    };
  }
);

const StyledForm = styled.form({
  flex: 1,
  display: 'flex',
  minWidth: 335,
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'space-between',
});

const TeenZipcodeEligibility = ({
  updateStep,
  step,
  flowId,
  updateAndSave,
  setClientAge,
  setRecoveredField,
  isCreateRoomFlow,
  isReactivationFlow,
  isUpdateCoverageFlow,
  isMBHIneligibilityFlow,
}: TeenZipcodeEligibilityProps) => {
  const location = useLocation();
  const history = useHistory();

  const { setHomePageState } = useHomePageActions();
  const handleServiceSelection = useHandleServiceSelection({
    isCreateRoomFlow,
    isReactivationFlow,
    isUpdateCoverageFlow,
    isMBHIneligibilityFlow,
    updateAndSave,
  });
  const methods = useForm<TeenZipcodeEligibilityFields, TeenZipcodeEligibilityYupContext>({
    resolver: yupResolver(teenZipcodeEligibilitySchema),
    context: {
      flowId,
    },
    defaultValues: {
      age: undefined,
      city: '',
      zipcode: '',
      country: '',
      clientState: '',
      // TODO: (Future, optional) Use a new version of this function, as this one is tied to OneFormEligibility.
      // Currently only recovers date of birth, missing address and other fields.
      ...getFormValuesFromSessionStorage({}),
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = useCallback(
    async (formData: TeenZipcodeEligibilityFields) => {
      upsertSession('TSQM_BasicInformation', {
        age: formData.age,
        state: formData.state,
        zipCode: formData.zipcode,
        country: formData.country,
      });
      setRecoveredField('basicInformation', sessionStorage.getItem('TSQM_BasicInformation'));
      setClientAge(formData.age);

      if (formData.age >= 18) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('clientAge', String(formData.age));
        history.replace({ search: searchParams.toString() });
        trackEvent('Submit Teen Zip Code Eligibility', {
          isEligible: false,
          isTeen: false,
        });
        handleServiceSelection('psychotherapy', true);
        return;
      }

      const response = await getAccessCodeByZipCode({
        flowId,
        zipCode: formData.zipcode,
        email: undefined,
        metadata: {
          age: formData.age,
          country: formData.country,
          clientState: formData.state,
        },
      }).catch((error) => {
        return { error: error.message, status: error.response?.status };
      });

      const isError = 'error' in response;
      trackEvent('Submit Teen Zip Code Eligibility', {
        isEligible: isError ? false : !!response.accessCode,
        isTeen: true,
      });

      if (isError) {
        switchFlowHelper(DEFAULT_FLOWS.TEENS, history, updateAndSave);
        return;
      }
      const { accessCode, accountType, accessCodeType, allowedModalities, totalSessions } =
        response;

      updateStep(step.buttonTarget, {
        oneFormClientState: formData.state,
        invalidVoucher: !accessCode,
        voucher: accessCode,
        accessCode,
        accessCodeType,
        insuranceEligibility: undefined,
        allowedModalities,
        totalSessions,
        accountType,
      });
    },
    [
      setRecoveredField,
      setClientAge,
      flowId,
      updateStep,
      step.buttonTarget,
      location.search,
      history,
      handleServiceSelection,
      updateAndSave,
    ]
  );

  return (
    <StyledContainer>
      <FormProvider {...methods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <View justify="center" align="stretch">
            <SpacingView itemSpacing="space150" centerContent inheritJustify>
              <ExtraHuge variant="extraHuge">Check your eligibility</ExtraHuge>
              <Large variant="largeGrey950">
                To see if you’re eligible for free therapy please confirm your age and street
                address
              </Large>
            </SpacingView>
            <View flex={1} align="center" style={{ marginTop: spacing.space300 }}>
              <RHFInput
                containerStyle={{ alignSelf: 'center' }}
                fieldName="age"
                placeholder="Enter age"
                label="Age"
                isRequired
                inputType="number"
                min={1}
                max={100}
              />
              {errors.age?.message === ageErrorCopy(flowId) && (
                <DateOfBirthUnderageAlert flowId={flowId} />
              )}
              <ZipcodeSearch
                onSelect={(parsedAddress) => {
                  storeGDPRProps({ isGDPR: isGDPRCountry({ countryCode: parsedAddress.country }) });
                  setHomePageState({
                    clientCountry: parsedAddress.country,
                    clientState: parsedAddress.state,
                  });
                }}
              />
            </View>
          </View>
          <View align="center" style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 10 }}>
            <Small>
              Talkspace is committed to protecting your privacy and follows HIPAA, state and federal
              laws
            </Small>
            <Button
              stretch
              style={{ marginTop: 29 }}
              type="submit"
              text="Check eligibility"
              dataQa="teenZipcodeEligibilityContinueButton"
            />
          </View>
        </StyledForm>
      </FormProvider>
    </StyledContainer>
  );
};

export default TeenZipcodeEligibility;
