import { useCallback, useEffect, useState } from 'react';
import { createMemoryHistory, Router, useHistory } from '@/core/routerLib';
import { DispatcherInQMStep, UpdateStep } from '../../Flows/types';
import DispatcherContextProvider from '../../hooks/dispatcherContext';
import { DEFAULT_FLOWS } from '../../Flows';
import { HomePageState } from '../HomePage/types';
import DispatcherContainer from '../../containers/DispatcherContainer';

interface DispatcherInQMProps {
  step: DispatcherInQMStep;
  updateStep: UpdateStep;
  service: HomePageState['service'];
}

function useMemoryHistory() {
  const history = useHistory();
  const [memoryHistory] = useState(() => createMemoryHistory());

  useEffect(() => {
    // Go back on main router or hardware back should instead goBack on memory router unless memory router cannot go back.
    const unsubscribeHistory = history.block((_location, action) => {
      if (action === 'POP' && memoryHistory.canGo(-1) === true) {
        memoryHistory.goBack();
        return false;
      }
      // Returning undefined allows transition to take effect
      return undefined;
    });
    // Going back on memory router should go back in history router if reached end of memory history
    const unsubscribeMemoryHistory = memoryHistory.block((_location, action) => {
      if (action === 'POP' && memoryHistory.canGo(-1) === false) {
        history.goBack();
      }
      // Returning undefined allows transition to take effect
      return undefined;
    });
    return () => {
      unsubscribeHistory();
      unsubscribeMemoryHistory();
    };
  }, [history, memoryHistory]);

  return memoryHistory;
}

const DispatcherInQM = ({ step, updateStep, service }: DispatcherInQMProps) => {
  const memoryHistory = useMemoryHistory();
  const { internalTarget, initialDispatcherStep } = step;
  const [isLoading, setIsLoading] = useState(true);

  const goNextStep: GoToFlowIDInterceptFunction = useCallback(
    async ({
      goToFlowID: flowID,
      accessCodeType,
      accessCode,
      totalSessions,
      allowedModalities,
      accountType,
    }) => {
      if (flowID === DEFAULT_FLOWS.NON_ELIGIBLE) {
        await updateStep(internalTarget, {
          b2bForkResult: 'b2c',
          collectReferralSourceOnSignUp: true,
        });
        return;
      }
      await updateStep(internalTarget, {
        accessCodeType: accessCodeType || undefined,
        accessCode: accessCode || undefined,
        organizationFlowID: flowID || undefined,
        collectReferralSourceOnSignUp: false,
        allowedModalities: allowedModalities || undefined,
        totalSessions,
        accountType,
      });
    },
    [internalTarget, updateStep]
  );

  useEffect(() => {
    const search = new URLSearchParams();
    search.append('initialDispatcherStep', initialDispatcherStep);
    memoryHistory.replace({
      pathname: '/dispatcher/organization-name',
      hash: search.toString(),
    });
    setIsLoading(false);
  }, [initialDispatcherStep, memoryHistory]);

  if (isLoading) return null;

  return (
    <Router history={memoryHistory}>
      <DispatcherContextProvider
        serviceType={service || undefined}
        goToFlowIDIntercept={goNextStep}
      >
        <DispatcherContainer />
      </DispatcherContextProvider>
    </Router>
  );
};

export default DispatcherInQM;
