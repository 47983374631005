import * as React from 'react';
import {
  View,
  Input,
  Button,
  BaseButton,
  Standard,
  ExtraHuge,
  Large,
  useWindowWidthState,
  useEmotionTheme,
  useContainerFocus,
  useUniqueID,
} from '@talkspace/react-toolkit';
import { useHistory } from 'react-router';
import { useInputChange } from '../../hooks/useInputChange';
import styled, { Theme } from '../../core/styled';
import { trackEvent } from '../../utils/analytics/events';

export interface EligibilityOrgProps {
  orgName: string;
  onSubmit: (value: string) => void;
}

const wrappedInputStyles = (colors: Theme['colors']) => {
  return {
    containerStyle: { width: '100%', borderColor: colors.periwinkleGrey },
    inputStyle: { width: '100%' },
    labelStyle: { marginBottom: 0 },
  };
};

const Container = styled(View)({ '&:focus': { outline: 'none' } });

const EligibilityOrg: React.VFC<EligibilityOrgProps> = ({ orgName, onSubmit }) => {
  const history = useHistory();
  const [input, handleInputChange] = useInputChange(orgName);
  const { isMobile } = useWindowWidthState();
  const header1Id = useUniqueID('header1Id');
  const header2Id = useUniqueID('header2Id');

  const { colors } = useEmotionTheme();
  const { containerRef } = useContainerFocus();

  const orgNameEmpty = input.length === 0;

  const onSubmitEditing = () => {
    if (orgNameEmpty) return;
    trackEvent('Submit organization name', {
      'Funnel Name': 'QuickMatch',
    });
    onSubmit(input);
  };

  const onSecondaryButtonPress = () => {
    history.goBack();
  };

  return (
    <Container ref={containerRef} aria-labelledby={header1Id} tabIndex={-1}>
      <View style={{ marginBottom: 45, maxWidth: isMobile ? 320 : undefined }}>
        <ExtraHuge as="h1" style={{ marginBottom: 20 }}>
          Enter organization name
        </ExtraHuge>
        <Large as="h2" variant="largeDarkGrey" style={{ fontWeight: 400 }}>
          Enter the name of your employer or educational organization providing Talkspace as a
          benefit.
        </Large>
      </View>
      <View style={{ marginBottom: 40, maxWidth: isMobile ? 320 : undefined }}>
        <Input
          autoFocus
          dataQa="organizationInput"
          ariaRequired
          aria-describedby={header2Id}
          placeholder="Organization name"
          value={input}
          placeHolderStyle={{ color: colors.baliHaiGrey }}
          wrappedInputProps={{
            label: 'Organization name',
            ...wrappedInputStyles(colors),
          }}
          onChange={handleInputChange}
          onSubmitEditing={onSubmitEditing}
        />
      </View>
      {!orgNameEmpty && (
        <Button
          size="medium"
          style={{ width: '100%' }}
          roundedFocusStyle
          primaryColor={colors.green}
          onPress={(e) => {
            if (e) e.preventDefault();
            onSubmitEditing();
          }}
        >
          Next
        </Button>
      )}
      <BaseButton
        style={{ borderRadius: 5 }}
        onPress={onSecondaryButtonPress}
        roundedFocusStyle
        primaryColor={colors.green}
      >
        <Standard variant="standardDarkGrey" style={{ marginTop: 17, alignSelf: 'center' }}>
          Return
        </Standard>
      </BaseButton>
    </Container>
  );
};

export default EligibilityOrg;
