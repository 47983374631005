/* eslint-disable import/prefer-default-export */
import { useEffect, useMemo, useState, useRef } from 'react';
import { useLocation } from 'react-router';
import { UseRedirectorOptions } from '../types';

const AUTO_SKIP_PARAM = 'skipServiceSelection';

export const useRedirector = ({ handleServiceSelection, serviceType }: UseRedirectorOptions) => {
  const ref = useRef({
    isRedirecting: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (!ref.current.isRedirecting) {
      const params = new URLSearchParams(location.search);
      if (params.get(AUTO_SKIP_PARAM) === 'true' && serviceType) {
        handleServiceSelection(serviceType, true);
        ref.current.isRedirecting = true;
      } else {
        setIsLoading(false);
      }
    }
  }, [handleServiceSelection, location.search, serviceType]);

  return useMemo(() => {
    return {
      isLoading,
    };
  }, [isLoading]);
};
