/**
 * Flow: 140
 * Description: Teen Therapy
 */
import { QMFlow } from './types';
import { initFlowConfig } from './utils';
// eslint-disable-next-line import/no-cycle
import flow132 from './Flow132';

export const FLOW_140_TEEN_THERAPY = 140;

const flow: QMFlow = {
  steps: flow132.steps,
  flowId: FLOW_140_TEEN_THERAPY,
  flowConfig: {
    ...initFlowConfig,
    ...flow132.flowConfig,
    version: 1,
  },
};

export default flow;
