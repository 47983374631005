import { QMFlow, EligibilityType, OneFormConfigSetType } from './types';
import { initFlowConfig } from './utils';
import { FLOW_90_SELECT_SERVICE } from './Flow90';

export const FLOW_129_B2B_TEEN_POSTAL_CODE = 129;

const flow: QMFlow = {
  flowId: FLOW_129_B2B_TEEN_POSTAL_CODE,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    isTeen: true,
    eligibilityType: EligibilityType.zipCodeEligibility,
  },
  steps: [
    {
      id: 1,
      analyticsId: 56,
      category: 'oneFormEligibility',
      response_category_id: 9,
      oneFormConfigSet: OneFormConfigSetType.nycTeen,
      skipText: 'Continue without insurance',
      skipRedirectToFlow: FLOW_90_SELECT_SERVICE,
    },
  ],
};

export default flow;
