/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: @Eric remove above line
import { ChangeEvent } from 'react';
import { useWatch } from 'react-hook-form';
import RHFInput from '../RHFInput';
import { EmotionStyle } from '../../core/styled';

// Temporary fix until https://github.com/react-hook-form/react-hook-form/discussions/6665
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Path<T> = any;

interface RHFDateInputProps<
  TFieldValues extends Record<string, string>,
  FieldName extends Path<TFieldValues> = Path<TFieldValues>
> {
  fieldName: FieldName;
  label: string;
  dateFormat?: string;
  placeholder?: string;
  isRequired?: boolean;
  onChange?: (value: string) => void;
  containerStyle?: EmotionStyle;
  wrapperStyle?: EmotionStyle;
  inputStyle?: EmotionStyle;
  placeHolderStyle?: EmotionStyle;
  errorStyle?: EmotionStyle;
  tooltip?: string;
  isDisabled?: boolean;
}

const RHFDateInput = <TFieldValues extends Record<string, string>>(
  props: RHFDateInputProps<TFieldValues>
) => {
  const {
    fieldName,
    label,
    dateFormat = 'MM/dd/yyyy',
    placeholder = 'MM/DD/YYYY',
    isRequired,
    onChange,
    containerStyle,
    wrapperStyle,
    inputStyle,
    placeHolderStyle,
    errorStyle,
    tooltip,
    isDisabled,
  } = props;
  // we have to a explicitly pass a value prop due to how our MaskedInput component renders a psuedo-placeholder
  const dateValue = useWatch({ name: fieldName });

  const handleChangeDate = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };
  return (
    <RHFInput
      fieldName={fieldName}
      label={label}
      isRequired={isRequired}
      value={dateValue}
      maskType="date"
      dateFormat={dateFormat}
      placeholder={placeholder}
      onChange={handleChangeDate}
      containerStyle={containerStyle}
      wrapperStyle={wrapperStyle}
      inputStyle={inputStyle}
      placeHolderStyle={placeHolderStyle}
      errorStyle={errorStyle}
      tooltip={tooltip}
      isDisabled={isDisabled}
      readOnly={isDisabled}
    />
  );
};

export default RHFDateInput;
