import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { AppSource } from 'ts-frontend/types';
import moment from 'moment-timezone';
import { View, COLORS, Modal } from '@talkspace/react-toolkit';
import { StripeProvider } from 'stripe/stripeContext';
import { MemoryRouter, useHistory } from 'react-router-dom';
import {
  calculatePlanDiscount,
  isTestAccount,
  sleep,
  OUT_OF_POCKET_OPTION,
} from 'ts-frontend/helpers';
import { PromiseMessageOffersInfo, PromoFromBanner } from 'ts-promise-message';
import { HandleQuickmatchSubscribeData, RoomOffer } from 'offer';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { getVWOWidgetsHeight } from 'ts-analytics/VWO/helpers';
import { getUseStripeLinkFlagValue } from '../../Helpers/adminConfig';
import appConfigs from '../../utils/configs';
import {
  registerWithPayment,
  redirectToAccountActivation,
} from '../../Helpers/registrationHelpers';
import { getMarketingConsentFromSessionStorage } from '../../utils/registrationHelpers';
import { HomePageState, RoomType, GenderType } from '../HomePage/types';
import { trackEvent } from '../../utils/analytics/events';
import getParamByName from '../../utils/queryString';
import sessionStorage from '../../core/storage/sessionStorage';
import styled from '../../core/styled/styled';
import {
  FLOW_100_ELIGIBILITY_QUESTIONS_THERAPY,
  FLOW_200_CONSUMER_THERAPY_EXPERIMENT_HUB,
  FLOW_132_CONSUMER_THERAPY,
} from '../../Flows';
import useInsuranceEligibilityExperiment from '../../hooks/useInsuranceEligibilityExperiment';

const PROMO_BANNER_FLOWS = [
  FLOW_100_ELIGIBILITY_QUESTIONS_THERAPY,
  FLOW_200_CONSUMER_THERAPY_EXPERIMENT_HUB,
  FLOW_132_CONSUMER_THERAPY,
];

const Content = styled(View)({
  backgroundColor: COLORS.white,
  height: '100%',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
});

type OutOfPocketPromo =
  | {
      experimentActive: boolean;
      enabled?: boolean;
    }
  | undefined;

const REACT_APP_CDN_ENDPOINT = appConfigs.endpoints.cdnEndpoint;

interface RegisterOutOfPocketProps {
  flowId: HomePageState['flowId'];
  coupon: HomePageState['coupon'];
  offerID: number;
  couponInputMode?: 'default' | 'disabled' | 'hidden';
  setRoomID: (roomID: number) => void;
  setCreateRoomReturnProps: (props: {
    oldRoomHasSessions: boolean;
    sessionsCanceled: boolean;
    sessionsTransferred: boolean;
    therapistID: number;
  }) => void;
  responses: HomePageState['responses'];
  appointment: HomePageState['appointment'];
  therapistId: HomePageState['therapistId'];
  updateCoverageRoomID?: HomePageState['updateCoverageRoomID'];
  setDiscardMatches: (shouldDiscard: boolean) => void;
  therapistFirstName: HomePageState['therapistFirstName'];
  therapistLastName: HomePageState['therapistLastName'];
  therapistLicenses: HomePageState['therapistLicenses'];
  hideProviderInfo?: boolean;
  funnelVariation: HomePageState['funnelVariation'];
  isCreateRoomFlow: boolean;
  isReactivationFlow: boolean;
  isUpdateCoverageFlow: boolean;
  isMBHIneligibilityFlow: boolean;
  clientDateOfBirth: HomePageState['clientDateOfBirth'];
  previousStepsUntilMatches: number;
  clientMatchPresentingProblems: HomePageState['clientMatchPresentingProblems'];
  clientCountry: HomePageState['clientCountry'];
  clientState: HomePageState['clientState'];
  insuranceEligibility: HomePageState['insuranceEligibility'];
  roomType: RoomType | undefined;
  isNoMatches: boolean;
  clientGender?: GenderType;
  cognitoActive: boolean;
  insurancePayer: HomePageState['insurancePayer'];
  collectReferralSourceOnSignUp: HomePageState['collectReferralSourceOnSignUp'];
  isCouldNotBeVerified: boolean;
}

const RegisterOutOfPocket = (props: RegisterOutOfPocketProps) => {
  const {
    flowId,
    coupon,
    offerID: requestedOfferID,
    setRoomID,
    responses,
    appointment,
    therapistId,
    updateCoverageRoomID,
    setDiscardMatches,
    setCreateRoomReturnProps,
    therapistFirstName,
    therapistLastName,
    therapistLicenses,
    hideProviderInfo,
    funnelVariation,
    couponInputMode,
    isCreateRoomFlow,
    isReactivationFlow,
    isUpdateCoverageFlow,
    isMBHIneligibilityFlow,
    clientDateOfBirth,
    previousStepsUntilMatches,
    clientMatchPresentingProblems,
    clientCountry,
    clientState,
    insuranceEligibility: stateInsuranceEligibility,
    roomType,
    isNoMatches,
    clientGender,
    cognitoActive,
    insurancePayer,
    collectReferralSourceOnSignUp,
    isCouldNotBeVerified,
  } = props;

  const isLoggedInUser =
    isCreateRoomFlow || isReactivationFlow || isUpdateCoverageFlow || isMBHIneligibilityFlow;
  const historyLib = useHistory();
  const initialHistoryLength = useRef(historyLib.length);
  const insuranceEligibility = useInsuranceEligibilityExperiment(stateInsuranceEligibility);

  const couponCode = coupon || insuranceEligibility?.ineligiblePromoCouponCode;

  const billingCycleExperiment: OutOfPocketPromo = useFlags()?.billingCycleExperiment;
  const VWOWidgetsHeight = getVWOWidgetsHeight();

  const billingExperimentInfo = useMemo<PromoFromBanner | undefined>(() => {
    if (!PROMO_BANNER_FLOWS.includes(flowId)) {
      return undefined;
    }
    const hasVWOWidget = VWOWidgetsHeight !== 0;
    let promoCode: string | undefined;
    // Parse promo code from VWO banner
    if (
      hasVWOWidget &&
      !couponCode &&
      (isCouldNotBeVerified || !insurancePayer || insurancePayer.id === OUT_OF_POCKET_OPTION.id)
    ) {
      const VWOBar = document.querySelector<HTMLDivElement>('.vwo-notification-bar');
      if (VWOBar?.innerText) {
        const promoText = VWOBar.innerText;
        promoCode = /code (\w+)/gi.exec(promoText)?.[1];
      }
    }

    // Send to roomOfferContext
    if (promoCode || billingCycleExperiment?.experimentActive) {
      return {
        ...(promoCode && { promoCode, showOnOffersPage: 'displayed' }),
        ...(billingCycleExperiment?.experimentActive && {
          billingCycleExperimentActive: true,
          shownAdditionalBillingCycles: billingCycleExperiment.enabled
            ? 'show_additional'
            : 'not_show_additional',
        }),
      };
    }
    return undefined;
  }, [
    billingCycleExperiment,
    VWOWidgetsHeight,
    couponCode,
    insurancePayer,
    flowId,
    isCouldNotBeVerified,
  ]);

  const handleQuickmatchSubscribe = async (data: HandleQuickmatchSubscribeData) => {
    try {
      const { email, offerID, planId, promoCode, paymentToken, attribution } = data;
      const finalPromoCode =
        typeof promoCode === 'string' && promoCode.length ? promoCode.toUpperCase() : undefined;
      const isTestUser = isTestAccount(email);
      const { phone } = JSON.parse(sessionStorage.getItem('TSQM_BasicInformation') || '{}');
      const consent = getMarketingConsentFromSessionStorage();

      const response = await registerWithPayment(
        {
          payload: {
            // Data from CW
            email,
            planId,
            promoCode: finalPromoCode,
            tokenizedCreditCard: paymentToken || '',
            // Data from QM
            appointment,
            timezone: moment.tz.guess(),
            therapistId,
            updateCoverageRoomID,
            attribution: {
              referrerUrl: document.referrer,
              registrationUrl: window.location.href,
            },
            quickmatchResponses: responses.filter(
              (quickmatchResponse) =>
                quickmatchResponse.payfirst_step_prompt || quickmatchResponse.response_category_id
            ),
            presentingProblems: clientMatchPresentingProblems,
            funnelVariation,
            clientDateOfBirth,
            clientState,
            clientCountry,
            insuranceCode: insuranceEligibility?.insuranceCode || null,
            trizettoRequest: insuranceEligibility?.trizettoRequestId || null,
            roomType,
            gender: clientGender || undefined,
            isPendingMatch: !!isNoMatches,
            flowID: flowId,
            offerID,
          },
          analytics: {
            discountPercent: attribution.discountPercent,
            promoValue: attribution.promoValue,
            promoCode: finalPromoCode,
            plan: {
              id: planId,
              name: attribution.planName,
              billingFrequency: attribution.billingFrequency,
              price: attribution.price,
            },
            flowId,
            offerId: offerID,
            requestedOfferID: attribution.requestedOfferID,
            currency: attribution.currency,
          },
        },
        isCreateRoomFlow,
        isReactivationFlow,
        isUpdateCoverageFlow,
        isMBHIneligibilityFlow,
        cognitoActive,
        isTestUser
      );
      if (response.data) {
        // give client-web analytics a sec to report before closing
        await sleep(1000);

        // These "property `in` response.data" checks are used to prevent casting response.data as A | B
        if (isLoggedInUser && 'roomID' in response.data) {
          const { roomID, oldRoomHasSessions, sessionsCanceled, sessionsTransferred, therapistID } =
            response.data;
          setRoomID(roomID);
          setCreateRoomReturnProps({
            oldRoomHasSessions,
            sessionsCanceled,
            sessionsTransferred,
            therapistID,
          });
          // This check in particular could be just `else {}` but added the `if` to narrow down `response.data`'s type
        } else if ('userID' in response.data) {
          const { authToken, userID, updateCredentialsJWTToken } = response.data;
          redirectToAccountActivation({
            email,
            phone,
            userID,
            authToken,
            updateCredentialsJWTToken,
            isNoMatches: !!isNoMatches,
            collectReferralSourceOnSignUp,
            smsConsent: consent,
          });
        }
        return { success: true, errors: null };
      }
      const { errors } = response;
      const errorMessage = [errors?.serverError, errors?.stripeErrorMessage]
        .filter((x) => !!x)
        .join(',');
      return { success: false, errors: errorMessage };
    } catch (error) {
      return { success: false, errors: error.message };
    }
  };

  const getOffersInfo = useCallback(() => {
    const registrationInformation = JSON.parse(
      sessionStorage.getItem('TSQM_RegistrationInformation') || '{}'
    );

    const memberDetailInformation = JSON.parse(
      sessionStorage.getItem('TSQM_MemberDetails') || '{}'
    );
    let email: string | null = null;

    if (registrationInformation?.email) {
      ({ email } = registrationInformation);
    } else if (isCouldNotBeVerified && memberDetailInformation?.email) {
      ({ email } = memberDetailInformation);
    }

    const offersInfo: PromiseMessageOffersInfo = {
      registrationInfo: {
        email,
        isLoggedInUser,
      },
      therapistInfo: {
        firstName: therapistFirstName,
        lastName: therapistLastName,
        therapistID: therapistId,
        therapistLicenses: (therapistLicenses || []).map((license) => license.type),
        therapistImage: `${REACT_APP_CDN_ENDPOINT}images/application/therapist/440/${therapistId}.png`,
      },
      insuranceEligibility,
      promoFromBanner: billingExperimentInfo,
    };

    return offersInfo;
  }, [
    isLoggedInUser,
    therapistFirstName,
    therapistLastName,
    therapistId,
    therapistLicenses,
    insuranceEligibility,
    billingExperimentInfo,
    isCouldNotBeVerified,
  ]);

  useEffect(() => {
    // track insurance eligibility data
    if (insuranceEligibility) {
      const MAX_PLAN_PRICE = 99 * 4;
      const MIN_PLAN_PRICE = 65 * 4;

      const { isEligible, copayCents, coinsurancePercent, deductible } = insuranceEligibility;
      const insuranceEligibilityValue = isEligible
        ? `$${Math.floor(
            calculatePlanDiscount(MIN_PLAN_PRICE, copayCents, coinsurancePercent, deductible) / 4
          )}-$${Math.floor(
            calculatePlanDiscount(MAX_PLAN_PRICE, copayCents, coinsurancePercent, deductible) / 4
          )} off`
        : '';

      const eligibilityValue = isEligible ? insuranceEligibilityValue : 0;

      trackEvent('Insurance Coverage', {
        'Insurance Value': eligibilityValue,
      });
    }
  }, [insuranceEligibility]);

  const search = new URLSearchParams(window.location.search);
  const showPlans = search.get('show_plans');

  const [routeQueryString, setRouteQueryString] = useState(new URLSearchParams());
  const [routeQueryStringReady, setRouteQueryStringReady] = useState(false);
  const getQueryString = useCallback(async () => {
    const queryString = new URLSearchParams();
    queryString.set('source', 'quickmatch');
    queryString.set('flow', flowId.toString());
    queryString.set('userCountry', clientCountry || 'US');
    queryString.set('showCloseButton', 'false');

    if (!isLoggedInUser) {
      queryString.set('isEmailRequired', 'true');
    }
    if (couponCode) {
      queryString.set('coupon', couponCode);
      queryString.set('couponInputMode', 'disabled');
    } else if (insuranceEligibility?.insuranceCode) {
      queryString.set('couponInputMode', 'hidden');
    }
    if (hideProviderInfo) {
      queryString.set('hide_provider_info', 'true');
    }
    if (showPlans) {
      queryString.set('show_plans', showPlans);
    }
    if (couponInputMode) queryString.set('couponInputMode', couponInputMode);
    const utmMedium = getParamByName('utm_medium');
    if (utmMedium) {
      queryString.set('utm_medium', utmMedium);
    }
    if (clientState) {
      queryString.set('userState', clientState);
    }
    if (isNoMatches) {
      queryString.set('isNoMatches', 'true');
    }

    setRouteQueryString(queryString);
    setRouteQueryStringReady(true);
  }, [
    clientCountry,
    clientState,
    couponCode,
    couponInputMode,
    flowId,
    hideProviderInfo,
    insuranceEligibility?.insuranceCode,
    isLoggedInUser,
    isNoMatches,
    showPlans,
  ]);

  useEffect(() => {
    getQueryString();
  }, [getQueryString]);

  return (
    <StripeProvider getUseStripeLinkFlag={getUseStripeLinkFlagValue}>
      {routeQueryStringReady && (
        <Modal isVisible>
          <Content>
            <MemoryRouter
              initialEntries={[
                `/room-offer/0/offer/${requestedOfferID}?${routeQueryString.toString()}`,
              ]}
            >
              <View style={{ width: '100%' }}>
                <RoomOffer
                  handleQuickmatchSubscribe={handleQuickmatchSubscribe}
                  getOffersInfo={getOffersInfo}
                  source={AppSource.qm}
                  onChangeTherapist={() => {
                    setDiscardMatches(false);
                    // Using window.history because useHistory doesn't have an updated .length
                    // Calculate steps to go back depending on initial vs current history length, then go back extra steps until we reach matches
                    historyLib.go(
                      -(window.history.length - initialHistoryLength.current) -
                        previousStepsUntilMatches
                    );
                  }}
                />
              </View>
            </MemoryRouter>
          </Content>
        </Modal>
      )}
    </StripeProvider>
  );
};
export default RegisterOutOfPocket;
