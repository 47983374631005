import { FunctionComponent } from 'react';
import { RHFInput } from '@talkspace/react-toolkit';
import {
  OneFormEligibilityResponsiveFieldWrapper,
  OneFormEligibilityResponsiveFieldRow,
} from './OneFormEligibilityResponsiveFieldRow';
import { OneFormEligibilityFieldNames } from './types';

interface OneFormEligibilityFullNameProps {
  firstNameFieldName: OneFormEligibilityFieldNames;
  firstNameLabel: string;
  lastNameFieldName: OneFormEligibilityFieldNames;
  lastNameLabel: string;
  onlyFirstName?: boolean;
}

export const OneFormEligibilityFullName: FunctionComponent<OneFormEligibilityFullNameProps> = ({
  firstNameFieldName,
  firstNameLabel,
  lastNameFieldName,
  lastNameLabel,
  onlyFirstName,
}) => (
  <OneFormEligibilityResponsiveFieldRow>
    <OneFormEligibilityResponsiveFieldWrapper fullWidth={!!onlyFirstName}>
      <RHFInput fieldName={firstNameFieldName} label={firstNameLabel} isRequired />
    </OneFormEligibilityResponsiveFieldWrapper>
    {!onlyFirstName && (
      <OneFormEligibilityResponsiveFieldWrapper>
        <RHFInput fieldName={lastNameFieldName} label={lastNameLabel} isRequired />
      </OneFormEligibilityResponsiveFieldWrapper>
    )}
  </OneFormEligibilityResponsiveFieldRow>
);

export default OneFormEligibilityFullName;
