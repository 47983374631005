import { useWatch } from 'react-hook-form';
import { RHFSelect } from '@talkspace/react-toolkit';
import { QuickEligibilityProps } from './types';

import EligibilityForm, {
  AA_TOOLTIP_STYLE,
  AdditionalFieldsParams,
  Styled,
} from './EligibilityForm';
import StatusModal from './StatusModal';
import { useFormSaver } from './hooks';
import countriesHelper from '@/Helpers/countriesHelper';
import {
  DiscoveryConfiguration,
  DiscoveryServiceFormData,
  useDiscoveryService,
} from '@/hooks/useDiscoveryService';
import { useDiscoveryContinue } from './hooks/useDiscoveryContinue';
import { genderOptions } from './util';
import useQueryPublicAdminConfigJson from '@/hooks/useQueryPublicAdminConfigJson';

const DiscoveryEligibility = ({
  updateStep,
  step,
  insurancePayer,
  setReferralSource,
  referralSource,
  partnerFlowID,
}: QuickEligibilityProps) => {
  const { data: discoveryConfigurationData } = useQueryPublicAdminConfigJson(
    'trizetto_discovery_configuration',
    true
  );
  const discoveryConfiguration = discoveryConfigurationData as DiscoveryConfiguration;
  useFormSaver();

  const { result, done, errorType, canRetry, attempts, isSubmitting, submit } = useDiscoveryService(
    {
      // Discovery service sends a POST request and then few GET requests for status
      // discoveryConfiguration has the configuration for number of attempts to get the status
      // Don't change maxAttempts as client shouldn't manually retry POST requests to discovery
      maxAttempts: 1,
      discoveryConfiguration,
    }
  );

  const data = useWatch() as any as DiscoveryServiceFormData;

  useDiscoveryContinue({
    result,
    data,
    done,
    errorType,
    updateStep,
    step,
    insurancePayer,
  });

  const statusModalIsVisible = (isSubmitting || (attempts > 0 && canRetry)) && !done;
  const shouldRetry = attempts > 0 && !isSubmitting && canRetry;

  const handleOnSubmit = (formData: DiscoveryServiceFormData) => {
    const parsedFormData = JSON.parse(JSON.stringify(formData));
    parsedFormData.gediPayerID = insurancePayer?.value;

    const { state: clientState, country } = countriesHelper.getStateAndCountryOverrides(
      parsedFormData.country,
      parsedFormData.state
    );

    parsedFormData.country = country;
    parsedFormData.state = clientState;

    return submit(parsedFormData);
  };

  const additionalFields = (props: AdditionalFieldsParams) => [
    <RHFSelect
      fieldName="gender"
      label="Sex listed on insurance"
      options={genderOptions}
      allowMobileSearch
      registerOptionValue
      placeholder="Select sex"
      tooltip="Selecting the sex listed with your insurance helps us identify your coverage."
      labelStyle={{ paddingBottom: 4 }}
      labelContainerStyle={{ alignItems: 'flex-end', marginBottom: 0 }}
      tooltipProps={{
        messageStyle: { marginRight: 10 },
        buttonStyle: AA_TOOLTIP_STYLE,
      }}
    />,
  ];

  const eligibilityForm = EligibilityForm({
    done,
    handleOnSubmit,
    isSubmitting,
    data,
    referralSource,
    setReferralSource,
    additionalFields,
    partnerFlowID,
  });

  if (result) {
    return null;
  }

  return (
    <Styled.View>
      <StatusModal
        visible={statusModalIsVisible}
        isCheckingCoverage={isSubmitting}
        showRetryButton={shouldRetry}
        onHandleRetry={() => submit(data)}
      />
      {eligibilityForm}
    </Styled.View>
  );
};

export default DiscoveryEligibility;
