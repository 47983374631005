import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

type offersAccessTokenResponse = {
  token?: string;
};

const getOffersAccessToken = async (): Promise<offersAccessTokenResponse> => {
  if (!apiHelper().quickMatchApiEndpoint) {
    return {};
  }
  const res = await apiWrapper.get(
    `${apiHelper().quickMatchApiEndpoint}api/v3/session/access-offers`
  );

  return res;
};

const accessTokenHelper = {
  getOffersAccessToken,
};

export default accessTokenHelper;
