import { initFlowConfig } from './utils';
import { EligibilityType, FlowServiceKeywords, QMFlow } from './types';
import { ServiceKeywords as Flow59Keywords } from './Flow59';
import { FLOW_90_SELECT_SERVICE } from './Flow90';
import appConfigs from '../utils/configs';

/** CIGNA Allegiance Trizetto (BH) */
export const FLOW_86_B2B_BH_TRIZETTO_CIGNA_ALLEGIANCE = 86;

export const ServiceKeywords: FlowServiceKeywords = {
  trizetto: {
    psychotherapy: 'allegiancebhwithvideo',
    therapyCouples: 'allegiancebhcouples',
    psychiatry: 'allegiancepsychiatry',
  },
  manual: {
    ...Flow59Keywords.manual,
  },
};

const flow: QMFlow = {
  flowId: FLOW_86_B2B_BH_TRIZETTO_CIGNA_ALLEGIANCE,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    isTeen: true,
    serviceKeywords: ServiceKeywords.trizetto,
    serviceKeywordsManual: ServiceKeywords.manual,
    isTrizetto: true,
    maximumCost: {
      psychotherapy: 125,
      psychiatry: 182,
    },
    eligibilityType: EligibilityType.trizetto,
  },
  steps: [
    {
      id: 1,
      prompt: 'Member Details',
      analyticsId: 56,
      category: 'oneFormEligibility',
      response_category_id: 9,
      isGroupIdRequired: false,
      removeOrganization: true,
      buttonTarget: 2,
      skipText: 'Continue without insurance',
      skipRedirectToFlow: FLOW_90_SELECT_SERVICE,
    },
    {
      id: 2,
      prompt: 'Validate Voucher',
      analyticsId: 58,
      category: 'validateVoucher',
      response_category_id: 8,
      heading1: null,
      heading2: "You're ready to begin.",
      heading3:
        'To get you the best care possible, we need to ask you a few questions that will help us find the right provider for your needs.',
      behavioralHealth: true,
      buttonText: 'Next',
      hideBackButton: true,
      supportEmail: appConfigs.supportEmailLinks.partnersSupport,
      linkToFAQ: 'https://www.talkspace.com/faq/bh',
    },
  ],
};

export default flow;
