/* eslint-disable @typescript-eslint/no-empty-function */
import { useState } from 'react';
import * as React from 'react';
import camelCase from 'lodash/camelCase';
import View from '../View';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';
import AnimatedError from '../AnimatedError';
import Button from '../Button';
import Input from '../Input';
import Image from '../Image';
import Small from '../Typography/Small';
import CameraIcon from '../../assets/CameraIcon';

const MAX_UPLOAD_SIZE_MB = 10;

interface Props {
  title: string;
  width?: number;
  height?: number;
  onUpload: (file: File) => void;
  onDelete: () => void;
  style?: EmotionStyle;
}
const UploadImage = ({
  title,
  width = 160,
  height = 86,
  onUpload,
  onDelete,
  style = {},
}: Props) => {
  const { colors } = useEmotionTheme();
  const [image, setImage] = useState<{ url: string; raw: File }>();
  const [error, setError] = useState<string>();

  const onImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    if (e?.target?.files?.length) {
      const file = e.target.files[0];
      const sizeMb = file.size / 1024 / 1024;
      if (sizeMb < MAX_UPLOAD_SIZE_MB) {
        setImage({
          url: URL.createObjectURL(file),
          raw: file,
        });
        onUpload(file);
      } else {
        setError(`Maximum size ${MAX_UPLOAD_SIZE_MB}MB`);
      }
    }
  };

  const onImageDelete = () => {
    if (image) {
      URL.revokeObjectURL(image.url);
      setImage(undefined);
      onDelete();
    }
  };
  return (
    <View style={{ width, ...style }}>
      <View
        align="center"
        justify="center"
        style={{
          borderRadius: 8,
          border: `1px ${image ? 'solid' : 'dashed'} ${error ? colors.red : colors.lightPurple}`,
          position: 'relative',
        }}
      >
        <View align="center" justify="center" style={{ width, height }}>
          {!image && (
            <>
              <CameraIcon />
              <Small style={{ marginTop: 4, fontSize: 14, fontWeight: 400 }}>{title}</Small>
              <Input
                dataQa={camelCase(`upload ${title}`)}
                type="file"
                accept="image/*"
                onChange={onImageUpload}
                style={{
                  width,
                  height,
                  opacity: 0,
                  position: 'absolute',
                  cursor: 'pointer',
                }}
              />
            </>
          )}
          {image && (
            <Image
              source={image.url}
              alt={`${title} preview`}
              style={{ maxHeight: height, maxWidth: width, position: 'absolute' }}
            />
          )}
        </View>
      </View>

      <View row align="center" justify="space-between" style={{ marginTop: 4, height: 20 }}>
        {image && (
          <>
            <Small>{title}</Small>
            <Button
              dataQa={camelCase(`delete ${title}`)}
              onPress={onImageDelete}
              style={{
                width: 50,
                margin: 0,
                backgroundColor: 'transparent',
                color: colors.permaTalkspaceDarkGreen,
                fontWeight: 700,
                fontSize: 14,
              }}
            >
              Delete
            </Button>
          </>
        )}
        {error && <AnimatedError shouldAnimate={!!error}>{error}</AnimatedError>}
      </View>
    </View>
  );
};

export default UploadImage;
