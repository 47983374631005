/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import { LDFlags, ServiceType } from 'ts-frontend/types';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { getLDExperimentEventInformation } from 'launchDarkly/util';
import { getQMFunnelName } from '@/Helpers/flowsHelper';
import switchFlowHelper from '@/Helpers/switchFlowHelper';
import {
  FLOW_200_CONSUMER_THERAPY_EXPERIMENT_HUB,
  FLOW_200_EXPERIMENTS,
  FLOW_132_CONSUMER_THERAPY,
} from '@/Flows';
import { trackEvent } from '@/utils/analytics/events';
import { useAvailableFlowsServiceTypeHash } from './useAvailableFlowsServiceTypeHash';
import { useChangeServiceTypeInLD } from './useChangeServiceTypeInLD';
import { useHistory } from '@/core/routerLib';

const trackResponse = (
  service: ServiceType,
  isCreateRoomFlow: boolean,
  isReactivationFlow: boolean,
  isUpdateCoverageFlow: boolean,
  isMBHIneligibilityFlow: boolean
) => {
  trackEvent('Answer Questionnaire', {
    Question: 'selectService',
    Response: service,
    // For backwards compatibility tracking
    Flow: 'Dispatcher',
    'Funnel Name': getQMFunnelName({
      isCreateRoomFlow,
      isReactivationFlow,
      isUpdateCoverageFlow,
      isMBHIneligibilityFlow,
    }),
  });
};

const trackExperimentSplit = (flowID: number, flags: LDFlags) => {
  if ([FLOW_200_CONSUMER_THERAPY_EXPERIMENT_HUB, FLOW_132_CONSUMER_THERAPY].includes(flowID)) {
    FLOW_200_EXPERIMENTS.forEach((experiment) => {
      const flag = flags[experiment];
      const trackingInfo = getLDExperimentEventInformation(flag, experiment);
      if (trackingInfo) {
        trackEvent('TS Experiment Split', {
          ...trackingInfo,
          finalFlowID: flowID,
        });
      }
    });
  }
};

export const useHandleServiceSelection = ({
  isCreateRoomFlow,
  isReactivationFlow,
  isUpdateCoverageFlow,
  isMBHIneligibilityFlow,
  updateAndSave,
}: {
  isCreateRoomFlow: boolean;
  isReactivationFlow: boolean;
  isUpdateCoverageFlow: boolean;
  isMBHIneligibilityFlow: boolean;
  updateAndSave: (state: Partial<RecoveredSessionState>) => void;
}) => {
  const history = useHistory();
  const availableFlowsHash = useAvailableFlowsServiceTypeHash();
  const changeServiceTypeInLD = useChangeServiceTypeInLD();
  const { setFlag, ...flags } = useFlags();

  const handleServiceSelection = useCallback(
    async (serviceType: ServiceType, skipTracking?: boolean) => {
      const selectedFlow = availableFlowsHash[serviceType];
      if (!selectedFlow) {
        throw new Error(`Unknown service type: ${serviceType}`);
      }

      trackExperimentSplit(selectedFlow.flowId, flags);

      switch (serviceType) {
        case 'psychotherapy':
          await changeServiceTypeInLD('psychotherapy', selectedFlow.flowId);
          if (!skipTracking)
            trackResponse(
              'psychotherapy',
              isCreateRoomFlow,
              isReactivationFlow,
              isUpdateCoverageFlow,
              isMBHIneligibilityFlow
            );
          switchFlowHelper(selectedFlow.flowId, history, updateAndSave, {
            step: 1,
          });
          break;

        case 'therapyCouples':
          await changeServiceTypeInLD('therapyCouples', selectedFlow.flowId);
          if (!skipTracking)
            trackResponse(
              'therapyCouples',
              isCreateRoomFlow,
              isReactivationFlow,
              isUpdateCoverageFlow,
              isMBHIneligibilityFlow
            );
          switchFlowHelper(selectedFlow.flowId, history, updateAndSave, {
            step: 1,
          });
          break;

        case 'therapyTeen':
          await changeServiceTypeInLD('therapyTeen', selectedFlow.flowId);
          if (!skipTracking)
            trackResponse(
              'therapyTeen',
              isCreateRoomFlow,
              isReactivationFlow,
              isUpdateCoverageFlow,
              isMBHIneligibilityFlow
            );
          switchFlowHelper(selectedFlow.flowId, history, updateAndSave, {
            step: 1,
          });
          break;

        case 'psychiatry':
          await changeServiceTypeInLD('psychiatry', selectedFlow.flowId);
          if (!skipTracking)
            trackResponse(
              'psychiatry',
              isCreateRoomFlow,
              isReactivationFlow,
              isUpdateCoverageFlow,
              isMBHIneligibilityFlow
            );
          switchFlowHelper(selectedFlow.flowId, history, updateAndSave, {
            step: 1,
          });
          break;

        default:
          throw new Error(`Unknown service type: ${serviceType}`);
      }
    },
    [
      availableFlowsHash,
      changeServiceTypeInLD,
      isCreateRoomFlow,
      isReactivationFlow,
      isUpdateCoverageFlow,
      isMBHIneligibilityFlow,
      history,
      updateAndSave,
      flags,
    ]
  );

  return handleServiceSelection;
};
