import { Styles } from 'react-select';
import { EmotionTheme, OptionType } from '@talkspace/react-toolkit';
import { OUT_OF_POCKET_OPTION } from 'ts-frontend/helpers';

// eslint-disable-next-line import/prefer-default-export
export const getCoverageVerificationStyles = (
  colors: EmotionTheme['colors']
): Styles<OptionType, boolean> => {
  return {
    input: (provided) => {
      return {
        ...provided,
        caretColor: colors.darkGreen,
      };
    },
    placeholder: (provided, { isFocused }) => {
      return {
        ...provided,
        paddingLeft: 2,
        color: colors.darkBlue,
        ...(isFocused && {
          color: colors.gray,
        }),
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        paddingLeft: 2,
      };
    },
    menuList: (provided) => {
      return {
        ...provided,
        maxHeight: 360,
      };
    },
    option: (provided, { data, isSelected, isFocused, selectProps }) => {
      const {
        value,
        isMulti,
        selectProps: { isMouseUser },
      } = selectProps;
      // isSelected seems to only be accurate for multi-select options, otherwise always evaluates to true after selection is made
      const showSelectedStyling = isMulti
        ? (isSelected && !isFocused) || (isSelected && isFocused && isMouseUser)
        : (data === value && !isFocused) || (data === value && isFocused && isMouseUser);
      return {
        ...provided,
        // overwrite active styles
        backgroundColor: 'transparent',
        color: showSelectedStyling ? colors.permaDarkRoyalBlue : colors.black,
        paddingTop: 12,
        paddingBottom: 12,
        ...(data?.isDisabled && {
          outline: 'none !important',
          boxShadow: 'none',
          border: 0,
          '&:hover': isMouseUser
            ? {
                cursor: 'default',
              }
            : {},
        }),
        ...(data?.id === OUT_OF_POCKET_OPTION.id && {
          color: colors.a11yConiferGreen,
          fontWeight: 'bold',
        }),
      };
    },
  };
};
