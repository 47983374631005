import { LDFlags } from 'ts-frontend/types';
import { HomePageState, AnswerValue } from '../Components/HomePage/types';
import { getAndFilterLanguages } from './matchingLanguages';

export type DataDependencyKeys = keyof Pick<HomePageState, 'matchingLanguages'>;

export interface DataDependencyHandlerOptions {
  forceRefetch?: boolean;
}
const defaultOptions: DataDependencyHandlerOptions = {
  forceRefetch: false,
};

type DataDependencyHandler<T extends DataDependencyKeys> = (
  state: HomePageState,
  answer: AnswerValue,
  flags: LDFlags,
  options?: DataDependencyHandlerOptions
) => Promise<HomePageState[T]> | HomePageState[T];

const getMatchingLanguages: DataDependencyHandler<'matchingLanguages'> = async (
  state,
  answer,
  flags,
  options = defaultOptions
) => {
  const {
    languageMatchingTherapyBh,
    languageMatchingTherapyB2C,
    languageMatchingTeenFlow128,
    languageMatchingDefault,
  } = flags;
  const anyFlagEnabled = [
    languageMatchingTherapyBh,
    languageMatchingTherapyB2C,
    languageMatchingTeenFlow128,
    languageMatchingDefault,
  ].some(Boolean);
  if (!anyFlagEnabled) {
    return null;
  }
  const { matchingLanguages } = state;
  const { forceRefetch } = options || {};
  if (matchingLanguages && !forceRefetch) {
    return matchingLanguages;
  }

  const languages = await getAndFilterLanguages({ state, answer });
  if (!languages?.length) {
    return [];
  }
  return languages;
};

const dataDependencyHandlers: Record<
  DataDependencyKeys,
  DataDependencyHandler<DataDependencyKeys>
> = {
  matchingLanguages: getMatchingLanguages,
};

export default dataDependencyHandlers;
