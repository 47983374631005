import { useState } from 'react';
import { checkEmailValidity } from 'ts-frontend/helpers';
import {
  AnimatedError,
  Button,
  Label,
  SparklingCheckMark,
  TSInput,
  View,
  useEmotionTheme,
  useUniqueID,
} from '@talkspace/react-toolkit';
import Title from '@/Components/Title';
import Description from '@/Components/Description';

interface Props {
  onChange: (email: string) => void;
  onSubmit: () => Promise<void>;
  submissionError: string | undefined;
  isLoading: boolean;
}
const OneFormEligibilitySubmitManualRequest = ({
  submissionError,
  onChange,
  onSubmit,
  isLoading,
}: Props) => {
  const [email, setEmail] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [confirmEmail, setConfirmEmail] = useState<string>('');
  const canSubmit = checkEmailValidity(email) && email === confirmEmail && !isLoading;
  const emailInputID = useUniqueID('emailInputID');
  const confirmEmailInputID = useUniqueID('confirmEmailInputID');
  const emailError =
    email && !checkEmailValidity(email) ? 'Please enter a valid email address' : undefined;
  const confirmEmailError =
    confirmEmail && email !== confirmEmail
      ? 'The email addresses you entered don’t match'
      : undefined;
  const { colors } = useEmotionTheme();
  return (
    <View align="center">
      <SparklingCheckMark />
      <Title
        style={{
          marginTop: 24,
        }}
      >
        We’ll assign an agent to help you
      </Title>
      <Description>
        A member of our team will be following up with you via email, please provide us with an
        email address where you can be reached.
      </Description>
      <View style={{ marginTop: 24, width: '100%' }}>
        <Label htmlFor={emailInputID}>Email</Label>
        <TSInput
          id={emailInputID}
          ariaDescribedBy={emailError || undefined}
          onChange={(value) => {
            onChange(value);
            setEmail(value);
          }}
          value={email}
          placeholder="Enter email"
          style={{ fontSize: 16, borderColor: emailError && colors.red }}
          shouldFocus={!!emailError}
          dataQa="emailInput"
        />
        <AnimatedError id={emailError} shouldAnimate={Boolean(emailError)}>
          {emailError}
        </AnimatedError>
        <Label htmlFor={confirmEmailInputID} style={{ marginTop: 16 }}>
          Confirm email
        </Label>
        <TSInput
          id={confirmEmailInputID}
          ariaDescribedBy={confirmEmailError || undefined}
          onChange={setConfirmEmail}
          value={confirmEmail}
          placeholder="Enter email"
          style={{ fontSize: 16, borderColor: confirmEmailError && colors.red }}
          shouldFocus={!!(confirmEmailError && !emailError)}
          dataQa="confirmNewEmailInput"
        />
        <AnimatedError id={confirmEmailError} shouldAnimate={Boolean(confirmEmailError)}>
          {confirmEmailError}
        </AnimatedError>
      </View>
      <Button
        dataQa="submitButton"
        onPress={async () => {
          setIsSubmitting(true);
          await onSubmit();
          setIsSubmitting(false);
        }}
        text="Submit"
        disabled={!canSubmit}
        isLoading={isSubmitting}
        style={{ width: '100%', marginTop: 40 }}
      />
      <AnimatedError id={submissionError} shouldAnimate={Boolean(submissionError)}>
        {submissionError}
      </AnimatedError>
    </View>
  );
};

export default OneFormEligibilitySubmitManualRequest;
