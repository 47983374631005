import { usTerritories } from '@talkspace/configs';

const usTerritoriesValues = Object.keys(usTerritories);
const getRelevantCountryCodesToDisplay = (supportedCountryCodes: string[]) => {
  let relevantCountryCodes: string[] = [];
  if (supportedCountryCodes.includes('US'))
    relevantCountryCodes = supportedCountryCodes.concat(usTerritoriesValues);

  return relevantCountryCodes;
};

const getStateAndCountryOverrides = (
  countryCode: string,
  stateCode: string
): { state: string; country: string } => {
  if (stateCode === 'other' && countryCode === 'CA') {
    return { state: 'ON', country: countryCode }; // Overwrites the state value to Ontario if the country is Canada
  }

  if (Object.keys(usTerritories).includes(countryCode)) {
    return { state: countryCode, country: 'US' }; // Overwrites the country value to US and state to the territory if the country is a US territory
  }

  return { state: stateCode, country: countryCode };
};

const countriesHelper = { getRelevantCountryCodesToDisplay, getStateAndCountryOverrides };
export default countriesHelper;
