import { Fragment } from 'react';
import { View } from '@talkspace/react-toolkit';

function ViewWrapper({ children }) {
  return (
    <Fragment>
      <View
        style={{
          backgroundColor: 'white',
          position: 'fixed',
          top: -200,
          height: 200,
          width: '100%',
          zIndex: 99999999,
        }}
      />
      {children}
    </Fragment>
  );
}

function withView(Component) {
  return (props) => (
    <ViewWrapper>
      <Component {...props} />
    </ViewWrapper>
  );
}

export default withView;
