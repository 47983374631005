// eslint-disable-next-line no-unused-vars
import { useState, useEffect, useCallback, FunctionComponent } from 'react';
import { HiddenText, useUniqueID, View, Label } from '@talkspace/react-toolkit';
import { OptionsType } from 'react-select';
import type { MultiSelectStep, UpdateStepObj } from '../../Flows/types';
import MultiSelectDropdown, { ContinueButton } from './Components/MultiSelectDropdown';

interface MultiSelectProps {
  value: { label: string; value: number }[];
  question: MultiSelectStep;
  updateStep: (
    internalTarget: string | number | null | undefined,
    objectArg: UpdateStepObj
  ) => void;
  onBlur?: () => void;
  hideButton?: boolean;
  onChange?: (selections: { label: string; value: number }[]) => void;
  heading1ID: string;
  heading2ID: string;
  disabled?: boolean;
  labelText?: string;
}
const MultiSelect: FunctionComponent<MultiSelectProps> = ({
  value,
  question,
  updateStep,
  onBlur,
  hideButton,
  onChange,
  heading1ID,
  heading2ID,
  disabled,
  labelText,
}) => {
  // Set selections to prop value
  const [selections, setSelections] = useState(value);

  // Update from prop when prop value is changed / loaded
  useEffect(() => {
    setSelections(value);
  }, [value]);

  // Call onChange of parent component
  useEffect(() => {
    if (onChange) {
      onChange(selections);
    }
  }, [onChange, selections]);

  const handleDropdownValuesChange = (newDropdownValues) => {
    if (!newDropdownValues) {
      setSelections([]);
      return;
    }

    if (
      newDropdownValues.length > 1 &&
      newDropdownValues[0] &&
      newDropdownValues[0].value === 'none'
    ) {
      // If 'None of the above' is the first in array, and the array has more than one item, remove 'None of the above'
      newDropdownValues.shift();
      setSelections(newDropdownValues);
    } else {
      // If 'None of the above' is later selected, replace the array with only 'None of the above'
      const noneOfTheAbove = newDropdownValues.filter((item) => item.value === 'none');
      setSelections(noneOfTheAbove.length === 1 ? noneOfTheAbove : newDropdownValues);
    }
  };

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (!selections.length) {
        return;
      }
      const selectionsList = selections.filter(
        (presentingProblem) => presentingProblem.value !== undefined
      );

      updateStep(question.internalTarget, {
        payfirst_step_id: question.analyticsId,
        payfirst_step_prompt: question.prompt,
        response_category_id: question.response_category_id,
        response_prompt: selectionsList
          .map((presentingProblem) => presentingProblem.label)
          .join(', '),
        response_value: selectionsList
          .map((presentingProblem) => presentingProblem.value)
          .toString(),
        go_to_matches: question.goToMatches,
      });
    },
    [
      selections,
      question.analyticsId,
      question.goToMatches,
      question.internalTarget,
      question.prompt,
      question.response_category_id,
      updateStep,
    ]
  );
  const promptId = useUniqueID('promptId');
  let ariaLabelledBy;
  if (question.prompt) {
    ariaLabelledBy = promptId;
  } else if (question.heading2) {
    ariaLabelledBy = heading2ID;
  } else if (question.heading1) {
    ariaLabelledBy = heading1ID;
  }
  return (
    <View style={{ width: '100%', maxWidth: 320 }}>
      {!!question.prompt && <HiddenText id={promptId}>{question.prompt}</HiddenText>}
      {!!labelText && <Label>{labelText}</Label>}
      <MultiSelectDropdown
        dataQa={`${question.category || ''}Dropdown`}
        ariaLabelledBy={ariaLabelledBy}
        options={question.multiselect as OptionsType<any>}
        onChange={handleDropdownValuesChange}
        onBlur={onBlur}
        values={selections}
      />
      <ContinueButton
        dataQa={`${question.category || ''}Submit`}
        onClick={handleSubmit}
        disabled={!selections.length}
      >
        {question.submitText || 'Submit'}
      </ContinueButton>
    </View>
  );
};

export default MultiSelect;
