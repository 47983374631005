import axios, { AxiosRequestConfig } from 'axios';
import { getParamByName } from 'ts-frontend/utils';
import apiHelper, { apiHelperV4 } from '../../Helpers/apiHelper';
import { getMixpanelDistinctID } from '../../utils/analytics/eventTracker';
import ReactFrameService from '../../utils/reactFrame/ReactFrameService';
import { FLOW_148_NEW_USER_JWT_EXPIRED } from '../../Flows';
import appConfigs from '../../utils/configs';

const PRE_REGISTER_JWT_EXPIRED_ERROR = 'PRE_REGISTER_JWT_EXPIRED';
const PRE_REGISTER_TS_USER_EXISTS_ERROR = 'PRE_REGISTER_TS_USER_EXISTS';

axios.defaults.withCredentials = true; // must be set for allowing set-cookie
axios.defaults.timeout = 30000;

const requestTokenAndSession = async () => {
  let roomType = 'privateRoom';
  const urlParams = new URLSearchParams(window.location.search);
  const serviceType = urlParams.get('serviceType') || 'psychotherapy';

  if (serviceType === 'psychiatry') {
    roomType = 'psychiatryRoom';
  } else if (serviceType === 'therapyCouples') {
    roomType = 'couplesRoom';
  }

  const distinctID = await getMixpanelDistinctID().catch(() => undefined);
  const res = await axios.post(`${apiHelperV4()}session/auth`, {
    attrLandingPage: {
      roomType,
      funnelVariation: getParamByName('funnel') || 'direct',
      registrationUrl: window.location.href,
      referrerUrl: document.referrer || '',
    },
    attrMP: distinctID ? { distinctID } : null,
  });
  return { token: res.data.data.accessToken, ...res.data.data } as {
    token: string;
    session: SessionAPIResponse;
    userID: string;
    createdAt: string;
    error: string;
  };
};

const requestSession = async (accessToken: string): Promise<SessionAPIResponse> => {
  const res = await axios({
    method: 'GET',
    url: `${apiHelper()}session/get`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return res.data.data as SessionAPIResponse;
};

/**
 * Validate if params are objects
 *
 * @param objects
 * @return {undefined}
 */
const validateObjects = (...objects) => {
  if (objects.filter((type) => type && typeof type !== 'object').length) {
    throw new Error('Bad params');
  }
};

class TokenRefreshedEventEmitter {
  eventListeners: ((success: boolean) => void)[] = [];

  callAllListeners = (success) => {
    this.eventListeners.forEach((fn) => typeof fn === 'function' && fn(success));
    this.eventListeners = [];
  };

  addEventListener = (fn) => {
    this.eventListeners.push(fn);
  };
}

class Client {
  eventEmitterTokenRefreshed = new TokenRefreshedEventEmitter();

  runningMiddlewareTokenRefresh = false;

  accessToken: string | undefined;

  userID: string | undefined;

  createdAt: string | undefined;

  constructor() {
    return this;
  }

  validateRequestToken = () => {
    if (this.runningMiddlewareTokenRefresh) {
      return new Promise<void>((resolve, reject) => {
        this.eventEmitterTokenRefreshed.addEventListener((refreshed) => {
          if (refreshed) {
            resolve();
          } else {
            reject(new Error('401'));
          }
        });
      });
    }
    return Promise.resolve();
  };

  tokenRefreshed = (success: boolean) => {
    this.runningMiddlewareTokenRefresh = false;
    this.eventEmitterTokenRefreshed.callAllListeners(success);
  };

  refreshTokenIfAboutToExpire = () => {
    if (!this.accessToken) {
      this.runningMiddlewareTokenRefresh = true;
      if (ReactFrameService.instance().isInFrame()) {
        return new Promise((resolve) => {
          ReactFrameService.instance().refreshTokenEvent(resolve);
        });
      }
      return requestTokenAndSession().then(({ token, userID, createdAt }) => {
        this.accessToken = token;
        this.userID = userID;
        this.createdAt = createdAt;
        this.tokenRefreshed(true);
      });
    }
    return Promise.resolve();
  };

  middlewares = async () => {
    await this.validateRequestToken();
    await this.refreshTokenIfAboutToExpire();
  };

  request = async (
    method: 'GET' | 'PATCH' | 'POST' | 'PUT' | 'DELETE',
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<any> => {
    const requestOptions: AxiosRequestConfig = {
      method,
      url,
      data,
      ...config,
      headers: {
        'Content-Type': 'application/json',
        ...(config && config.headers),
      },
    };
    let shouldIgnore401 = false;
    if (data) validateObjects(data);

    await this.middlewares();

    if (this.accessToken && requestOptions.headers && !requestOptions.headers.Authorization) {
      requestOptions.headers.Authorization = `Bearer ${this.accessToken}`;
    } else {
      shouldIgnore401 = true;
    }

    return axios(requestOptions)
      .catch((error) => {
        // Only attempt to refresh token when doing authenticated requests
        if (error.response && error.response.status === 401 && shouldIgnore401 === false) {
          // if we got 401 & there is no running middleware for refresh we set the token to undefined
          // and requeue the request, the first request that will get to the middleware will refresh
          if (!this.runningMiddlewareTokenRefresh) this.accessToken = undefined;
          return this.request(method, url, data, config);
        }
        throw error;
      })
      .then((res) => {
        if (res.data !== undefined && res.data.data !== undefined) return res.data.data;
        if (res.data !== undefined) return res.data;
        return res;
      });
  };

  initialize = async (): Promise<{
    session?: SessionAPIResponse;
    redirectToInternal?: string;
    redirectToExternal?: string;
  }> => {
    const getSessionWithoutToken = async () => {
      const { token, session, userID, createdAt, error } = await requestTokenAndSession();
      this.accessToken = token;
      this.userID = userID;
      this.createdAt = createdAt;

      if (error === PRE_REGISTER_TS_USER_EXISTS_ERROR) {
        return { session, redirectToExternal: `${appConfigs.endpoints.clientWebEndpoint}/login` };
      }
      if (error === PRE_REGISTER_JWT_EXPIRED_ERROR) {
        return { session, redirectToInternal: `/flow/${FLOW_148_NEW_USER_JWT_EXPIRED}/step/1` };
      }

      return { session };
    };
    if (this.accessToken) {
      return requestSession(this.accessToken)
        .then((session) => {
          if (session.createdAt) {
            this.createdAt = session.createdAt;
          } else if (!this.createdAt) {
            this.createdAt = new Date().toISOString();
          }
          return { session };
        })
        .catch((error) => {
          // Could happen if JWT expired
          if (error.response && error.response.status === 401) return getSessionWithoutToken();
          throw error;
        });
    }
    return getSessionWithoutToken();
  };
}

const client = new Client();

const apiWrapper = {
  post: (url: string, data: any, config?: AxiosRequestConfig) =>
    client.request('POST', url, data, config),
  get: (url: string, config?: AxiosRequestConfig) => client.request('GET', url, config),
  patch: (url: string, data: any, config?: AxiosRequestConfig) =>
    client.request('PATCH', url, data, config),
  put: (url: string, data: any, config?: AxiosRequestConfig) =>
    client.request('PUT', url, data, config),
  setToken: (token: string, userID: string) => {
    client.accessToken = token;
    client.userID = userID;
  },
  initialize: client.initialize,
  currentUserID: () => client.userID,
  qmCreatedAt: () => client.createdAt,
};
export default apiWrapper;
