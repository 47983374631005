/* eslint-disable import/prefer-default-export */
import { CommonServiceType } from 'ts-frontend/types';
import { apiHelperV4 } from './apiHelper';
import apiWrapper from '../core/api/apiWrapper';

const FILE_NAME_SUFFIXES = ['insurance-front', 'insurance-back', 'id-front', 'id-back'] as const;

type FileNameSuffix = typeof FILE_NAME_SUFFIXES[number];

const getFileExtensionFromFileType = (fileType: string) => fileType.split('/')[1];

const uploadFileToS3 = async (signedRequestUrl: string, file: File) => {
  const body = new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
  const response = await fetch(signedRequestUrl, {
    method: 'PUT',
    body,
  });
  if (!response.ok) throw new Error('S3 upload error');
};

interface SignManualCoverageImageUploadURLProps {
  nameSuffix: FileNameSuffix;
  fileType: string;
}

interface SignedUrlResponse {
  signedRequest: string;
  url: string;
}

const signManualCoverageImageUploadURL = ({
  nameSuffix,
  fileType,
}: SignManualCoverageImageUploadURLProps): Promise<SignedUrlResponse> => {
  if (!fileType.startsWith('image/')) throw new Error('fileType must be image/*');
  const extension = getFileExtensionFromFileType(fileType);
  return apiWrapper.post(`${apiHelperV4()}eligibility/manual-coverage-request/sign-image-url`, {
    fileName: `${nameSuffix}.${extension}`, // NOTE: this will be prepended with QM session ID within qm-api
    fileType,
  });
};

interface SubmitManualCoverageRequestProps {
  email: string;
  memberID: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  state: string;
  partnerID: number;
  serviceType: CommonServiceType;
  uploadedFiles: { nameSuffix: FileNameSuffix; extension: string }[];
}
const submitManualCoverageRequest = ({
  email,
  memberID,
  firstName,
  lastName,
  dateOfBirth,
  state,
  partnerID,
  serviceType,
  uploadedFiles,
}: SubmitManualCoverageRequestProps): Promise<{}> =>
  apiWrapper.post(`${apiHelperV4()}eligibility/manual-coverage-request/submit`, {
    email,
    memberID,
    firstName,
    lastName,
    dateOfBirth,
    state,
    partnerID,
    serviceType,
    uploadedFiles,
  });

export {
  uploadFileToS3,
  getFileExtensionFromFileType,
  submitManualCoverageRequest,
  signManualCoverageImageUploadURL,
};
