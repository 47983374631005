import { A11yProvider } from '@talkspace/react-toolkit';
import sessionStorage from '@/core/storage/sessionStorage';

const withAccessibility = (Component) => (props) =>
  (
    <A11yProvider storage={sessionStorage}>
      <Component {...props} />
    </A11yProvider>
  );

export default withAccessibility;
