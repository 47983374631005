import { FunctionComponent, useState } from 'react';
import register from '@talkspace/auth/register';
import { CreateAccountForm, SignUpData, View } from '@talkspace/react-toolkit';
import moment from 'moment-timezone';
import { ClientMatchPresentingProblem } from 'ts-frontend/types';
import formatPhoneNumber from 'ts-frontend/utils/phoneNumbers';
import { getGDPRProps } from 'ts-frontend/helpers/gdpr';
import { HomePageState, GenderType, Response, RoomType } from '../HomePage/types';
import {
  QuickmatchRegistrationErrorResponse,
  registerUserWithoutPaymentCognito,
  registerUserWithoutPayment,
} from '../../Helpers/apiService';
import { trackEvent, identify, setPeopleLogin } from '../../utils/analytics/events';
import { minimumAge } from '../../Helpers/ageHelper';
import appConfigs from '../../utils/configs';
import { redirectToRoom, RegistrationErrors } from '../../Helpers/registrationHelpers';
import { useIonicFrameKeyboardListener } from '../../hooks';

const defaultHeaderCopy = 'Great work!';
const defaultBodyCopy = 'Now, create an account to talk to your dedicated therapy consultant.';

interface RegisterWithoutPaymentProps {
  funnelVariation: HomePageState['funnelVariation'];
  responses: Response[];
  clientMatchPresentingProblems: ClientMatchPresentingProblem[];
  redirectFrom: number;
  setQMFlowDone: () => void;
  clientCountry?: string;
  clientState?: string;
  roomType?: RoomType;
  clientDateOfBirth?: string;
  clientGender?: GenderType;
  cognitoActive: boolean;
  showReferralField?: boolean;
  headerCopy?: string;
  bodyCopy?: string;
  submitText?: string;
}

const RegisterWithoutPayment: FunctionComponent<RegisterWithoutPaymentProps> = ({
  funnelVariation,
  responses,
  clientMatchPresentingProblems,
  redirectFrom,
  setQMFlowDone,
  clientCountry,
  clientState,
  roomType,
  clientDateOfBirth,
  clientGender = undefined,
  cognitoActive,
  showReferralField = true,
  headerCopy = defaultHeaderCopy,
  bodyCopy = defaultBodyCopy,
  submitText,
}) => {
  const [requestPending, setRequestPending] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);
  const hideFooter = useIonicFrameKeyboardListener();

  const onSubmit = async (formState: SignUpData, password: string) => {
    let url: string;
    let userId: number;
    let authToken: string;
    setRequestPending(true);

    const params = {
      clientDateOfBirth,
      funnelVariation,
      roomType,
      email: formState.email,
      nickname: formState.nickname,
      timezone: moment.tz.guess(),
      registerUrl: window.location.href,
      attribution: {
        referrerUrl: document.referrer,
        registrationUrl: window.location.href,
      },
      quickmatchResponses: responses.filter(
        (qmResponse) => qmResponse.payfirst_step_prompt || qmResponse.response_category_id
      ),
      presentingProblems: clientMatchPresentingProblems,
      gender: clientGender,
      ...(cognitoActive
        ? {
            customerInformation: {
              ...(formState.country &&
                appConfigs.featureFlags.countryStateDropDown && { country: formState.country }),
              ...(formState.country === 'US' && { state: formState.state }),
            },
            acceptSMSMarketing: formState.isChecked,
            phoneNumber: formState.phoneNumber,
            phoneNumberCountryCode: formState.phoneNumberCountryCode,
          }
        : { password }),
    };
    try {
      if (cognitoActive) {
        ({ url, userId, authToken } = await registerUserWithoutPaymentCognito(params));
        const cognitoPhoneNumber = formState.phoneNumber
          ? formatPhoneNumber(formState.phoneNumber, formState.phoneNumberCountryCode)
          : undefined;
        await register({
          password,
          authToken,
          email: formState.email as string,
          phoneNumber: cognitoPhoneNumber,
        });
      } else {
        ({ url, userId } = await registerUserWithoutPayment(params));
      }

      if (!url) throw new Error('no room url');

      setQMFlowDone();
      sessionStorage.clear();

      identify(userId);
      setPeopleLogin(userId);
      trackEvent('Register', {
        'User ID': userId,
        'How did you hear about us?': formState.referralSource?.value,
      });

      setTimeout(() => redirectToRoom(url), 1000);
    } catch (err) {
      const error = err as QuickmatchRegistrationErrorResponse;
      const errors = error?.response?.data?.errors || [];
      setRequestPending(false);

      if (errors.length && errors[0].code !== 'paramsError') {
        errors.forEach(({ code, message }) => {
          if (code === 'serverError') {
            setIsError(true);
            setErrorMessage(message);
          }
        });
        return;
      }
      setIsError(true);
      setErrorMessage(RegistrationErrors.generalError);
    }
  };

  const { isGDPR, consentGDPRMarketing, consentGDPRProcessing, consentGDPRTransferring } =
    getGDPRProps();
  return (
    <View
      align="center"
      style={{
        marginTop: headerCopy ? -80 : 0,
      }}
    >
      <CreateAccountForm
        clientCountry={clientCountry}
        clientState={clientState}
        headerCopy={headerCopy}
        bodyCopy={bodyCopy}
        minimumAge={minimumAge(redirectFrom)}
        isUpdating={requestPending}
        onSubmit={onSubmit}
        isError={isError}
        errorMessage={errorMessage}
        showCountryStateDropdown={appConfigs.featureFlags.countryStateDropDown && cognitoActive}
        showPhoneNumberInput={cognitoActive}
        showReferralField={showReferralField}
        submitText={submitText}
        bodyStyle={{
          textAlign: 'left',
        }}
        tinyStyle={{
          textAlign: 'center',
        }}
        hideFooter={hideFooter}
        isGDPR={isGDPR}
        consentGDPRMarketing={consentGDPRMarketing}
        consentGDPRProcessing={consentGDPRProcessing}
        consentGDPRTransferring={consentGDPRTransferring}
      />
    </View>
  );
};

export default RegisterWithoutPayment;
