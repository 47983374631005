/* eslint-disable camelcase */
import { AppSource } from 'ts-frontend/types';
import { ValidateCouponResponse } from 'offer/utils/paymentApiHelper';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';
import accessTokenHelper from '@/utils/accessTokenHelper';

async function getSetupIntentInner({
  userID,
  planID,
  currency,
}: {
  userID?: any;
  planID?: number;
  currency?: string;
}) {
  if (userID) {
    const res = await apiWrapper.post(
      `${apiHelper().apiEndpoint}/v2/payments/users/${userID}/setup-intent`,
      {
        planID,
        currency,
      }
    );
    return res;
  }

  const { token } = await accessTokenHelper.getOffersAccessToken();

  const res = await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/payments/setup-intent`,
    { planID, currency },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return res;
}

async function validateCouponQM(
  couponCode: string,
  planID?: number
): Promise<ValidateCouponResponse['data']> {
  const { token } = await accessTokenHelper.getOffersAccessToken();

  const res = await apiWrapper.post(
    `${apiHelper().apiEndpoint}/v2/payments/coupons/validate/qm`,
    {
      planID,
      couponCode,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );

  return res;
}

const paymentApiHelperInner = {
  validateCouponQM,
  getSetupIntentInner,
  getSource: () => AppSource.qm,
};

export default paymentApiHelperInner;
