import { FunctionComponent, useState, useEffect, useCallback, useRef } from 'react';
import * as React from 'react';
import {
  Tooltip,
  ImplicitBusinessHoursChart,
  useWindowWidth,
  View,
  TouchableView,
  Image,
  Text,
  Large,
  ExtraHuge,
  Button,
  useEmotionTheme,
  useUniqueID,
  TabRadioGroup,
  TimeZone,
  TimeOff,
  Spinner,
} from '@talkspace/react-toolkit';
import moment from 'moment';
import { Player } from 'video-react';
import {
  ClientMatchPresentingProblem,
  ImplicitBusinessHoursByDay,
  BusinessHoursDay,
} from 'ts-frontend/types';
import styled, { EmotionStyle } from '@/core/styled';
import IntroVideoPlayer from './IntroVideoPlayer';
import videoButton from '../../Assets/video-button.svg';
import 'video-react/dist/video-react.css';
import { trackEvent } from '../../utils/analytics/events';
import ProviderInfo from './ProviderInfo';
import appConfigs from '../../utils/configs';
import { MatchesStep, UpdateStepObj, InternalTarget, ExternalTarget } from '../../Flows/types';
import { Match } from '../HomePage/types';
import useQueryImplicitBusinessHours from '../../hooks/useQueryImplicitBusinessHours';

interface MatchTabProps {
  // eslint-disable-next-line react/no-unused-prop-types
  key: number;
  step: MatchesStep;
  updateStep: (
    target: InternalTarget | ExternalTarget,
    updateStepObj: UpdateStepObj | undefined,
    match: Match
  ) => void;
  match: Match;
  // eslint-disable-next-line react/no-unused-prop-types
  flowId: number;
  clientCountry?: string;
  presentingProblems: ClientMatchPresentingProblem[];
}
const REACT_APP_CDN_ENDPOINT = appConfigs.endpoints.cdnEndpoint;

const ProviderInfoContainer = styled(View)({
  marginBottom: 40,
});
const DesktopRowMobileColumn = styled(View)(
  ({
    theme: {
      window: { isMobile },
      colors,
    },
  }) => {
    const mobileStyles = isMobile
      ? {
          display: 'block',
          background: colors.white,
          borderRadius: 9,
          border: `1px solid ${colors.permaLinkWaterGrey}`,
          maxWidth: 335,
          padding: '26px 15px 40px 15px',
          margin: '0px auto',
          width: '100%',
        }
      : {};
    return {
      display: 'flex',
      flexDirection: 'row',
      ...mobileStyles,
    };
  }
);
const ProviderHeadShotContainer = styled(View)({
  width: '100%',
  maxWidth: 205,
  display: 'block',
  margin: '0 auto',
  textAlign: 'center',
  position: 'relative',
});
const ProviderHeadShot = styled(Image)(({ theme: { colors } }) => {
  return {
    width: 195,
    height: 195,
    backgroundSize: 195,
    backgroundColor: colors.permaLinkWaterGrey,
    backgroundBlendMode: 'multiply',
    borderRadius: '50%',
    margin: '0px auto 15px',
  };
});
const IntroVideoButton = styled(Button)({
  backgroundImage: `url(${videoButton})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: 72,
  width: 72,
  padding: 0,
  position: 'absolute',
  top: 120,
  right: 0,
  borderRadius: '50%',
  backgroundColor: 'transparent',
  ':hover:focus': {
    border: 'none',
  },
});
const ProviderCard = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    const mobileStyles = isMobile
      ? {
          border: 'none',
          padding: 0,
          margin: 0,
          background: 'none',
          borderRadius: 0,
        }
      : {};
    return {
      background: colors.white,
      borderRadius: 9,
      border: `1px solid ${colors.permaLinkWaterGrey}`,
      marginLeft: 29,
      padding: '25px 25px 30px 25px',
      maxWidth: '640px',
      width: '100%',
      height: 'fit-content',
      ...mobileStyles,
    };
  }
);
const ProviderCardTabView = styled(TouchableView)<{ isActive: boolean }>(
  ({
    isActive,
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    const mobileStyles = isMobile
      ? {
          border: `1px solid ${colors.permaLinkWaterGrey}`,
          padding: '9px 31px 11px',
        }
      : {};
    return {
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      fontSize: 18,
      color: isActive ? colors.black : colors.osloGrey,
      letterSpacing: 0,
      cursor: 'pointer',
      margin: 0,
      width: 'fit-content',
      height: 'fit-content',
      borderRadius: 44,
      padding: 0,
      ...mobileStyles,
    };
  }
);
const ProviderInfoPanel = styled(View)({
  display: 'block',
  width: '100%',
  textAlign: 'left',
});
const ProviderTextInfoContainer = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      display: isMobile ? 'block' : 'flex',
      flexDirection: 'row',
      marginLeft: isMobile ? undefined : -15,
    };
  }
);
const ProviderDescriptionContainer = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      maxWidth: 355,
      marginRight: 35,
      padding: 0,
      marginLeft: isMobile ? 0 : 15,
      heigh: 'auto',
      width: isMobile ? '100%' : undefined,
    };
  }
);
const ProviderDescriptionText = styled(Text)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      textAlign: 'left',
      marginTop: isMobile ? 15 : 9,
      marginBottom: 0,
      fontSize: 15,
      fontWeight: 400,
      maxWidth: 270,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      overflowWrap: 'break-word',
      display: '-webkit-box',
      maxHeight: '146px',
      WebkitLineClamp: 7,
      WebkitBoxOrient: 'vertical',
    };
  }
);
const ShowMoreLessButton = styled(Button)(({ theme: { colors } }) => {
  return {
    color: colors.green,
    whiteSpace: 'nowrap',
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '19px',
    fontWeight: 'bold',
    marginTop: 5,
    float: 'left',
    textAlign: 'left',
    backgroundColor: colors.white,
    width: 'fit-content',
    '&:hover': {
      color: colors.darkGreen,
    },
  };
});
const BarChartTitleRow = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      marginTop: isMobile ? 23 : 31,
      marginBottom: isMobile ? 21 : 18,
      display: 'flex',
      flexDirection: 'row',
    };
  }
);
const BarChartTitle = styled(Large)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      fontWeight: 500,
      fontSize: isMobile ? 16 : 14,
    };
  }
);

const SelectProviderButton = ({
  match,
  step,
  updateStep,
  style,
  dataQa,
}: {
  match: Match;
  step: MatchesStep;
  updateStep: (
    target: InternalTarget | ExternalTarget,
    updateStepObj: UpdateStepObj | undefined,
    match: Match
  ) => void;
  style?: EmotionStyle;
  dataQa: string;
}) => {
  const chooseTherapist = () => {
    trackEvent('Select a Therapist', {
      'Therapist Name': `${match.firstName} ${match.lastName}`,
      'Licensed State': match.licenses.map((lic) => lic.state),
      Expertise: match.expertise.mostRelevant,
      'Treatment Orientations': match.expertise.treatmentOrientations,
      'Therapist ID': match.id,
      'Funnel Name': 'QuickMatch',
    });

    updateStep(step.buttonTarget, undefined, match);
  };
  const { colors } = useEmotionTheme();
  return (
    <Button
      data-qa={dataQa}
      style={{ width: '100%', marginTop: 0, ...style }}
      aria-label={`Select ${match.firstName} ${match.lastName}`}
      roundedFocusStyle
      primaryColor={colors.green}
      onPress={chooseTherapist}
    >
      Select
    </Button>
  );
};

const ProviderCardTab: React.FunctionComponent<{
  isActive: boolean;
  tabText: string;
  tabPanelId: string;
  onPress: (tabText: string) => void;
  style?: EmotionStyle;
}> = ({ isActive, tabText, tabPanelId, onPress, style }) => {
  const { colors } = useEmotionTheme();
  return (
    <ProviderCardTabView
      role="tab"
      aria-selected={isActive}
      aria-controls={isActive ? tabPanelId : undefined}
      tabIndex={isActive ? 0 : -1}
      isActive={isActive}
      onPress={() => onPress(tabText)}
      roundedFocusStyle
      primaryColor={colors.permaWaikawaGrey}
      style={style}
    >
      {tabText}
    </ProviderCardTabView>
  );
};

const ProviderName = ({ match }: { match: Match }) => (
  <ExtraHuge
    as="h3"
    style={{ paddingTop: 15, fontSize: 28 }}
  >{`${match.firstName} ${match.lastName}`}</ExtraHuge>
);

const TimeZoneTimeOff = ({
  timeOffEnd,
  timeOffStart,
  therapistTimezone,
}: {
  timeOffEnd: string;
  timeOffStart: string;
  therapistTimezone: string;
}) => {
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidth();

  const boldStyles = isMobile
    ? { fontWeight: 500, color: colors.osloGrey, fontSize: 15 }
    : {
        fontWeight: 500,
        marginLeft: 5,
        marginRight: 20,
        color: colors.black,
        fontSize: 14,
      };
  const textStyles = isMobile
    ? { fontSize: 16, color: colors.black }
    : { fontSize: 14, color: colors.black };
  const iconStyles = isMobile ? { margin: 'auto 14px auto 0' } : {};
  return (
    <View style={{ marginTop: 60 }}>
      <View row style={{ marginBottom: isMobile ? 15 : 10 }}>
        <TimeZone
          height={isMobile ? 25 : 18}
          width={isMobile ? 22 : 16}
          color={colors.a11yRoyalBlue}
          style={iconStyles}
        />
        <View row style={{ display: isMobile ? 'grid' : undefined }}>
          <Large style={boldStyles}>Timezone</Large>
          <Large style={textStyles}>{`${therapistTimezone.replace(/_/g, ' ')}`}</Large>
        </View>
      </View>
      <View row>
        <TimeOff height={isMobile ? 25 : 18} width={isMobile ? 24 : 19} style={iconStyles} />
        <View row style={{ display: isMobile ? 'grid' : undefined }}>
          <Large style={boldStyles}>On time off</Large>
          <Large style={textStyles}>
            {timeOffStart
              ? `${moment(timeOffStart).format('MMM D, YYYY')} - ${moment(timeOffEnd).format(
                  'MMM D, YYYY'
                )}`
              : 'No time off scheduled'}
          </Large>
        </View>
      </View>
    </View>
  );
};

const ProviderCredentials = ({ match }: { match: Match }) => {
  const uniqLicenses = {};
  match.licenses.forEach((license) => {
    uniqLicenses[license.type] = license.description;
  });
  const { isMobile } = useWindowWidth();
  const { colors } = useEmotionTheme();
  const mobileStyles = isMobile
    ? {
        lineHeight: 23,
        alignSelf: 'center',
        justifyContent: 'center',
        margin: '0px auto 20px auto',
      }
    : {};
  return (
    <View
      row
      style={{
        textAlign: 'left',
        marginTop: 2,
        maxWidth: 275,
        flexWrap: 'wrap',
        cursor: 'arrow',
        ...mobileStyles,
      }}
    >
      {Object.keys(uniqLicenses).map((licenseType) => (
        <Tooltip
          key={licenseType}
          place="bottom"
          tip={uniqLicenses[licenseType]}
          label="provider license tooltip"
          buttonStyle={{
            width: 'fit-content',
            borderRadius: 10,
            marginRight: 5,
            alignSelf: isMobile ? 'center' : 'flex-start',
          }}
          roundedFocusStyle
        >
          <Text style={{ color: colors.osloGrey, fontWeight: 500, letterSpacing: 0 }}>
            {licenseType}
          </Text>
        </Tooltip>
      ))}
    </View>
  );
};

const MatchTab: FunctionComponent<MatchTabProps> = ({
  step,
  updateStep,
  match,
  clientCountry,
  presentingProblems,
}) => {
  const {
    id: matchId,
    firstName,
    lastName,
    yearsExperience,
    bio,
    timezone: therapistTimezone,
    timeOffStart,
    timeOffEnd,
    businessDays,
  } = match;

  const videoPlayerRef = useRef<Player>(null);
  const {
    data: implicitBusinessHours,
    isFetched,
    isLoading,
    refetch: getImplicitBusinessHours,
  } = useQueryImplicitBusinessHours(matchId);

  const [showProfile, setShowProfile] = useState<boolean>(true);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [showVideoButton, setShowVideoButton] = useState<boolean>(false);
  const [videoProgress, setVideoProgress] = useState<number>(0);
  const [moreExpertise, setMoreExpertise] = useState<boolean>(false);

  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidth();

  const tabPanelId = useUniqueID('tabPanelId');
  const providerDescriptionTextId = useUniqueID('providerDescriptionTextId');

  const checkForTherapistVideo = useCallback(() => {
    if (REACT_APP_CDN_ENDPOINT === undefined) {
      throw new Error('Please set CDN endpoint');
    }

    fetch(`${REACT_APP_CDN_ENDPOINT}therapist-intro-videos/${matchId}.mp4`, { method: 'HEAD' })
      .then((response) => {
        if (response.status !== 404) {
          setShowVideoButton(true);
        }
      })
      .catch(() => undefined);
  }, [matchId]);

  useEffect(() => {
    checkForTherapistVideo();
  }, [checkForTherapistVideo]);

  const providerDescriptionRef = useRef<HTMLParagraphElement>(null);
  const readMoreLessButtonRef = useRef<HTMLButtonElement>(null);

  // renders the "Read more" button if the description is long enough that some of the text needs to be hidden
  useEffect(() => {
    if (
      matchId &&
      readMoreLessButtonRef.current &&
      providerDescriptionRef.current &&
      providerDescriptionRef.current.scrollHeight - providerDescriptionRef.current.offsetHeight >=
        20
    ) {
      // At least one line is hidden
      providerDescriptionRef.current.setAttribute('style', 'display: block; float: left;');
    } else if (readMoreLessButtonRef.current) {
      readMoreLessButtonRef.current.style.display = 'none';
    }
  }, [matchId]);

  const showMoreOrLess = () => {
    if (
      readMoreLessButtonRef.current &&
      providerDescriptionRef.current &&
      providerDescriptionRef.current.scrollHeight - providerDescriptionRef.current.offsetHeight >=
        20
    ) {
      // At least one line is hidden
      providerDescriptionRef.current.style.maxHeight = '600px';
      providerDescriptionRef.current.style.webkitLineClamp = '2147483647';
      readMoreLessButtonRef.current.innerText = 'Read less';
      setMoreExpertise(true);
    } else if (providerDescriptionRef.current && readMoreLessButtonRef.current) {
      providerDescriptionRef.current.style.maxHeight = '146px';
      setTimeout(() => {
        // this conditional seems redundant but is needed due to scope issues with setTimeout
        if (providerDescriptionRef.current && readMoreLessButtonRef.current) {
          providerDescriptionRef.current.style.webkitLineClamp = '7';
          readMoreLessButtonRef.current.innerText = 'Read more';
        }
      }, 500);
      setMoreExpertise(false);
    }
  };

  const onAvailabilityClick = () => {
    if (!isFetched && !isLoading) {
      getImplicitBusinessHours();
    }
  };

  const setTabSelection = (tabName) => {
    trackEvent('View Therapist Info', {
      'Therapist Name': `${match.firstName} ${match.lastName}`,
      'Information Type': tabName,
      'Licensed State': (match.licenses || []).map((license) => license.state),
      Expertise: match.expertise.mostRelevant,
      'Treatment Orientations': match.expertise.treatmentOrientations,
      'Therapist ID': matchId,
      'Funnel Name': 'QuickMatch',
    });
    if (tabName === 'Profile') {
      setShowProfile(true);
    } else {
      onAvailabilityClick();
      setShowProfile(false);
      setMoreExpertise(false);
    }
  };

  const abbreviatedKeysImplicitBusinessHours = {};
  if (implicitBusinessHours) {
    Object.entries(implicitBusinessHours).forEach(([day, hourValueArray]) => {
      abbreviatedKeysImplicitBusinessHours[day.substring(0, 3).toLowerCase() as BusinessHoursDay] =
        hourValueArray;
    });
  }

  return (
    <View>
      <ProviderInfoContainer>
        <DesktopRowMobileColumn>
          <ProviderHeadShotContainer>
            <ProviderHeadShot
              alt={`${firstName} ${lastName}'s profile picture`}
              src={`${REACT_APP_CDN_ENDPOINT}images/application/therapist/440/${matchId}.png`}
            />
            {showVideoButton && (
              <IntroVideoButton
                aria-label={`play ${firstName} ${lastName}'s intro video`}
                dataQa={`introVideoButton${matchId}`}
                roundedFocusStyle
                primaryColor={colors.green}
                onPress={() => {
                  setShowVideo(true);
                }}
              />
            )}
            {!isMobile && (
              <SelectProviderButton
                dataQa={`webSelectProviderButton${matchId}`}
                match={match}
                step={step}
                updateStep={updateStep}
                style={{ fontWeight: 700 }}
              />
            )}
          </ProviderHeadShotContainer>
          {isMobile && (
            <>
              <ProviderName match={match} />
              <ProviderCredentials match={match} />
            </>
          )}
          <ProviderCard>
            <TabRadioGroup
              isTabList
              style={{ display: 'flex', justifyContent: isMobile ? 'space-evenly' : undefined }}
            >
              <ProviderCardTab
                isActive={showProfile}
                tabText="Profile"
                tabPanelId={tabPanelId}
                onPress={setTabSelection}
              />
              {/* Hide availability tab for UK users */}
              {clientCountry !== 'GB' && (
                <ProviderCardTab
                  isActive={!showProfile}
                  tabText="Availability"
                  tabPanelId={tabPanelId}
                  onPress={setTabSelection}
                  style={{
                    marginLeft: isMobile ? 5 : 25,
                  }}
                />
              )}
            </TabRadioGroup>
            {showProfile ? (
              <ProviderInfoPanel id={tabPanelId} style={{ display: 'block' }}>
                {!isMobile && (
                  <>
                    <ProviderName match={match} />
                    <ProviderCredentials match={match} />
                  </>
                )}
                <ProviderTextInfoContainer>
                  <ProviderDescriptionContainer>
                    <ProviderDescriptionText
                      id={providerDescriptionTextId}
                      ref={providerDescriptionRef}
                    >
                      {bio}
                    </ProviderDescriptionText>
                    <ShowMoreLessButton
                      ref={readMoreLessButtonRef}
                      data-qa={`showMoreOrlessButton${matchId}`}
                      aria-label={`Read more or less about ${firstName} ${lastName}`}
                      onPress={showMoreOrLess}
                      roundedFocusStyle
                      primaryColor={colors.green}
                      aria-expanded={moreExpertise}
                      aria-controls={providerDescriptionTextId}
                    >
                      Read more
                    </ShowMoreLessButton>
                  </ProviderDescriptionContainer>
                  <ProviderInfo
                    yearsExperience={yearsExperience}
                    yearsWithTalkspace={match.yearsWithTalkspace}
                    allFieldsOfExpertise={match.allFieldsOfExpertise}
                    mostRelevantExpertise={match.expertise.mostRelevant}
                    moreExpertise={moreExpertise}
                    additionalLanguages={match.additionalLanguages}
                    presentingProblems={presentingProblems}
                  />
                </ProviderTextInfoContainer>

                {isMobile && (
                  <SelectProviderButton
                    dataQa={`mobileProviderInfoProviderSelectButton${matchId}`}
                    match={match}
                    step={step}
                    updateStep={updateStep}
                    style={{ marginTop: 30, fontWeight: 700 }}
                  />
                )}
              </ProviderInfoPanel>
            ) : (
              <ProviderInfoPanel id={tabPanelId}>
                <BarChartTitleRow>
                  <BarChartTitle as="h4">Typically active</BarChartTitle>
                  <Tooltip
                    dataQa={`providerAvailabilityTooltip${matchId}`}
                    roundedFocusStyle
                    buttonStyle={{ borderRadius: 5, marginLeft: 5 }}
                    label="More info about provider availability bar graph"
                    tip="The bars in this graph represent the level of typical activity for this provider on a given day. The data is sourced from the past several weeks of platform activity. It is intended to help you find a provider whose activity patterns best fit your schedule."
                    questionMarkSize={25}
                  />
                </BarChartTitleRow>
                {isLoading ? (
                  <Spinner isLoading />
                ) : (
                  <ImplicitBusinessHoursChart
                    implicitBusinessHoursByDay={
                      implicitBusinessHours
                        ? (abbreviatedKeysImplicitBusinessHours as ImplicitBusinessHoursByDay)
                        : undefined
                    }
                    roundedFocusStyle
                    businessDaysQM={businessDays}
                    showArrows={!isMobile}
                    containerStyle={isMobile ? undefined : { margin: '0px 100px' }}
                    daysContainerStyle={
                      isMobile ? undefined : { width: '80%', alignSelf: 'center' }
                    }
                  />
                )}

                <TimeZoneTimeOff
                  timeOffEnd={timeOffEnd}
                  timeOffStart={timeOffStart}
                  therapistTimezone={therapistTimezone}
                />
                {isMobile && (
                  <SelectProviderButton
                    dataQa={`mobileAvailabilitySelectProviderButton${matchId}`}
                    style={{ marginTop: 30, fontWeight: 700 }}
                    match={match}
                    step={step}
                    updateStep={updateStep}
                  />
                )}
              </ProviderInfoPanel>
            )}
          </ProviderCard>
        </DesktopRowMobileColumn>
      </ProviderInfoContainer>
      <IntroVideoPlayer
        match={match}
        showVideo={showVideo}
        videoPlayerRef={videoPlayerRef}
        setShowVideo={setShowVideo}
        videoProgress={videoProgress}
        setVideoProgress={setVideoProgress}
      />
    </View>
  );
};

export default MatchTab;
