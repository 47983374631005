import {
  Button,
  Check,
  ExtraHuge,
  Large,
  View,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import NYCPeopleCheckBanner from '../../assets/NYCPeopleCheckBanner';
import { TeenNYCSummaryStep, UpdateStep } from '../../../Flows';
import styled from '@/core/styled';

const Styled = {
  Container: styled(View)(({ isMobile }: { isMobile: boolean }) => {
    return {
      maxWidth: 350,
      height: isMobile ? 'auto' : undefined,
      justifyContent: 'space-between',
    };
  }),
  Title: styled(ExtraHuge)({ margin: '24px 0 4px' }),
  InfoItemContainer: styled(View)({
    marginTop: 12,
  }),
  ContinueButton: styled(Button)({
    marginBottom: 16,
    marginTop: 40,
    width: '100%',
  }),
  BannerContainer: styled(View)({
    width: 349,
  }),
};

const InfoItem = ({ text }: { text: string }) => (
  <Styled.InfoItemContainer row align="center">
    <Check style={{ marginRight: 12 }} />
    <Large>{text}</Large>
  </Styled.InfoItemContainer>
);

interface Props {
  step: TeenNYCSummaryStep;
  updateStep: UpdateStep;
}
const TeenNYCSummary = ({ step, updateStep }: Props) => {
  const { isMobile } = useWindowWidthState();
  return (
    <Styled.Container isMobile={isMobile}>
      <View>
        <Styled.BannerContainer>
          <NYCPeopleCheckBanner />
        </Styled.BannerContainer>
        <Styled.Title>You get free therapy through NYC Health Department!</Styled.Title>
        <InfoItem text="A dedicated therapist to support you" />
        <InfoItem text="One free live therapy session per month" />
        <InfoItem text="Unlimited messaging therapy" />
        <InfoItem text="Need help signing up? Call (888) 850-0986" />
      </View>
      <Styled.ContinueButton
        text="Continue"
        onPress={() => updateStep(step.buttonTarget)}
        dataQa="continueButton"
      />
    </Styled.Container>
  );
};
export default TeenNYCSummary;
