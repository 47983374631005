import {
  SESSION_STORAGE_MEMBER_DETAILS_KEY,
  getSessionStorageValuesFromJson,
} from 'ts-frontend/utils';
import PreRegisterExpired from '../PreRegisterExpired';

const PreRegisterHoldExpired = () => {
  const { referralSource } = getSessionStorageValuesFromJson(SESSION_STORAGE_MEMBER_DETAILS_KEY, [
    'referralSource',
  ]);

  let partnerDesignationString = '';
  let displayString = '';

  if (referralSource?.value) {
    displayString = referralSource.value.charAt(0).toUpperCase() + referralSource.value.slice(1);
    partnerDesignationString = `on ${displayString}`;
  }

  const heading = `Sorry, your 
    ${displayString} reservation has expired`;
  const substring = `It’s been too long between booking your appointment
    ${partnerDesignationString} and completing the Talkspace onboarding. Don't worry, we'll help you explore other options.`;
  return <PreRegisterExpired heading={heading} subHeading={substring} />;
};

export default PreRegisterHoldExpired;
