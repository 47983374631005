import { states } from '../Helpers/locales';
import { QM_COPY } from '../utils/qmCopy';
import { QMFlow } from './types';
import {
  initFlowConfig,
  skipIfExperiment,
  skipToStepIfDoesntMeetAvailabilityPreferencesRequirements,
} from './utils';

const OFFER_ID = 62;

/** Service Specific Funnels: Therapy */
export const FLOW_106_CONSUMER_WALMART = 106;

const flow: QMFlow = {
  flowId: FLOW_106_CONSUMER_WALMART,
  flowConfig: {
    ...initFlowConfig,
    serviceType: 'psychotherapy',
    isB2B: false,
    isTeen: true,
    shouldHideServiceSelection: true,
    version: 3,
  },
  steps: [
    {
      id: 1,
      prompt: 'What led you to seek help?',
      analyticsId: 54,
      response_category_id: 2,
      category: 'presentingProblems',
      internalTarget: 135,
      selfServe: {
        field: 'fieldsOfExpertise',
        type: Array,
      },
      heading1: null,
      heading2: "To begin, tell us why you're looking for help today.",
      progressCurrent: 1,
      progressMax: 11,
      tip: 'findTheRightMatch',
    },
    {
      id: 135,
      category: 'memberAvailability',
      prompt: 'Select your preferred times for therapy',
      heading2: 'Select your preferred times for therapy',
      skipQuestionActions: [
        skipToStepIfDoesntMeetAvailabilityPreferencesRequirements(3),
        skipIfExperiment({
          experimentName: 'clientAvailabilityPreferencesExperiment',
          variant: 'control',
          nextStepId: 3,
          shouldTrack: true,
        }),
      ],
      buttonTarget: 3,
      progressCurrent: 1.5,
      progressMax: 11,
    },
    {
      id: 3,
      prompt: 'How would you rate your sleeping habits?',
      analyticsId: 59,
      response_category_id: 7,
      category: 'select1',
      heading2: 'How would you rate your sleeping habits?',
      buttons: [
        {
          text: 'Excellent',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 'Excellent',
        },
        {
          text: 'Good',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 'Good',
        },
        {
          text: 'Fair',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 'Fair',
        },
        {
          text: 'Poor',
          externalTarget: null,
          internalTarget: 4,
          answerValue: 'Poor',
        },
      ],
      progressCurrent: 2,
      progressMax: 11,
    },
    {
      id: 4,
      prompt: 'How would you rate your physical health?',
      analyticsId: 60,
      response_category_id: 7,
      category: 'select1',
      heading2: 'How would you rate your current physical health?',
      buttons: [
        {
          text: 'Excellent',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 'Excellent',
        },
        {
          text: 'Good',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 'Good',
        },
        {
          text: 'Fair',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 'Fair',
        },
        {
          text: 'Poor',
          externalTarget: null,
          internalTarget: 11,
          answerValue: 'Poor',
        },
      ],
      progressCurrent: 3,
      progressMax: 11,
    },
    // matching questions
    {
      id: 11,
      prompt: 'My gender is',
      analyticsId: 62,
      response_category_id: 4,
      category: 'select1',
      heading2: 'What gender do you identify with?',
      buttons: [
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 2,
        },
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 1,
        },
        {
          text: 'Transgender female',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 4,
        },
        {
          text: 'Transgender male',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 3,
        },
        {
          text: 'Gender queer',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 5,
        },
        {
          text: 'Gender variant',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 6,
        },
        {
          text: 'Other',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 7,
        },
        {
          text: 'Non-binary',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 8,
        },
      ],
      skipText: 'Skip',
      skipExternalTarget: null,
      skipInternalTarget: 12,
      progressCurrent: 4,
      progressMax: 11,
    },
    {
      id: 12,
      prompt: 'Preferred provider gender',
      analyticsId: 57,
      response_category_id: 3,
      category: 'select1',
      heading2: 'What gender would you prefer in a provider?',
      selfServe: {
        field: 'therapistGender',
      },
      buttons: [
        {
          text: 'No preference',
          externalTarget: null,
          internalTarget: 13,
          answerValue: undefined,
        },
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 13,
          answerValue: 2,
        },
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 13,
          answerValue: 1,
        },
        {
          text: "I'm not sure yet",
          externalTarget: null,
          internalTarget: 13,
          answerValue: undefined,
        },
      ],
      progressCurrent: 5,
      progressMax: 11,
    },
    {
      id: 13,
      prompt: 'What is your age?',
      analyticsId: 61,
      response_category_id: 5,
      category: 'dateOfBirth',
      heading2: "What's your date of birth?",
      heading3: 'As a healthcare provider, we must ask this.',
      targets: {
        adult: 14,
        teen: 21,
        under13: 'https://helpnow.talkspace.com/under-13',
        under18GDPR: 42,
      },
      progressCurrent: 6,
      progressMax: 11,
    },
    {
      id: 42,
      category: 'notAvailableInYourRegion',
    },
    // teen consent (age 13 - 18)
    {
      id: 21,
      prompt: 'Consent',
      analyticsId: 562,
      response_category_id: 7, // TODO
      heading2:
        'Based on your age and state, parent or guardian consent may be needed. Does this work for you?',
      category: 'select1',
      buttons: [
        {
          text: 'Yes',
          answerValue: 'Yes',
          externalTarget: null,
          internalTarget: 14,
        },
        {
          text: 'No, show me other resources',
          answerValue: 'No, show me other resources',
          externalTarget: 'https://helpnow.talkspace.com/under-18-no-consent',
          internalTarget: null,
        },
      ],
      progressCurrent: 7,
      progressMax: 11,
    },
    {
      id: 14,
      prompt: QM_COPY.state.prompt,
      analyticsId: 63,
      response_category_id: 6,
      category: 'dropdown',
      heading2: QM_COPY.state.title,
      heading3: QM_COPY.state.subtitle,
      selectPlaceholder: QM_COPY.state.placeholder,
      selectOptions: states,
      buttonText: QM_COPY.state.continue,
      buttonTarget: 19,
      skipExternalTarget: null,
      progressCurrent: 8,
      progressMax: 11,
    },
    {
      id: 19,
      prompt: 'Matches',
      category: 'matches1',
      buttonTarget: 33,
      zeroMatchTarget: 33,
      matchOverlay: true,
      matchOverlayCampaignType: 'quickmatch90_1',
      canMatchOverlayBeSkipped: true,
      showCustomerRegistrationModalWithoutPayment: true,
    },
    {
      id: 33,
      category: 'chooseAndPayClientWeb',
      offerID: OFFER_ID,
      previousStepsUntilMatches: 3,
    },
  ],
};

export default flow;
