import { BrowserRouter } from 'react-router-dom';

const withRouter = (WrappedComponent) =>
  function ReactRouterWrapper(props) {
    return (
      <BrowserRouter>
        <WrappedComponent {...props} />
      </BrowserRouter>
    );
  };

export default withRouter;
