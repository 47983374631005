import { createCookie, readCookie } from 'ts-frontend/utils';

const COOKIE_NAME = '_tsAltAssessmentCTA';

export const altAssessmentCTACookieValues = ['default', 'learn', 'getStarted', 'find'];

export type AltAssessmentCTACookie = 'default' | 'learn' | 'getStarted' | 'find';

export const getAltAssessmentCTACookie = (): AltAssessmentCTACookie | null => {
  const cookie = readCookie(COOKIE_NAME);
  try {
    return JSON.parse(cookie);
  } catch (error) {
    return null;
  }
};

export const setAltAssessmentCTACookie = (value: AltAssessmentCTACookie) => {
  const cookieLifetime = 7 * 24 * 60 * 60; // 7 days
  createCookie(COOKIE_NAME, JSON.stringify(value), cookieLifetime);
};
