/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';
import moment from 'moment';

import { states } from '@/Helpers/locales';
import { isUnderage } from '../../../Helpers/ageHelper';

export const stateOptions = [
  {
    value: 'other',
    label: 'I live outside of the US',
  },
  ...states,
];

export const genderOptions = [
  {
    value: 'M',
    label: 'Male',
  },
  {
    value: 'F',
    label: 'Female',
  },
  {
    value: 'U',
    label: 'Other',
  },
];

export interface EligibilityYupContext {
  flowId?: number;
}

export const formSchema = yup.object().shape({
  firstName: yup.string().required('First name is required.'),
  lastName: yup.string().required('Last name is required.'),
  memberID: yup.string().required('Member ID is required.'),
  state: yup.string().required('Location is required.'),
  country: yup.string().when('state', {
    is: 'other',
    then: (schema) => schema.required('Country is required.'),
    otherwise: (schema) => schema.optional(),
  }),
  dob: yup
    .string()
    .test('invalidDate', 'This is not a valid date.', (value) => {
      if (!value) {
        return true;
      }
      if (!value.match(/\d\d\/\d\d\/\d\d\d\d/gi)) {
        return false;
      }
      const parsed = moment(value, 'MM/DD/YYYY');
      if (!parsed.isValid()) {
        return false;
      }
      return true;
    })
    .test(
      'under13',
      'Age too young.',
      (userInput?: string, testContext?: yup.TestContext<EligibilityYupContext>) => {
        if (!userInput) {
          return true;
        }

        const flowID = testContext?.options?.context?.flowId;
        const isUnderAge = flowID && isUnderage(userInput, flowID);

        return !isUnderAge;
      }
    )
    .required('Date of Birth is required.'),
});

export const discoveryFormSchema = formSchema.shape({
  memberID: yup.string().optional(),
  gender: yup.string().required('Sex is required.'),
});
