import { HtmlMetaManager } from '@talkspace/react-toolkit';
import Setup from '@/Components/Setup';
import Routes from '@/routes';

function Application() {
  return (
    <Setup>
      <HtmlMetaManager />
      <Routes />
    </Setup>
  );
}

export default Application;
