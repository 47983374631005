import { FunctionComponent } from 'react';
import { EmotionStyle } from '../../core/styled';
import RoundClock from '../icons/RoundClock';
import CircledCheckMark from '../Svgs/CircledCheckMark';
import View from '../View';

const TherapistStatus: FunctionComponent<{
  status: string;
  style: EmotionStyle;
}> = ({ status, style }) => (
  <View style={style}>
    {(() => {
      switch (status) {
        case 'available':
          return <CircledCheckMark circleStrokeWidth={3} />;
        case 'unavailable':
          return <RoundClock strokeWidth={3} />;
        default:
          return null;
      }
    })()}
  </View>
);

export default TherapistStatus;
