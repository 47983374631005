import { useQuery } from 'react-query';
import { ImplicitBusinessHoursByDay } from 'ts-frontend/types';
import { getTherapistImplicitBusinessHours } from '../Helpers/apiService';
import { implicitBusinessHoursQueryKey } from './queryKeys';

const fetchImplicitBusinessHours =
  (providerID: number) => async (): Promise<ImplicitBusinessHoursByDay> => {
    const implicitBusinessHours = await getTherapistImplicitBusinessHours({
      therapistIDs: providerID.toString(),
      offset: new Date().getTimezoneOffset() / -60,
    });

    return implicitBusinessHours[providerID];
  };

const useQueryImplicitBusinessHours = (providerID: number) =>
  useQuery<ImplicitBusinessHoursByDay, Error>({
    queryKey: implicitBusinessHoursQueryKey(providerID),
    queryFn: fetchImplicitBusinessHours(providerID),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: false,
  });

export default useQueryImplicitBusinessHours;
