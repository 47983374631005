import {
  Modal,
  View,
  Button,
  Spinner,
  Large,
  ExtraHuge,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { useMemo } from 'react';

interface Props {
  isCheckingCoverage: boolean;
  showRetryButton: boolean;
  onHandleRetry: () => void;
  visible: boolean;
}

const CheckCoverage = () => (
  <>
    <View
      alignSelf="center"
      style={{
        width: 54,
        height: 54,
        borderRadius: '50%',
        boxShadow: '0px 4px 14px -4px rgba(52, 43, 80, 0.15)',
      }}
    >
      <Spinner />
    </View>

    <ExtraHuge style={{ marginBottom: 12, marginTop: 26 }}>Checking your coverage</ExtraHuge>
    <Large style={{ marginBottom: 24 }}>
      This may take up to a minute. We appreciate your patience.
    </Large>
  </>
);

const StatusModal = ({ isCheckingCoverage, showRetryButton, onHandleRetry, visible }: Props) => {
  const { isMobile } = useWindowWidthState();

  const panelStyles = useMemo(() => {
    return {
      wrapperStyle: {
        position: 'relative',
        width: isMobile ? 327 : 'initial',
        minWidth: 327,
        minHeight: 'initial',
        borderRadius: 10,
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 24,
        paddingRight: 24,
      },
      contentViewStyle: {
        padding: 0,
        margin: 0,
      },
    };
  }, [isMobile]);

  return (
    <Modal
      isVisible={visible}
      onBackdropPress={() => false}
      titleText="Retry"
      underlayStyle={{
        backgroundColor: `rbga(34, 47, 45, 0.2)`,
        alignItems: 'center',
      }}
    >
      <Modal.Panel {...panelStyles} showCloseButton={false}>
        <View
          style={{ textAlign: 'center', margin: 0, padding: 0, alignItems: 'center' }}
          tabIndex={0}
        >
          {isCheckingCoverage && <CheckCoverage />}
          {showRetryButton && (
            <>
              <ExtraHuge style={{ marginBottom: 12 }}>
                We’re having trouble reaching your insurer
              </ExtraHuge>
              <Large style={{ marginBottom: 24 }}>Please submit your information again</Large>
              <Button dataQa="retryCheckCoverage" onPress={onHandleRetry}>
                Retry
              </Button>
            </>
          )}
        </View>
      </Modal.Panel>
    </Modal>
  );
};

export default StatusModal;
