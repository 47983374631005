import Svg, { Path } from 'svgs';

const MagnifyingGlass = ({ color = '#758DD7', width = 34, height = 34, ...otherProps }) => (
  <Svg width={width} height={height} {...otherProps}>
    <Path
      d="M31.155 31.155a1.507 1.507 0 01-2.131 0L20.7 22.831A12.927 12.927 0 0022.83 20.7l8.324 8.324a1.507 1.507 0 010 2.131zM12.787 1.965c5.976 0 10.82 4.845 10.82 10.822 0 5.976-4.844 10.82-10.82 10.82-5.977 0-10.822-4.843-10.822-10.82S6.81 1.965 12.787 1.965zm3.855 5.769a.983.983 0 00-.983-.983H6.77a.983.983 0 000 1.965h8.889c.543 0 .983-.44.983-.982zm1.172 0c0 .542.44.982.982.982h.01a.983.983 0 100-1.965h-.01a.983.983 0 00-.982.983zM5.788 11.102c0 .543.44.983.982.983h12.035a.983.983 0 100-1.966H6.77a.983.983 0 00-.982.983zm0 3.369c0 .542.44.982.982.982h12.036a.982.982 0 100-1.965H6.77a.982.982 0 00-.982.983zm13.018 2.386h-8.89a.982.982 0 100 1.965h8.89a.982.982 0 100-1.965zm-11.044.982a.982.982 0 00-.982-.982h-.01a.982.982 0 100 1.965h.01c.542 0 .982-.44.982-.983zm17.81-5.052C25.572 5.724 19.85 0 12.787 0S0 5.725 0 12.787c0 7.062 5.725 12.786 12.786 12.786 2.157 0 4.314-.542 6.248-1.627l8.601 8.6a3.472 3.472 0 004.91-4.91l-8.6-8.602a12.757 12.757 0 001.627-6.247z"
      transform="translate(-46 -442) translate(28 90) translate(0 331) translate(18 21)"
      fill={color}
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </Svg>
);

export default MagnifyingGlass;
