import { FunctionComponent } from 'react';
import { View, Tiny, useEmotionTheme } from '@talkspace/react-toolkit';
import { EmotionStyle } from '@/core/styled';
import Rx from '@/Components/assets/Rx';

interface Props {
  shouldAnnounce?: boolean;
  style?: EmotionStyle;
}

const PsychiatryRxMessage: FunctionComponent<Props> = ({ shouldAnnounce, style }) => {
  const { colors } = useEmotionTheme();
  return (
    <View
      row
      style={{ textAlign: 'left', width: '100%', ...style }}
      // aria=live="polite" is generally preferred but "assertive" seems to be necessary to ensure that screen readers give the text
      // priority over react-select's hidden accessibility texts
      aria-live={shouldAnnounce ? 'assertive' : undefined}
    >
      <Rx style={{ marginRight: 14 }} />
      <Tiny style={{ width: 'calc(100% - 68px)', color: colors.permaWaikawaGrey }}>
        {/* 68px is the width of Rx */}
        Talkspace prescribers are able to prescribe all medications, with the exclusion of
        controlled substances. Controlled substances include, but are not limited to: Adderall,
        Ativan, Concerta, Klonopin, Librium, Tranxene, Ritalin, Vyvanse, Valium, Xanax, Lithium.{' '}
        <br />
        Psychiatry is available for members 18 and over.
      </Tiny>
    </View>
  );
};

export default PsychiatryRxMessage;
