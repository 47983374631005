import { VoidFunctionComponent } from 'react';
import { View, ExtraHuge, Large, Button, useEmotionTheme } from '@talkspace/react-toolkit';
import styled from '../../core/styled';
import Assistance from '../Assistance/Assistance';
import appConfigs from '../../utils/configs';

export type EligibilityError = 'generalError' | 'redeemsReachedForEmailError';

interface OneFormEligibilityErrorProps {
  handleOnPressReturn?: () => void;
  error?: EligibilityError;
  linkToFAQ?: string;
}

const OneFormEligibilityErrorWrapper = styled(View)({
  marginTop: 111,
  alignItems: 'center',
});

const errorsMap = {
  generalError: {
    title: 'We’re unable to locate your coverage',
    message:
      'Unfortunately, we can’t verify your coverage. Please try again or contact your health insurance provider to confirm if Talkspace is a covered service',
  },
  redeemsReachedForEmailError: {
    title: 'There was an error while registering',
    message:
      'Too many repeating attempts for registration. If you still have issues joining Talkspace please contact Customer Support',
  },
};

const OneFormEligibilityError: VoidFunctionComponent<OneFormEligibilityErrorProps> = ({
  handleOnPressReturn,
  error = 'generalError',
  linkToFAQ,
}) => {
  const { colors } = useEmotionTheme();
  const { title, message } = errorsMap[error];
  return (
    <OneFormEligibilityErrorWrapper>
      <View align="center" style={{ maxWidth: 335 }}>
        <ExtraHuge as="h1" style={{ maxWidth: 296, textAlign: 'center' }}>
          {title}
        </ExtraHuge>
        <Large
          as="h2"
          variant="largeDarkGrey"
          style={{ marginTop: 19, fontWeight: 400, textAlign: 'center' }}
        >
          {message}
        </Large>
        {handleOnPressReturn && (
          <Button
            dataQa="returnButton"
            text="Return"
            roundedFocusStyle
            primaryColor={colors.green}
            style={{ marginTop: 40, width: '100%' }}
            onPress={handleOnPressReturn}
          />
        )}
        <Assistance
          linkToFAQ={linkToFAQ}
          supportEmail={appConfigs.supportEmailLinks.verificationSupport}
        />
      </View>
    </OneFormEligibilityErrorWrapper>
  );
};

export default OneFormEligibilityError;
