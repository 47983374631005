import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  ExtraHuge,
  Input,
  Large,
  Button,
  BaseButton,
  Standard,
  View,
  Mini,
  useWindowWidthState,
  useEmotionTheme,
  useUniqueID,
} from '@talkspace/react-toolkit';
import { getGDPRProps, storeGDPRProps } from 'ts-frontend/helpers/gdpr';
import GDPRConsentCheckbox from '@talkspace/react-toolkit/src/components/GDPRConsentCheckbox';
import { useHistory } from '@/core/routerLib';

import styled from '../../core/styled';

export interface EligibilityEmailProps {
  email: string;
  isLoading: boolean;
  onSubmit: (value: string) => void;
}

const Container = styled(View)({ '&:focus': { outline: 'none' } });

const EligibilityEmail: React.VFC<EligibilityEmailProps> = ({ email, isLoading, onSubmit }) => {
  const history = useHistory();
  const { isMobile } = useWindowWidthState();
  const titleID = useUniqueID('header1Id');
  const header2Id = useUniqueID('header2Id');
  const notSharedTextId = useUniqueID('notSharedTextId');
  const { colors, spacing } = useEmotionTheme();

  const onSecondaryButtonPress = () => {
    history.goBack();
  };

  const { isGDPR, consentGDPRProcessing } = getGDPRProps();
  const formParams = useForm<{ consentGDPRProcessing: boolean; email: string }>({
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().email().required(),
        ...(isGDPR ? { consentGDPRProcessing: yup.boolean().oneOf([true]).required() } : {}),
      })
    ),
    defaultValues: { email, consentGDPRProcessing },
  });
  const emailValue = formParams.watch('email');
  const consentGDPRProcessingValue = formParams.watch('consentGDPRProcessing');
  return (
    <Container aria-labelledby={titleID} tabIndex={-1}>
      <View style={{ marginBottom: 35, maxWidth: isMobile ? 320 : undefined }}>
        <ExtraHuge as="h1" style={{ marginBottom: 28 }}>
          Enter your email address
        </ExtraHuge>
        <Large id={header2Id} as="h2" variant="largeDarkGrey" style={{ fontWeight: 400 }}>
          Please enter the email address you use with your employer or organization providing
          Talkspace as a benefit.
        </Large>
      </View>
      <FormProvider {...formParams}>
        <form
          onSubmit={formParams.handleSubmit(() => {
            if (isGDPR) {
              storeGDPRProps({ consentGDPRProcessing: consentGDPRProcessingValue });
            }
            onSubmit(emailValue);
          })}
        >
          <View style={{ marginBottom: 40, maxWidth: isMobile ? 320 : undefined }}>
            <Input
              type="email"
              autoFocus
              dataQa="emailInput"
              ariaDescribedBy={`${header2Id} ${notSharedTextId}`}
              ariaRequired
              wrappedInputProps={{
                label: 'Email',
                errorMessage: 'Invalid email address',
                isError: Boolean(formParams.formState.errors.email),
                containerStyle: { width: '100%' },
                inputStyle: { width: '100%', color: colors.black },
                wrapperStyle: { width: '100%', borderColor: colors.periwinkleGrey },
              }}
              placeholder="Email"
              value={emailValue}
              onChange={(e) => {
                formParams.clearErrors('email');
                formParams.setValue('email', e.target.value);
              }}
            />
            <Mini id={notSharedTextId} style={{ marginTop: 5, textAlign: 'left' }}>
              This information will not be shared with your employer.
            </Mini>
            {isGDPR && (
              <GDPRConsentCheckbox type="processing" style={{ marginTop: spacing('space300') }} />
            )}
          </View>
          {emailValue && (
            <Button
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
              style={{ backgroundColor: colors.green, width: '100%' }}
              primaryColor={colors.green}
              roundedFocusStyle
            >
              Next
            </Button>
          )}
        </form>
      </FormProvider>
      <BaseButton
        onPress={onSecondaryButtonPress}
        style={{ borderRadius: 5 }}
        primaryColor={colors.gray}
        roundedFocusStyle
      >
        <Standard variant="standardDarkGrey" style={{ marginTop: 17, alignSelf: 'center' }}>
          Return
        </Standard>
      </BaseButton>
    </Container>
  );
};

export default EligibilityEmail;
