import { Large } from '@talkspace/react-toolkit';
// css is needed in this component to allow for premature flow configs (aka strings containing html)
import './Content.css';

const Content = ({ paragraphs }: { paragraphs: string[] }) => (
  <>
    {paragraphs.map((paragraph, index) => (
      <Large
        style={{ maxWidth: 430, fontWeight: 400, marginBottom: 10 }}
        key={String(index)}
        dangerouslySetInnerHTML={{ __html: paragraph }}
      />
    ))}
  </>
);

export default Content;
