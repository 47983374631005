import { QMFlow } from './types';
import { initFlowConfig } from './utils';

/** Eligibility by geolocation */
export const FLOW_69_B2B_LOCATION_ELIGIBILITY = 69;

const flow: QMFlow = {
  flowId: FLOW_69_B2B_LOCATION_ELIGIBILITY,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
  },
  steps: [
    {
      id: 1,
      prompt: "Let's confirm your eligibility",
      analyticsId: 94,
      category: 'locationEligibility',
      internalTarget: 2,
    },
    {
      id: 2,
      prompt: 'Something went wrong',
      analyticsId: 95,
      category: 'locationEligibilityError',
      internalTarget: 3,
    },
    {
      id: 3,
      prompt:
        'Enter the access code or keyword provided to gain access to Talkspace at no charge through your regional program',
      analyticsId: 96,
      category: 'locationEligibilityManualAccessCode',
      // Link to request access information
      externalTarget:
        'https://docs.google.com/forms/d/e/1FAIpQLSd7Xi23BPxTWjHeniq2nUIEA05G6b_3pGmWaFJgHyKm_skqGw/viewform',
    },
  ],
};

export default flow;
