import { QMFlow } from './types';
import { initFlowConfig } from './utils';

export const FLOW_79_CONSUMER_PANIC_DISORDER_ASSESSMENT = 79;

const flow: QMFlow = {
  flowId: FLOW_79_CONSUMER_PANIC_DISORDER_ASSESSMENT,
  flowConfig: {
    ...initFlowConfig,
    isB2B: false,
    isTeen: true,
    surveyID: 2,
    removeNavBar: true,
  },
  steps: [
    {
      id: 1,
      category: 'surveyIntroduction',
      buttonTarget: 2,
    },
    {
      id: 2,
      hideBackButton: true,
      category: 'surveyWizard',
      buttonTarget: 3,
    },
    {
      id: 3,
      category: 'surveyResults',
    },
  ],
};

export default flow;
