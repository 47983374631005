export const getFileExtensionFromFileType = (fileType: string) => fileType.split('/')[1];

export const uploadFileToS3 = async (signedRequestUrl: string, file: File) => {
  const body = new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
  const response = await fetch(signedRequestUrl, {
    method: 'PUT',
    body,
  });
  if (!response.ok) throw new Error('S3 upload error');
};
