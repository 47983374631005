import Svg, { Path } from 'svgs';

const TherapistAvatarOverlay = ({ size = 115, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 115 115" {...props}>
    <g id="TherapistAvatarOverlay" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <Path
        id="Line-TherapistAvatarOverlay"
        d="M-161.526946,-597.5 L-161.526946,173 C-161.526946,189.129476 -148.582177,202.23554 -132.514783,202.496048 L-132.026946,202.5 L97.473,202.5 L97.4730539,198.5 L106.473054,203 L97.4730539,207.5 L97.473,203.5 L-132.026946,203.5 C-148.703184,203.5 -162.253521,190.116426 -162.52286,173.504373 L-162.526946,173 L-162.526946,-597.5 L-161.526946,-597.5 Z"
        fill="#5C84FB"
        fillRule="nonzero"
      />
      <Path
        d="M10,14 C4.4771525,14 0,18.4771525 0,24 C0,29.5228475 4.4771525,34 10,34 C15.5228475,34 20,29.5228475 20,24 C20,18.4771525 15.5228475,14 10,14 Z M10,20 C12.209139,20 14,21.790861 14,24 C14,26.209139 12.209139,28 10,28 C7.790861,28 6,26.209139 6,24 C6,21.790861 7.790861,20 10,20 Z"
        id="Oval-TherapistAvatarOverlay"
        fill="#BCCCFF"
        fillRule="nonzero"
      />
      <Path
        d="M15.8122404,70.4981468 C17.101246,70.0033439 18.5473084,70.6471724 19.0421113,71.936178 L19.9380312,74.2701291 L22.2719823,73.3742092 C23.5609878,72.8794063 25.0070503,73.5232348 25.5018532,74.8122404 C25.9966561,76.101246 25.3528276,77.5473084 24.063822,78.0421113 L21.7298709,78.9380312 L22.6257908,81.2719823 C23.1205937,82.5609878 22.4767652,84.0070503 21.1877596,84.5018532 C19.898754,84.9966561 18.4526916,84.3528276 17.9578887,83.063822 L17.0619688,80.7298709 L14.7280177,81.6257908 C13.4390122,82.1205937 11.9929497,81.4767652 11.4981468,80.1877596 C11.0033439,78.898754 11.6471724,77.4526916 12.936178,76.9578887 L15.2701291,76.0619688 L14.3742092,73.7280177 C13.8794063,72.4390122 14.5232348,70.9929497 15.8122404,70.4981468 Z"
        id="Path-TherapistAvatarOverlay"
        fill="#C0E3E0"
      />
      <Path
        d="M108,38.5 C104.410149,38.5 101.5,41.4101491 101.5,45 C101.5,48.5898509 104.410149,51.5 108,51.5 C111.589851,51.5 114.5,48.5898509 114.5,45 C114.5,41.4101491 111.589851,38.5 108,38.5 Z M108,43.5 C108.828427,43.5 109.5,44.1715729 109.5,45 C109.5,45.8284271 108.828427,46.5 108,46.5 C107.171573,46.5 106.5,45.8284271 106.5,45 C106.5,44.1715729 107.171573,43.5 108,43.5 Z"
        id="Oval-1-TherapistAvatarOverlay"
        fill="#ADE953"
        fillRule="nonzero"
      />
    </g>
  </Svg>
);
export default TherapistAvatarOverlay;
