import { FunctionComponent } from 'react';
import * as React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  SelectRounded,
  Button as AccessibleButton,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { OptionsType } from 'react-select';
import styled from '@/core/styled';

const StyledAccessibleButton = styled(AccessibleButton)(({ theme: { colors } }) => {
  return {
    transition: 'all 200ms ease-in-out',
    '&:not([disabled]):hover': {
      backgroundColor: colors.permaTropicalRainForest,
    },
  };
});

const MultiSelectDropdown: FunctionComponent<
  React.ComponentProps<typeof SelectRounded> & { ariaLabelledBy?: string }
> = ({ options, onChange, onBlur, values, ariaLabelledBy, dataQa }) => (
  <SelectRounded
    dataQa={dataQa}
    aria-labelledby={ariaLabelledBy}
    options={options as OptionsType<any>}
    onChange={onChange}
    onBlur={onBlur}
    value={values}
    width="100%"
    wrapperStyle={{ maxWidth: 320, marginTop: 0 }}
    isMulti
  />
);

export const ContinueButton: FunctionComponent<{
  onClick: (event: any) => void;
  dataQa?: string;
  disabled?: boolean;
}> = ({ onClick, dataQa, children, disabled }) => {
  const { colors } = useEmotionTheme();
  return (
    <StyledAccessibleButton
      dataQa={dataQa}
      onClick={onClick}
      style={{
        marginTop: 12,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        fontWeight: 700,
      }}
      roundedFocusStyle
      primaryColor={colors.green}
      disabled={disabled}
    >
      {children}
    </StyledAccessibleButton>
  );
};

export default MultiSelectDropdown;
