import { FunctionComponent, useMemo, useState, useEffect } from 'react';
import {
  View,
  Button,
  Large,
  ExtraHuge,
  AvatarUpdateCoverage,
  AvatarWithFloatingShapes,
} from '@talkspace/react-toolkit';
import { sleep } from 'ts-frontend/helpers';
import styled from '@/core/styled/index';
import ReactFrameService from '@/utils/reactFrame/ReactFrameService';
import { getQMFunnelName } from '../../Helpers/flowsHelper';
import { trackUpdateCoverageEvent } from '../../utils/analytics/events';

const REDIRECT_SLEEP_MS = 1000;

interface TherapistSelectedConfirmationProps {
  therapistImageURL: string;
  defaultImageURL: string;
  buttonHandler: () => void;
  buttonText: string;
  title: string;
  subtitleFirstLine: string;
  subtitleSecondLine: string;
  providerAvailable?: boolean;
  hasRemainingSessions?: boolean;
  newRoomID?: number;
  oldRoomID?: number;
  isNewProvider: boolean;
  isUpdateCoverageFlow?: boolean;
  isMBHIneligibilityFlow?: boolean;
  isReactivationFlow?: boolean;
  isCreateRoomFlow?: boolean;
  sessionsCanceled: boolean | undefined;
  sessionsTransferred: boolean | undefined;
  previousTherapistImageURL: string;
}

const Title = styled(ExtraHuge)({ maxWidth: 300, textAlign: 'center', fontSize: 29 });

const SelectButton = styled(Button)({ marginTop: 40 });

const SecondaryButton = styled(Button)(({ theme: { colors } }) => {
  return {
    color: colors.green,
    backgroundColor: 'transparent',
    border: 'none',
  };
});

const StyledLine = styled(Large)({ maxWidth: 320, textAlign: 'center', marginTop: 10 });

const TherapistSelectedConfirmation: FunctionComponent<TherapistSelectedConfirmationProps> = ({
  therapistImageURL,
  defaultImageURL,
  buttonHandler,
  buttonText,
  title,
  subtitleFirstLine,
  subtitleSecondLine,
  providerAvailable,
  hasRemainingSessions,
  newRoomID,
  oldRoomID,
  isNewProvider,
  isUpdateCoverageFlow,
  isMBHIneligibilityFlow,
  isReactivationFlow,
  isCreateRoomFlow,
  sessionsCanceled,
  sessionsTransferred,
  previousTherapistImageURL = '',
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const shouldRedirectToMBHSuccess =
    isMBHIneligibilityFlow &&
    oldRoomID &&
    newRoomID &&
    sessionsCanceled !== undefined &&
    sessionsTransferred !== undefined;

  useEffect(() => {
    if (shouldRedirectToMBHSuccess) {
      setIsLoading(true);
      try {
        window.onbeforeunload = null;
        ReactFrameService.instance().closePopup({
          navigateTo: 'modal',
          metadata: {
            modalName: 'eligibilityWarningSuccess',
            roomID: oldRoomID,
            newRoomID,
            isNewProvider,
            sessionsCanceled,
            sessionsTransferred,
          },
        });
      } catch {
        setIsLoading(false);
      }
    }
    return () => {};
  }, [
    shouldRedirectToMBHSuccess,
    oldRoomID,
    newRoomID,
    isNewProvider,
    sessionsCanceled,
    sessionsTransferred,
  ]);

  const onPressHandler = () => {
    try {
      setIsLoading(true);
      window.onbeforeunload = null;
      buttonHandler();
    } catch {
      setIsLoading(false);
    }
  };

  const newRoomHandler = async () => {
    if (!newRoomID || !oldRoomID) return;

    try {
      setIsLoading(true);
      window.onbeforeunload = null;
      await trackUpdateCoverageEvent(+oldRoomID, { providerAvailable, hasRemainingSessions });
      await sleep(REDIRECT_SLEEP_MS);
      ReactFrameService.instance().closePopup({
        navigateTo: 'room',
        metadata: {
          roomID: newRoomID,
          source: getQMFunnelName({
            isCreateRoomFlow: !!isCreateRoomFlow,
            isReactivationFlow: !!isReactivationFlow,
            isUpdateCoverageFlow: !!isUpdateCoverageFlow,
            isMBHIneligibilityFlow: !!isMBHIneligibilityFlow,
          }),
        },
      });
    } catch {
      setIsLoading(false);
    }
  };

  const providerUnavailableHandler = async () => {
    if (!oldRoomID) return;

    try {
      setIsLoading(true);
      window.onbeforeunload = null;
      await trackUpdateCoverageEvent(+oldRoomID, { providerAvailable, hasRemainingSessions });
      await sleep(REDIRECT_SLEEP_MS);
      ReactFrameService.instance().closePopup({
        navigateTo: 'modal',
        metadata: {
          modalName: 'findingProvider',
          roomID: oldRoomID,
          hasRemainingSessions: Boolean(hasRemainingSessions),
        },
      });
    } catch {
      setIsLoading(false);
    }
  };

  const oldRoomHandler = async () => {
    if (!oldRoomID) return;

    try {
      setIsLoading(true);
      window.onbeforeunload = null;
      await trackUpdateCoverageEvent(+oldRoomID, { providerAvailable, hasRemainingSessions });
      await sleep(REDIRECT_SLEEP_MS);
      ReactFrameService.instance().closePopup({
        navigateTo: 'room',
        metadata: {
          roomID: oldRoomID,
          source: getQMFunnelName({
            isCreateRoomFlow: !!isCreateRoomFlow,
            isReactivationFlow: !!isReactivationFlow,
            isUpdateCoverageFlow: !!isUpdateCoverageFlow,
            isMBHIneligibilityFlow: !!isMBHIneligibilityFlow,
          }),
        },
      });
    } catch {
      setIsLoading(false);
    }
  };

  const subtitleCopy = useMemo(() => {
    if (providerAvailable && hasRemainingSessions) {
      return (
        <>
          <StyledLine variant="largeDarkGrey">
            Since you changed your coverage, we created a new room for you. Don't worry, you can
            access your old messages in your previous room.
          </StyledLine>
          <StyledLine variant="largeDarkGrey">
            Before you start your conversation with your provider in your new room, please finish
            services in your old room first. You can use any scheduled sessions, in progress
            sessions, or session credits in your old room. After you finish services in your old
            room, you can start services in your new room.
          </StyledLine>
          <StyledLine variant="largeDarkGrey">
            Both rooms are now accessible from your dashboard.
          </StyledLine>
        </>
      );
    }
    if (providerAvailable && !hasRemainingSessions) {
      return (
        <>
          <StyledLine variant="largeDarkGrey">
            Since you changed your coverage, we created a new room for you. Don't worry, you can
            access your old messages in your previous room. Just make sure you start all new
            conversations in your new room.
          </StyledLine>
          <StyledLine variant="largeDarkGrey">
            Both rooms are now accessible from your dashboard.
          </StyledLine>
        </>
      );
    }
    if (!providerAvailable && !hasRemainingSessions) {
      return (
        <>
          <StyledLine variant="largeDarkGrey">
            Unfortunately, your previous provider is no longer available. We know this might feel
            disappointing and we understand that it's hard to start over with a new provider.
          </StyledLine>
          <StyledLine variant="largeDarkGrey">
            We also know that finding another provider is hard. That's why we are finding one for
            you. We will connect you with another experienced provider soon so that you can continue
            making progress.
          </StyledLine>
        </>
      );
    }
    if (!providerAvailable && hasRemainingSessions) {
      return (
        <>
          <StyledLine variant="largeDarkGrey">
            Unfortunately, your previous provider is no longer available. We know this might feel
            disappointing and we understand that it's hard to start over with a new provider.
          </StyledLine>
          <StyledLine variant="largeDarkGrey">
            We also know that finding another provider is hard. That's why we are finding one for
            you. We will connect you with another experienced provider soon so that you can continue
            making progress.
          </StyledLine>
          <StyledLine variant="largeDarkGrey">
            While we find you a new provider, you can use any remaining sessions or credits in your
            old room with your current provider.
          </StyledLine>
        </>
      );
    }

    return <></>;
  }, [providerAvailable, hasRemainingSessions]);

  const primaryButtonText = useMemo(() => {
    if (providerAvailable && hasRemainingSessions) {
      return 'Finish services in my old room';
    }
    if (providerAvailable && !hasRemainingSessions) {
      return 'Go to my new room';
    }
    return 'Continue';
  }, [providerAvailable, hasRemainingSessions]);

  const primaryButtonHandler = () => {
    if (providerAvailable && hasRemainingSessions) {
      return oldRoomHandler;
    }
    if (providerAvailable && !hasRemainingSessions) {
      return newRoomHandler;
    }
    return providerUnavailableHandler;
  };

  if (isUpdateCoverageFlow) {
    return (
      <View align="center">
        <AvatarUpdateCoverage
          imageURL={previousTherapistImageURL}
          defaultImageURL={defaultImageURL}
          providerAvailable={Boolean(providerAvailable)}
        />
        <Title>
          {providerAvailable
            ? "Your coverage is updated and you've got a new room"
            : 'Your provider is unavailable so we are finding you a new one'}
        </Title>
        {subtitleCopy}
        <View style={{ marginBottom: 40 }}>
          <SelectButton
            text={primaryButtonText}
            isLoading={isLoading}
            onPress={primaryButtonHandler()}
            dataQa="therapistSelectedConfirmationPrimaryButtonPress"
            disabled={isLoading}
          />
          {/* Secondary button for Available and Sessions */}
          {providerAvailable && hasRemainingSessions && (
            <SecondaryButton
              text="Check out my new room"
              isLoading={isLoading}
              onPress={newRoomHandler}
              dataQa="therapistSelectedConfirmationSecondaryButtonPress"
              disabled={isLoading}
            />
          )}
        </View>
      </View>
    );
  }

  if (shouldRedirectToMBHSuccess) {
    return null;
  }

  return (
    <View align="center">
      <AvatarWithFloatingShapes imageURL={therapistImageURL} />
      <Title>{title}</Title>
      {subtitleFirstLine && <StyledLine variant="largeDarkGrey">{subtitleFirstLine}</StyledLine>}
      <StyledLine variant="largeDarkGrey">{subtitleSecondLine}</StyledLine>
      <SelectButton
        text={buttonText}
        isLoading={isLoading}
        onPress={onPressHandler}
        dataQa="therapistSelectedConfirmationV1ButtonPress"
        disabled={isLoading}
      />
    </View>
  );
};

export default TherapistSelectedConfirmation;
