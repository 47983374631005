/* eslint-disable import/prefer-default-export */
import { useEffect, useRef } from 'react';
import { videoFirstStates } from '../util';
import { useAvailableModalities } from './useAvailableModalities';
import { UseForceVideoModalityOptions } from '../types';

/**
 * If the user is from a state that requires that their first session be a live video-based one,
 * this hook will automatically choose the appropriate modality.
 */
export const useForceVideoModality = ({
  clientState,
  onContinuePress,
  allowedModalities,
}: UseForceVideoModalityOptions) => {
  const ref = useRef({
    continuePressed: false,
  });

  const availableModalities = useAvailableModalities({
    allowedModalities,
  });

  useEffect(() => {
    if (clientState && !videoFirstStates.includes(clientState)) {
      return;
    }

    const videoModality = allowedModalities?.find((modality) => modality.name === 'video');
    if (videoModality) {
      if (!ref.current.continuePressed) {
        ref.current.continuePressed = true;
        onContinuePress(videoModality.name);
      }
    }
  }, [availableModalities, allowedModalities, onContinuePress, clientState]);
};
