import {
  Button,
  ExtraHuge,
  Large,
  View,
  useWindowWidthState,
  spacing,
} from '@talkspace/react-toolkit';

import { TeensHereToHelpStep, UpdateStep } from '../../../Flows';
import styled from '@/core/styled';
import SeparatingThreeDots from './SeparatingThreeDots';
import NeedHelpPill from '../../NeedHelpPill';

const Styled = {
  Container: styled(View)(({ isMobile }: { isMobile: boolean }) => {
    return {
      maxWidth: 350,
      minHeight: isMobile ? 'calc(100vh - 260px)' : undefined,
      justifyContent: 'space-between',
    };
  }),
  Title: styled(ExtraHuge)({ marginBottom: 12 }),
  ContinueButton: styled(Button)({
    marginBottom: 16,
    marginTop: 24,
    width: '100%',
  }),
  BulletPointsContainer: styled(View)({
    flexDirection: 'column',
  }),
  BulletPointsAndTextContainer: styled(View)({
    flexDirection: 'row',
    gap: spacing.space150,
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  BulletPointsTextBackground: styled(View)(({ theme: { colors } }) => {
    return {
      width: 38,
      height: 38,
      borderRadius: 19,
      backgroundColor: colors.permaAtlantisNew,
      justifyContent: 'center',
    };
  }),
  BulletPointsText: styled(Large)(({ theme: { colors } }) => {
    return {
      fontFamily: 'SF Pro Display',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '21.48px',
      textAlign: 'center',
      color: colors.white,
    };
  }),
  BulletPoint: ({
    number,
    text,
    showDots,
  }: {
    number: string;
    text: string;
    showDots?: boolean;
  }) => (
    <Styled.BulletPointsContainer>
      <Styled.BulletPointsAndTextContainer>
        <Styled.BulletPointsTextBackground>
          <Styled.BulletPointsText>{number}</Styled.BulletPointsText>
        </Styled.BulletPointsTextBackground>
        <Large style={{ flex: 1, textAlign: 'start' }}>{text}</Large>
      </Styled.BulletPointsAndTextContainer>
      {showDots && <SeparatingThreeDots />}
    </Styled.BulletPointsContainer>
  ),
};

interface Props {
  step: TeensHereToHelpStep;
  updateStep: UpdateStep;
}

const TeensHereToHelp = ({ step, updateStep }: Props) => {
  const { isMobile } = useWindowWidthState();
  return (
    <Styled.Container isMobile={isMobile}>
      <View align="stretch">
        <Styled.Title>We’re here to help</Styled.Title>
        <Large variant="largeGrey950">
          Over 10,310 teens have already accessed free therapy. It only takes 2 minutes.
        </Large>
        <View style={{ marginTop: spacing.space300 }} align="stretch" justify="center">
          <Styled.BulletPoint showDots number="1" text="Find out if you’re eligible" />
          <Styled.BulletPoint showDots number="2" text="Create your profile and account" />
          <Styled.BulletPoint number="3" text="Connect with a therapist" />
        </View>
      </View>
      <View style={{ marginTop: 40 }}>
        <NeedHelpPill />
        <Styled.ContinueButton
          text="Get started"
          onPress={() => updateStep(step.buttonTarget)}
          dataQa="getStartedButton"
        />
      </View>
    </Styled.Container>
  );
};
export default TeensHereToHelp;
