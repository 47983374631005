import { FunctionComponent, ReactNode, useCallback } from 'react';
import {
  TouchableView,
  View,
  Large,
  ExtraHuge,
  useUniqueID,
  Huge,
  useEmotionTheme,
  Small,
} from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import styled from '../../core/styled';

const Container = styled(TouchableView)<{ bgColor?: string; smallVariant?: boolean }>(
  ({ bgColor, smallVariant, theme }) => {
    return {
      height: smallVariant ? undefined : 165,
      backgroundColor: bgColor || theme.colors.extraLightGrey,
      borderRadius: 12,
      padding: smallVariant ? '24px 16px 24px 24px' : '22px 16px 20px 21px',
      position: 'relative',
      transition: '200ms ease-in-out',
      cursor: 'pointer',
      textAlign: 'left',
      flexDirection: smallVariant ? 'row' : 'column',
      '&:hover': {
        boxShadow: `0 12px 40px -10px ${bgColor}`,
      },
    };
  }
);

const Title = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 5,
});

const Desc = styled(View)({
  marginRight: 60,
});

const Continue = styled(View)({
  position: 'absolute',
  bottom: 20,
  right: 22,
});

const ContinueText = styled(Large)(
  ({
    theme: {
      colors: { blueStone },
    },
  }) => {
    return {
      color: blueStone,
    };
  }
);

const StyledExtraHuge = styled(ExtraHuge)(
  ({
    theme: {
      colors: { blueStone },
    },
  }) => {
    return {
      color: blueStone,
    };
  }
);

interface TherapyTypeCardProps {
  title: string;
  description: string;
  icon: ReactNode;
  bgColor?: string;
  focusColor: string;
  onClick?: () => void;
  dataQa: string;
}

const TherapyTypeCard: FunctionComponent<TherapyTypeCardProps> = ({
  title,
  description,
  icon,
  bgColor,
  focusColor,
  onClick,
  dataQa,
}) => {
  const {
    colors: { permaCello },
  } = useEmotionTheme();
  const { nycTeensQuickmatch } = useFlags();
  const headerID = useUniqueID('headerID');
  const textID = useUniqueID('textID');
  const TitleText = useCallback(
    (props) => {
      if (nycTeensQuickmatch) {
        return <Huge style={{ color: permaCello }} {...props} />;
      }
      return <StyledExtraHuge id={headerID} variant="extraHugeBranded" {...props} />;
    },
    [headerID, nycTeensQuickmatch, permaCello]
  );
  return (
    <Container
      bgColor={bgColor}
      onPress={onClick}
      roundedFocusStyle
      primaryColor={focusColor}
      aria-labelledby={headerID}
      aria-describedby={textID}
      dataQa={dataQa}
      smallVariant={!!nycTeensQuickmatch}
    >
      {nycTeensQuickmatch ? (
        <>
          <View style={{ flexDirection: 'column', marginRight: 12 }}>
            <Title>
              <TitleText id={headerID} variant="extraHugeBranded">
                {title}
              </TitleText>
            </Title>
            <Desc style={{ maxWidth: 236, marginRight: 'auto' }}>
              <Small id={textID} variant="smallDarkGrey">
                {description}
              </Small>
            </Desc>
          </View>
          <View>{icon}</View>
        </>
      ) : (
        <>
          <Title>
            <TitleText id={headerID} variant="extraHugeBranded">
              {title}
            </TitleText>
            <View>{icon}</View>
          </Title>
          <Desc>
            <Large id={textID} variant="largeDarkGrey">
              {description}
            </Large>
          </Desc>
          {!nycTeensQuickmatch && (
            <Continue>
              <ContinueText variant="largeBoldWide">Continue</ContinueText>
            </Continue>
          )}
        </>
      )}
    </Container>
  );
};

export default TherapyTypeCard;
