import { useState } from 'react';
import * as React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  View,
  ExtraHuge,
  Large,
  Button,
  useEmotionTheme,
  useUniqueID,
  HiddenText,
  useErrorFocus,
  BaseButton,
  Link,
  VerificationCodeInput,
} from '@talkspace/react-toolkit';
import styled from '../../core/styled';

export interface EligibilityVerifyEmailProps {
  email?: string;
  onSubmit: (value: number) => void;
  resendVerificationCode(): Promise<void>;
  isError: boolean;
  isLoading: boolean;
}

const Container = styled(View)({ '&:focus': { outline: 'none' } });

const EligibilityVerifyEmail: React.VFC<EligibilityVerifyEmailProps> = ({
  resendVerificationCode,
  email,
  onSubmit,
  isError,
  isLoading,
}) => {
  const [verificationCode, setVerificationCode] = useState('');
  const verificationCodeComplete = verificationCode.length === 4;
  const { colors } = useEmotionTheme();
  const header1Id = useUniqueID('header1Id');
  const hiddenTextId = useUniqueID('hiddenTextId');
  const { formContainerRef, setShouldFocusFirstInvalidField } = useErrorFocus();

  const handleVerificationCodeKeyInput = (code: string) => {
    setVerificationCode(code);
  };

  const onSubmitEditing = () => {
    if (verificationCode.length === 4) {
      onSubmit(Number(verificationCode));
    } else {
      setShouldFocusFirstInvalidField(true);
    }
  };

  return (
    <Container ref={formContainerRef} aria-labelledby={header1Id}>
      <ExtraHuge as="h1" style={{ marginBottom: 24 }}>
        Verify your email
      </ExtraHuge>
      {isError ? (
        <View style={{ marginBottom: 35, textAlign: 'center' }} aria-live="polite">
          <Large inline style={{ fontWeight: 700, color: colors.red }}>
            Invalid verification code.
          </Large>{' '}
          <Large inline style={{ color: colors.darkGray }}>
            Please re-enter the 4-digit verification code emailed to
          </Large>{' '}
          {email ? (
            <Large inline style={{ fontWeight: 700 }}>
              {email}.
            </Large>
          ) : (
            <Large inline>your address.</Large>
          )}
        </View>
      ) : (
        <View style={{ marginBottom: 35, textAlign: 'center', width: '100%', alignSelf: 'center' }}>
          <span>
            <Large as="h2" inline style={{ color: colors.darkGray, fontWeight: 400 }}>
              To verify your benefit eligibility, enter the <br /> 4-digit verification code emailed
              to
            </Large>{' '}
            {email ? (
              <Large inline style={{ fontWeight: 700 }}>
                {email}.
              </Large>
            ) : (
              <Large inline>your address.</Large>
            )}
          </span>
        </View>
      )}
      <View align="center" style={{ marginBottom: 35 }}>
        <View row>
          <HiddenText id={hiddenTextId}>digit {verificationCode.length} of 4</HiddenText>
          <VerificationCodeInput
            ariaRequired
            inputLength={4}
            isError={isError}
            onChange={handleVerificationCodeKeyInput}
          />
        </View>
      </View>
      {verificationCodeComplete && (
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onPress={onSubmitEditing}
          style={{ width: '100%', backgroundColor: colors.green }}
          primaryColor={colors.green}
          roundedFocusStyle
        >
          Next
        </Button>
      )}
      <View
        style={{
          width: 250,
          marginTop: 20,
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        <Large>Didn&apos;t receive a verification code?</Large>
        <span>
          <BaseButton
            onClick={() => resendVerificationCode()}
            style={{ marginLeft: 0, marginRight: 0, borderRadius: 5 }}
            roundedFocusStyle
            primaryColor={colors.green}
          >
            <Large
              inline
              style={{
                color: colors.green,
                fontWeight: 700,
              }}
            >
              Resend code
            </Large>
          </BaseButton>
          <Large inline style={{ marginLeft: 11, marginRight: 11, color: colors.altoGrey }}>
            |
          </Large>
          <Link
            target="_blank"
            href="https://help.talkspace.com"
            style={{ borderRadius: 5 }}
            roundedFocusStyle
            primaryColor={colors.green}
          >
            <Large
              inline
              style={{
                color: colors.green,
                fontWeight: 700,
              }}
            >
              Contact Support
            </Large>
          </Link>
        </span>
      </View>
    </Container>
  );
};

export default EligibilityVerifyEmail;
