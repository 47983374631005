import { VoidFunctionComponent } from 'react';
import { Trans } from '@talkspace/i18n';

import { Tiny, Button } from '@talkspace/react-toolkit';
import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import styled from '@/core/styled';

const StyledTiny = styled(Tiny)({
  fontSize: 13,
  letterSpacing: 0.2,
  marginTop: 16,
});

const StyledButton = styled(Button)(({ theme: { colors } }) => {
  return {
    color: colors.green,
    width: 'auto',
    fontWeight: 700,
    fontSize: 13,
    height: 0,
    minHeight: 0,
    margin: 0,
    padding: 0,
    '&:hover': {
      color: colors.permaTropicalRainForest,
    },
  };
});

interface Props {
  onCancellationPolicyPress: () => void;
}

const CancellationPolicyMessage: VoidFunctionComponent<Props> = ({ onCancellationPolicyPress }) => {
  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');
  return (
    <StyledTiny>
      <Trans
        t={tQuickmatchCommon}
        i18nKey="sessionModalityStepAsyncSessionCarousel.cancellationPolicyText"
      >
        By clicking “Confirm session” you are agreeing to{' '}
        <StyledButton
          dataQa="schedulingMessageSessionPolicyLink"
          onPress={onCancellationPolicyPress}
        >
          Talkspace Cancellation Policy
        </StyledButton>
        .
      </Trans>
    </StyledTiny>
  );
};

export default CancellationPolicyMessage;
