import { Location } from 'history';

const getParamByName = (paramName: string, location?: Location) => {
  const { search, hash } = location || window.location;

  const queryParams = new URLSearchParams(search);
  const hashParams = new URLSearchParams(hash.replace('#', '?'));

  return hashParams.get(paramName) || queryParams.get(paramName);
};

export default getParamByName;
