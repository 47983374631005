import { QMFlow, EligibilityType } from './types';
import { initFlowConfig } from './utils';
import { FLOW_90_SELECT_SERVICE } from './Flow90';

//* * HUMANA EAP*/
export const FLOW_19_B2B_EAP_HUMANA = 19;

const flow: QMFlow = {
  flowId: FLOW_19_B2B_EAP_HUMANA,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    isEAP: true,
    eligibilityType: EligibilityType.organizationWithAddress,
  },
  steps: [
    {
      id: 1,
      analyticsId: 56,
      category: 'oneFormEligibility',
      response_category_id: 9,
      optionalOrganization: false,
      skipText: 'Continue without insurance',
      skipRedirectToFlow: FLOW_90_SELECT_SERVICE,
    },
  ],
};

export default flow;
