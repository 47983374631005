import {
  View,
  ExtraHuge,
  Large,
  Button,
  useEmotionTheme,
  CheckMark,
} from '@talkspace/react-toolkit';
import { InsuranceEligibilityPayer } from 'ts-frontend/types';
import { useEffect } from 'react';
import { OutOfNetworkStep, UpdateStep } from '@/Flows/types';
import OutOFNetworkImage from './OutOfNetworkImage';
import styled from '@/core/styled';
import StickyStepFooter from '@/Components/StickyStepFooter';
import { trackEvent } from '@/utils/analytics/eventTracker';

const DESCRIPTION_ITEMS = [
  'Affordable plans starting at $69/week',
  'Get $80 off when you pay directly',
  'You can also submit a claim to your insurance provider to see if you qualify for reimbursement',
];

interface OutOfNetworkProps {
  step: OutOfNetworkStep;
  updateStep: UpdateStep;
  insurancePayer?: InsuranceEligibilityPayer | null;
}

const ChecklistContainer = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      marginTop: 16,
      marginBottom: isMobile ? 36 : 40,
      flexDirection: 'column',
      gap: 12,
    };
  }
);

const StyledButton = styled(Button)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      width: isMobile ? 336 : 430,
      transition: 'all 200ms ease-in-out',
      '&:not([disabled]):hover': {
        backgroundColor: colors.permaTropicalRainForest,
      },
    };
  }
);

const DescriptionItem = ({ text }: { text: string }) => {
  const { colors } = useEmotionTheme();
  return (
    <View row justify="start">
      <View style={{ marginTop: 6, marginRight: 16 }} align="center" justify="start">
        <CheckMark width={11} height={9} color={colors.permaTalkspaceDarkGreen} />
      </View>
      <Large style={{ textAlign: 'left' }}>{text}</Large>
    </View>
  );
};

const OutOfNetwork = ({ step, updateStep, insurancePayer }: OutOfNetworkProps) => {
  useEffect(() => {
    trackEvent('Not covered by health insurance Seen', {});
  }, []);

  const handleSubmit = async () => {
    trackEvent('Not covered by health insurance CTA', {});
    updateStep(step.buttonTarget, {
      insuranceEligibility: {
        isEligible: false,
        ineligiblePromoCouponCode: step.ineligiblePromoCouponCode,
        ineligiblePromo: step.ineligiblePromo,
        ineligiblePromoWeeks: step.ineligiblePromoWeeks,
        payer: insurancePayer || undefined,
      },
    });
  };

  return (
    <>
      <StickyStepFooter.StepContainer>
        <OutOFNetworkImage />
        <ExtraHuge style={{ marginTop: 23, textAlign: 'left' }}>
          Your insurance is not in network yet, but you qualify for a discount!
        </ExtraHuge>
        <ChecklistContainer>
          {DESCRIPTION_ITEMS.map((description) => (
            <DescriptionItem key={description} text={description} />
          ))}
        </ChecklistContainer>
      </StickyStepFooter.StepContainer>

      <StickyStepFooter.Footer showFooterBar>
        <StyledButton dataQa="outOfNetworkContinueButton" text="Continue" onPress={handleSubmit} />
      </StickyStepFooter.Footer>
    </>
  );
};

export default OutOfNetwork;
