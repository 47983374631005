import { useLocation } from 'react-router';
import { FunctionComponent, useEffect, useState } from 'react';
import { View, RHFSelect, RHFInput, OptionType, RHFDateInput } from '@talkspace/react-toolkit';
import {
  OneFormEligibilityResponsiveFieldWrapper,
  OneFormEligibilityResponsiveFieldRow,
} from './OneFormEligibilityResponsiveFieldRow';
import { OneFormEligibilityFieldNames } from './types';
import styled from '../../core/styled';
import { FLOW_62_B2B_EAP_OPTUM } from '@/Flows';

const getEligibleSessionOptions = (flowID?: number): OptionType[] => {
  const ELIGIBLE_SESSIONS_OPTIONS = Array.from(new Array(12 + 1 - 3), (x, i) => i + 3).map((el) => {
    return { value: String(el), label: String(el) };
  });
  if (flowID === FLOW_62_B2B_EAP_OPTUM) {
    ELIGIBLE_SESSIONS_OPTIONS.push({ value: '16', label: '12+' });
  }
  return ELIGIBLE_SESSIONS_OPTIONS;
};

interface OneFormEligibilityAuthorizationCodeProps {
  authorizationCodeFieldName?: OneFormEligibilityFieldNames;
  numberOfSessionsFieldName: OneFormEligibilityFieldNames;
  authorizationCodeExpirationFieldName?: OneFormEligibilityFieldNames;
  hasNumberOfSessions?: boolean;
  numberOfSessionsTooltip?: string;
  numberOfSessionsOptions?: OptionType[];
  authCodeTooltip?: string;
  flowID?: number;
  authCodeExpirationEnabled?: boolean;
  authCodeExpirationTooltip?: string;
}

const SingleInputWrapper = styled(View)();

export const OneFormEligibilityAuthorizationCode: FunctionComponent<
  OneFormEligibilityAuthorizationCodeProps
> = ({
  flowID,
  numberOfSessionsOptions = getEligibleSessionOptions(flowID),
  hasNumberOfSessions,
  numberOfSessionsTooltip = 'This is the number of remaining sessions you have left on your EAP. If you’re not sure, contact your EAP provider for more details.',
  authorizationCodeFieldName,
  numberOfSessionsFieldName,
  authCodeTooltip = 'This code is required in order to proceed with your EAP benefits.',
  authorizationCodeExpirationFieldName,
  authCodeExpirationEnabled,
  authCodeExpirationTooltip = 'This is the expiration date for your auth code. If you’re not sure, contact your EAP provider for more details.',
}) => {
  const location = useLocation();
  const [authCode, setAuthCode] = useState<string>();
  const OuterInputWrapper = hasNumberOfSessions
    ? OneFormEligibilityResponsiveFieldRow
    : SingleInputWrapper;
  const InnerInputWrapper = hasNumberOfSessions
    ? OneFormEligibilityResponsiveFieldWrapper
    : OneFormEligibilityResponsiveFieldRow;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const authCodeValue = queryParams.get('authCode');
    if (authCodeValue) {
      setAuthCode(authCodeValue);
    }
  }, [location]);

  return (
    <>
      <OuterInputWrapper style={{ marginBottom: hasNumberOfSessions ? undefined : 12 }}>
        <InnerInputWrapper>
          <RHFInput
            fieldName={authorizationCodeFieldName}
            label="Authorization Code"
            tooltip={authCodeTooltip}
            placeholder="Enter code"
            isRequired
            readOnly={!!authCode}
          />
        </InnerInputWrapper>
        {hasNumberOfSessions && (
          <InnerInputWrapper>
            <RHFSelect
              fieldName={numberOfSessionsFieldName}
              label="Number of Sessions"
              tooltip={numberOfSessionsTooltip}
              tooltipLabel="Number of sessions tooltip"
              options={numberOfSessionsOptions}
              // prevents label from stacking on two lines and throwing dropdown out of alignment with input
              labelStyle={{ letterSpacing: 0, whiteSpace: 'nowrap' }}
              tooltipStyle={{ margin: '2px 0px 3px 2px' }}
            />
          </InnerInputWrapper>
        )}
      </OuterInputWrapper>
      {authCodeExpirationEnabled && (
        <OneFormEligibilityResponsiveFieldRow>
          <RHFDateInput
            fieldName={authorizationCodeExpirationFieldName}
            label="Authorization code expiration date"
            isRequired
            tooltip={authCodeExpirationTooltip}
          />
        </OneFormEligibilityResponsiveFieldRow>
      )}
    </>
  );
};

export default OneFormEligibilityAuthorizationCode;
