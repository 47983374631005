/**
 * Asks the user the bare minimum questions in order to determine their insurance coverage status.
 *
 * @todo - Field error states
 */
import compose from 'lodash/fp/compose';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { FormProvider } from 'react-hook-form';
import { useLocation } from 'react-router';
import useQueryPublicAdminConfigJson from '@/hooks/useQueryPublicAdminConfigJson';
import { useQuickEligibilityForm } from './hooks';
import { DiscoveryAllowedPayers, QuickEligibilityProps } from './types';
import DiscoveryEligibility from './DiscoveryEligibility';
import MemberIdEligibility from './MemberIdEligibility';

const QuickEligibility = (props: QuickEligibilityProps) => {
  const { isDiscoveryScreen = false } = props;
  if (isDiscoveryScreen) {
    return <DiscoveryEligibility {...props} />;
  }

  return <MemberIdEligibility {...props} />;
};

export default compose((Component) => (props) => {
  const location = useLocation();
  const { noMemberId } = useFlags();
  const { data: allowedDiscoveryPayers } = useQueryPublicAdminConfigJson(
    'trizetto_discovery_allowed_payers',
    true
  );
  const { insurancePayer, quickEligibilityInfo, partnerFlowID } = props;
  const discoveryPayers = (allowedDiscoveryPayers as DiscoveryAllowedPayers)?.fromDispatcher
    ? Object.keys((allowedDiscoveryPayers as DiscoveryAllowedPayers).fromDispatcher)
    : [];
  const isNoMemberIDPayer = discoveryPayers.includes(insurancePayer?.value || '');
  const urlParams = new URLSearchParams(location.search);
  const utmSource = urlParams.get('utm_source');
  const isManualSignUp = utmSource === 'manual-sign-up';
  const shouldShowDiscoveryScreen =
    !isManualSignUp && noMemberId && isNoMemberIDPayer && !quickEligibilityInfo?.memberID;

  const methods = useQuickEligibilityForm(partnerFlowID, shouldShowDiscoveryScreen);
  return (
    <FormProvider {...methods}>
      <Component {...props} isDiscoveryScreen={shouldShowDiscoveryScreen} />
    </FormProvider>
  );
})(QuickEligibility) as typeof QuickEligibility;
