import { VoidFunctionComponent } from 'react';

import PaymentDetails, { Props as PaymentDetailsProps } from 'checkout/components/PaymentDetails';
import SkipPaymentDetails, { Props as SkipPaymentDetailsProps } from './SkipPaymentDetails';
import { HomePageState } from '../../HomePage';

interface Props
  extends Omit<PaymentDetailsProps, 'email' | 'errorMessage'>,
    SkipPaymentDetailsProps {
  skipCreditCard: HomePageState['skipCreditCard'];
}

const SubStep1: VoidFunctionComponent<Props> = ({
  skipCreditCard,
  containerStyle,
  heading1Style,
  heading2Style,
  paymentFormContainerStyle,
  email,
  isEmailRequired,
  isProcessing,
  isNoMatches,
  provider,
  submitText,
  onStripeLinkError,
  onSubmit,
  onLoadStripeLink,
  currency,
  total,
  totalsVariant,
  maxCost,
  stripe,
  stickySummary,
  resetError,
  errorMessage,
  additionalMessage,
  buttonDescribedBy,
  useStripeLink,
  subtitle,
  specifyCopay,
  perSession,
  perSessionMin,
  isLoading,
  handleSubmitSkipPayment,
}) =>
  skipCreditCard ? (
    <SkipPaymentDetails
      isLoading={isLoading}
      email={email}
      handleSubmitSkipPayment={handleSubmitSkipPayment}
      errorMessage={errorMessage}
    />
  ) : (
    <PaymentDetails
      containerStyle={containerStyle}
      heading1Style={heading1Style}
      heading2Style={heading2Style}
      paymentFormContainerStyle={paymentFormContainerStyle}
      email={email}
      isEmailRequired={isEmailRequired}
      provider={provider}
      submitText={submitText}
      currency={currency}
      total={total}
      totalsVariant={totalsVariant}
      maxCost={maxCost}
      isProcessing={isProcessing}
      stripe={stripe}
      isNoMatches={isNoMatches}
      onSubmit={onSubmit}
      onLoadStripeLink={onLoadStripeLink}
      resetError={resetError}
      onStripeLinkError={onStripeLinkError}
      errorMessage={errorMessage}
      additionalMessage={additionalMessage}
      buttonDescribedBy={buttonDescribedBy}
      useStripeLink={useStripeLink}
      subtitle={subtitle}
      stickySummary={stickySummary}
      specifyCopay={specifyCopay}
      perSession={perSession}
      perSessionMin={perSessionMin}
    />
  );

export default SubStep1;
