import { RouteComponentProps } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { LocationDescriptorObject, LocationState } from 'history';
import { AccountType } from '../Components/HomePage/types';

export const STEPS_TO_ROUTES: {
  [step in Exclude<DispatcherStep, 'goToFlowID'>]: DispatcherRoute;
} = {
  organizationName: '/dispatcher/organization-name',
  organizationEmail: '/dispatcher/organization-email',
  verifyEmail: '/dispatcher/verify-email',
  eligibilityCode: '/dispatcher/eligibility-code',
  keywordCheck: '/dispatcher/keyword-check',
  redeemsReachedForEmailError: '/dispatcher/eligibility-error',
};

export const ROUTES_TO_STEPS: {
  [route in DispatcherRoute]: Exclude<DispatcherStep, 'goToFlowID'>;
} = Object.entries(STEPS_TO_ROUTES).reduce((acc, [key, value]) => {
  acc[value] = key as Exclude<DispatcherStep, 'goToFlowID'>;
  return acc;
}, {} as { [route in DispatcherRoute]: Exclude<DispatcherStep, 'goToFlowID'> });

const allowedDispatcherParams = ['initialDispatcherStep'];

const prepareRouteChange = (
  pathname: string,
  removeDispatcherQueryParams: boolean = false
): LocationDescriptorObject<LocationState> => {
  const [path, query] = pathname.split('?');
  const search = new URLSearchParams(query);
  if (removeDispatcherQueryParams) {
    // Remove query strings used for initial state
    allowedDispatcherParams.forEach((param) => {
      if (search.has(param)) {
        search.delete(param);
      }
    });
  }
  return {
    pathname: path,
    search: search.toString(),
  };
};

const routeIfNotThere = (
  path: string,
  location: RouteComponentProps['location'],
  history: RouteComponentProps['history']
): void => {
  if (location.pathname === prepareRouteChange(path).pathname) return;
  history.push(prepareRouteChange(path));
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isDispatcherRoute = (path: string): path is DispatcherRoute =>
  // Remove trailing `/`
  path.replace(/\/$/, '') in ROUTES_TO_STEPS;
const isDispatcherStep = (step: string): step is DispatcherStep => step in STEPS_TO_ROUTES;

export const getDispatcherStepID = (currentStep: DispatcherStep) =>
  Object.keys(STEPS_TO_ROUTES).indexOf(currentStep) + 1; // Index starts at 0

const useDispatcherRouting = (
  {
    isReady,
    accessCode,
    accessCodeType,
    currentStep,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setCurrentStep,
    goToFlowID,
    restoringState,
    goToFlowIDIntercept,
    allowedModalities,
    totalSessions,
    accountType,
  }: {
    isReady: boolean;
    accessCode: string | null;
    accessCodeType: AccessCodeType | null;
    currentStep: DispatcherStep;
    setCurrentStep(currentStep: DispatcherStep): void;
    goToFlowID: number | null;
    restoringState: boolean;
    goToFlowIDIntercept?: GoToFlowIDInterceptFunction;
    allowedModalities?: Array<AllowedModality>;
    totalSessions: number;
    accountType: AccountType | null;
  },
  location: RouteComponentProps['location'],
  history: RouteComponentProps['history']
) => {
  const historyRef = useRef(history);
  const locationRef = useRef(location);
  const searchRef = useRef(location.search);

  useEffect(() => {
    historyRef.current = history;
  }, [history]);

  useEffect(() => {
    locationRef.current = location;
    searchRef.current = location.search;
  }, [location]);

  // On currentStep change, change route
  useEffect(() => {
    const newRoute = STEPS_TO_ROUTES[currentStep];
    const searchParams = new URLSearchParams(searchRef.current);
    if (accessCode && accessCodeType) {
      searchParams.set(accessCodeType, accessCode);
      searchParams.set('accessCodeType', accessCodeType);
      if (goToFlowID) {
        searchParams.set('orgFlowId', goToFlowID.toString());
      }
    }
    const queryString = searchParams.toString() ? `?${searchParams.toString()}` : '';
    switch (currentStep) {
      case 'goToFlowID':
        if (goToFlowIDIntercept) {
          goToFlowIDIntercept({
            goToFlowID,
            accessCodeType,
            accessCode,
            totalSessions,
            allowedModalities,
            accountType,
          });
        } else if (goToFlowID) {
          historyRef.current.push(`/flow/${goToFlowID}${queryString}`);
        }
        break;
      default:
        if (isDispatcherStep(currentStep) && newRoute)
          routeIfNotThere(
            `${newRoute}${searchRef.current}`,
            locationRef.current,
            historyRef.current
          );
        break;
    }
  }, [
    accessCode,
    accessCodeType,
    totalSessions,
    accountType,
    allowedModalities,
    currentStep,
    goToFlowID,
    goToFlowIDIntercept,
  ]);

  // onRouteChange, change step
  // Might need this, might not
  useEffect(() => {
    if (!isReady || restoringState) return;
    if (!isDispatcherRoute(location.pathname)) return;
    const newStep = ROUTES_TO_STEPS[location.pathname];

    if (newStep) {
      setCurrentStep(newStep);
    }
  }, [isReady, location.pathname, restoringState, setCurrentStep]);
};

export default useDispatcherRouting;
