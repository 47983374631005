const ADOLESCENT = {
  name: 'Adolescent/Teenager',
  expertise: '135',
};

const INTERNATIONAL = {
  name: 'International',
  expertise: '177',
};

export const getAdolescentFieldOfExpertise = () => ADOLESCENT.expertise;

export const getInternationalFieldOfExpertise = () => INTERNATIONAL.expertise;
