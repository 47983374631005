import { VoidFunctionComponent } from 'react';
import {
  BaseButton,
  ExtraHuge,
  Large,
  Massive,
  Standard,
  Tiny,
  useEmotionTheme,
  View,
  X,
} from '@talkspace/react-toolkit';

import styled from '../../core/styled';
import { PROVIDER_GENDERS } from '../HomePage/types';

// If adding gender filter results in less than [SHOW_GENDER_TOOLTIP_THRESHOLD] matches
const SHOW_GENDER_TOOLTIP_THRESHOLD = 4;

const BoldSpan = styled.span({ fontWeight: 'bold' });

const MeetYourMatchesHeader: VoidFunctionComponent<{
  isMobile: boolean;
  filteredMatchesLength: number;
  usingInNetworkMatches: boolean;
  removeGenderFilter: () => void;
  clientMatchGenderPreference?: string;
  filteredMatchesDifference?: number;
  hideGenderToolTip: boolean;
}> = ({
  isMobile,
  filteredMatchesLength,
  usingInNetworkMatches,
  removeGenderFilter,
  clientMatchGenderPreference = undefined,
  filteredMatchesDifference = 0,
  hideGenderToolTip = false,
}) => {
  const { colors } = useEmotionTheme();

  if (filteredMatchesLength === 0) {
    return (
      <View style={{ textAlign: 'center', marginTop: 50 }}>
        <Large as="h1" variant="largeDarkGrey">
          <BoldSpan>0 providers</BoldSpan> meet your search
        </Large>
      </View>
    );
  }

  const showGenderToolTip =
    clientMatchGenderPreference !== PROVIDER_GENDERS.ANY &&
    filteredMatchesDifference &&
    filteredMatchesLength < SHOW_GENDER_TOOLTIP_THRESHOLD &&
    !hideGenderToolTip;

  return (
    <View
      style={{
        marginTop: isMobile ? 40 : 60,
        marginBottom: isMobile ? 25 : 30,
        maxWidth: isMobile ? 355 : 875,
        textAlign: 'left',
        width: '100%',
        ...(isMobile && { alignSelf: 'center' }),
      }}
    >
      {isMobile ? (
        <ExtraHuge as="h1">Meet your matches</ExtraHuge>
      ) : (
        <Massive as="h1">Meet your matches</Massive>
      )}
      <Large as="h2" variant="largeDarkGrey" style={{ marginBottom: isMobile ? 15 : 10 }}>
        <BoldSpan>{`${filteredMatchesLength} ${
          usingInNetworkMatches ? 'in-network ' : ''
        }providers `}</BoldSpan>
        meet your search
      </Large>
      {showGenderToolTip ? (
        <View
          style={{
            backgroundColor: colors.permaPanache,
            borderRadius: 10,
            paddingTop: 12,
            paddingLeft: 15,
            paddingBottom: 12,
            paddingRight: 24,
            alignItems: 'flex-start',
          }}
        >
          <Tiny style={{ color: colors.black }}>
            {`Widening your search to include [${
              clientMatchGenderPreference === PROVIDER_GENDERS.MALE ? 'female' : 'male'
            }] providers will result in`}
            <BoldSpan> {filteredMatchesDifference} additional matches.</BoldSpan>
          </Tiny>
          <BaseButton
            data-qa="removeGenderFilterButton"
            aria-label="Remove Gender Filter"
            onPress={removeGenderFilter}
          >
            <View row>
              <X size={9} color={colors.greenText} style={{ marginTop: 7, marginRight: 8 }} />
              <Standard variant="standardBoldGreen">Remove Gender Filter</Standard>
            </View>
          </BaseButton>
        </View>
      ) : null}
    </View>
  );
};

export default MeetYourMatchesHeader;
