/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import { EligibilityType } from '@/Flows/types';
import { useMoveCoverageEnabled } from '../../../hooks';

export const useHeading = ({
  eligibilityType,
  isB2BOutOfNetwork,
}: {
  eligibilityType: EligibilityType | undefined;
  isB2BOutOfNetwork: boolean | undefined;
}) => {
  const isMoveCoverageEnabled = useMoveCoverageEnabled();
  return useMemo(() => {
    if (
      isMoveCoverageEnabled &&
      eligibilityType === EligibilityType.trizetto &&
      !isB2BOutOfNetwork
    ) {
      return 'We just need a few more details to create your plan';
    }
    return `Let's verify your information`;
  }, [isMoveCoverageEnabled, eligibilityType, isB2BOutOfNetwork]);
};
