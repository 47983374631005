import { FunctionComponent, useEffect, useState } from 'react';
import { Trans } from '@talkspace/i18n';

import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import { ExtraHuge, Small, TabRadioGroup, View, Button } from '@talkspace/react-toolkit';
import { SessionModality } from 'ts-frontend/types';
import useStringTranslation from '@talkspace/i18n/hooks/useStringTranslation';
import styled from '@/core/styled';
import ModalityOption from './ModalityOption';
import useRemoveMessagingNudge from '@/hooks/useRemoveMessagingNudge';
import { trackAskModalityPreferenceView } from '@/utils/analytics/events';
import { AskModalityPreference } from '@/utils/analytics/trackerTypes';
import { useAvailableModalities, useDefaultModalitySetter } from './hooks';
import { SelectModalityProps } from './types';

const Styled = {
  RecommendedSmall: styled(Small)(({ theme: { colors } }) => {
    return {
      color: colors.white,
      marginLeft: 10,
      textAlign: 'left',
    };
  }),
  ContinueButton: styled(Button)({
    marginTop: 0,
    fontWeight: 700,
    fontSize: 17,
    width: 335,
  }),
  RecommendedBanner: styled(View)(({ theme: { colors } }) => {
    return {
      height: 28,
      backgroundColor: colors.bannerBlue,
      borderRadius: '8px 8px 0 0',
      justifyContent: 'center',
    };
  }),
};

const SelectModality: FunctionComponent<SelectModalityProps> = ({
  allowedModalities,
  userSelectedLiveSession,
  onContinuePress,
  b2bForkResult,
  accountType,
  clientState,
  flowConfig,
}) => {
  const availableModalities = useAvailableModalities({
    allowedModalities,
  });

  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');

  const [modality, setModality] = useState<SessionModality>('messaging');

  const removeMessagingNudge = useRemoveMessagingNudge(
    clientState,
    b2bForkResult,
    accountType,
    flowConfig
  );

  useDefaultModalitySetter({
    allowedModalities,
    availableModalities,
    userSelectedLiveSession,
    removeMessagingNudge,
    setModality,
  });

  useEffect(() => {
    if (availableModalities) {
      const preferenceOptions: AskModalityPreference['preferenceOptions'] = {};
      if (availableModalities.messaging) preferenceOptions.asyncChat = true;
      if (availableModalities.chat) preferenceOptions.liveChat = true;
      if (availableModalities.video) preferenceOptions.video = true;
      if (availableModalities.audio) preferenceOptions.audio = true;
      trackAskModalityPreferenceView({ preferenceOptions });
    }
  }, [availableModalities]);

  const onSelectModality = (type: SessionModality) => setModality(type);

  const continueButtonText = useStringTranslation(
    'Continue',
    'quickmatch.common',
    'sessionModalityStepSelectModality.continueButtonText'
  );
  return (
    <View flex={1} align="center" justify="center" style={{ marginTop: 32, width: 335 }}>
      <ExtraHuge>
        <Trans t={tQuickmatchCommon} i18nKey="sessionModalityStepSelectModality.titleText">
          How do you want to start therapy?
        </Trans>
      </ExtraHuge>
      <Small variant="smallDarkGrey" style={{ marginTop: 10 }}>
        <Trans t={tQuickmatchCommon} i18nKey="sessionModalityStepSelectModality.descriptionText">
          Your selection today is for your first session. You'll have the option to choose a
          different type of therapy for later sessions.
        </Trans>
      </Small>
      <View>
        <TabRadioGroup
          style={{ marginTop: 32 }}
          legendText="Select How do you want to start therapy"
        >
          {availableModalities.messaging &&
            !removeMessagingNudge && ( // NOTE: messaging is the last option when removed the nudge.
              <View>
                <Styled.RecommendedBanner>
                  <Styled.RecommendedSmall variant="smallBolderGreen">
                    <Trans
                      t={tQuickmatchCommon}
                      i18nKey="sessionModalityStepSelectModality.recommendedBadgeText"
                    >
                      Recommended for you
                    </Trans>
                  </Styled.RecommendedSmall>
                </Styled.RecommendedBanner>
                <ModalityOption
                  modality="messaging"
                  activeModality={modality}
                  onHandlePress={onSelectModality}
                  dataQa="messagingModalityRadioButton"
                  isFlatTop
                />
              </View>
            )}
          {availableModalities.video && (
            <ModalityOption
              modality="video"
              activeModality={modality}
              onHandlePress={onSelectModality}
              dataQa="liveVideoModalityRadioButton"
            />
          )}
          {availableModalities.audio && (
            <ModalityOption
              modality="audio"
              activeModality={modality}
              onHandlePress={onSelectModality}
              dataQa="liveAudioModalityRadioButton"
            />
          )}
          {availableModalities.chat && (
            <ModalityOption
              modality="chat"
              activeModality={modality}
              onHandlePress={onSelectModality}
              dataQa="liveChatModalityRadioButton"
            />
          )}
          {availableModalities.messaging && removeMessagingNudge && (
            <View>
              <ModalityOption
                modality="messaging"
                activeModality={modality}
                onHandlePress={onSelectModality}
                dataQa="messagingModalityRadioButton"
                isFlatTop={false}
              />
            </View>
          )}
        </TabRadioGroup>
      </View>
      <Styled.ContinueButton
        onPress={async () => {
          await onContinuePress(modality);
        }}
        text={continueButtonText}
        dataQa="qmSelectModalityContinueButton"
      />
    </View>
  );
};

export default SelectModality;
