// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FunctionComponent, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, useEmotionTheme } from '@talkspace/react-toolkit';
import { TranslationNamespaceQuickmatchFlow } from '@talkspace/i18n/types';
import useStringTranslation from '@talkspace/i18n/hooks/useStringTranslation';
import { trackEvent } from '@/utils/analytics/events';
import { useRecoveredSessionActions } from '@/hooks/recoveredSessionContext';
import switchFlowHelper from '../../Helpers/switchFlowHelper';
import { BaseStep, UpdateStepObj } from '../../Flows/types';
import styled, { EmotionStyle } from '../../core/styled';

interface SkipOptionProps {
  step: BaseStep;
  updateStep: (
    internalTarget: string | number | null | undefined,
    objectArg: UpdateStepObj
  ) => void;
  primaryColor?: string;
  buttonStyles?: EmotionStyle;
  dataQa?: string;
  i18nNamespace?: TranslationNamespaceQuickmatchFlow;
}

const StyledSkipOptionButton = styled(Button)<{ buttonStyles?: EmotionStyle }>(
  ({ buttonStyles, theme: { colors } }) => {
    return {
      color: colors.green,
      backgroundColor: 'transparent',
      border: 'none',
      width: 'auto',
      alignSelf: 'center',
      fontWeight: 700,
      transition: 'all 200ms ease-in-out',
      '&:hover': {
        color: colors.permaTropicalRainForest,
      },
      ...buttonStyles,
    };
  }
);

const SkipOption: FunctionComponent<SkipOptionProps> = ({
  step,
  updateStep,
  primaryColor,
  buttonStyles,
  dataQa,
  i18nNamespace,
}) => {
  const { updateAndSave } = useRecoveredSessionActions();
  const history = useHistory();
  const { colors } = useEmotionTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);

  // prevents the button from retaining focus if subsequent steps have a SkipOption
  const blurAfterClick = () => {
    (buttonRef.current as HTMLElement).blur();
  };
  const buttonSkipText = useStringTranslation(
    step.skipText || 'Skip',
    i18nNamespace,
    `step${step.id}.buttonSkipText`
  );
  return (
    <StyledSkipOptionButton
      dataQa={dataQa || `${step.category}SkipOption`}
      buttonStyles={buttonStyles}
      ref={buttonRef}
      roundedFocusStyle
      primaryColor={primaryColor || colors.green}
      onClick={() => {
        blurAfterClick();
        if (step.category === 'oneFormEligibility') {
          trackEvent('Answer Questionnaire', { 'Insurance Provider': 'skip' });
        }
        if (step.skipRedirectToFlow) {
          switchFlowHelper(step.skipRedirectToFlow, history, updateAndSave);
        } else {
          updateStep(
            `${step.skipExternalTarget ? step.skipExternalTarget : step.skipInternalTarget}`,
            {
              payfirst_step_id: step.analyticsId,
              payfirst_step_prompt: step.heading2 || step.prompt,
              response_category_id: step.response_category_id,
              response_prompt: step.skipText,
              response_value: undefined,
              go_to_matches: step.goToMatches,
            }
          );
        }
      }}
    >
      {buttonSkipText}
    </StyledSkipOptionButton>
  );
};

export default SkipOption;
