import React, { ReactElement } from 'react';
import {
  ExtraHuge,
  Large,
  View,
  TouchableView,
  CaretRight,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { useHistory } from 'react-router-dom';
import styled from '@/core/styled';
import { useRecoveredSessionActions } from '@/hooks/recoveredSessionContext';
import switchFlowHelper from '../../../Helpers/switchFlowHelper';

const Wrapper = styled(View)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    alignItems: 'center',
    maxWidth: 430,
    marginTop: isMobile ? 20 : 100,
  };
});

const MenuLinks = ({ items }) => {
  const { colors } = useEmotionTheme();
  return (
    <View style={{ borderTop: `1px ${colors.periwinkleGrey} solid`, marginTop: 20, width: '100%' }}>
      {items.map(({ key, Content, onPress }) => (
        <TouchableView
          row
          justify="space-between"
          key={key}
          dataQa={key}
          style={{
            textAlign: 'left',
            padding: 20,
            borderBottom: `1px ${colors.periwinkleGrey} solid`,
          }}
          onPress={onPress}
          align="center"
        >
          <View style={{ flex: 1 }}>
            <Content />
          </View>

          <CaretRight style={{ marginLeft: 24 }} />
        </TouchableView>
      ))}
    </View>
  );
};

interface MenuItems {
  key: string;
  shouldHide?: boolean;
  Content: () => ReactElement;
  onPress: () => void;
}

interface PreRegisterExpiredProps {
  heading: string;
  subHeading: string;
}

const PreRegisterExpired = ({ heading, subHeading }: PreRegisterExpiredProps) => {
  const { updateAndSave } = useRecoveredSessionActions();
  const history = useHistory();
  const { isMobile } = useWindowWidthState();
  const items: MenuItems[] = [
    {
      key: 'matchMeWithAProviderOnTalkspacePress',
      Content: () => <Large>Match me with a provider on Talkspace</Large>,
      onPress: () => {
        switchFlowHelper(90, history, updateAndSave);
      },
    },
    {
      key: 'visitTalkspaceHomepagePress',
      Content: () => <Large>Visit the Talkspace homepage</Large>,
      onPress: () => {
        window.location.href = `https://www.talkspace.com/`;
      },
    },
  ];

  return (
    <Wrapper isMobile={isMobile}>
      <ExtraHuge style={{ marginBottom: 12 }}>{heading}</ExtraHuge>
      <Large>{subHeading}</Large>
      <MenuLinks items={items} />
    </Wrapper>
  );
};

export default PreRegisterExpired;
