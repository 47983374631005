import { Large, EmotionStyle } from '@talkspace/react-toolkit';

interface Props {
  children: React.ReactNode;
  style?: EmotionStyle;
}

const Subtitle = ({ children, style }: Props) => (
  <Large
    as="h2"
    variant="largeDarkGrey"
    style={{
      marginTop: 15,
      maxWidth: 330,
      alignSelf: 'center',
      textAlign: 'center',
      fontWeight: 400,
      ...style,
    }}
  >
    {children}
  </Large>
);

export default Subtitle;
