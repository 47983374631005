import { VoidFunctionComponent } from 'react';
import { Trans } from '@talkspace/i18n';

import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import { TFKeysQuickmatchCommon } from '@talkspace/i18n/types';
import {
  View,
  ExtraHuge,
  Large,
  MessagingSession,
  MessagingConfirmSession,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';

interface StepData {
  icon: JSX.Element;
  title: string;
  titleTranslationKey: TFKeysQuickmatchCommon;
  text: string;
  textTranslationKey: TFKeysQuickmatchCommon;
}

type StepNumber = 1 | 2;

const stepsData: Record<StepNumber, StepData> = {
  1: {
    icon: <MessagingSession />,
    title: 'What is a Messaging Session?',
    titleTranslationKey:
      'sessionModalityStepAsyncSessionCarousel.messagingSessionDefinitionStepTitle',
    text: 'A messaging session usually consists of about a week of back-and-forth messaging with your provider.',
    textTranslationKey:
      'sessionModalityStepAsyncSessionCarousel.messagingSessionDefinitionStepDescription',
  },
  2: {
    icon: <MessagingConfirmSession />,
    title: 'Confirm your session',
    titleTranslationKey: 'sessionModalityStepAsyncSessionCarousel.confirmSessionStepTitle',
    text: 'Your messaging session will begin right after we match you with a provider. We’ll send you an email notifying you when your provider is ready.',
    textTranslationKey: 'sessionModalityStepAsyncSessionCarousel.confirmSessionStepDescription',
  },
};

const eapStepsData: Record<StepNumber, StepData> = {
  1: {
    icon: <MessagingSession />,
    title: '1 session = 7 days',
    titleTranslationKey:
      'sessionModalityStepAsyncSessionCarousel.messagingSessionDefinitionStepEAPTitle',
    text: 'A messaging session gets you access to seven consecutive days of unlimited messaging with your provider.',
    textTranslationKey:
      'sessionModalityStepAsyncSessionCarousel.messagingSessionDefinitionStepEAPDescription',
  },
  2: {
    icon: <MessagingConfirmSession />,
    title: 'Confirm your session',
    titleTranslationKey: 'sessionModalityStepAsyncSessionCarousel.confirmSessionStepTitle',
    text: 'Your messaging session will begin right after we match you with a provider. We’ll send you an email notifying you when your provider is ready.',
    textTranslationKey: 'sessionModalityStepAsyncSessionCarousel.confirmSessionStepDescription',
  },
};

const Title = styled(ExtraHuge)(({ theme: { colors } }) => {
  return {
    fontStyle: 'normal',
    marginTop: 24,
    textAlign: 'center',
  };
});

const Text = styled(Large)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      color: colors.darkGray,
      marginTop: 12,
      marginBottom: isMobile ? 173 : 103,
    };
  }
);

const StyledView = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      height: isMobile ? 460 : 400,
      maxWidth: isMobile ? 335 : 430,
      flexDirection: 'column',
      alignItems: 'center',
    };
  }
);

const ImageContainer = styled(View)<{
  step: number;
}>(
  ({
    step,
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      backgroundColor: step === 1 ? colors.permaCornflowerBlueNew : colors.permaTitanWhite,
      height: 200,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20 0',
      gap: 10,
      width: isMobile ? 335 : 430,
      borderRadius: 16,
    };
  }
);

interface Props {
  step: StepNumber;
  isEAP: boolean;
}

const AsyncSessionCarouselStep: VoidFunctionComponent<Props> = ({ step, isEAP }) => {
  const { icon, title, titleTranslationKey, text, textTranslationKey } = (
    isEAP ? eapStepsData : stepsData
  )[step];
  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');
  return (
    <StyledView flex={1}>
      <ImageContainer step={step}>{icon}</ImageContainer>
      <Title>
        <Trans t={tQuickmatchCommon} i18nKey={titleTranslationKey}>
          {title}
        </Trans>
      </Title>
      <Text>
        <Trans t={tQuickmatchCommon} i18nKey={textTranslationKey}>
          {text}
        </Trans>
      </Text>
    </StyledView>
  );
};

export default AsyncSessionCarouselStep;
