import * as yup from 'yup';
import {
  Huge,
  View,
  Button,
  useEmotionTheme,
  useWindowWidthState,
  RHFInput,
  Large,
  RHFSelect,
} from '@talkspace/react-toolkit';
import { useState, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SESSION_STORAGE_MEMBER_DETAILS_KEY, upsertSession } from 'ts-frontend/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from '@/core/styled';
import { useInsurancePayerOptions } from '../B2BFork/hooks';
import { getCoverageVerificationStyles } from '../B2BFork/utils';
import { SecondaryInsuranceProps } from './types';
import { OneFormEligibilityYupContext } from '../OneFormEligibility/types';
import { SecondaryInsuranceMetadata } from '@/Helpers/apiService';

const Styled = {
  Subheading: styled(Large)(({ theme: { colors } }) => {
    return {
      fontSize: 16,
      color: colors.darkGray,
      marginBottom: 14,
      marginTop: 12,
    };
  }),
  Button: styled(Button)(({ theme: { colors } }) => {
    return {
      transition: 'all 200ms ease-in-out',
      '&:not([disabled]):hover': {
        backgroundColor: colors.permaTropicalRainForest,
      },
    };
  }),
  Form: styled.form({
    width: 335,
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    margin: '30px 10px 0px 10px',
    alignSelf: 'center',
  }),
};

const responsiveSchema: yup.SchemaOf<SecondaryInsuranceMetadata> = yup.object().shape({
  secondaryMemberID: yup.string().required('Member/Subscriber ID missing or invalid.'),
  secondaryPayerID: yup.string().required('Make a selection.'),
});

const SecondaryInsurance = ({
  step,
  service,
  updateStep,
  primaryInsurancePayer,
}: SecondaryInsuranceProps) => {
  const methods = useForm<SecondaryInsuranceMetadata, OneFormEligibilityYupContext>({
    resolver: yupResolver(responsiveSchema),
    context: {},
    // defaultValues seem to be necessary for proper error focus when using a resolver
    defaultValues: {
      secondaryMemberID: '',
      secondaryPayerID: '',
    },
  });
  const { handleSubmit, watch } = methods;
  const { colors } = useEmotionTheme();
  const secondaryPayerValue = watch('secondaryPayerID');
  const { options: insurancePayerOptions = [], loading: isLoadingOptions } =
    useInsurancePayerOptions({ service, shouldHideOutOfPocket: true });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const experimentalDropdownStyles = useMemo(() => getCoverageVerificationStyles(colors), [colors]);

  const onSubmit = async (data: SecondaryInsuranceMetadata) => {
    upsertSession(SESSION_STORAGE_MEMBER_DETAILS_KEY, {
      secondaryMemberID: data.secondaryMemberID,
      secondaryPayerID: secondaryPayerValue,
    });

    await updateStep(step.buttonTarget);
  };

  const { isMobile } = useWindowWidthState();

  return (
    <FormProvider {...methods}>
      <Styled.Form onSubmit={handleSubmit(onSubmit)}>
        <View
          style={{
            marginTop: -8,
            marginBottom: 14,
            // animation for hiding this header upon opening the dropdown on mobile
            transition: 'margin 0.1s ease',
            ...(isMenuOpen && isMobile
              ? {
                  marginTop: -86,
                }
              : {}),
          }}
        >
          <Huge as="h1" style={{ fontSize: 29, lineHeight: '34px', fontWeight: 700 }}>
            Do you have any secondary insurance?
          </Huge>
          <Styled.Subheading>
            Additional coverage (e.g. through an employer or partner) may reduce out-of-pocket
            costs.
          </Styled.Subheading>
        </View>
        <View align="stretch">
          <RHFSelect
            cacheOptions
            fieldName="secondaryPayerID"
            placeholder="Find your secondary insurance"
            dataQa={`${step.category || ''}Dropdown`}
            options={insurancePayerOptions.filter(
              (insurancePayerOption) =>
                insurancePayerOption.partnerID !== primaryInsurancePayer?.partnerID
            )}
            isLoading={isLoadingOptions}
            // allows for the dropdown to be keyboard searchable
            isInputReadOnly={false}
            wrapperStyle={{
              marginTop: 0,
              width: 335,
            }}
            styles={experimentalDropdownStyles}
            onMenuOpen={() => {
              setIsMenuOpen(true);
            }}
            onMenuClose={() => {
              setIsMenuOpen(false);
            }}
            label="Secondary insurance"
            registerOptionValue
            hasDuplicateOptionValues
          />

          <RHFInput
            labelStyle={{ marginTop: 12 }}
            tooltipStyle={{ marginTop: 12 }}
            fieldName="secondaryMemberID"
            label="Member/Subscriber ID"
            tooltip="Located on your Health Plan ID card. Type the full ID, including letters and numbers."
            placeholder="Member / Subscriber ID"
          />

          <Styled.Button
            text="Continue"
            type="submit"
            style={{ width: '100%', marginTop: 15, fontWeight: 700 }}
            dataQa={`${step.category || ''}ContinueButton`}
            roundedFocusStyle
            primaryColor={colors.green}
          />
        </View>
      </Styled.Form>{' '}
    </FormProvider>
  );
};

export default SecondaryInsurance;
