import { FunctionComponent, useEffect } from 'react';
import {
  View,
  Button,
  ExtraHuge,
  Big,
  useUniqueID,
  EmotionStyle,
  Large,
  useEmotionTheme,
  Tiny,
  useContainerFocus,
  ExtraBig,
  RectangleExclamationPoint,
  ComplianceBadges,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { getCopayCost } from './registerWithBHCopayHelpers';
import { ReviewYourPlanProps } from './types';
import { trackEvent, trackSelectModalityPreference } from '@/utils/analytics/events';
import { useHomePageActions } from '@/Components/HomePage';

const Container = styled(View)({
  '&:focus': {
    outline: 'none',
  },
  textAlign: 'left',
});
const PlanSummaryContainer = styled(View)(({ theme: { colors } }) => {
  return {
    height: 'fit-content',
    width: '100%',
    maxWidth: 355,
    borderRadius: 15,
    margin: '20px 10px 0px 10px',
    backgroundColor: colors.white,
    padding: '0px 30px 28px 28px',
    boxShadow: `0 5px 18px -1px ${colors.permaBlueDianne}`,
  };
});
const CostInfoRow = ({
  text,
  value,
  textStyle,
  valueStyle,
}: {
  text: string;
  value: string;
  textStyle?: EmotionStyle;
  valueStyle?: EmotionStyle;
}) => (
  <View row style={{ justifyContent: 'space-between', width: '100%', marginBottom: 9 }}>
    <Large style={textStyle} variant="largeDarkGrey">
      {text}
    </Large>
    <Large style={valueStyle}>{value}</Large>
  </View>
);
const HorizontalGreyLine = styled(View)(({ theme: { colors } }) => {
  return { width: '100%', height: 1, backgroundColor: colors.permaMystic };
});
const SubtitleLarge = styled(Large)({
  maxWidth: 335,
  overflowWrap: 'break-word',
  fontWeight: 500,
  marginTop: 6,
});
const AdditionalDetails = ({
  title,
  subTitle,
  text,
  Icon,
}: {
  title: string;
  subTitle?: string;
  text: string | JSX.Element;
  Icon?: JSX.Element;
}) => (
  <View style={{ marginTop: 26 }}>
    <View flex={1} row align="center">
      {Icon}
      <ExtraBig as="h4">{title}</ExtraBig>
    </View>
    {subTitle && <SubtitleLarge>{subTitle}</SubtitleLarge>}
    <Large variant="largeDarkGrey" style={{ marginTop: 6 }}>
      {text}
    </Large>
  </View>
);
const BILLING_INFO =
  'You will be billed on a per session basis, subject to the same co-pay or co-insurance (after deductible) that would apply to an in-person visit.';
const getSessionDetails = (
  isPsychiatry: boolean,
  isVideoOnlyPlan: boolean,
  insurancePayerLabel?: string
) => {
  const sessionDetails: { title: string; description: string } = {
    title: 'Therapy Session',
    description: `Each session is equivalent to either a full length session or 7 days of daily messaging—it’s your choice. ${BILLING_INFO}`,
  };
  if (isPsychiatry) {
    sessionDetails.title = 'Psychiatry Session';
    sessionDetails.description = `A session is a live video consultation with your psychiatrist to determine whether medication can be helpful. An electronic prescription may be issued. ${BILLING_INFO}`;
  } else if (!isPsychiatry && isVideoOnlyPlan) {
    sessionDetails.title = 'Live Video Session';
    sessionDetails.description = `One session equals one Live Video Session. ${BILLING_INFO}`;
  }
  if (insurancePayerLabel) {
    sessionDetails.title = `${insurancePayerLabel} ${sessionDetails.title}`;
  }
  return sessionDetails;
};
const ReadyToBeginBig = styled(Big)({
  fontSize: 16,
  fontWeight: 'normal',
  marginLeft: 20,
  marginTop: 16,
});
const ReviewYourPlan: FunctionComponent<ReviewYourPlanProps> = ({
  copayCents,
  finalFlowID,
  setSubStep,
  isVideoOnlyPlan,
  insurancePayer,
  sessionModality,
  allowedModalities,
  service,
}) => {
  const { getMaxCost } = useHomePageActions();
  const { containerRef } = useContainerFocus();
  const heading1ID = useUniqueID('heading1ID');
  const secureSLLTextID = useUniqueID('secureSSLTextID');
  const { colors } = useEmotionTheme();

  const maxCost = getMaxCost(finalFlowID, service);

  const sessionDetails = getSessionDetails(
    service === 'psychiatry',
    isVideoOnlyPlan,
    insurancePayer?.label
  );
  const handleContinueToCheckout = () => {
    trackEvent('Continue to Checkout', {
      'Funnel Name': 'QuickMatch',
    });
    setSubStep(1);
  };
  useEffect(() => {
    if (allowedModalities?.length === 1 && allowedModalities[0].name === 'messaging') {
      trackSelectModalityPreference({ preferenceSelected: 'Async Chat' });
    } else if (!sessionModality) {
      trackSelectModalityPreference({ preferenceSelected: 'Video' });
    }
  }, [allowedModalities, sessionModality]);
  return (
    <Container tabIndex={-1} ref={containerRef} aria-labelledby={heading1ID}>
      <ExtraHuge as="h1" style={{ marginLeft: 20 }} id={heading1ID}>
        Review your plan
      </ExtraHuge>
      <ReadyToBeginBig as="h2">You're ready to begin!</ReadyToBeginBig>
      <PlanSummaryContainer>
        <Button
          aria-describedby={secureSLLTextID}
          onPress={handleContinueToCheckout}
          roundedFocusStyle
          primaryColor={colors.green}
          style={{ marginTop: 20, marginBottom: 10, width: '100%', maxWidth: 310 }}
          data-qa="registerWithBhCopayReviewPlanButton"
        >
          Continue
        </Button>
        <Big as="h3" style={{ marginTop: 22, marginBottom: 10 }}>
          Summary
        </Big>
        <CostInfoRow text="Maximum cost of service" value={`$${maxCost}`} />
        <Tiny style={{ marginTop: 22 }}>
          You will only be charged once we match you with a provider.
        </Tiny>
        <HorizontalGreyLine style={{ marginTop: 22, marginBottom: 28 }} />
        <CostInfoRow
          text="Copay"
          value={`$${copayCents && copayCents > 0 ? getCopayCost(copayCents, maxCost) : 0}`}
          textStyle={{ fontSize: 19, fontWeight: 'bold' }}
          valueStyle={{ fontSize: 19, fontWeight: 'bold' }}
        />
        <Large variant="largeDarkGrey">Charged at the time of the session</Large>
      </PlanSummaryContainer>
      <AdditionalDetails
        title="Insurance details"
        Icon={
          <RectangleExclamationPoint
            color={colors.permaFuchsia}
            width={19}
            height={19}
            style={{ marginRight: 8 }}
          />
        }
        text={
          <>
            Your copay may not be accurately displayed. Our system tries to get the most up-to-date
            information from your insurer, but actual amounts may vary. If your plan has a
            cost-share that is different from the above, the charge will be adjusted after each
            session is processed.
            <br />
            <br /> If your insurance coverage changes or your insurer rejects your claim, you could
            owe up to this amount per session.
          </>
        }
      />
      <AdditionalDetails
        title="Session details"
        subTitle={sessionDetails.title}
        text={sessionDetails.description}
      />
      <HorizontalGreyLine style={{ marginTop: 26, marginBottom: 30 }} />
      <ComplianceBadges style={{ margin: 'auto' }} />
    </Container>
  );
};
export default ReviewYourPlan;
