import {
  Button,
  ExtraHuge,
  Large,
  View,
  useWindowWidthState,
  spacing,
  Image,
  TouchableView,
  Small,
  Modal,
  CloseButton,
  Big,
  EmotionThemeProvider,
  ExtraTiny,
  SpacingView,
  StickyDrawer,
  StickyDrawerParentContainer,
} from '@talkspace/react-toolkit';

import { useState, useCallback } from 'react';
import { TeensEligibleFreeTherapyStep, UpdateStep } from '../../../Flows';
import styled from '@/core/styled';
import TeenThoughtsIllustration from '../../assets/TeenThoughtsIllustration';
import NYCTeenspace from './NYCTeenspace.png';
import UnionIcon from './UnionIcon.png';
import Header from '../../Header/Header';
import NYCPeopleCheckBanner from '../../assets/NYCPeopleCheckBanner';
import NeedHelpPill from '../../NeedHelpPill';
import Footer from '../../Footer/Footer';

const Styled = {
  Container: styled(View)(({ isMobile }: { isMobile: boolean }) => {
    return {
      maxWidth: 350,
      minHeight: isMobile ? 'calc(100vh - 260px)' : undefined,
      justifyContent: 'space-between',
    };
  }),
  Title: styled(ExtraHuge)({ marginBottom: 12 }),
  ContinueButton: styled(Button)({
    marginBottom: 16,
    marginTop: 24,
    width: '100%',
    maxWidth: 350,
  }),
  PlanInformationContainer: styled(TouchableView)(() => {
    return {
      backgroundColor: '#F2F5FB',
      borderRadius: 8,
      height: 76,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '21px 20px 21px 21px',
    };
  }),
  PlanInformationButton: ({ onPress }) => (
    <Styled.PlanInformationContainer onPress={onPress}>
      <Image source={NYCTeenspace} width={67} height={34} style={{ marginRight: 18 }} />
      <Small
        style={{
          marginRight: 11,
          textAlign: 'start',
          width: 184,
        }}
        variant="smallBolderGrey"
      >
        Learn what’s included in your free therapy plan
      </Small>
      <Image source={UnionIcon} width={14} height={14} />
    </Styled.PlanInformationContainer>
  ),
  BannerContainer: styled(View)({
    width: 349,
    alignItems: 'center',
    gap: spacing.space150,
  }),
  ModalContent: styled(View)({
    alignSelf: 'center',
    padding: '32px 20px',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    maxWidth: 390,
  }),
  PlanDetailsItem: ({ title, description }) => (
    <View flex={1} style={{ gap: spacing.space050 }}>
      <Big variant="bigBold">{title}</Big>
      <Small variant="smallGrey950">{description}</Small>
    </View>
  ),
};

interface Props {
  step: TeensEligibleFreeTherapyStep;
  updateStep: UpdateStep;
}

const planDetailsItems = [
  {
    title: 'A dedicated therapist to support you',
    description:
      'Once you’ve been matched with a therapist, they will support you through private messaging and monthly live video sessions - for free!',
  },
  {
    title: 'One monthly live video session',
    description:
      'Connect with your therapist in real-time by scheduling a free 30 minute live video, audio or instant chat session every month.',
  },
  {
    title: 'Messaging with your therapist',
    description:
      'Write your therapist anytime from your secure and confidential therapy chat room. You can message your therapist anytime and get daily responses.',
  },
  {
    title: 'Self-guided therapy exercises',
    description:
      'With the free Talkspace Go app, you’ll have access to bite-sized exercises, daily reflection prompts, and interactive classes, led by licensed therapists.',
  },
];

// Having this as a constant allows us to combine JSX elements and spread the planDetailsItems array.
// Spreading children is not allowed by React
const MODAL_ITEMS = [
  <Styled.Title>Your free therapy plan</Styled.Title>,
  <Styled.BannerContainer>
    <NYCPeopleCheckBanner />
    <ExtraTiny>Provided by the NYC Department of Health</ExtraTiny>
  </Styled.BannerContainer>,
  ...planDetailsItems.map((item) => (
    <>
      <Styled.PlanDetailsItem key={item.title} {...item} />
    </>
  )),
  <View flex={1} style={{ alignSelf: 'stretch' }}>
    <NeedHelpPill />
  </View>,
];

const TeensEligibleFreeTherapy = ({ step, updateStep }: Props) => {
  const { isMobile } = useWindowWidthState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const renderHeader = useCallback(
    () => (
      <EmotionThemeProvider version="2.0.0">
        <Header closeButton={<CloseButton onPress={closeModal} />} />
      </EmotionThemeProvider>
    ),
    [closeModal]
  );
  return (
    <Styled.Container isMobile={isMobile}>
      <View align="stretch">
        <Styled.Title>You’re eligible for free therapy!</Styled.Title>
        <Large variant="largeGrey950">
          Great! Next, let’s find out how we can best support you. Your answers are confidential.
        </Large>
        <View style={{ marginTop: spacing.space400 }} align="center" justify="center">
          <TeenThoughtsIllustration />
        </View>
      </View>
      <View style={{ marginTop: 40 }}>
        <Styled.PlanInformationButton
          onPress={() => {
            setIsModalOpen(true);
          }}
        />
        <Styled.ContinueButton
          text="Continue"
          onPress={() => updateStep(step.buttonTarget)}
          dataQa="teensEligibleFreeTherapyContinueButton"
        />
      </View>
      <Modal isVisible={isModalOpen}>
        <Modal.FullScreenPanel onBackdropPress={closeModal} renderHeader={renderHeader}>
          <StickyDrawerParentContainer>
            <Styled.ModalContent>
              <SpacingView centerContent inheritJustify itemSpacing="space300">
                {MODAL_ITEMS}
              </SpacingView>
              <Footer />
            </Styled.ModalContent>
            <StickyDrawer>
              <Button
                style={{ maxWidth: 350, width: '100%', marginTop: 0 }}
                text="Close"
                onPress={closeModal}
                dataQa="modalCloseButton"
              />
            </StickyDrawer>
          </StickyDrawerParentContainer>
        </Modal.FullScreenPanel>
      </Modal>
    </Styled.Container>
  );
};
export default TeensEligibleFreeTherapy;
