import { Small, SparklingCheckMark, UploadImage, View } from '@talkspace/react-toolkit';
import Title from '@/Components/Title';
import Subtitle from '@/Components/Subtitle';

interface Props {
  onUploadFront: (image: File) => void;
  onDeleteFront: () => void;
  onUploadBack: (image: File) => void;
  onDeleteBack: () => void;
}

const InsuranceCardImages = ({
  onUploadFront,
  onDeleteFront,
  onUploadBack,
  onDeleteBack,
}: Props) => (
  <View align="center">
    <SparklingCheckMark />
    <Title
      style={{
        marginTop: 24,
      }}
    >
      Great work! Just two more steps.
    </Title>
    <Subtitle>As a part of our verification process, we require your insurance card</Subtitle>
    <View style={{ marginTop: 24 }}>
      <Small style={{ textAlign: 'left' }}>Upload a picture of your insurance card</Small>
      <View row style={{ marginTop: 8 }}>
        <UploadImage title="Front of card" onUpload={onUploadFront} onDelete={onDeleteFront} />
        <UploadImage
          title="Back of card"
          style={{ marginLeft: 12 }}
          onUpload={onUploadBack}
          onDelete={onDeleteBack}
        />
      </View>
    </View>
  </View>
);

export default InsuranceCardImages;
