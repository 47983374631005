import { View } from '@talkspace/react-toolkit';
import { SurveyWelcomeScreen } from 'surveyWizard';
import styled from '@/core/styled';
import { trackEvent } from '@/utils/analytics/events';

const Wrapper = styled(View)({
  paddingBottom: 20,
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 20,
});

const DEFAULT_PROMPT =
  "After receiving your results, you'll have the opportunity to learn more about your symptoms, sign up for Talkspace and talk to a mental health professional.";

const SurveyIntroduction = ({ onPress }: { onPress: () => void }) => (
  <View>
    <Wrapper>
      <SurveyWelcomeScreen
        title="About the test"
        handleStartButton={() => {
          onPress();
          trackEvent('Continue to Assessment', {
            'Funnel Name': 'Assessments',
          });
        }}
        prompt={DEFAULT_PROMPT}
      />
    </Wrapper>
  </View>
);

export default SurveyIntroduction;
