/* eslint-disable import/prefer-default-export */
import { useCallback, useMemo, useState } from 'react';
import { signManualCoverageImageUploadURL, submitManualCoverageRequest } from './util';
import { getFileExtensionFromFileType, uploadFileToS3 } from '@/utils/files';
import { UseManualSubmitParams } from '../types';

export const useManualSubmit = ({
  insuranceFront,
  insuranceBack,
  idFront,
  idBack,
  memberID,
  state,
  email,
  firstName,
  lastName,
  dob,
  partnerID,
  serviceType,
}: UseManualSubmitParams) => {
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = useCallback(async () => {
    if (success) {
      return;
    }

    setError(null);
    setIsSubmitting(true);

    if (!insuranceFront || !insuranceBack || !idFront) {
      setError(`Images must be provided for: Insurance coverage and Identification`);
      setIsSubmitting(false);
      return;
    }

    try {
      // sign upload urls for each image
      const [signedInsuranceFront, signedInsuranceBack, signedIDFront, signedIDBack] =
        await Promise.all([
          signManualCoverageImageUploadURL({
            nameSuffix: 'insurance-front',
            fileType: insuranceFront.type,
          }),
          signManualCoverageImageUploadURL({
            nameSuffix: 'insurance-back',
            fileType: insuranceBack.type,
          }),
          signManualCoverageImageUploadURL({
            nameSuffix: 'id-front',
            fileType: idFront.type,
          }),
          idBack && // ID image back is optional
            signManualCoverageImageUploadURL({
              nameSuffix: 'id-back',
              fileType: idBack.type,
            }),
        ]);

      // call api to submit the request
      await submitManualCoverageRequest({
        email,
        memberID,
        firstName,
        lastName,
        dob,
        state,
        partnerID,
        serviceType: serviceType === 'therapyTeen' ? 'psychotherapy' : serviceType || undefined,
        uploadedFiles: [
          {
            nameSuffix: 'insurance-front',
            extension: getFileExtensionFromFileType(insuranceFront.type),
          },
          {
            nameSuffix: 'insurance-back',
            extension: getFileExtensionFromFileType(insuranceBack.type),
          },
          {
            nameSuffix: 'id-front',
            extension: getFileExtensionFromFileType(idFront.type),
          },
          ...(idBack
            ? [
                {
                  nameSuffix: 'id-back' as const,
                  extension: getFileExtensionFromFileType(idBack.type),
                },
              ]
            : []),
        ],
      });

      // upload all images to signed urls
      await Promise.all([
        uploadFileToS3(signedInsuranceFront.signedRequest, insuranceFront),
        uploadFileToS3(signedInsuranceBack.signedRequest, insuranceBack),
        uploadFileToS3(signedIDFront.signedRequest, idFront),
        signedIDBack && idBack && uploadFileToS3(signedIDBack.signedRequest, idBack),
      ]);

      setSuccess(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setError('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  }, [
    success,
    insuranceFront,
    insuranceBack,
    idFront,
    idBack,
    email,
    memberID,
    firstName,
    lastName,
    dob,
    state,
    partnerID,
    serviceType,
  ]);

  const result = useMemo(() => {
    return {
      error,
      isSubmitting,
      onSubmit,
      success,
    };
  }, [error, isSubmitting, onSubmit, success]);

  return result;
};
