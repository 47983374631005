/* eslint-disable import/prefer-default-export */
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useMemo } from 'react';
import { debug } from 'ts-frontend/utils/dev-console';
import { useMoveCoverageEnabled } from '../../../hooks';
import { ThanksForSharingProps } from '../types';

type UseShouldDisplayModalParams = Pick<
  ThanksForSharingProps,
  | 'step'
  | 'isLoggedInUser'
  | 'b2bForkResult'
  | 'isB2BOutOfNetwork'
  | 'isCouldNotBeVerified'
  | 'isMatchesOverlayDismissed'
  | 'insurancePayer'
>;
/**
 * Returns true / false as to whether the "Thanks for Sharing" modal should be displayed
 * when the user clicks "Continue".
 */
export const useShouldDisplayModal = ({
  step,
  insurancePayer,
  isLoggedInUser,
  b2bForkResult,
  isB2BOutOfNetwork,
  isCouldNotBeVerified,
  isMatchesOverlayDismissed,
}: UseShouldDisplayModalParams) => {
  const { emailLeadCaptureMove } = useFlags();
  const isMoveCoverageEnabled = useMoveCoverageEnabled();
  const isEmailLeadCaptureScreenEnabled =
    emailLeadCaptureMove?.experimentActive &&
    ['moveCopy1', 'moveCopy2', 'screenReplacement'].includes(emailLeadCaptureMove?.variant);
  return useMemo(() => {
    let result: boolean = false;

    if (isEmailLeadCaptureScreenEnabled) return false;

    if (step.matchOverlay && !isMatchesOverlayDismissed && !isLoggedInUser) {
      if (b2bForkResult !== 'b2b' || isB2BOutOfNetwork || isCouldNotBeVerified) {
        result = true;
      }
      if (isMoveCoverageEnabled) result = true;
    }

    debug(`Email update modal ${result ? 'WILL' : 'WILL NOT'} be shown.`, {
      matchOverlay: step.matchOverlay,
      isLoggedInUser,
      b2bForkResult,
      isB2BOutOfNetwork,
      insurancePayer,
      isMatchesOverlayDismissed,
    });

    return result;
  }, [
    step.matchOverlay,
    isLoggedInUser,
    b2bForkResult,
    insurancePayer,
    isB2BOutOfNetwork,
    isCouldNotBeVerified,
    isMatchesOverlayDismissed,
    isMoveCoverageEnabled,
    isEmailLeadCaptureScreenEnabled,
  ]);
};
