import { QMFlow, EligibilityType } from './types';
import { initFlowConfig } from './utils';

/** Cigna EAP with Authorization Code */
export const FLOW_78_B2B_EAP_CIGNA = 78;

const flow: QMFlow = {
  flowId: FLOW_78_B2B_EAP_CIGNA,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    isEAP: true,
    isTeen: true,
    eligibilityType: EligibilityType.organizationWithAddress,
  },
  steps: [
    {
      id: 1,
      analyticsId: 56,
      category: 'oneFormEligibility',
      response_category_id: 9,
      removeOrganization: true,
      authCodeLabel: 'EAP Code',
      authCodeTooltip:
        'This code is required in order to proceed with your EAP benefits. If you have not already received your Cigna EAP Code, you can obtain it from Cigna by phone, live-chat or through their online portal, which can be accessed at https://my.cigna.com/. Please enter the full code including all characters.',
      authCodeMaxLength: 30,
      hasNumberOfSessions: true,
      hasAuthCodeExpiration: true,
      authCodeExpirationMaxDays: 210,
    },
  ],
};

export default flow;
