import { useEmotionTheme, useUniqueID } from '@talkspace/react-toolkit';
import Svg, { Path, Defs, LinearGradient, Stop } from 'svgs';
import { EmotionStyle } from '../../core/styled';

type Props = {
  style?: EmotionStyle;
  width?: number;
  height?: number;
};

const NYCHealthBanner = (props: Props) => {
  const { colors } = useEmotionTheme();
  const titleText = 'city health coverage';
  const linearGradientID = useUniqueID('NYCHealthBannerLinearGradient');
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={349}
      height={201}
      viewBox="0 0 349 201"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M0 172.524V184.637h349v-12.113l-6.737-2.243v-15.703h-5.39l-10.78 4.038v4.486h-3.594v-10.767h-4.042v-31.406h-3.144l-10.331-13.908-10.331 13.908h-3.144v17.049l-8.085-2.692v4.935h-2.246v-22.432h-1.796V99.356l-8.085-11.216-8.085 11.216v18.433h-1.797v10.767h-6.288v5.384h-2.695v9.87h-1.348l-3.593 27.644h-3.144l-7.187-9.179v-18.638h-2.246v18.638l-7.186 9.179h-3.144l-2.695-37.514-4.043 1.346v-23.33h-21.56v16.152h-7.186v8.075h-9.433v13.46h-3.593v-34.097h-3.593l-10.331-10.319-10.331 10.319h-4.042v10.767l-7.636-2.692v11.665h-2.695V93.832l-20.662 8.973v8.973h-7.186v7.627h-2.246v6.281h-5.39v9.6h-3.144v-46.66h-1.797V76.514h-1.796V65.746h-3.144V60.81h-4.043l-3.144-20.637V25.367h-3.144v14.806L78.604 60.81H74.56v4.936h-3.144v10.767H69.62v12.114h-1.796v39.929l-5.84 2.692v29.611H58.84v-11.665h-2.245v-4.935h-2.246v-35.892h-2.695v-9.87h-1.797v-8.973L47.162 81V63.95h-1.797V81l-3.144 8.524v8.973h-1.796v9.87H37.73v35.892h-2.246v4.935h-2.246v3.589H18.865l-4.492 6.281H8.085v10.319L0 172.524z"
        fill="#E1F0EE"
      />
      <Path
        d="M0 172.524V184.637h349v-12.113l-6.737-2.243v-15.703h-5.39l-10.78 4.038v4.486h-3.594v-10.767h-4.042v-31.406h-3.144l-10.331-13.908-10.331 13.908h-3.144v17.049l-8.085-2.692v4.935h-2.246v-22.432h-1.796V99.356l-8.085-11.216-8.085 11.216v18.433h-1.797v10.767h-6.288v5.384h-2.695v9.87h-1.348l-3.593 27.644h-3.144l-7.187-9.179v-18.638h-2.246v18.638l-7.186 9.179h-3.144l-2.695-37.514-4.043 1.346v-23.33h-21.56v16.152h-7.186v8.075h-9.433v13.46h-3.593v-34.097h-3.593l-10.331-10.319-10.331 10.319h-4.042v10.767l-7.636-2.692v11.665h-2.695V93.832l-20.662 8.973v8.973h-7.186v7.627h-2.246v6.281h-5.39v9.6h-3.144v-46.66h-1.797V76.514h-1.796V65.746h-3.144V60.81h-4.043l-3.144-20.637V25.367h-3.144v14.806L78.604 60.81H74.56v4.936h-3.144v10.767H69.62v12.114h-1.796v39.929l-5.84 2.692v29.611H58.84v-11.665h-2.245v-4.935h-2.246v-35.892h-2.695v-9.87h-1.797v-8.973L47.162 81V63.95h-1.797V81l-3.144 8.524v8.973h-1.796v9.87H37.73v35.892h-2.246v4.935h-2.246v3.589H18.865l-4.492 6.281H8.085v10.319L0 172.524z"
        fill={`url(#${linearGradientID})`}
      />
      <Path
        d="M227.003 53.545l5.975-10.533a28.201 28.201 0 017.158-37.371 28.203 28.203 0 11-.409 44.893l-12.724 3.01z"
        fill="#FAFAFA"
      />
      <Path
        d="M268.505 15.15l-12.347-4.134-12.225 4.076c-1.563.688-2.933 1.099-2.933 2.455v6.754c-.027 4.733 1.367 9.382 4.032 13.448 2.616 4.064 6.543 7.299 11.248 9.267 4.995-1.514 8.726-5.552 11.008-9.002 2.662-4.067 4.054-8.716 4.027-13.448v-6.074c0-1.932-1.098-2.877-2.81-3.342z"
        fill="#9FBC2A"
      />
      <Path
        d="M265.381 26.52h-7.243v-7.247h-4.161v7.247h-7.247v4.161h7.247v7.243h4.161V30.68h7.243v-4.16z"
        fill="#FAFAFA"
      />
      <Path
        d="M227.003 53.543l12.729-3.063-.037.104a.073.073 0 010-.094.065.065 0 01.042-.016.06.06 0 01.041.016 29.207 29.207 0 005.778 3.442 28.096 28.096 0 007.683 2.165c1.467.198 2.949.268 4.428.207.758-.046 1.558-.062 2.316-.13l2.362-.363a28.22 28.22 0 0017.427-11.233 27.854 27.854 0 004.703-10.783c.8-4.108.685-8.343-.338-12.401-.14-.52-.259-1.039-.41-1.558-.15-.519-.368-1.007-.555-1.516a24.69 24.69 0 00-1.319-2.964 12.328 12.328 0 00-.804-1.422c-.286-.467-.561-.94-.857-1.402l-.991-1.324-.494-.664c-.166-.218-.368-.41-.55-.623l-1.137-1.235c-.374-.421-.82-.769-1.225-1.153l-.623-.566a5.347 5.347 0 00-.644-.519c-.446-.332-.892-.664-1.334-1.002-.228-.156-.436-.342-.675-.483l-.711-.43-1.417-.862-1.479-.717a12.042 12.042 0 00-1.496-.664A28.461 28.461 0 00254.921.359a27.58 27.58 0 00-11.483 3.38 28.414 28.414 0 00-13.284 16.284 30.388 30.388 0 00-1.241 9.09c.079 2.659.537 5.293 1.36 7.823a28.796 28.796 0 002.783 6.043.08.08 0 01.008.033.08.08 0 01-.008.034l-6.053 10.497c.228-.4 2.118-3.774 5.918-10.564v.067a28.5 28.5 0 01-2.824-6.063 28.124 28.124 0 01-1.381-7.906 30.34 30.34 0 011.23-9.158 28.606 28.606 0 0113.373-16.425A27.808 27.808 0 01254.9.074a28.727 28.727 0 0112.62 1.94c.516.19 1.019.415 1.506.67.519.244 1.002.478 1.495.727l1.427.867.717.436c.244.14.457.327.685.488l1.35 1.012c.228.158.445.331.649.52l.628.57c.41.39.856.743 1.235 1.169l1.153 1.261c.181.213.384.405.55.628l.519.67 1.002 1.34c.296.461.576.939.867 1.411.3.462.57.942.81 1.438.51.968.956 1.969 1.334 2.996l.555 1.526c.156.519.275 1.038.416 1.557a28.712 28.712 0 01.332 12.521 28.127 28.127 0 01-4.755 10.902 28.404 28.404 0 01-17.588 11.296l-2.383.358c-.784.068-1.557.088-2.331.13a25.14 25.14 0 01-4.459-.223 28.3 28.3 0 01-7.724-2.201 28.76 28.76 0 01-5.794-3.489l.099-.093a.074.074 0 01.019.049.074.074 0 01-.019.05h-.026l-12.786 2.943z"
        fill="#263238"
      />
      <Path
        d="M238.603 109.912l.81 29.91-10.67-16.429-25-.241 5.58 9.9c5.84 8.66 9.38 15.94 17.05 24.24 3.83 4.15 7.39 9.931 9.13 11.141 4.16 2.92 10.41 5.26 15.84.56 7.58-6.56 6.68-19.181 4.29-32.07-2.66-14.31-4.05-28.111-4.05-28.111l-12.98 1.1z"
        fill="#E29D7D"
      />
      <Path
        d="M238.48 110.662s-5.691-4.739-5.789-5.89c-.099-1.151-2.205-1.618-2.205-1.618l-3.473-1.373s-2.32-1.868-1.932-3.06c.219-.691.68-1.28 1.298-1.66 0 0-.077-2.83 1.952-3.766 0 0-2.149-1.976-.318-3.71 1.832-1.733 2.36-1.11 2.36-1.11s-.357-1.646.784-1.866c1.14-.22 9.197-2.984 9.197-2.984a16.516 16.516 0 013.475 1.898c1.11.935 1.679 1.527 1.679 1.527a12.554 12.554 0 001.722-4.014c.343-1.966.622-7.793 6.404-8.029l.123 1.43a15.63 15.63 0 00-1.826 5.83c-.459 3.639-1.158 11.697-1.158 11.697l.927 16.217-13.22.481z"
        fill="#E29D7D"
      />
      <Path
        d="M242.454 86.236c0 .052-.599.037-1.558.164-.958.127-2.291.262-3.744.397a37.243 37.243 0 01-5.392.322 36.141 36.141 0 015.317-.681c1.498-.135 2.808-.233 3.744-.285a3.521 3.521 0 011.633.082zM239.923 92.673a7.996 7.996 0 01-1.558-.053c-.951-.06-2.246-.082-3.744-.12a28.567 28.567 0 01-3.744-.277 4.77 4.77 0 01-1.498-.382 8.979 8.979 0 011.55.105c.951.083 2.247.135 3.745.165 1.497.03 2.8.105 3.744.232a5.244 5.244 0 011.505.33zM237.939 97.88a6.567 6.567 0 01-1.588-.187 76.202 76.202 0 01-3.782-.749c-1.475-.33-2.801-.599-3.744-.83a6.583 6.583 0 01-1.535-.458 6.57 6.57 0 011.595.188c.966.164 2.306.419 3.744.748 1.438.33 2.793.6 3.744.84a6.585 6.585 0 011.566.448zM241.553 154.541a36.314 36.314 0 01-1.47-7.32 38.562 38.562 0 01-.55-7.51c.17 0 .55 3.31 1.11 7.4.431 2.459.735 4.939.91 7.43z"
        fill="#C77957"
      />
      <Path
        d="M152.023 107.081l16.09-1.64 27.35 1.2 12.45 4.29-17.32 17.55-22.9-2.85-10.26-5.83-5.41-12.72z"
        fill="#E29D7D"
      />
      <Path
        d="M188.153 38.902c2.82-1.79 3.5-5.94 2-8.91-1.5-2.97-4.77-4.77-8.06-5.3a15.337 15.337 0 00-13.24 4.39c-1.82 1.87-3.3 4.31-5.76 5.17-2.17.76-4.57.08-6.85.37a10.363 10.363 0 00-7.23 5.22 22.357 22.357 0 00-2.57 8.79c-.33 2.81-.55 5.89-2.5 7.93s-4.89 2.42-7.25 3.84a9.938 9.938 0 00-3.02 13.57c.93 1.36 2.27 2.61 2.38 4.26.1 1.5-.87 2.84-1.33 4.28a6.71 6.71 0 003.049 7.41c.822.493 1.74.803 2.691.91 2 .12 4-.65 5.81.23 1.81.88 2.39 3 3.5 4.57a8.4 8.4 0 0010.22 2.64c1.44-.71 2.8-1.88 4.4-1.77 1.6.11 2.86 1.47 4.43 1.78 2.06.39 4-1.12 5.23-2.82 1.23-1.7 2.08-3.71 3.64-5.1 2.05-1.82 4.93-2.27 7.52-3.17a21.214 21.214 0 0012.779-12.123 21.223 21.223 0 00-.779-17.597"
        fill="#263238"
      />
      <Path
        d="M158.333 75.76a5.748 5.748 0 00-2.29-1.5h.17a2.65 2.65 0 00-.49-.09 2.287 2.287 0 00-1.78.22 4.213 4.213 0 00-2.31 2 5.007 5.007 0 00.59 5.16 3.832 3.832 0 005.943.67 3.828 3.828 0 001.057-1.99 4.75 4.75 0 00-.89-4.47z"
        fill="#F2FBCD"
      />
      <Path
        d="M195.463 106.643l3-55a4.656 4.656 0 00-4-4.85l-24.58-7c-7.91-1.07-13.46 8.55-14.18 16.49-.81 8.83-1.45 19.64-.59 26.26 1.68 13.38 12.56 15.4 12.56 15.4s.08 13.57 0 18.39l8.18 17.71 19.61-27.4z"
        fill="#E29D7D"
      />
      <Path
        d="M160.674 66.33a1.697 1.697 0 001.6 1.76 1.606 1.606 0 001.614-.86c.1-.192.159-.403.176-.62a1.702 1.702 0 00-1.59-1.77 1.638 1.638 0 00-1.8 1.49zM157.903 61.131c.2.23 1.52-.69 3.35-.63 1.83.06 3.14 1 3.35.79.21-.21-.1-.5-.68-.95a4.67 4.67 0 00-2.67-.91 4.522 4.522 0 00-2.68.79c-.57.41-.78.81-.67.91zM177.114 66.76a1.688 1.688 0 001.56 1.78 1.608 1.608 0 001.789-1.48 1.688 1.688 0 00-1.59-1.76 1.606 1.606 0 00-1.759 1.46zM175.884 61.982c.21.23 1.52-.69 3.35-.63 1.83.06 3.15 1 3.35.79.2-.21-.1-.5-.67-.94a4.58 4.58 0 00-2.68-.91 4.386 4.386 0 00-2.67.78c-.58.41-.78.81-.68.91zM168.853 75.372a12.498 12.498 0 00-3-.62c-.46-.06-.9-.17-1-.49a2.425 2.425 0 01.35-1.38c.47-1.11 1-2.29 1.48-3.52 2.07-5 3.58-9.14 3.37-9.22-.21-.08-2 3.92-4.1 8.93-.5 1.24-1 2.42-1.43 3.54a2.728 2.728 0 00-.29 1.83 1.152 1.152 0 00.75.71c.257.076.523.12.79.13 1.019.159 2.054.19 3.08.09z"
        fill="#263238"
      />
      <Path
        d="M167.773 97.891a31.847 31.847 0 0017.1-4.27s-3.84 9.87-17.08 8.25l-.02-3.98zM169.003 79.733a4.505 4.505 0 013.76-1.86 2.926 2.926 0 012.38 1.38 2.684 2.684 0 01-.204 3.05 2.684 2.684 0 01-2.926.89 6.696 6.696 0 01-2.65-1.74 2.16 2.16 0 01-.56-.71.927.927 0 01.1-.92"
        fill="#C77957"
      />
      <Path
        d="M174.203 76.793c-.29 0-.35 2-2.09 3.32-1.74 1.32-3.85 1.09-3.88 1.36-.03.27.46.4 1.36.45a4.997 4.997 0 003.23-1 4.326 4.326 0 001.63-2.79c.1-.85-.11-1.35-.25-1.34zM175.293 54.423c.16.5 2 .32 4.14.64 2.14.32 3.86.95 4.16.52.13-.21-.16-.69-.84-1.19a7 7 0 00-3.07-1.17 6.871 6.871 0 00-3.26.29c-.75.29-1.2.67-1.13.91zM158.723 54.853c.31.42 1.58 0 3.1.05s2.79.36 3.1-.07c.14-.21-.05-.63-.6-1a4.319 4.319 0 00-2.5-.75 4.27 4.27 0 00-2.5.76c-.55.38-.74.8-.6 1.01z"
        fill="#263238"
      />
      <Path
        d="M146.773 52.651a9.003 9.003 0 013.49-5 32.077 32.077 0 003-1.72c1.91-1.55 2.36-4.24 3.54-6.4a9.996 9.996 0 017.63-5.07c2.63-.27 5.34.52 7.9-.12 1.9-.48 3.55-1.71 5.47-2.07 3.74-.71 7.21 2 9.92 4.64 0 2.67-2.12 4.46-4.17 6.18a9.29 9.29 0 01-7.51 2c-2-.37-4.28-1.4-6.05-.32-1.77 1.08-1.93 3.95-3.58 5.38-2.36 2-6.1.13-9.09 1-2.31.69-3.75 2.9-5.29 4.75s-3.83 3.63-6.14 2.94c.054-2.09.349-4.167.88-6.19z"
        fill="#263238"
      />
      <Path
        d="M174.823 41.343c1.68-.48 5.11-1.56 6-3 2-3.15 5.391-5.31 8.901-5.12a8.457 8.457 0 017.61 6.68 12.165 12.165 0 0110.45 6.73c.252.505.472 1.026.66 1.56a9.218 9.218 0 01.619 7.76c-.58 1.51-1.569 3.2-.789 4.62.78 1.42 2.8 1.49 4.36 2a6.121 6.121 0 013.86 6.85 7.77 7.77 0 00-.49 3 6.597 6.597 0 001.809 2.61 7.647 7.647 0 011.396 7.552 7.654 7.654 0 01-5.866 4.958c-1.19.21-2.479.16-3.509.8-1.19.73-1.701 2.15-2.541 3.26a6.595 6.595 0 01-7.14 2.24 6.495 6.495 0 01-1.44-.72 4.724 4.724 0 01-4.189 1.85l.489-28a2.305 2.305 0 01-1.69 1.33c-4.49 1-5.15-8.24-4.15-10.74a26.63 26.63 0 01-4.5-4.74c-1.24-1.83-2.33-6.83-2.07-9.08-1.62.09-8.51-2.2-7.78-2.4z"
        fill="#263238"
      />
      <Path
        d="M193.783 69.242c.14-2.85 3.62-3.46 6.08-2 1.35.78 2.39 2.35 2.26 5.37-.33 8.05-8.34 6.06-8.34 5.83 0-.23-.17-5.9 0-9.2z"
        fill="#E29D7D"
      />
      <Path
        d="M196.363 75.462s.14.1.37.22a1.38 1.38 0 001.05.07c.86-.28 1.63-1.6 1.73-3.05a4.615 4.615 0 00-.31-2 1.593 1.593 0 00-1-1.11.69.69 0 00-.84.35c-.12.22-.08.38-.12.39-.04.01-.17-.14-.09-.47a.856.856 0 01.34-.49c.228-.157.507-.222.78-.18a2 2 0 011.42 1.31c.314.714.448 1.493.39 2.27-.12 1.62-1 3.1-2.19 3.39a1.508 1.508 0 01-1.27-.27c-.25-.24-.29-.42-.26-.43z"
        fill="#C77957"
      />
      <Path
        d="M185.913 39.172a4.698 4.698 0 011.6 1.34c.8 1.1 1.1 3.2.6 5.57l-.05.2h.2a7.819 7.819 0 015.19 4.57c.247.64.385 1.315.41 2-.017.716-.1 1.43-.25 2.13-.158.754-.258 1.52-.3 2.29-.061.841.161 1.68.63 2.38a9.786 9.786 0 004 3c1.371.6 2.632 1.424 3.73 2.44a10.07 10.07 0 013 6.75 10.399 10.399 0 01-1.06 5c-.53 1.1-1 1.63-.94 1.66.06.03.13-.11.35-.36.296-.378.56-.78.79-1.2a10.22 10.22 0 00-1.78-12.19 14.002 14.002 0 00-3.85-2.57 9.441 9.441 0 01-3.77-2.81c-.89-1.23-.49-2.82-.26-4.26.154-.737.237-1.487.25-2.24a6.591 6.591 0 00-.47-2.15 8.18 8.18 0 00-5.58-4.78l.16.25c.47-2.48.09-4.65-.82-5.8a3.386 3.386 0 00-1.26-1 1.859 1.859 0 00-.52-.22z"
        fill="#455A64"
      />
      <Path
        d="M199.374 78.472a5.76 5.76 0 00-2.29-1.5h.17a2.665 2.665 0 00-.49-.09 2.294 2.294 0 00-1.78.22 4.206 4.206 0 00-2.31 2 4.997 4.997 0 00.59 5.16 3.825 3.825 0 003.948 1.72 3.83 3.83 0 003.052-3.04 4.758 4.758 0 00-.89-4.47z"
        fill="#F2FBCD"
      />
      <Path
        d="M214.683 193.402L214 178.016l.683-29.304 3.15 6.25 19.68-19.56s-9.52-17.32-13.93-20.67c-4.41-3.35-21.63-6.84-22.74-7.5 0 0-4.46 7.52-18.36 11.69-13.9 4.17-25.56-13.77-25.56-13.77l-15.81.7s-1.34 23.74-1.58 40c-.08 4.78.2 8.95.32 11.63.53 11.81 1.26 38.534 1.26 38.534s23.49 3.19 41.37 1 32.2-3.614 32.2-3.614z"
        fill="#455A64"
      />
      <Path
        d="M143.574 168.508a.676.676 0 01.125.072l.342.234 1.298.914a46.74 46.74 0 002.077 1.433c.83.539 1.723 1.188 2.767 1.842 1.043.654 2.17 1.397 3.415 2.134 1.246.737 2.596 1.521 3.998 2.331 2.85 1.557 6.032 3.224 9.448 4.791 3.415 1.568 6.748 2.881 9.811 3.998 1.557.519 2.99 1.038 4.376 1.489 1.386.452 2.669.841 3.852 1.184 1.184.343 2.263.628 3.208.883.945.254 1.76.462 2.43.617l1.557.384.4.104.135.047a.411.411 0 01-.14 0l-.405-.078-1.558-.332c-.674-.14-1.5-.327-2.45-.576-.95-.25-2.035-.519-3.218-.847a91.75 91.75 0 01-3.868-1.147c-1.381-.446-2.85-.939-4.397-1.469a135.617 135.617 0 01-9.837-3.992c-3.432-1.557-6.609-3.224-9.459-4.817-1.412-.815-2.761-1.558-3.986-2.357-1.226-.8-2.373-1.485-3.406-2.17-1.033-.685-1.941-1.319-2.751-1.874a37.778 37.778 0 01-2.035-1.474l-1.272-.956-.322-.254-.135-.114z"
        fill="#263238"
      />
      <Path
        d="M191.765 190.782a46.923 46.923 0 01-44.318-19.399c14.011 8.731 28.24 16.316 44.318 19.399z"
        fill="#000"
        fillOpacity={0.2}
      />
      <Path
        d="M141.113 105.832c-8.25 1.71-15.63 12.11-24.44 32.45l24 17.94.44-50.39z"
        fill="#455A64"
      />
      <Path
        d="M215.723 127.98c.1.345.15.702.15 1.06.06.69.11 1.68.15 2.91.08 2.47.07 5.87-.16 9.63-.23 3.76-.62 7.13-1 9.57-.19 1.22-.36 2.2-.5 2.87a3.546 3.546 0 01-.28 1c.037-1.339.157-2.675.36-4 .27-2.44.6-5.81.83-9.55.23-3.74.3-7.13.33-9.58a31.125 31.125 0 01.12-3.91zM141.863 122.684c.008.446-.032.892-.12 1.33-.14 1-.32 2.17-.52 3.58-.44 3-1 7.2-1.38 11.83a106.412 106.412 0 00-.22 11.9c.07 1.43.13 2.63.17 3.61a6.16 6.16 0 010 1.33 5.356 5.356 0 01-.24-1.31c-.12-.85-.24-2.08-.36-3.61a83.731 83.731 0 010-12c.331-3.959.868-7.898 1.61-11.8.28-1.5.52-2.72.71-3.55.054-.452.172-.893.35-1.31zM179.523 135.07l.8 32.3a2.994 2.994 0 01-.829 2.122 2.996 2.996 0 01-2.081.928l-13.61.32a3 3 0 01-3-2.78l-2.2-32a3 3 0 012.85-3.18l15-.67a3.01 3.01 0 012.153.84 3.01 3.01 0 01.917 2.12z"
        fill="#263238"
      />
      <Path d="M167.673 138.741a1.26 1.26 0 102.52-.104 1.26 1.26 0 00-2.52.104z" fill="#fff" />
      <Path
        d="M159.223 143.383s-3.69 4.79-5 6.9c-1.31 2.11-1.56 8.79-1.56 8.79v4.65l-7.37 3.27 5.69 15.87 11.18-9.67 11.29-2.12s5.21-5.36 6-7.76c.482-1.355.696-2.792.63-4.23a79.74 79.74 0 002.74-7.61c.264-1.803.354-3.628.27-5.45 0 0-1.45-.35-2.58 2.06l1.77-4.08s-1.16-3-3.69.06c-2.53 3.06-6.71 8.3-6.71 8.3l-4.79 4.18-.65-.69s8.43-11.25 8.14-13.19c-.29-1.94-2.14-1.95-4 .18-1.86 2.13-9.38 12.73-10.4 12.88-1.02.15-1.39-1.27-1.39-1.27l.54-3.93.37-.83-.48-6.31z"
        fill="#E29D7D"
      />
      <Path
        d="M173.753 158.962c.131-.117.246-.252.34-.4.21-.27.5-.66.85-1.15.7-1 1.64-2.36 2.61-3.92s1.78-3 2.35-4.09c.28-.53.5-1 .64-1.27.086-.152.156-.313.21-.48-.117.132-.215.28-.29.44l-.71 1.23c-.61 1-1.45 2.48-2.41 4-.96 1.52-1.88 3-2.54 4-.31.47-.57.87-.79 1.19a2.685 2.685 0 00-.26.45zM176.383 163.984a35.167 35.167 0 002.28-3.25 32.969 32.969 0 002.07-3.39 34.9 34.9 0 00-2.28 3.25 31.773 31.773 0 00-2.07 3.39z"
        fill="#C77957"
      />
      <Path
        d="M120.073 140.852s-12.47 32.67-13.05 44c-.54 10.3 8.38 16.52 15.1 15.54 15.8-2.29 40.06-27.16 40.06-27.16l-9.12-9.69-21.31 9.55 6.62-18.55-18.3-13.69z"
        fill="#E29D7D"
      />
      <Defs>
        <LinearGradient
          id={linearGradientID}
          x1={169.5}
          y1={112.001}
          x2={169.5}
          y2={185.001}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor={colors.white} stopOpacity={0} />
          <Stop offset={1} stopColor={colors.white} />
        </LinearGradient>
      </Defs>
    </Svg>
  );
};

export default NYCHealthBanner;
