import moment from 'moment';
import {
  HomePageState,
  DynamicValueHomePageState,
  PlanInfo,
  AnswerValue,
} from '../Components/HomePage/types';
import { postLanguagesForMatching } from './apiService';

export const LANGUAGE_ID_ENGLISH = 15;
const CONSUMER_B2C_PLAN_GROUP = 16;

const languageMatchingGetPlanInfo = (state: DynamicValueHomePageState): PlanInfo | null => {
  // TODO make this work for DTE since the oneForm is after current provider now.
  const { insurancePayer, qmPartnerCode, cpPartnerCode, b2bForkResult } = state;
  if (b2bForkResult === 'b2c') return { planGroup: CONSUMER_B2C_PLAN_GROUP };
  if (insurancePayer?.id) return { insurancePayerID: insurancePayer?.id };
  if (qmPartnerCode) return { voucherCode: qmPartnerCode };
  if (cpPartnerCode) return { voucherCode: cpPartnerCode };
  return null;
};

export const getAndFilterLanguages = async ({
  state,
  answer,
}: {
  state: HomePageState;
  answer: AnswerValue;
}) => {
  const { roomType, clientState, clientCountry, clientDateOfBirth } = state;
  const planInfo = languageMatchingGetPlanInfo(state);
  if (!planInfo || !roomType || (!clientCountry && !clientState)) {
    return null;
  }

  const countryPayload =
    clientCountry && clientCountry !== 'US'
      ? { clientCountry }
      : { clientCountry: 'US', clientState };

  const payload = {
    ...countryPayload,
    roomType,
    planInfo,
    clientDateOfBirth: moment(clientDateOfBirth).toISOString(),
  };

  const resp = await postLanguagesForMatching(payload);

  return resp.languages.filter((language) => language.id !== LANGUAGE_ID_ENGLISH);
};
