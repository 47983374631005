import { Moment } from 'moment';
import sessionStorage from './sessionStorage';
import memoryStorage from './memoryStorage';
import localStorage from './localStorage';
import { stringifyWithExpiration, isItemExpired, ValueType, ItemWithExpiration } from './utils';

export enum StoragePersistanceMode {
  'SESSION',
  'LOCAL',
  /**
   * Only used by iFramed apps to prevent modifying non-iFramed or a second iFramed app's storage
   */
  'MEMORY',
}

let persistentMode = StoragePersistanceMode.SESSION;

class Storage {
  /**
   * Sets mode from to persistent (localStorage) to non-persistent (sessionStorage)
   */
  static setStoragePersistanceMode = (mode: StoragePersistanceMode) => {
    persistentMode = mode;
  };

  static clear() {
    if (persistentMode === StoragePersistanceMode.LOCAL) return localStorage.clear();
    if (persistentMode === StoragePersistanceMode.SESSION) return sessionStorage.clear();
    return memoryStorage.clear();
  }

  static getItem(key: string) {
    if (persistentMode === StoragePersistanceMode.LOCAL) return localStorage.getItem(key);
    if (persistentMode === StoragePersistanceMode.SESSION) return sessionStorage.getItem(key);
    return memoryStorage.getItem(key);
  }

  static key(index: number) {
    if (persistentMode === StoragePersistanceMode.LOCAL) return localStorage.key(index);
    if (persistentMode === StoragePersistanceMode.SESSION) return sessionStorage.key(index);
    return memoryStorage.key(index);
  }

  static removeItem(key: string) {
    if (persistentMode === StoragePersistanceMode.LOCAL) return localStorage.removeItem(key);
    if (persistentMode === StoragePersistanceMode.SESSION) return sessionStorage.removeItem(key);
    return memoryStorage.removeItem(key);
  }

  static setItem(key: string, value: string) {
    if (persistentMode === StoragePersistanceMode.LOCAL) return localStorage.setItem(key, value);
    if (persistentMode === StoragePersistanceMode.SESSION)
      return sessionStorage.setItem(key, value);
    return memoryStorage.setItem(key, value);
  }

  static setItemWithExpiration(key: string, value: any, expiration: Moment | Date) {
    const item = stringifyWithExpiration(value, expiration);
    return this.setItem(key, item);
  }

  static getItemWithExpiration(key: string): ValueType | null {
    let item: ItemWithExpiration | null = null;
    try {
      item = JSON.parse(this.getItem(key) || '');
      if (!item || isItemExpired(item)) {
        this.removeItem(key);
        return null;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return null;
    }
    return item.value;
  }

  /** Preserves and restores original persistance mode when a single callback requires a different mode */
  static setTemporaryMode(mode: StoragePersistanceMode, cb: () => any) {
    const currentMode = persistentMode;
    this.setStoragePersistanceMode(mode);
    const result = cb();
    this.setStoragePersistanceMode(currentMode);
    return result;
  }
}

export default Storage;
