import { useEffect, useState } from 'react';
import { PromiseMessageTypeNames } from 'ts-promise-message';
import { PromiseMessageTypes } from '../utils/promiseMessage/promiseMessageEvents';
import ReactFrameService from '../utils/reactFrame/ReactFrameService';

const useIonicFrameKeyboardListener = () => {
  const [hideFooter, setHideFooter] = useState<boolean>(false);

  useEffect(() => {
    if (ReactFrameService.isIonicParent()) {
      ReactFrameService.instance().setPromiseMessageListener(async (_postMessage, event) => {
        const { innerType } = event.data.data;
        switch (innerType) {
          case PromiseMessageTypeNames.ionicNotifyKeyboardStatus: {
            const innerData = event.data.data
              .innerData as PromiseMessageTypes[typeof innerType]['responseData'];
            if (innerData && 'isKeyboardOpen' in innerData) {
              setHideFooter(innerData.isKeyboardOpen);
            }
            break;
          }
          default:
            break;
        }
      });
    }

    return () => {
      if (ReactFrameService.isIonicParent()) {
        ReactFrameService.instance().setPromiseMessageListener(undefined);
      }
    };
  }, []);

  return hideFooter;
};

export default useIonicFrameKeyboardListener;
