import { sleep } from 'ts-frontend/helpers';
import apiWrapper from '@/core/api/apiWrapper';
import { trackEvent } from '@/utils/analytics/eventTracker';
import { apiHelperV4 } from '@/Helpers/apiHelper';
import { DiscoveryStatusResponse } from './types';

const FIRST_DELAY_MS = 4_000;
const OTHER_DELAY_MS = 1_000;
const MAX_ATTEMPTS = 12;

const sendDiscoveryStatusRequest = async (
  externalEligibilityRequestID: number,
  flowID: number
): Promise<DiscoveryStatusResponse> =>
  apiWrapper.post(`${apiHelperV4()}internal/eligibility/discovery/status`, {
    externalEligibilityRequestID,
    flowID,
  });

interface GetTransactionStatusParams {
  externalEligibilityRequestID: number;
  flowID: number;
  firstDelayMs?: number;
  otherDelayMs?: number;
  maxAttempts?: number;
}

const getTransactionStatus = async ({
  externalEligibilityRequestID,
  flowID,
  firstDelayMs = FIRST_DELAY_MS,
  otherDelayMs = OTHER_DELAY_MS,
  maxAttempts = MAX_ATTEMPTS,
}: GetTransactionStatusParams): Promise<DiscoveryStatusResponse> => {
  let result: DiscoveryStatusResponse = {
    responseDone: false,
    copayCents: 0,
    insuranceCode: '',
    isEligible: false,
    isVideoOnlyPlan: false,
    trizettoRequestId: 0,
    isTimeout: false,
    memberID: '',
  };
  let requestAttempt = 0;
  const startTime = Date.now();
  await sleep(firstDelayMs);
  do {
    // eslint-disable-next-line no-await-in-loop
    result = await sendDiscoveryStatusRequest(externalEligibilityRequestID, flowID);
    if (!result.responseDone) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(otherDelayMs);
    }
    trackEvent('Waiting For Discovery Result', {
      isDiscoveryScreen: true,
      waitTime: (Date.now() - startTime) / 1000,
    });
    requestAttempt += 1;
  } while (!result.responseDone && requestAttempt < maxAttempts);
  if (
    (!result.responseDone && requestAttempt === maxAttempts) ||
    result.isTimeout ||
    !result.memberID
  ) {
    trackEvent('Discovery Member Not Found', {
      isDiscoveryScreen: true,
      waitTime: (Date.now() - startTime) / 1000,
      isTimeout: result.isTimeout,
    });
    throw new Error('408');
  }
  return result;
};

export default getTransactionStatus;
