import * as React from 'react';
import { Global } from '@emotion/core';
import { EmotionThemeProvider, A11Y_COLORS } from '@talkspace/react-toolkit';
import { globalStyles, theme } from '@/Elements';
import { Theme } from '@/core/styled';

const withEmotion = (Component) => (props) => {
  const quickmatchTheme: Partial<Theme> = {
    ...theme,
    isHighContrast: true,
    colors: A11Y_COLORS,
  };

  return (
    <React.Fragment>
      <Global styles={globalStyles} />
      <EmotionThemeProvider theme={quickmatchTheme}>
        <Component {...props} />
      </EmotionThemeProvider>
    </React.Fragment>
  );
};

export default withEmotion;
