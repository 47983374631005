import { isZipValid } from '@talkspace/react-toolkit';
import * as yup from 'yup';
import { ageErrorCopy } from '../../../Helpers/ageHelper';

export interface TeenZipcodeEligibilityFields {
  age: number;
  zipcode: string;
  country: string;
  state: string;
}

export interface TeenZipcodeEligibilityYupContext {
  flowId?: number;
}

export const ZIPCODE_ERROR = 'Please enter or select a valid zipcode';
const AGE_MISSING_ERROR = 'Age is required';

const teenZipcodeEligibilitySchema = yup.object().shape({
  age: yup
    .number()
    .required(AGE_MISSING_ERROR)
    .typeError(AGE_MISSING_ERROR)
    .test(
      'is-under-age',
      (userInput?: number, testContext?: yup.TestContext<TeenZipcodeEligibilityYupContext>) => {
        if (!userInput) return false;
        if (testContext && userInput && userInput < 13) {
          return testContext.createError({
            message: ageErrorCopy(testContext.options?.context?.flowId),
          });
        }

        return true;
      }
    ),
  zipcode: yup
    .string()
    .test('is-required-zip-code', ZIPCODE_ERROR, (zipCodeValue) => {
      if (zipCodeValue && zipCodeValue.length > 0) {
        return true;
      }
      return false;
    })
    .test(
      'is-valid-zip-code',
      ZIPCODE_ERROR,
      (zipCodeValue, context) =>
        zipCodeValue && context.parent.country && isZipValid(zipCodeValue, context.parent.country)
    ),
  state: yup.string().required('State is required'),
});

export default teenZipcodeEligibilitySchema;
