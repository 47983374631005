import PreRegisterExpired from '../PreRegisterExpired';

const PreRegisterJwtExpired = () => (
  <PreRegisterExpired
    heading="This link has expired"
    subHeading="Don’t worry, we’ll help you explore other options."
  />
);

export default PreRegisterJwtExpired;
