import { useFlags } from 'launchDarkly/FlagsProvider';
import { EmailWall } from '@talkspace/react-toolkit';
import { checkEmailValidity } from 'ts-frontend/helpers';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import { useRecoveredSessionActions } from '../../hooks/recoveredSessionContext';
import { putAnonymousUserDetails } from '../../Helpers/apiService';
import { trackEvent, trackGTMEvent } from '../../utils/analytics/events';
import { VWO } from '../../utils/analytics/eventTracker';
import sessionStorage from '../../core/storage/sessionStorage';
import { EventValueTypes } from '../../utils/analytics/trackerTypes';

interface MatchOverlayProps extends RouteComponentProps {
  isNoMatches: boolean;
  campaignType?: string;
  canMatchOverlayBeSkipped: boolean;
  setBlurParentContent(bool: boolean): void;
  handleSetIsMatchesOverlayDismissed: (isMatchesOverlayDismissed: boolean) => void;
  onDismiss?: () => void;
}

const MatchOverlay = ({
  isNoMatches,
  campaignType,
  canMatchOverlayBeSkipped,
  setBlurParentContent,
  handleSetIsMatchesOverlayDismissed,
  onDismiss,
}: MatchOverlayProps) => {
  const [submittedEmailOrDismissed, setSubmittedEmailOrDismissed] = useState(true);
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const { bhNoInsurance } = useFlags<{ bhNoInsurance: boolean }>();
  const { setRecoveredField } = useRecoveredSessionActions();

  const handleDismiss = useCallback(
    ({
      infoCaptured,
    }: {
      infoCaptured?: EventValueTypes['Enter Info on Lead Capture Modal']['Info Captured'];
    } = {}) => {
      setSubmittedEmailOrDismissed(true);
      setBlurParentContent(false);
      handleSetIsMatchesOverlayDismissed(true);
      if (onDismiss) {
        onDismiss();
      }
      trackEvent('Enter Info on Lead Capture Modal', {
        'Funnel Name': 'QuickMatch',
        'Info Captured': infoCaptured || 'skipped',
      });
      if (infoCaptured) {
        trackGTMEvent('QM Lead Info Captured', {});
        VWO.trackQMLeadCapturedGoal();
      }
    },
    [setBlurParentContent, handleSetIsMatchesOverlayDismissed, onDismiss]
  );

  const onSubmitEmail = useCallback(
    (email: string) => {
      setIsEmailInvalid(false);
      if (!checkEmailValidity(email)) {
        setIsEmailInvalid(true);
      } else {
        setIsSubmittingEmail(true);
        putAnonymousUserDetails({ email, consent: true }).then(async (isValid) => {
          setIsSubmittingEmail(false);
          if (isValid) {
            handleDismiss({ infoCaptured: 'email' });

            sessionStorage.setItem(
              'TSQM_RegistrationInformation',
              JSON.stringify({
                email,
              })
            );
            setRecoveredField(
              'registrationInformation',
              sessionStorage.getItem('TSQM_RegistrationInformation')
            );
          } else {
            setIsEmailInvalid(true);
          }
        });
      }
    },
    [handleDismiss, setRecoveredField]
  );

  useEffect(() => {
    const registrationInformation = JSON.parse(
      sessionStorage.getItem('TSQM_RegistrationInformation') || '{}'
    );
    const email = registrationInformation?.email || null;

    if (email && checkEmailValidity(email)) handleDismiss();
    else setSubmittedEmailOrDismissed(false);
  }, [handleDismiss]);

  if (submittedEmailOrDismissed) return null;

  const { email: qmMemberDetailEmail } = JSON.parse(
    sessionStorage.getItem('TSQM_MemberDetails') || '{}'
  );

  return (
    <EmailWall
      isVisible
      isLoading={isSubmittingEmail}
      errorMessage="There was an error validating your email address."
      shouldDisplayError={isEmailInvalid}
      title="For updates on your match, enter your email below"
      buttonText="Continue"
      buttonDataQa="showMatchesButton"
      tinyText="You can opt out anytime through email preferences or by contacting customer support."
      onSubmit={onSubmitEmail}
      dismissButtonText="Skip"
      dismissButtonDataQa="skipButton"
      onDismiss={canMatchOverlayBeSkipped ? handleDismiss : undefined}
      setBlurParentContent={setBlurParentContent}
      emailFieldDataQa="matchUpdateEmailEntry"
      emailFieldAriaLabel="matchUpdateEmailEntry"
      defaultValue={bhNoInsurance ? qmMemberDetailEmail : ''}
    />
  );
};

export default withRouter(MatchOverlay);
