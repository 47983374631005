import { FunctionComponent } from 'react';
import { COLORS, Button, Huge, useWindowWidthState, View } from '@talkspace/react-toolkit';
import MagnifyingGlassTextBox from '../assets/MagnifyingGlassTextBox';

export interface EligibilityKeywordCheckProps {
  setDoesHaveCode(gasAccessCode: boolean): void;
}

const EligibilityKeywordCheck: FunctionComponent<EligibilityKeywordCheckProps> = ({
  setDoesHaveCode,
}) => {
  const { isMobile } = useWindowWidthState();

  return (
    <View
      style={{ maxWidth: isMobile ? 334 : '100%', alignItems: 'center', justifyContent: 'center' }}
    >
      <Huge style={{ marginBottom: 64 }}>
        Do you have a Keyword / <br />
        EAP code?
      </Huge>
      <Button
        dataQa="hasKeywordButton"
        style={{ backgroundColor: COLORS.green, width: isMobile ? 320 : '100%' }}
        onPress={() => setDoesHaveCode(true)}
      >
        I have a keyword or EAP code
      </Button>
      <Button
        dataQa="continueWithoutCodeButton"
        style={{ backgroundColor: COLORS.green, width: isMobile ? 320 : '100%' }}
        onPress={() => setDoesHaveCode(false)}
      >
        No, continue without code
      </Button>
      <MagnifyingGlassTextBox
        style={{ marginTop: 93, alignSelf: 'center', width: '100%' }}
        text="Talk to your HR or benefits manager about how to retrieve your keyword or access code."
        headerText="Where do I find my code?"
      />
    </View>
  );
};

export default EligibilityKeywordCheck;
