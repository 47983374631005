import apiWrapper from '@/core/api/apiWrapper';
import { apiHelperV4 } from '@/Helpers/apiHelper';
import { DiscoveryPayload, DiscoveryRequestResponse } from './types';

export class DiscoveryRequest {
  public constructor(
    private options: {
      payload: DiscoveryPayload;
      timeout?: number;
    }
  ) {
    this.options.timeout = this.options.timeout || 15000;
  }

  public async submit(): Promise<DiscoveryRequestResponse> {
    return apiWrapper.post(`${apiHelperV4()}internal/eligibility/discovery`, this.options.payload, {
      timeout: this.options.timeout,
    });
  }

  /**
   * Returns true / false as to whether the error is the result of a network timeout.
   */
  public static isTimeoutError(err: any) {
    if (
      err.message &&
      (err.message.match(/timeout of \w*ms exceeded/) || err.message.match(/408/))
    ) {
      return true;
    }
    return false;
  }

  public static getErrorType(err: Error) {
    if (DiscoveryRequest.isTimeoutError(err)) {
      return 'TIMEOUT';
    }
    return 'OTHER';
  }
}

export const submitDiscoveryRequest = (
  payload: DiscoveryPayload,
  {
    timeout,
  }: {
    timeout?: number;
  } = {}
) =>
  new DiscoveryRequest({
    payload,
    timeout,
  }).submit();
