/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import { InsuranceConfirmation, UpdateStep } from '@/Flows/types';
import { trackEvent } from '@/utils/analytics/events';

const b2bAnswerValues: Array<InsuranceConfirmation> = ['check', 'orgEap'];

export const useOnSelect = ({ updateStep }: { updateStep: UpdateStep }) =>
  useCallback(
    (value: InsuranceConfirmation, target) => {
      trackEvent('Select Coverage', {
        selection: (() => {
          switch (value) {
            case 'check':
              return 'insurance';
            case 'direct':
              return 'out-of-pocket';
            case 'orgEap':
              return 'organizations';
            default:
              throw new Error(`Unexpected selection: ${value}`);
          }
        })(),
      });
      updateStep(target, {
        insuranceConfirmation: value,
        b2bForkResult: b2bAnswerValues.includes(value) ? 'b2b' : 'b2c',
        isB2BOutOfNetwork: undefined,
        isCouldNotBeVerified: undefined,
        insurancePayer: undefined,
        insuranceEligibility: undefined,
        partnerFlowID: undefined,
        organizationFlowID: undefined,
      });
    },
    [updateStep]
  );
