import { View } from '@talkspace/react-toolkit';
import styled from '../../core/styled';

export const OneFormEligibilityResponsiveFieldWrapper = styled(View)<{ fullWidth?: boolean }>(
  ({ fullWidth = false }) => {
    return {
      width: '100%',
      ...(!fullWidth && {
        maxWidth: 165,
        '@media(max-width: 350px)': {
          maxWidth: 335,
        },
      }),
    };
  }
);

export const OneFormEligibilityResponsiveFieldRow = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: 335,
  '@media(max-width: 350px)': {
    display: 'inline-block',
  },
});

export default OneFormEligibilityResponsiveFieldRow;
