import { useMemo } from 'react';
import { View, Spinner } from '@talkspace/react-toolkit';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { useLocation } from '@/core/routerLib';
import apiWrapper from '@/core/api/apiWrapper';
import appConfig from '@/utils/configs';

const getQmCreatedAt = (apiWrapper as any).qmCreatedAt;
const getCurrentUserID = (apiWrapper as any).currentUserID;

export const convertValueType = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (err) {
    return value;
  }
};

const withLaunchDarkly = (Component) => (props) => {
  const anonID = getCurrentUserID();

  const location = useLocation();

  const user = useMemo(() => {
    const qmCreatedAt = getQmCreatedAt ? getQmCreatedAt() : undefined;
    const urlParams = new URLSearchParams(decodeURIComponent(location.search));
    const custom: Record<string, any> = {};
    let overrideKey;
    for (const [key, value] of urlParams) {
      if (key.startsWith('ld_')) {
        const ldKey = key.replace('ld_', '');
        if (ldKey === 'key') {
          overrideKey = value;
        } else {
          custom[ldKey] = convertValueType(value);
        }
      }
    }
    custom.kind = 'user';
    custom.isAuthenticated = !!custom.isAuthenticated;
    custom.uuid = custom.uuid || anonID;
    custom.qmCreatedAt = custom.qmCreatedAt || qmCreatedAt;
    custom.qmServiceType = '';
    custom.insuranceProvider = '';
    custom.installedAppVersion = custom.installedAppVersion || '';
    return { ...custom, key: overrideKey || anonID };
  }, [location, anonID]);

  if (!user) {
    return (
      <View style={{ height: '100vh' }}>
        <View flex={1}>
          <Spinner isLoading />
        </View>
      </View>
    );
  }

  return (
    <LDProvider
      user={user || 'anonymous-quickmatch-user'}
      clientSideID={appConfig.launchDarkly.sdkKey}
      options={{
        bootstrap: 'localStorage',
      }}
    >
      <Component {...props} />
    </LDProvider>
  );
};

export default withLaunchDarkly;
