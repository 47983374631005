import { useCallback, useState } from 'react';
import { ServiceType } from 'ts-frontend/types';
import {
  getFileExtensionFromFileType,
  signManualCoverageImageUploadURL,
  submitManualCoverageRequest,
  uploadFileToS3,
} from '../Helpers/uploadVerificationImage';

export enum ManualCoverageRequestStep {
  UPLOAD_INSURANCE_IMAGES = 'uploadInsuranceImages',
  UPLOAD_ID_IMAGES = 'uploadIDImages',
  SUBMIT = 'submit',
  SUBMIT_SUCCESS = 'submitSuccess',
}

interface Props {
  memberID: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  state: string;
  partnerID: number;
  serviceType: ServiceType;
}
const useManualCoverageVerification = ({
  memberID,
  firstName,
  lastName,
  dateOfBirth,
  state,
  partnerID,
  serviceType,
}: Props) => {
  const [isManualCoverageRequest, setIsManualCoverageRequest] = useState<boolean>(false);
  const [manualCoverageRequestError, setManualCoverageRequestError] = useState<string>();
  const [manualCoverageRequestStep, setManualCoverageRequestStep] =
    useState<ManualCoverageRequestStep>(ManualCoverageRequestStep.UPLOAD_INSURANCE_IMAGES);
  const [insuranceImageFront, setInsuranceImageFront] = useState<File>();
  const [insuranceImageBack, setInsuranceImageBack] = useState<File>();
  const [idImageFront, setIDImageFront] = useState<File>();
  const [idImageBack, setIDImageBack] = useState<File>();
  const [email, setEmail] = useState<string>('');

  const onSubmitManualCoverageRequest = async () => {
    setManualCoverageRequestError(undefined);
    if (insuranceImageFront && insuranceImageBack && idImageFront && memberID && state) {
      try {
        // sign upload urls for each image
        const [signedInsuranceFront, signedInsuranceBack, signedIDFront, signedIDBack] =
          await Promise.all([
            signManualCoverageImageUploadURL({
              nameSuffix: 'insurance-front',
              fileType: insuranceImageFront.type,
            }),
            signManualCoverageImageUploadURL({
              nameSuffix: 'insurance-back',
              fileType: insuranceImageBack.type,
            }),
            signManualCoverageImageUploadURL({
              nameSuffix: 'id-front',
              fileType: idImageFront.type,
            }),
            idImageBack && // ID image back is optional
              signManualCoverageImageUploadURL({
                nameSuffix: 'id-back',
                fileType: idImageBack.type,
              }),
          ]);

        // call api to submit the request
        // NOTE: This is intentionally happening before the actual upload to S3 to prevent a racing condition
        // that is happening when upload and virus scan happens faster than the manual coverage request record is being created.
        // We are essentially assuming the S3 upload is going to succeed and if it will not user will not be able to continue which we agreed is acceptable behavior.
        await submitManualCoverageRequest({
          email,
          memberID,
          firstName,
          lastName,
          dateOfBirth,
          state,
          partnerID,
          serviceType: serviceType === 'therapyTeen' ? 'psychotherapy' : serviceType || undefined,
          uploadedFiles: [
            {
              nameSuffix: 'insurance-front',
              extension: getFileExtensionFromFileType(insuranceImageFront.type),
            },
            {
              nameSuffix: 'insurance-back',
              extension: getFileExtensionFromFileType(insuranceImageBack.type),
            },
            {
              nameSuffix: 'id-front',
              extension: getFileExtensionFromFileType(idImageFront.type),
            },
            ...(idImageBack
              ? [
                  {
                    nameSuffix: 'id-back' as const,
                    extension: getFileExtensionFromFileType(idImageBack.type),
                  },
                ]
              : []),
          ],
        });

        // upload all images to signed urls
        await Promise.all([
          uploadFileToS3(signedInsuranceFront.signedRequest, insuranceImageFront),
          uploadFileToS3(signedInsuranceBack.signedRequest, insuranceImageBack),
          uploadFileToS3(signedIDFront.signedRequest, idImageFront),
          signedIDBack && idImageBack && uploadFileToS3(signedIDBack.signedRequest, idImageBack),
        ]);
      } catch {
        setManualCoverageRequestError('Something went wrong, please try again');
        return;
      }
      if (!manualCoverageRequestError) {
        setManualCoverageRequestStep(ManualCoverageRequestStep.SUBMIT_SUCCESS);
      }
    }
  };
  const goToNextStep = useCallback(() => {
    switch (manualCoverageRequestStep) {
      case ManualCoverageRequestStep.UPLOAD_INSURANCE_IMAGES:
        setManualCoverageRequestStep(ManualCoverageRequestStep.UPLOAD_ID_IMAGES);
        break;
      case ManualCoverageRequestStep.UPLOAD_ID_IMAGES:
        setManualCoverageRequestStep(ManualCoverageRequestStep.SUBMIT);
        break;
      case ManualCoverageRequestStep.SUBMIT:
        setManualCoverageRequestStep(ManualCoverageRequestStep.SUBMIT_SUCCESS);
        break;
      default:
        break;
    }
  }, [manualCoverageRequestStep]);
  return {
    isManualCoverageRequest,
    setIsManualCoverageRequest,
    manualCoverageRequestStep,
    setManualCoverageRequestStep,
    manualCoverageRequestError,
    insuranceImageFront,
    setInsuranceImageFront,
    insuranceImageBack,
    setInsuranceImageBack,
    idImageFront,
    setIDImageFront,
    idImageBack,
    setIDImageBack,
    email,
    setEmail,
    goToNextStep,
    onSubmitManualCoverageRequest,
  };
};

export default useManualCoverageVerification;
