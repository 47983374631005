/* eslint-disable import/prefer-default-export */
import { useState, ChangeEvent } from 'react';

export const useInputChange = (
  initialState?: string
): [string, (e: ChangeEvent<HTMLInputElement>) => void] => {
  const [input, setInput] = useState<string>(initialState || '');
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => setInput(e.target.value);
  return [input, handleInputChange];
};
