import {
  EmotionThemeProvider,
  Link,
  TextDS,
  View,
  useEmotionTheme,
  useUniqueID,
} from '@talkspace/react-toolkit';

import GlobeWithLocationPinIllustration from './assets/GlobeWithLocationPinIllustration';

const NotAvailableInYourRegion = () => {
  const { spacing } = useEmotionTheme();
  const freeResourcesID = useUniqueID('freeResourcesID');
  return (
    <EmotionThemeProvider version="2.0.0">
      <View align="center" style={{ maxWidth: 450 }}>
        <GlobeWithLocationPinIllustration />
        <TextDS variant="heading2xl" style={{ marginTop: spacing('space300') }}>
          Coming soon
        </TextDS>
        <TextDS
          id={freeResourcesID}
          inline
          colorRole="textSubtle"
          style={{ marginTop: spacing('space150') }}
        >
          We’re not available in your region yet. We're working to expand, and hope to be in your
          area soon. Meanwhile, you can explore our
          <Link
            target="_blank"
            href="https://www.talkspace.com/blog"
            dataQa="freeResourcesLink"
            aria-describedby={`${freeResourcesID}`}
            isNested
          >
            <TextDS
              inline
              colorRole="textInteractiveDefault"
              style={{ textDecoration: 'underline', fontWeight: 'bold' }}
            >
              free resources
            </TextDS>
          </Link>
          and check back for updates.
        </TextDS>
      </View>
    </EmotionThemeProvider>
  );
};

export default NotAvailableInYourRegion;
