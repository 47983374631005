import { Fragment, useEffect, useState } from 'react';

import {
  Button,
  ExtraHuge,
  Large,
  Spinner,
  Standard,
  Tiny,
  TherapistAvatarOverlay,
  TherapistAvatarOverlayVideo,
  View,
  Image,
  MagnifyingGlassTherapists,
  useEmotionTheme,
  useUniqueID,
  useContainerFocus,
} from '@talkspace/react-toolkit';

import { trackEvent } from '../../utils/analytics/events';
import appConfigs from '../../utils/configs';
import useQueryLiveSessionAvailability from '../../hooks/useQueryLiveSessionAvailability';
import useWindowWidth from '../../hooks/useWindowWidth';
import HolidayMessageOverlay from './HolidayMessageOverlay';
import { MatchConfirmationStep, UpdateStep } from '../../Flows/types';

const REACT_APP_CDN_ENDPOINT = appConfigs.endpoints.cdnEndpoint;

const avatarOverlayStyle = { position: 'relative', top: -100, marginBottom: -115 };

interface MatchConfirmationProps {
  step: MatchConfirmationStep;
  updateStep: UpdateStep;
  selectedMatch: any;
  setDiscardMatches: (shouldDiscard: boolean) => void;
  setBlurParentContent(bool: boolean): void;
}

const MatchConfirmation = ({
  step,
  updateStep,
  selectedMatch,
  setDiscardMatches,
  setBlurParentContent,
}: MatchConfirmationProps) => {
  const { isSmall, isMobile } = useWindowWidth();
  const { colors } = useEmotionTheme();
  const isPsych = selectedMatch.therapistType === 'psychiatrist';
  const { data: availability, isLoading } = useQueryLiveSessionAvailability(
    selectedMatch.id,
    isPsych
  );
  const isLVSAvailable = availability && availability.timeslots.length > 0;

  useEffect(() => {
    // Allow going back to matches page without rematching
    setDiscardMatches(false);
  }, [setDiscardMatches]);

  const { firstName: therapistFirstName, lastName: therapistLastName } = selectedMatch;

  const chooseTherapist = () => {
    updateStep(step.buttonTarget, {}, selectedMatch);

    trackEvent('Confirm Therapist Selection', {
      'Therapist Name': `${therapistFirstName} ${therapistLastName}`,
      'Licensed State': selectedMatch.licenses.map((lic) => lic.state),
      Expertise: selectedMatch.expertise.mostRelevant,
      'Treatment Orientations': selectedMatch.expertise.treatmentOrientations,
      'Therapist ID': selectedMatch.id,
      'Funnel Name': 'QuickMatch',
    });
  };

  const [shouldDisplayOverlay, setShouldDisplayOverlay] = useState<boolean>(false);

  useEffect(() => {
    let isHoliday = false;
    // Use hard-coded dates to determine if today is a holiday
    const now = new Date();
    const beginDate = new Date('2021-12-21T00:00:00.000Z');
    const endDate = new Date('2022-01-02T00:00:00.000Z');
    isHoliday = now >= beginDate && now <= endDate;
    setShouldDisplayOverlay(isHoliday);
    // Blur background and unblur on unmount
    if (isHoliday) {
      setBlurParentContent(true);
      return () => {
        setBlurParentContent(false);
      };
    }
    return undefined; // return a value at the end of arrow function for eslint
  }, [setBlurParentContent]);
  const handleDismissOverlay = () => {
    setShouldDisplayOverlay(false);
    setBlurParentContent(false);
  };

  const header1ID = useUniqueID('header1ID');
  const subHeaderID = useUniqueID('subHeaderID');
  const { containerRef } = useContainerFocus(!isLoading);

  return (
    <View
      ref={containerRef}
      tabIndex={-1}
      aria-labelledby={header1ID}
      align="center"
      justify="space-between"
      style={{
        minWidth: isSmall ? undefined : 375,
        maxWidth: 440,
        margin: 'auto',
        height: isMobile ? '68vh' : undefined,
        outline: 'none',
      }}
    >
      <View align="center">
        <View style={{ height: 85, width: 85 }}>
          <Image
            dataQa="providerProfileImage"
            source={`${REACT_APP_CDN_ENDPOINT}images/application/therapist/440/${selectedMatch.id}.png`}
            initialWidth={85}
            initialHeight={85}
            height={85}
            alt={`${therapistFirstName} ${therapistLastName}'s profile photo'`}
            style={{
              borderRadius: '50%',
            }}
          />
        </View>
        {isLoading ? (
          <Spinner style={{ marginTop: 40 }} />
        ) : (
          <>
            {isLVSAvailable ? (
              <TherapistAvatarOverlayVideo style={avatarOverlayStyle} />
            ) : (
              <TherapistAvatarOverlay style={avatarOverlayStyle} />
            )}

            <ExtraHuge id={header1ID} as="h1" style={{ marginTop: 16, marginBottom: 15 }}>
              You've selected {therapistFirstName}!
            </ExtraHuge>
            <Large
              as="h2"
              id={subHeaderID}
              variant="largeDarkGrey"
              style={{ fontWeight: 'normal', maxWidth: 320 }}
            >
              {isPsych &&
                isLVSAvailable &&
                'Talkspace psychiatry is conducted through live video sessions. As a next step, reserve your initial evaluation (~60min).'}
              {!isPsych && isLVSAvailable && (
                <>
                  To break the ice, Talkspace would like to offer you a complimentary{' '}
                  <Large as="span" style={{ fontWeight: 'bold' }}>
                    10 minute Live Video Intro Session.
                  </Large>
                </>
              )}
              {!isLVSAvailable &&
                `${therapistFirstName} is ready to help you on your journey towards feeling better.`}
            </Large>
          </>
        )}
      </View>

      <View>
        <View style={{ flexDirection: 'row', maxWidth: 360, marginTop: 45 }}>
          <View style={{ flex: 1, alignItems: 'flex-end' }}>
            <MagnifyingGlassTherapists />
          </View>
          <View style={{ flex: 5, textAlign: 'left', marginLeft: 12 }}>
            <Standard variant="standardBoldBrightGreen" as="h3" style={{ marginBottom: 5 }}>
              What happens if I change my mind?
            </Standard>
            <Tiny>
              You can switch providers any time at the tap of a button! It’s common to try a few
              providers before finding the right fit.
            </Tiny>
          </View>
        </View>

        <Button
          dataQa="continueButton"
          aria-label={`Continue with ${therapistFirstName} ${therapistLastName}`}
          aria-describedby={subHeaderID}
          style={{
            marginTop: 35,
            fontWeight: 'bold',
            width: 'auto',
            minWidth: isSmall ? undefined : 360,
          }}
          onClick={chooseTherapist}
          roundedFocusStyle
          primaryColor={colors.green}
        >
          Continue with {therapistFirstName}
        </Button>
      </View>

      <HolidayMessageOverlay
        isVisible={shouldDisplayOverlay}
        onDismiss={handleDismissOverlay}
        title="Keep in mind"
        content={
          <Fragment>
            As a heads up, you are signing up for Talkspace during the holiday season when many of
            our providers may be taking time off. (They need a rest, too!).
            <br />
            <br />
            If you decide to sign up today, please be aware that you may experience delayed replies
            due to the holiday season.
          </Fragment>
        }
      />
    </View>
  );
};

export default MatchConfirmation;
