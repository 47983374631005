import { FunctionComponent, useState, useEffect, KeyboardEvent } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import Lottie from 'react-lottie';
import {
  View,
  HiddenText,
  Big,
  Image,
  useContainerFocus,
  useUniqueID,
  isSubArray,
} from '@talkspace/react-toolkit';
import { LoadingStep, MatchesStep, InternalTarget } from '@/Flows/types';
import styled from '@/core/styled';
import therapists from '@/Assets/therapists.gif';
import loadingSpinner from '@/Assets/loadingSpinner.json';
import checkmark from '@/Assets/checkmark.json';
import { isLoadingStep } from '@/Helpers/stepTypeHelper';

interface LoadingProps {
  updateStep: (target: InternalTarget) => void;
  step: LoadingStep | MatchesStep;
  loadingMatches: boolean;
  isNoMatches: boolean;
}

const Container = styled(View)({
  position: 'relative',
  paddingLeft: 15,
  paddingRight: 15,
  width: '100%',
  textAlign: 'center',
  display: 'block',
  '&:focus': {
    outline: 'none',
  },
});

const LoadingMatchesContainer = styled(View)({
  maxWidth: 530,
  margin: '0 auto',
  float: 'none',
  '&:focus': {
    outline: 'none',
  },
});

const SpinnerContainer = styled(View)({
  position: 'relative',
  height: 170,
  width: 160,
  margin: '0px auto',
  paddingLeft: 15,
});

const SpinnerWrapper = styled(View)<{ isDoingInitialLoad: boolean }>(
  ({ isDoingInitialLoad, theme: { colors } }) => {
    return {
      position: 'absolute',
      top: -11,
      left: -2,
      '& path': {
        stroke: isDoingInitialLoad ? colors.periwinkleGrey : colors.green,
      },
    };
  }
);

// Lottie container divs have tabindex=0 with no prop to override, which is very problematic from an accessibility perspective if they are only being used for presentation
const removeLottieFromTabOrder = (container: Document | HTMLDivElement, title: string) => {
  const lottieElement = container.querySelector<HTMLDivElement>(`[title="${title}"]`);
  if (lottieElement) lottieElement.tabIndex = -1;
};

const LottieComponent: FunctionComponent<{ show: boolean }> = ({ show }) => {
  const lottieTitle = useUniqueID('lottieTitle');
  useEffect(() => {
    if (show) removeLottieFromTabOrder(document, lottieTitle);
  }, [lottieTitle, show]);
  return show ? (
    <Lottie
      ariaRole="presentation"
      ariaLabel="check mark"
      title={lottieTitle}
      options={{ loop: false, autoplay: false, animationData: checkmark }}
      height={100}
      width={100}
      speed={1.4}
    />
  ) : null;
};

const ProviderImageContainer = styled(View)({
  position: 'absolute',
  paddingTop: 6,
});

const ProviderImage = styled(Image)({
  textAlign: 'center',
  margin: 'auto',
  width: '100%',
  maxWidth: 130,
  opacity: 1,
  zIndex: 100,
  boxShadow: '0px 8px 30px -4px rgb(0 30 67 / 20%)',
  borderRadius: '50%',
});

const StagesContainer = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  return {
    maxWidth: 332,
    margin: '0 auto',
    width: isMobile ? '100%' : '66%',
  };
});

const LoadingStageTextContainer = styled(View)({
  textAlign: 'left',
  fontSize: 16,
  paddingTop: 4,
  paddingBottom: 12,
  position: 'relative',
});

const LoadingStageText = styled(Big)<{ isActive?: boolean }>(({ isActive, theme: { colors } }) => {
  return {
    color: isActive ? colors.black : colors.permaLividBlue,
    fontWeight: 'normal',
  };
});

const CheckmarkContainer = styled(View)({
  display: 'inline-block',
  position: 'absolute',
  right: -40,
  top: -32,
});

const LoadingStage = ({
  text,
  isActive,
  zIndex,
}: {
  text: string;
  isActive: boolean;
  zIndex: number;
}) => (
  <LoadingStageTextContainer style={{ zIndex }} aria-live="polite">
    {/* wrapping the text in an aria-live region and re-rendering it when it becomes "active" allows for SRs to announce the stages in the appropriate order */}
    {isActive ? (
      <LoadingStageText isActive>{text}</LoadingStageText>
    ) : (
      <LoadingStageText>{text}</LoadingStageText>
    )}
    <CheckmarkContainer>
      <LottieComponent show={isActive} />
    </CheckmarkContainer>
  </LoadingStageTextContainer>
);

const Loading: FunctionComponent<LoadingProps> = ({
  updateStep,
  step,
  loadingMatches,
  isNoMatches,
}) => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [stage, setStage] = useState<number>(0);
  const isDoingInitialLoad = stage === 0;

  const lottieTitle = useUniqueID('lottieTitle');
  const loadingTextID = useUniqueID('loadingTextID');
  const pleaseWaitTextID = useUniqueID('pleaseWaitTextID');
  const { containerRef: mainContainerRef } = useContainerFocus(isDoingInitialLoad);
  const { containerRef: loadingMatchesContainerRef } = useContainerFocus(!isDoingInitialLoad);

  useEffect(() => {
    if (mainContainerRef.current) {
      removeLottieFromTabOrder(mainContainerRef.current, lottieTitle);
    }
  }, [mainContainerRef, lottieTitle]);

  useEffect(() => {
    const initialTimeoutId = window.setTimeout(() => {
      setIsReady(true);
    }, 1000);

    return () => {
      clearTimeout(initialTimeoutId);
    };
  });

  useEffect(() => {
    if (!loadingMatches && isReady) {
      if (isNoMatches) {
        // If no results
        updateStep(step.buttonTarget);
      } else {
        setStage(1);
      }
    }
  }, [isNoMatches, loadingMatches, step.buttonTarget, updateStep, isReady]);

  useEffect(() => {
    let updateStageTimer: number;
    let goToNextStepTimer: number;

    if (stage > 0) {
      if (stage >= 5 && isLoadingStep(step)) {
        goToNextStepTimer = window.setTimeout(() => {
          updateStep(step.buttonTarget);
        }, 1500);
      }
      updateStageTimer = window.setTimeout(() => {
        if (stage < 5) setStage(stage + 1);
      }, 1000);
    }
    return () => {
      if (updateStageTimer) {
        clearTimeout(updateStageTimer);
      }
      if (goToNextStepTimer) {
        clearTimeout(goToNextStepTimer);
      }
    };
  }, [stage, step, updateStep]);

  // skip to matches by pressing T and S keys
  useEffect(() => {
    const cheatCode = (e: globalThis.KeyboardEvent) => {
      const keystrokePattern: KeyboardEvent['key'][] = [];
      const { key } = e;
      keystrokePattern.push(key);
      if (isSubArray(keystrokePattern, ['t', 's'])) {
        updateStep(step.buttonTarget);
      }
    };
    let currentRef;
    if (mainContainerRef.current) currentRef = mainContainerRef.current;
    currentRef.addEventListener('keydown', cheatCode);
    return () => {
      if (mainContainerRef && currentRef) currentRef.removeEventListener('keydown', cheatCode);
    };
  }, [mainContainerRef, step.buttonTarget, updateStep]);

  return (
    <Container
      ref={mainContainerRef}
      tabIndex={-1}
      aria-labelledby={loadingTextID}
      style={{ marginTop: 45 }}
    >
      {/* useContainerFocus on the outer container is being ignored for some reason so we'll make this hidden text an aria-live region to ensure that "loading" is announced by SRs when initial grey spinner renders */}
      <HiddenText as="h1" aria-live="polite" id={loadingTextID}>
        Loading
      </HiddenText>
      <View row>
        <LoadingMatchesContainer
          ref={loadingMatchesContainerRef}
          tabIndex={-1}
          aria-labelledby={pleaseWaitTextID}
        >
          <Big
            as="h2"
            id={pleaseWaitTextID}
            variant="bigMedium"
            style={{
              margin: '20px auto 25px auto',
              maxWidth: 320,
              height: 46,
            }}
          >
            {isDoingInitialLoad
              ? ''
              : 'Please hang on while we find the best matched providers for you.'}
          </Big>
          <View style={{ marginLeft: -15, marginRight: -15 }}>
            <SpinnerContainer>
              <SpinnerWrapper isDoingInitialLoad={isDoingInitialLoad}>
                <Lottie
                  title={lottieTitle}
                  ariaRole="presentation"
                  ariaLabel="Searching for providers animation"
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: loadingSpinner,
                  }}
                  height={164}
                  width={164}
                />
              </SpinnerWrapper>
              {!isDoingInitialLoad && (
                <ProviderImageContainer>
                  <ProviderImage src={therapists} alt="Provider head shot" role="presentation" />
                </ProviderImageContainer>
              )}
            </SpinnerContainer>
            {!isDoingInitialLoad && (
              <StagesContainer>
                <LoadingStage isActive={stage >= 1} zIndex={10} text="Calculating profile..." />
                <LoadingStage isActive={stage >= 2} zIndex={8} text="Searching for matches..." />
                <LoadingStage isActive={stage >= 3} zIndex={6} text="Analyzing matches..." />
                <LoadingStage isActive={stage >= 4} zIndex={4} text="Returning best matches..." />
              </StagesContainer>
            )}
          </View>
        </LoadingMatchesContainer>
      </View>
    </Container>
  );
};

export default Loading;
