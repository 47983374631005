/* eslint-disable import/prefer-default-export */
import { useMemo, useState } from 'react';
import { useOnSubmit } from './useOnSubmit';
import {
  UseEligibilityServiceOptions,
  UseEligibilityServiceAPI,
  UseEligibilityServiceState,
} from './types';
import { useHomePageState, useHomePageActions } from '@/Components/HomePage';

/**
 * The `useEligibilityService` hook exposes an API for submitting insurance information
 * to Trizetto in order to confirm a user's insurance coverage. It can be configured to
 * allow a specified number of retries. It returns information regarding the status
 * of the submission and whether further retry attempts can be made. In order to be
 * reusable across multiple components, it should focus on that and nothing else.
 *
 * Things to be avoided:
 *
 * - Passing a direct reference to the `updateStep` method.
 * - Adding any additional logic or passing any additional parameters that are specific
 *   to any one particular component or step. Such things should live within the component
 *   that is consuming this hook or - better yet - within their own custom hook.
 */
export const useEligibilityService = ({
  maxAttempts = 1,
  isDiscoveryScreen = false,
}: UseEligibilityServiceOptions): UseEligibilityServiceAPI => {
  const { flowConfig, insurancePayer, service } = useHomePageState();
  const { setIsPayerOutage } = useHomePageActions();

  if (!flowConfig) {
    throw new Error('flowConfig is required');
  }

  if (!insurancePayer) {
    throw new Error('insurancePayer is required');
  }

  if (!service) {
    throw new Error('service is required');
  }

  const [state, setState] = useState<UseEligibilityServiceState>({
    result: null,
    error: null,
    attempts: 0,
    isSubmitting: false,
    errorType: null,
    canRetry: true,
    done: false,
  });

  const submit = useOnSubmit({
    state,
    setState,
    service,
    insurancePayer,
    maxAttempts,
    setIsPayerOutage,
    isDiscoveryScreen,
  });

  return useMemo(() => {
    return {
      result: state.result,
      error: state.error,
      errorType: state.errorType,
      attempts: state.attempts,
      canRetry: state.canRetry,
      isSubmitting: state.isSubmitting,
      done: state.done,
      submit,
    };
  }, [
    state.result,
    state.error,
    state.attempts,
    state.canRetry,
    state.isSubmitting,
    state.errorType,
    state.done,
    submit,
  ]);
};
