import { Path, Svg } from 'svgs';

import { EmotionTheme, useEmotionTheme } from '../core/styled';

interface Props {
  width?: number;
  height?: number;
  color?: keyof EmotionTheme['colors'];
}
const CameraIcon = ({ width = 28, height = 24, color = 'lightPurple' }: Props) => {
  const titleText = 'Camera';
  const { colors } = useEmotionTheme();
  return (
    <Svg
      title={titleText}
      aria-label={titleText}
      width={width}
      height={height}
      viewBox="0 0 28 24"
      fill="none"
    >
      <Path
        d="M14.25 19.1333C15.85 19.1333 17.1944 18.5889 18.2833 17.5C19.3722 16.4111 19.9166 15.0556 19.9166 13.4333C19.9166 11.8333 19.3722 10.4944 18.2833 9.41667C17.1944 8.33889 15.85 7.8 14.25 7.8C12.6277 7.8 11.2777 8.33889 10.2 9.41667C9.12218 10.4944 8.58329 11.8333 8.58329 13.4333C8.58329 15.0556 9.12218 16.4111 10.2 17.5C11.2777 18.5889 12.6277 19.1333 14.25 19.1333ZM14.25 17.1333C13.1833 17.1333 12.3055 16.7833 11.6166 16.0833C10.9277 15.3833 10.5833 14.5 10.5833 13.4333C10.5833 12.3889 10.9277 11.5222 11.6166 10.8333C12.3055 10.1444 13.1833 9.8 14.25 9.8C15.2944 9.8 16.1666 10.1444 16.8666 10.8333C17.5666 11.5222 17.9166 12.3889 17.9166 13.4333C17.9166 14.5 17.5666 15.3833 16.8666 16.0833C16.1666 16.7833 15.2944 17.1333 14.25 17.1333ZM2.91663 24C2.38329 24 1.91663 23.8 1.51663 23.4C1.11663 23 0.916626 22.5333 0.916626 22V4.9C0.916626 4.38889 1.11663 3.92778 1.51663 3.51667C1.91663 3.10556 2.38329 2.9 2.91663 2.9H7.81663L10.25 0H18.25L20.6833 2.9H25.5833C26.0944 2.9 26.5555 3.10556 26.9666 3.51667C27.3777 3.92778 27.5833 4.38889 27.5833 4.9V22C27.5833 22.5333 27.3777 23 26.9666 23.4C26.5555 23.8 26.0944 24 25.5833 24H2.91663ZM25.5833 22V4.9H19.75L17.3166 2H11.1833L8.74996 4.9H2.91663V22H25.5833Z"
        fill={colors[color]}
      />
    </Svg>
  );
};

export default CameraIcon;
