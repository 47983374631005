import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const withQueryClient = (Component) => (props) =>
  (
    <QueryClientProvider client={queryClient}>
      <Component {...props} />
    </QueryClientProvider>
  );

export default withQueryClient;
