import { ExtraHuge, EmotionStyle } from '@talkspace/react-toolkit';

interface Props {
  children: React.ReactNode;
  style?: EmotionStyle;
}

const Title = ({ children, style }: Props) => (
  <ExtraHuge
    dataQa="titleText"
    as="h1"
    style={{
      paddingTop: 0,
      fontWeight: 'bold',
      maxWidth: 310,
      alignSelf: 'center',
      textAlign: 'center',
      ...style,
    }}
  >
    {children}
  </ExtraHuge>
);

export default Title;
