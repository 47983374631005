/* eslint-disable no-multi-assign, prefer-destructuring */

/* Breakpoints */

const breakpoints = { sm: '576px', md: '768px', lg: '992px', xl: '1200px' };
const breakpointsPlusOne = { sm: '577px', md: '769px', lg: '993px', xl: '1201px' };

const mq = {
  below: {
    sm: `@media screen and (max-width: ${breakpoints.sm})`,
    md: `@media screen and (max-width: ${breakpoints.md})`,
    lg: `@media screen and (max-width: ${breakpoints.lg})`,
    xl: `@media screen and (max-width: ${breakpoints.xl})`,
  },
  above: {
    sm: `@media screen and (min-width: ${breakpoints.sm})`,
    md: `@media screen and (min-width: ${breakpoints.md})`,
    lg: `@media screen and (min-width: ${breakpoints.lg})`,
    xl: `@media screen and (min-width: ${breakpoints.xl})`,
  },
  only: {
    sm: `@media screen and (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.md})`,
    md: `@media screen and (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg})`,
    lg: `@media screen and (min-width: ${breakpoints.lg}) and (max-width: ${breakpoints.xl})`,
  },
  abovePlusOne: {
    sm: `@media screen and (min-width: ${breakpointsPlusOne.sm})`,
    md: `@media screen and (min-width: ${breakpointsPlusOne.md})`,
    lg: `@media screen and (min-width: ${breakpointsPlusOne.lg})`,
    xl: `@media screen and (min-width: ${breakpointsPlusOne.xl})`,
  },
};

/* Colors */

const colors = {
  green: '#0fc1a7',
  lightGreen: '#e4f3f1',
  darkGreen: '#009b8a',
  promoGreen: '#6bc73b',
  white: '#ffffff',
  black: '#222f2d',
  gray: '#7d8993',
  lightGray: '#bcc7d0',
  darkGray: '#454a5e',
  extraLightGrey: '#D8D8E0',
};
// aliases
colors.text = colors.black;
colors.primary = colors.green;

/* Typography */

const fonts = {
  sans: 'Roboto, sans-serif',
};
const headings = [{ fontFamily: fonts.sans }];

/* Buttons */

const buttonBase = {
  cursor: 'pointer',
  borderRadius: '1rem',
  fontSize: '1.8rem',
  transition: 'background-color 0.2s linear',
  padding: '1.5rem',
  minWidth: '23rem',
  '&[disabled]': {
    cursor: 'not-allowed',
    color: colors.white,
    backgroundColor: colors.extraLightGrey,
  },
  '&:focus': {
    outline: 'none',
  },
  [mq.below.sm]: {
    width: '100%',
  },
};

const buttons = {
  default: {
    ...buttonBase,
    backgroundColor: colors.white,
    color: colors.gray,
    border: `1px solid ${colors.extraLightGrey}`,
    fontWeight: 'normal',
    '&:not([disabled]):hover, &:focus': {
      backgroundColor: '#e4f3f1',
    },
    '&.selected': {
      borderColor: colors.green,
      backgroundColor: colors.green,
      color: colors.white,
    },
    '&.selected:hover, &.selected:focus': {
      borderColor: colors.darkGreen,
      backgroundColor: colors.darkGreen,
    },
  },
  primary: {
    ...buttonBase,
    backgroundColor: colors.primary,
    color: colors.white,
    border: '1px solid transparent',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '.07rem',
    '&:not([disabled]):hover, &:focus': {
      backgroundColor: colors.darkGreen,
    },
  },
  link: {
    ...buttonBase,
    color: colors.gray,
    fontSize: '1.2em',
    minWidth: 'auto',
    backgroundColor: 'transparent !important',
    border: 0,
    padding: 0,
    '&:focus': {
      outline: 'none',
      color: colors.darkGray,
    },
  },
};

/* Links */

const linkBase = {
  textDecoration: 'none',
  '&:hover': { textDecoration: 'underline' },
  '&:focus': {
    outline: 'none',
    textDecoration: 'none',
    color: colors.darkGray,
  },
};
const buttonLinkBase = {
  ...linkBase,
  display: 'inline-block',
  '&:hover': { textDecoration: 'none' },
};
const links = {
  default: {
    ...linkBase,
    color: colors.gray,
    '&:hover': {
      color: colors.gray,
    },
  },
  primary: {
    ...linkBase,
    color: colors.primary,
    '&:hover': {
      color: colors.primary,
    },
  },
  defaultButton: {
    ...buttonLinkBase,
    ...buttons.default,
    color: colors.darkGray,
    '&:hover': {
      color: colors.darkGray,
    },
  },
  primaryButton: {
    ...buttonLinkBase,
    ...buttons.primary,
    color: colors.white,
    '&:hover, &:focus': {
      color: colors.white,
    },
  },
};

const theme = {
  colors,
  fonts,
  headings,
  buttons,
  links,
  breakpoints,
  mq,
};

export default theme;
