import { useFlags } from 'launchDarkly/FlagsProvider';
import { useHomePageState } from '../Components/HomePage';

// eslint-disable-next-line import/prefer-default-export
export const useMoveCoverageEnabled = () => {
  const { experimentalCoverageVerification } = useFlags();
  const { flowConfig } = useHomePageState();
  return (
    !!flowConfig?.moveCoverageEnabled ||
    (!!experimentalCoverageVerification?.experimentActive &&
      experimentalCoverageVerification?.variant !== 'control')
  );
};
