import { useEffect, VoidFunctionComponent } from 'react';
import { MatchesStep, UpdateStep } from '@/Flows/types';
import { trackEvent } from '@/utils/analytics/events';
import { HomePageState } from '@/Components/HomePage/types';
import ThanksForSharing from '@/Components/ThanksForSharing';

interface NoMatchesProps {
  step: MatchesStep;
  updateStep: UpdateStep;
  isNoMatches: HomePageState['isNoMatches'];
  setBlurParentContent: (bool: boolean) => void;
  handleSetIsMatchesOverlayDismissed: (isMatchesOverlayDismissed: boolean) => void;
  isLoggedInUser: boolean;
  b2bForkResult: HomePageState['b2bForkResult'];
  isMatchesOverlayDismissed: HomePageState['isMatchesOverlayDismissed'];
  isB2BOutOfNetwork: HomePageState['isB2BOutOfNetwork'];
  isCouldNotBeVerified: HomePageState['isCouldNotBeVerified'];
  insurancePayer: HomePageState['insurancePayer'];
}

const NoMatches: VoidFunctionComponent<NoMatchesProps> = ({
  step,
  insurancePayer,
  updateStep,
  isNoMatches,
  setBlurParentContent,
  handleSetIsMatchesOverlayDismissed,
  isLoggedInUser,
  b2bForkResult,
  isMatchesOverlayDismissed,
  isB2BOutOfNetwork,
  isCouldNotBeVerified,
}) => {
  useEffect(() => {
    trackEvent('No Matches Found', {
      'Funnel Name': 'QuickMatch',
    });
  }, []);

  return (
    <ThanksForSharing
      step={step}
      insurancePayer={insurancePayer}
      updateStep={updateStep}
      isNoMatches={isNoMatches}
      setBlurParentContent={setBlurParentContent}
      handleSetIsMatchesOverlayDismissed={handleSetIsMatchesOverlayDismissed}
      isLoggedInUser={isLoggedInUser}
      b2bForkResult={b2bForkResult}
      isMatchesOverlayDismissed={isMatchesOverlayDismissed}
      isB2BOutOfNetwork={isB2BOutOfNetwork}
      isCouldNotBeVerified={isCouldNotBeVerified}
    />
  );
};

export default NoMatches;
