import Svg, { G, Path } from 'svgs';

const TherapistAvatarOverlayVideo = ({ size = 115, ...props }) => (
  <Svg width={size} height={size} viewBox="0 0 115 115" {...props}>
    <defs>
      <Path
        d="M19.4419898,17 C20.456581,17 21.2790698,17.8224887 21.2790698,18.83708 L21.2790698,25.16292 C21.2790698,26.1775113 20.456581,27 19.4419898,27 L13.83708,27 C12.8224887,27 12,26.1775113 12,25.16292 L12,18.83708 C12,17.8224887 12.8224887,17 13.83708,17 L19.4419898,17 Z M25.8911892,18.6879171 C25.9623349,18.8036605 26,18.9368618 26,19.0727229 L26,24.5598912 C26,24.9657277 25.6710045,25.2947232 25.265168,25.2947232 C25.1293069,25.2947232 24.9961056,25.2570581 24.8803622,25.1859124 L22.2802588,23.5876682 C22.0627531,23.4539707 21.9302326,23.216958 21.9302326,22.9616469 L21.9302326,20.6709671 C21.9302326,20.415656 22.0627531,20.1786433 22.2802588,20.0449459 L24.8803622,18.4467016 C25.2261042,18.2341793 25.6786669,18.3421751 25.8911892,18.6879171 Z"
        id="path-1-TherapistAvatarOverlayVideo"
      />
      <filter
        x="-57.1%"
        y="-70.0%"
        width="214.3%"
        height="260.0%"
        filterUnits="objectBoundingBox"
        id="filter-2-TherapistAvatarOverlayVideo"
      >
        <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.194204162   0 0 0 0 0.338142979   0 0 0 0 0.76636096  0 0 0 0.449737762 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <G id="Revised" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <G id="Group-4-TherapistAvatarOverlayVideo" transform="translate(0.000000, 14.000000)">
        <G id="Group-5-TherapistAvatarOverlayVideo" transform="translate(73.000000, 51.000000)">
          <G id="Group-12" fillRule="nonzero">
            <Path
              d="M10.993393,0 L10.7534308,0.00404742118 C7.32821584,0.119763069 4.5287993,2.7033427 4.07711203,6.03285545 L4.059,6.194 L4.00555546,6.23439336 C1.5453223,8.15433931 0,11.1419059 0,14.4378153 L0,29.4928569 C0,35.2366184 4.6562387,39.8928571 10.4000002,39.8928571 L27.3053569,39.8928571 C33.0491184,39.8928571 37.7053571,35.2366184 37.7053571,29.4928569 L37.7053571,14.4378153 L37.7013621,14.1477942 C37.6166733,11.0764812 36.1909474,8.29563231 33.9431499,6.43024897 L33.647,6.193 L33.6282451,6.03285545 C33.1768021,2.70514341 30.3801686,0.122557857 26.9574832,0.00423733494 L26.6585716,0 L26.4186094,0.00404742118 C23.789956,0.0928524534 21.5298773,1.63514795 20.4166824,3.85262473 L20.328,4.037 L17.376,4.037 L17.2886746,3.85330348 C16.1799776,1.64413008 13.9336457,0.105327224 11.3185921,0.00519500372 L10.993393,0 Z"
              id="Path-TherapistAvatarOverlayVideo"
              fill="#FFFFFF"
            />
            <Path
              d="M11.0467856,5 C12.1403095,5 13.0267857,5.88647625 13.0267857,6.98000012 L13.026,9.037 L24.678,9.037 L24.6785714,6.98000012 C24.6785714,5.93618188 25.4862905,5.08101653 26.5108018,5.00543088 L26.7119642,5 C27.805488,5 28.6919643,5.88647625 28.6919643,6.98000012 L28.6919643,9.07672257 L28.6868001,9.21613333 C30.9998861,9.82645578 32.7053571,11.9329685 32.7053571,14.4378153 L32.7053571,29.4928569 C32.7053571,32.4751947 30.2876947,34.8928571 27.3053569,34.8928571 L10.4000002,34.8928571 C7.41766245,34.8928571 5,32.4751947 5,29.4928569 L5,14.4378153 C5,11.9331727 6.70519298,9.82679929 9.01799129,9.21628265 L9.01339286,9.07672257 L9.01339286,6.98000012 C9.01339286,5.93618188 9.82111192,5.08101653 10.8456232,5.00543088 L11.0467856,5 Z"
              id="Path-1-TherapistAvatarOverlayVideo"
              fill="#5C84FB"
            />
          </G>
          <G id="Combined-Shape-TherapistAvatarOverlayVideo">
            <use
              fill="black"
              fillOpacity="1"
              filter="url(#filter-2-TherapistAvatarOverlayVideo)"
              xlinkHref="#path-1-TherapistAvatarOverlayVideo"
            />
            <use
              fill="#CFEAFF"
              fillRule="evenodd"
              xlinkHref="#path-1-TherapistAvatarOverlayVideo"
            />
          </G>
        </G>
        <Path
          d="M10,0 C4.4771525,0 0,4.4771525 0,10 C0,15.5228475 4.4771525,20 10,20 C15.5228475,20 20,15.5228475 20,10 C20,4.4771525 15.5228475,0 10,0 Z M10,6 C12.209139,6 14,7.790861 14,10 C14,12.209139 12.209139,14 10,14 C7.790861,14 6,12.209139 6,10 C6,7.790861 7.790861,6 10,6 Z"
          id="Oval-3-TherapistAvatarOverlayVideo"
          fill="#BCCCFF"
          fillRule="nonzero"
        />
        <Path
          d="M15.8122404,56.4981468 C17.101246,56.0033439 18.5473084,56.6471724 19.0421113,57.936178 L19.9380312,60.2701291 L22.2719823,59.3742092 C23.5609878,58.8794063 25.0070503,59.5232348 25.5018532,60.8122404 C25.9966561,62.101246 25.3528276,63.5473084 24.063822,64.0421113 L21.7298709,64.9380312 L22.6257908,67.2719823 C23.1205937,68.5609878 22.4767652,70.0070503 21.1877596,70.5018532 C19.898754,70.9966561 18.4526916,70.3528276 17.9578887,69.063822 L17.0619688,66.7298709 L14.7280177,67.6257908 C13.4390122,68.1205937 11.9929497,67.4767652 11.4981468,66.1877596 C11.0033439,64.898754 11.6471724,63.4526916 12.936178,62.9578887 L15.2701291,62.0619688 L14.3742092,59.7280177 C13.8794063,58.4390122 14.5232348,56.9929497 15.8122404,56.4981468 Z"
          id="Path-2-TherapistAvatarOverlayVideo"
          fill="#C0E3E0"
        />
        <Path
          d="M108,24.5 C104.410149,24.5 101.5,27.4101491 101.5,31 C101.5,34.5898509 104.410149,37.5 108,37.5 C111.589851,37.5 114.5,34.5898509 114.5,31 C114.5,27.4101491 111.589851,24.5 108,24.5 Z M108,29.5 C108.828427,29.5 109.5,30.1715729 109.5,31 C109.5,31.8284271 108.828427,32.5 108,32.5 C107.171573,32.5 106.5,31.8284271 106.5,31 C106.5,30.1715729 107.171573,29.5 108,29.5 Z"
          id="Oval-4-TherapistAvatarOverlayVideo"
          fill="#ADE953"
          fillRule="nonzero"
        />
      </G>
    </G>
  </Svg>
);
export default TherapistAvatarOverlayVideo;
