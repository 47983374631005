import { QMFlow, EligibilityType } from './types';
import { initFlowConfig } from './utils';
import { FLOW_90_SELECT_SERVICE } from './Flow90';

//* * PHP manual eligibility (BH) */
export const FLOW_24_B2B_BH_MANUAL_PHP = 24;

const flow: QMFlow = {
  flowId: FLOW_24_B2B_BH_MANUAL_PHP,
  flowConfig: {
    ...initFlowConfig,
    version: 2,
    isB2B: true,
    isManualFlow: true,
    serviceKeywords: {
      psychotherapy: 'php',
    },
    eligibilityType: EligibilityType.manual,
  },
  steps: [
    {
      id: 1,
      analyticsId: 56,
      category: 'oneFormEligibility',
      response_category_id: 9,
      optionalOrganization: true,
      isGroupIdRequired: false,
      optionalVoucher: true,
      skipText: 'Continue without insurance',
      skipRedirectToFlow: FLOW_90_SELECT_SERVICE,
    },
  ],
};

export default flow;
