import { QMFlow, EligibilityType } from './types';
import { initFlowConfig } from './utils';

//* * GOOGLE EAP continues on flow 2 */
export const FLOW_11_B2B_VOUCHER_GOOGLE = 11;

const flow: QMFlow = {
  flowId: FLOW_11_B2B_VOUCHER_GOOGLE,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    eligibilityType: EligibilityType.organization,
  },
  steps: [
    {
      id: 1,
      analyticsId: 54,
      category: 'oneFormEligibility',
      progressCurrent: 2,
      response_category_id: 9,
      progressMax: 10,
      optionalOrganization: false,
      hasEmployeeId: true,
    },
  ],
};

export default flow;
