/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import { useMoveCoverageEnabled } from '../../../hooks';

export const useDescription = ({
  isB2BOutOfNetwork,
}: {
  isB2BOutOfNetwork: boolean | undefined;
}) => {
  const isMoveCoverageEnabled = useMoveCoverageEnabled();
  return useMemo(() => {
    if (isMoveCoverageEnabled && !isB2BOutOfNetwork) {
      return '';
    }
    return `To verify your coverage and cost, please enter your information below.`;
  }, [isMoveCoverageEnabled, isB2BOutOfNetwork]);
};
