import { VFC } from 'react';

import { TranslationProvider } from '@talkspace/i18n/hooks/translationContext';
import { initReactI18next, i18nInit } from '@talkspace/i18n';
import configs from '../../utils/configs';

const withTranslation = <P extends {}>(Component: VFC<P>) => {
  i18nInit(initReactI18next, configs.i18n.staticPath);

  return (props: P) => (
    <TranslationProvider>
      <Component {...props} />
    </TranslationProvider>
  );
};

export default withTranslation;
