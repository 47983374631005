import { useState } from 'react';
import { Button, Small, SparklingCheckMark, UploadImage, View } from '@talkspace/react-toolkit';
import Title from '@/Components/Title';
import Description from '@/Components/Description';

interface Props {
  onUploadFront: (image: File) => void;
  onDeleteFront: () => void;
  onUploadBack: (image: File) => void;
  onDeleteBack: () => void;
  onContinue: () => void;
}
const OneFormEligibilityUploadIDCardImages = ({
  onUploadFront,
  onDeleteFront,
  onUploadBack,
  onDeleteBack,
  onContinue,
}: Props) => {
  const [frontImage, setFrontImage] = useState<File>();
  return (
    <View align="center">
      <SparklingCheckMark />
      <Title
        style={{
          marginTop: 24,
        }}
      >
        Help us confirm your identity
      </Title>
      <Description>
        Please upload a picture of your government issued identification card
      </Description>
      <View style={{ marginTop: 24 }}>
        <Small style={{ textAlign: 'left' }}>Upload a picture of your ID card</Small>
        <View row style={{ marginTop: 8 }}>
          <UploadImage
            title="Front of card"
            onUpload={(image) => {
              setFrontImage(image);
              onUploadFront(image);
            }}
            onDelete={() => {
              setFrontImage(undefined);
              onDeleteFront();
            }}
          />
          <UploadImage
            title="Back of card"
            style={{ marginLeft: 12 }}
            onUpload={onUploadBack}
            onDelete={onDeleteBack}
          />
        </View>
      </View>
      <Button
        dataQa="continueButton"
        onPress={onContinue}
        text="Continue"
        disabled={!frontImage}
        style={{ width: '100%', marginTop: 40 }}
      />
    </View>
  );
};

export default OneFormEligibilityUploadIDCardImages;
