import { FunctionComponent } from 'react';

import { useEmotionTheme, OptionBlock, Small } from '@talkspace/react-toolkit';
import { SessionModality } from 'ts-frontend/types';
import useStringTranslation from '@talkspace/i18n/hooks/useStringTranslation';
import { modalitiesIconAndText } from '@/Helpers/preferredModalityHelper';

const optionBlockProps = (isFlatTop: boolean) => {
  return {
    radioButtonContainerStyle: {
      marginLeft: 0,
      marginRight: 28.5,
    },
    iconContainerStyle: {
      marginLeft: 19,
      marginTop: 16,
      width: 24,
    },
    textContainerStyle: {
      margin: '16px 10px 16px 15px',
      textAlign: 'left',
    },
    style: {
      height: 'auto',
      opacity: 1,
      borderRadius: isFlatTop ? '0px 0px 8px 8px' : 8,
    },
  };
};

interface ModalityOptionProps {
  modality: SessionModality;
  activeModality: SessionModality;
  onHandlePress: (modality: SessionModality) => void;
  dataQa: string;
  isFlatTop?: boolean;
}

const ModalityOption: FunctionComponent<ModalityOptionProps> = ({
  modality,
  activeModality,
  onHandlePress,
  dataQa,
  isFlatTop = false,
}) => {
  const { colors } = useEmotionTheme();
  const { Icon, description, descriptionTranslationKey, displayTitle, displayTitleTranslationKey } =
    modalitiesIconAndText[modality];
  const displayTitleText = useStringTranslation(
    displayTitle,
    'quickmatch.common',
    displayTitleTranslationKey
  );
  const descriptionText = useStringTranslation(
    description,
    'quickmatch.common',
    descriptionTranslationKey
  );
  return (
    <OptionBlock<SessionModality>
      isActive={activeModality === modality}
      icon={<Icon color={colors.purple} />}
      displayTitle={displayTitleText}
      description={<Small style={{ width: 225 }}>{descriptionText}</Small>}
      value={modality}
      onHandlePress={onHandlePress}
      dataQa={dataQa}
      {...optionBlockProps(isFlatTop)}
    />
  );
};

export default ModalityOption;
