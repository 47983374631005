import { useEffect, VFC } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { debug, enabled } from 'ts-frontend/utils/dev-console';

/**
 * This HOC is only applied in non-production environments. It serves as a point
 * from which you can monitor and log events of interest that may change over time.
 * For example - we use it to log LaunchDarkly feature flag settings to the console
 * whenever they change (helpful for debug / QA purposes).
 */
const withAppLogs = <P extends {}>(Component: VFC<P>) => {
  if (!enabled()) {
    return Component;
  }

  return (props: P) => {
    const { setFlag, ...flags } = useFlags();

    useEffect(() => {
      debug('QuickMatch: LaunchDarkly flags have changed:', flags);
    }, [flags]);

    return <Component {...props} />;
  };
};

export default withAppLogs;
