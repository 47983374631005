// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FunctionComponent } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  View,
  Large,
  ExtraHuge,
  Button as AccessibleButton,
  useUniqueID,
  useContainerFocus,
  HiddenText,
  useEmotionTheme,
  commonStyles,
} from '@talkspace/react-toolkit';
import useStringTranslation from '@talkspace/i18n/hooks/useStringTranslation';
import {
  TranslationNamespaceQuickmatchFlow,
  TFKeysPrefixedQuickmatchCommon,
  TFKeysQuickmatchFlow,
} from '@talkspace/i18n/types';
import styled from '../../../core/styled';
import { FlowStep } from '../../../Flows/types';

const { QMResponsiveWidth } = commonStyles;

export const StepLayoutButton: FunctionComponent<{
  onClick: () => void;
  step: FlowStep;
  i18nNamespace?: TranslationNamespaceQuickmatchFlow;
}> = ({ onClick, step, i18nNamespace, ...otherProps }) => {
  const { colors } = useEmotionTheme();
  const { buttonText, category: stepCategory } = step;
  const buttonTextTranslated = useStringTranslation(
    buttonText || '',
    i18nNamespace,
    `step${step.id}.buttonText`
  );
  return (
    <AccessibleButton
      dataQa={`${stepCategory || ''}Button`}
      style={{ ...QMResponsiveWidth, alignSelf: 'center' }}
      roundedFocusStyle
      primaryColor={colors.green}
      onClick={onClick}
      {...otherProps}
    >
      {buttonTextTranslated}
    </AccessibleButton>
  );
};

const StyledMainWrapper = styled(View)<{ opacity: number }>(({ opacity }) => {
  return {
    opacity,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    '&:focus': {
      outline: 'none',
    },
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
    maxWidth: 900,
  };
});

const StyledHeading1 = styled(ExtraHuge)({
  maxWidth: 320,
  marginBottom: 28,
});

const StyledHeading2 = styled(Large)({
  maxWidth: 320,
  fontWeight: 400,
  marginBottom: 35,
});

const StyledHeading3 = styled(Large)(({ theme: { colors } }) => {
  return {
    marginBottom: 24,
    color: colors.grey950,
  };
});

let heading2AsHeading1Styes: Record<string, any> = {
  maxWidth: 430,
  fontSize: 29,
  fontWeight: 700,
  lineHeight: '34px',
};

const MainContentWrapper = ({
  hasBackButtonOrProgress,
  stepId,
  opacity,
  hideComponent,
  heading1,
  heading2,
  heading2TranslationKey,
  heading1ID,
  heading2ID,
  children,
  progressHelpers: { isProgressHidden, progressCurrent, progressMax },
  stepCategory,
  heading3,
  heading3TranslationKey,
  i18nNamespace,
}: {
  hasBackButtonOrProgress: boolean;
  stepId: number;
  opacity: number;
  hideComponent: boolean;
  heading1?: string | null;
  heading2?: string | null;
  heading2TranslationKey?: TFKeysPrefixedQuickmatchCommon | TFKeysQuickmatchFlow;
  heading1ID: string;
  heading2ID: string;
  children: React.ReactNode;
  progressHelpers: { progressCurrent?: number; progressMax?: number; isProgressHidden?: boolean };
  stepCategory?: string;
  heading3?: string | null;
  heading3TranslationKey?: TFKeysPrefixedQuickmatchCommon | TFKeysQuickmatchFlow;
  i18nNamespace?: TranslationNamespaceQuickmatchFlow;
}) => {
  // If the step object provides a heading, we should focus the main container on step change to allow for the most pertinent heading to be announced by a screen reader.
  // Some steps render their own headings (i.e. ValidateVoucher), in which case we should not focus the main container so as to allow for that specific step's container to receive
  // focus, enabling SRs to announce the step's heading/title
  const shouldFocusMain = heading1 || (!heading1 && heading2 && !hideComponent);
  const { containerRef } = useContainerFocus(stepId, undefined, !shouldFocusMain);

  const progressTextID = useUniqueID('progressTextID');
  const isValidProgress = !!(!isProgressHidden && progressCurrent && progressMax);
  // some flows have variable number of steps so it makes more sense for SRs to announce percentage completion
  const progressText =
    progressCurrent && progressMax
      ? `Progress: ${Math.round((progressCurrent / progressMax) * 100)}% completed`
      : '';
  // Allows the step progress and most pertinent heading to be announced on step change
  const ariaDescribedby = isValidProgress ? { 'aria-describedby': progressTextID } : {};
  let ariaLabelledby;
  if (heading1) {
    ariaLabelledby = heading1ID;
  } else if (heading2) {
    ariaLabelledby = heading2ID;
  }

  const stepsWithPaddingAlready = [
    'oneFormEligibility',
    'dispatcherInQM',
    'matches1',
    'scheduler',
    'insuranceConfirmation',
    'quickEligibilityStatus',
  ];
  // A given page must always have an h1 heading so this is used to render step.heading2 as an h1 when heading1 is not provided
  const heading2AsHeading1 = !heading1 && heading2 && !hideComponent;
  let paddingTop = heading1 || heading2 ? 113 : 45;
  // TODO: confirm top padding/margin with design. The new QM designs are all over the place in that regard hence all this logic
  if (hasBackButtonOrProgress && (heading1 || heading2)) {
    paddingTop -= 70;
  } else if (stepCategory && stepsWithPaddingAlready.includes(stepCategory)) {
    paddingTop = 0;
  } else if (hasBackButtonOrProgress) {
    paddingTop += 3;
  }

  heading2AsHeading1Styes = {
    ...heading2AsHeading1Styes,
    marginBottom: heading3 ? 12 : 35,
  };
  const heading1HTML = useStringTranslation(
    heading1 || '',
    i18nNamespace,
    `step${stepId}.heading1`
  );
  const heading2Text = useStringTranslation(
    heading2 || '',
    i18nNamespace,
    heading2TranslationKey || `step${stepId}.heading2`
  );
  const heading3Text = useStringTranslation(
    heading3 || '',
    i18nNamespace,
    heading3TranslationKey || `step${stepId}.heading3`
  );
  return (
    <StyledMainWrapper
      tabIndex={-2}
      ref={containerRef}
      opacity={opacity}
      as="main"
      aria-labelledby={ariaLabelledby}
      {...ariaDescribedby}
      style={{
        paddingTop,
        minWidth: stepCategory === 'matches1' ? '100%' : undefined,
      }}
    >
      {isValidProgress && <HiddenText id={progressTextID}>{progressText}</HiddenText>}
      {heading1 && (
        <StyledHeading1
          dataQa={`${stepCategory || ''}Heading`}
          as="h1"
          id={heading1ID}
          // need to set inner html due to how we embed html within flow config strings
          dangerouslySetInnerHTML={{
            __html: heading1HTML,
          }}
        />
      )}
      {heading2 && !hideComponent && (
        <StyledHeading2
          dataQa={`${stepCategory || ''}${heading2AsHeading1 ? 'Heading' : 'Subheading'}`}
          as={heading2AsHeading1 ? 'h1' : 'h2'}
          id={heading2ID}
          style={heading2AsHeading1 ? heading2AsHeading1Styes : undefined}
        >
          {heading2Text}
        </StyledHeading2>
      )}
      {heading3 && !hideComponent && (
        <StyledHeading3
          dataQa={`${stepCategory || ''}Subheading2`}
          as={heading2AsHeading1 ? 'h2' : 'h3'}
        >
          {heading3Text}
        </StyledHeading3>
      )}
      {children}
    </StyledMainWrapper>
  );
};

export default MainContentWrapper;
