import {
  Big,
  Button,
  ClinicalProgressGaugeChart,
  EmailWall,
  Large,
  Link,
  View,
  useUniqueID,
  ScrollView,
  Carousel,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { useCallback, useEffect, useState } from 'react';
import { SurveyResult } from 'ts-frontend/types';
import { checkEmailValidity } from 'ts-frontend/helpers';
import { RouteComponentProps, withRouter } from '@/core/routerLib';
import appConfigs from '@/utils/configs';

import styled from '@/core/styled';
import { FlowConfig } from '@/Flows/types';
import switchFlowHelper from '@/Helpers/switchFlowHelper';
import { FLOW_90_SELECT_SERVICE } from '@/Flows';
import { useRecoveredSessionActions } from '@/hooks/recoveredSessionContext';
import { getSurveyResults, putAnonymousUserDetails } from '@/Helpers/apiService';
import { trackEvent } from '@/utils/analytics/events';
import {
  AltAssessmentCTACookie,
  altAssessmentCTACookieValues,
  getAltAssessmentCTACookie,
  setAltAssessmentCTACookie,
} from './utils/altAssessmentCtaCookie';

const StyledButton = styled(Button)(({ theme: { colors } }) => {
  return {
    transition: 'all 200ms ease-in-out',
    fontWeight: 700,
    '&:not([disabled]):hover': {
      backgroundColor: colors.permaTropicalRainForest,
    },
  };
});

interface SurveyResultsProps extends RouteComponentProps {
  flowId: number;
  flowConfig: FlowConfig;
  setBlurParentContent(bool: boolean): void;
}

const defaultSurveyResults: SurveyResult = {
  userRoomSurveyID: 0,
  completedAt: '2020-05-19T18:09:09.000Z',
  surveyLabel: 'Quality of life',
  assessmentLabel: 'QOLS',
  learnMoreText:
    'Your score is based on the QOLS, a screening tool for the presence and severity quality of life issues.',
  maximum: 112,
  roomID: 0,
  score: 63,
  scoreChange: null,
  scoreChangePercent: null,
  progressSummary:
    'Experiencing symptoms is common. By engaging in therapy will you learn strategies to reduce symptoms and manage everyday stressors.',
  progressPopup:
    "Your score indicates you're experiencing poor quality of life. By deciding to start therapy, you've already taken an important step toward progress. For further insight, be sure to check in with your therapist about your assessment results.",
  scoreRanges: [
    {
      start: 0,
      end: 84,
      label: 'Poor quality of life',
    },
    {
      start: 85,
      end: 89,
      label: 'Quality of Life Concerns',
    },
    {
      start: 90,
      end: 112,
      label: 'Good Life Quality',
    },
  ],
  scaleRangeLabel: 'Poor quality of life',
  isScoreReversed: true,
};

const SurveyResultsTnC = () => {
  const { colors } = useEmotionTheme();
  return (
    <>
      By providing your email in this testing process, you agree to get your results and further
      content and promotional offerings related to the subject of this testing. All email replies
      will be in accordance with the{' '}
      <Link
        target="_blank"
        href="https://www.talkspace.com/public/terms"
        style={{ color: colors.green, fontWeight: 700 }}
        roundedFocusStyle
        primaryColor={colors.green}
      >
        Terms of Use
      </Link>{' '}
      and the{' '}
      <Link
        target="_blank"
        href="https://www.talkspace.com/public/privacy-policy"
        style={{ color: colors.green, fontWeight: 700 }}
        roundedFocusStyle
        primaryColor={colors.green}
      >
        Privacy Policy
      </Link>{' '}
      listed on the Talkspace homepage. You can opt out anytime through email preferences or by
      contacting customer support.
    </>
  );
};

const SingleSurveyResult = ({
  surveyResult,
  progressSummaryID,
}: {
  surveyResult: SurveyResult;
  progressSummaryID: string;
}) => {
  const { score, scoreRanges, maximum, isScoreReversed, surveyLabel, progressSummary } =
    surveyResult;

  return (
    <View style={{ width: 340 }}>
      <ClinicalProgressGaugeChart
        score={score}
        scoreRanges={scoreRanges}
        max={maximum}
        isScoreReversed={isScoreReversed}
        style={{ overflowX: 'hidden' }}
      />
      <Big as="h1" style={{ marginBottom: 12, marginTop: 24 }}>
        Your {surveyLabel} score is {score}/{maximum}
      </Big>
      <Large variant="largeDarkGrey" id={progressSummaryID}>
        {progressSummary}
      </Large>
    </View>
  );
};

const ARROW_HORIZONTAL_MARGIN = 142;

const SurveyResults = ({
  flowId,
  flowConfig,
  history,
  setBlurParentContent,
}: SurveyResultsProps) => {
  const { surveyID } = flowConfig;
  const [submittedEmail, setSubmittedEmail] = useState(true);
  const [altAssessmentCTA, setAltAssessmentCTA] = useState<AltAssessmentCTACookie>('default');
  const [isSubmittingEmail, setIsSubmittingEmail] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [surveyResults, setSurveyResults] = useState<SurveyResult[]>([defaultSurveyResults]);
  const { updateAndSave, setRecoveredField } = useRecoveredSessionActions();
  const { colors } = useEmotionTheme();

  const fetchSurveyResults = useCallback(async () => {
    if (!surveyID) return;
    try {
      const { error, results } = await getSurveyResults(surveyID);
      if (error) {
        setSubmittedEmail(false);
        return;
      }
      setSurveyResults(results);
      setSubmittedEmail(true);
    } catch (error) {
      setSubmittedEmail(false);
    }
  }, [surveyID]);

  const getCTAText = () => {
    switch (altAssessmentCTA) {
      case 'find':
        return 'Find me a therapist';
      case 'getStarted':
        return 'Get started with a therapist';
      case 'learn':
        return 'Learn more about Talkspace therapy';
      default:
        return 'Match with a provider';
    }
  };

  useEffect(() => {
    fetchSurveyResults();
  }, [fetchSurveyResults]);

  useEffect(() => {
    if (!appConfigs.featureFlags.altAssessmentCTA) {
      return;
    }

    const altAssessmentCTACookie = getAltAssessmentCTACookie();
    let altAssessmentCTACookieVariant: AltAssessmentCTACookie = 'default';

    if (!altAssessmentCTACookie || !altAssessmentCTACookieValues.includes(altAssessmentCTACookie)) {
      const altAssessmentCTARng = Math.random() * 100;

      if (altAssessmentCTARng >= 25 && altAssessmentCTARng < 50) {
        altAssessmentCTACookieVariant = 'find';
      } else if (altAssessmentCTARng >= 50 && altAssessmentCTARng < 75) {
        altAssessmentCTACookieVariant = 'getStarted';
      } else if (altAssessmentCTARng >= 75) {
        altAssessmentCTACookieVariant = 'learn';
      }

      trackEvent('TS Experiment Session', {
        experimentName: 'AssessmentResultCTAVariant',
        variantName: altAssessmentCTACookieVariant,
      });

      setAltAssessmentCTACookie(altAssessmentCTACookieVariant);
    } else {
      altAssessmentCTACookieVariant = altAssessmentCTACookie;
    }
    setAltAssessmentCTA(altAssessmentCTACookieVariant);
  }, []);

  const getMatchedAction = useCallback(() => {
    switchFlowHelper(FLOW_90_SELECT_SERVICE, history, updateAndSave, {
      newFlowSearch: `?redirectFrom=${flowId}`,
    });

    trackEvent('TS Experiment Conversion', {
      experimentName: 'AssessmentResultCTAVariant',
      variantName: altAssessmentCTA,
    });

    trackEvent('Continue from Results', {
      'Funnel Name': 'Assessments',
      Response: 'Get matched with a provider',
    });
  }, [flowId, history, updateAndSave, altAssessmentCTA]);

  const onSubmitEmail = useCallback(
    (email: string) => {
      setIsEmailInvalid(false);
      if (!checkEmailValidity(email)) {
        setIsEmailInvalid(true);
      } else {
        setIsSubmittingEmail(true);
        putAnonymousUserDetails({ email, consent: true }).then((isValid) => {
          setIsSubmittingEmail(false);
          if (isValid) {
            setSubmittedEmail(true);
            fetchSurveyResults();
            trackEvent('Enter Email for Assessment Results', {
              'Funnel Name': 'Assessments',
            });

            sessionStorage.setItem(
              'TSQM_RegistrationInformation',
              JSON.stringify({
                email,
              })
            );
            setRecoveredField(
              'registrationInformation',
              sessionStorage.getItem('TSQM_RegistrationInformation')
            );
          } else {
            setIsEmailInvalid(true);
          }
        });
      }
    },
    [fetchSurveyResults, setRecoveredField]
  );

  useEffect(() => {
    if (surveyResults[0] !== defaultSurveyResults) {
      surveyResults.forEach((surveyResult) => {
        trackEvent('Show Results Screen', {
          'Funnel Name': 'Assessments',
          Assessment: surveyResult.surveyLabel,
          Result: surveyResult.score,
          Scale: surveyResult.maximum,
        });
      });
    }
  }, [surveyResults]);

  const progressSummaryID = useUniqueID('progressSummaryText');

  const showEmailWall = !submittedEmail;

  if (!surveyID) return null;
  /* Copied mostly from SurveyResultsView, there's potential to move this to toolkit */
  return (
    <View
      align="center"
      justify="center"
      style={{ paddingTop: 68, paddingBottom: 38, overflowX: 'hidden' }}
    >
      <ScrollView style={{ minHeight: 300 }}>
        <View align="center" aria-label="survey results">
          {surveyResults.length === 1 ? (
            <SingleSurveyResult
              progressSummaryID={progressSummaryID}
              surveyResult={surveyResults[0]}
            />
          ) : (
            <Carousel
              containerStyle={{ maxWidth: 680, overflowX: 'hidden' }}
              arrowHorizontalMargin={ARROW_HORIZONTAL_MARGIN}
            >
              {surveyResults.map((surveyResult) => (
                <SingleSurveyResult
                  progressSummaryID={progressSummaryID}
                  surveyResult={surveyResult}
                  key={surveyResult.surveyLabel}
                />
              ))}
            </Carousel>
          )}
        </View>
      </ScrollView>
      <StyledButton
        style={{ marginBottom: 30 }}
        onPress={getMatchedAction}
        text={getCTAText()}
        aria-describedby={progressSummaryID}
        roundedFocusStyle
        primaryColor={colors.green}
        dataQa="surveyResultsCTA"
      />
      <EmailWall
        isVisible={showEmailWall}
        isLoading={isSubmittingEmail}
        errorMessage="There was an error validating your email address."
        shouldDisplayError={isEmailInvalid}
        title="Enter your email to see your results"
        buttonText="Show results"
        tinyText={SurveyResultsTnC()}
        onSubmit={onSubmitEmail}
        setBlurParentContent={setBlurParentContent}
        buttonDataQa="surveyResultsShowResultsButton"
        emailFieldDataQa="surveyResultsEmailTextInput"
      />
    </View>
  );
};

export default withRouter(SurveyResults);
