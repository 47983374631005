import { InsuranceEligibilityPayer, ServiceType } from 'ts-frontend/types';
// This import in particular needs user interaction, therefore, "safe" to be a cycle
// eslint-disable-next-line import/no-cycle
import { getMergedFlowConfig } from '../Flows';
import { getFlowConfigByKeyword } from './apiService';

// eslint-disable-next-line import/prefer-default-export
export const getInsuranceVerificationFlowID = async (
  payer: InsuranceEligibilityPayer | null,
  service: ServiceType | null
): Promise<{ flowID: number | null; b2bOutOfNetwork?: boolean; mismatchingService?: boolean }> => {
  // Payer is OTHER or no payer was set
  if (!payer?.id) return { flowID: null };

  if (!payer.keyword) return { flowID: null, b2bOutOfNetwork: true }; // For insurance payers out of network, use flow 61.

  const { flowID = null, flowConfig: serverFlowConfig } = await getFlowConfigByKeyword(
    payer.keyword
  );
  if (flowID) {
    const flow = getMergedFlowConfig(flowID, serverFlowConfig);
    if (!service || !flow || !flow.flowConfig) {
      // This is going to make a big difference with debugging if we ever hit this edge-case
      if (!flow?.flowConfig)
        // eslint-disable-next-line no-console
        console.warn('Attempted to open flow with no flowConfig', { flowID, service });
      return { flowID: null, mismatchingService: true };
    }
    const { serviceKeywords } = flow.flowConfig;
    // If we don't support the selected service for this payer, treat them as B2C (Might change in future)
    if (serviceKeywords && !(service in serviceKeywords))
      return { flowID: null, mismatchingService: true };
    return { flowID };
  }
  return { flowID: null };
};
