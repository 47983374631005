import { QMFlow } from './types';
import { initFlowConfig } from './utils';

export const FLOW_142_NEW_USER_HOLD_EXPIRED = 142;

const flow: QMFlow = {
  flowId: FLOW_142_NEW_USER_HOLD_EXPIRED,
  flowConfig: {
    ...initFlowConfig,
    serviceType: 'psychotherapy',
    isB2B: true,
    isTeen: false,
    shouldHideServiceSelection: true,
    moveCoverageEnabled: false,
    version: 3,
  },
  steps: [
    {
      id: 1,
      category: 'preRegisterHoldExpired',
      hideBackButton: true,
    },
  ],
};

export default flow;
