/* eslint-disable import/prefer-default-export */
import {
  Big,
  Tiny,
  BaseButton,
  TabRadioGroup,
  getButtonStyles,
  withHover,
  WithHoverProps,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { QuestionsProps } from './types';

const Question = withHover(
  styled(BaseButton)<
    {
      selected: boolean;
      removeTransition?: boolean;
    } & WithHoverProps
  >(({ selected, isHovering, removeTransition, theme: { colors, isHighContrast } }) => {
    return {
      fontSize: '1.4rem',
      textAlign: 'left',
      padding: '1.5rem',
      width: '100%', // for some Android browsers where flex does not properly fill width
      border: `1px solid ${colors.lightGray}`,
      borderRadius: '1rem',
      cursor: 'pointer',
      transition: 'background-color 0.2s ease-in-out',
      color: selected && isHighContrast ? colors.white : colors.black,
      backgroundColor: selected ? colors.green : colors.white,
      '&:not(:last-child)': {
        borderBottom: '0',
      },
      '&:first-of-type': {
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0',
      },
      '&:last-child': {
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
      },
      '&:not(:first-of-type):not(:last-child)': {
        borderRadius: '0',
      },
      ...getButtonStyles(
        {
          activeColors: {
            color: colors.white,
            backgroundColor: colors.green,
          },
          hoverColors: {
            color: colors.black,
            backgroundColor: colors.lightGreen,
          },
        },
        { isSelected: selected, isHovering, removeTransition }
      ),
    };
  })
);

export const Questions = ({ questions, onSelect }: QuestionsProps) => (
  <TabRadioGroup>
    {questions.map((question, index) => {
      const isSelected = false;
      return (
        <Question
          dataQa="insuranceCheckQuestion"
          role="radio"
          removeWrapper
          removeTransition
          key={question.label}
          aria-checked={isSelected}
          tabIndex={isSelected ? 0 : -1}
          selected={isSelected}
          onPress={() => onSelect(question.value, question.target)}
        >
          <Big>{question.label}</Big>
          {question.subheading && <Tiny style={{ paddingTop: 4 }}>{question.subheading}</Tiny>}
        </Question>
      );
    })}
  </TabRadioGroup>
);
