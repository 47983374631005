import { useWindowWidthState, Image as OriginalImage } from '@talkspace/react-toolkit';
import friendsChattingMobile from '@/Assets/friends-chatting-mobile.png';
import friendsChattingDesktop from '@/Assets/friends-chatting-desktop.png';
import { EmotionStyle } from '@/core/styled';

type OutOfNetworkImageProps = {
  width?: number;
  height?: number;
  style?: EmotionStyle;
};

const OutOFNetworkImage = ({ width, height, style, ...props }: OutOfNetworkImageProps) => {
  const { isMobile } = useWindowWidthState();
  const defaultWidth = isMobile ? 335 : 430;
  const defaultHeight = 200;

  const finalWidth = width || defaultWidth;
  const finalHeight = height || defaultHeight;

  return (
    <OriginalImage
      width={finalWidth}
      height={finalHeight}
      source={isMobile ? friendsChattingMobile : friendsChattingDesktop}
      alt="two people having a conversation"
      {...props}
    />
  );
};
export default OutOFNetworkImage;
