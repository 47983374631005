import { useEffect, VoidFunctionComponent } from 'react';
import {
  Modal,
  CloseButton,
  Button,
  View,
  Large,
  useWindowWidthState,
  useUniqueID,
  useEmotionTheme,
} from '@talkspace/react-toolkit';

const defaultButtonText = 'OK';

interface Props {
  onSubmit: () => void;
  title?: string;
  subTitle?: string | JSX.Element;
  isVisible: boolean;
  buttonText?: string;
  buttonDataQa?: string;
  text?: string | JSX.Element;
  isLoading?: boolean;
  shouldDisplayError?: boolean;
  dismissButtonText?: string;
  dismissButtonDataQa?: string;
  onClose: () => void;
  setBlurParentContent(bool: boolean): void;
}

const FlowRedirectionModal: VoidFunctionComponent<Props> = ({
  onSubmit,
  title,
  subTitle,
  isVisible,
  buttonText = defaultButtonText,
  buttonDataQa,
  onClose,
  isLoading,
  setBlurParentContent,
  ...otherProps
}) => {
  const { colors } = useEmotionTheme();

  const { isMobile } = useWindowWidthState();

  const handleSubmit = () => {
    onSubmit();
  };

  useEffect(() => {
    setBlurParentContent(isVisible);
  }, [setBlurParentContent, isVisible]);

  useEffect(
    () => () => {
      setBlurParentContent(false);
    },
    [setBlurParentContent]
  );

  const ID_TITLE = useUniqueID('titleId');
  const ID_SUB_TITLE = useUniqueID('subTitleId');

  return (
    <Modal
      titleID={ID_TITLE}
      isVisible={isVisible}
      underlayStyle={{
        alignItems: 'flex-end',
        overflow: 'hidden',
      }}
      dialogStyle={
        isMobile
          ? { width: '100%', height: '100%' }
          : { position: 'absolute', width: 500, height: '100%' }
      }
      onBackdropPress={onClose}
    >
      <View
        style={{
          backgroundColor: colors.white,
          width: '100%',
          borderRadius: 10,
          borderTopRightRadius: 10,
          padding: 20,
          ...otherProps,
        }}
      >
        <CloseButton dataQa="CloseButton" onPress={onClose} />
        <Large
          id={ID_TITLE}
          style={{
            textAlign: 'center',
            marginBottom: 30,
            marginLeft: 30,
            marginRight: 30,
            marginTop: 10,
          }}
        >
          {title}
        </Large>

        {subTitle && (
          <Large
            id={ID_SUB_TITLE}
            style={{
              textAlign: 'center',
              marginBottom: 20,
              marginLeft: 30,
              marginRight: 30,
            }}
          >
            {subTitle}
          </Large>
        )}

        <Button
          style={{ width: '100%', fontWeight: 'bold' }}
          onPress={handleSubmit}
          isLoading={isLoading}
          dataQa={buttonDataQa}
          roundedFocusStyle
          aria-describedby={ID_SUB_TITLE}
          primaryColor={colors.green}
        >
          {buttonText}
        </Button>
      </View>
    </Modal>
  );
};

export default FlowRedirectionModal;
