type ExperimentVariants = 'control' | string;

type ComponentType = JSX.Element | null;

type VariantsComponents<T extends ExperimentVariants = ExperimentVariants> = Record<
  T,
  ComponentType
>;

type Props<T extends ExperimentVariants = ExperimentVariants> = {
  featureFlagBoolean: boolean;
  featureFlagVariant: T;
  /**
   * We always want to have a control.
   * Add it here so we can use it inside the ExperimentalComponent as a backup
   */
  control: ComponentType;
} & VariantsComponents<T>;

/**
 *
 * @example Usage
 * ```jsx
 * <ExperimentalComponent
 *  // Data coming from launchdarkly
 *  featureFlagBoolean={featureFlagBoolean}
 *  featureFlagVariant={featureFlagVariant as 'control' | 'treatment'}
 *  // Variants
 *  treatment={<View />}
 *  control={<View />}
 * />
 * ```
 */
const ExperimentalComponent = <T extends ExperimentVariants = ExperimentVariants>(
  props: Props<T>
) => {
  const { featureFlagBoolean, featureFlagVariant, control, ...components } = props;
  if (!featureFlagBoolean) return control;
  if (props[featureFlagVariant] === undefined) {
    // eslint-disable-next-line no-console
    console.warn('Component for variant was not found', { featureFlagVariant, components });
    return control;
  }
  return props[featureFlagVariant];
};

export default ExperimentalComponent;
