/* eslint-disable import/prefer-default-export */
import useStringTranslation from '@talkspace/i18n/hooks/useStringTranslation';
import { useMemo } from 'react';
import { debug } from 'ts-frontend/utils/dev-console';
import { HomePageState } from '../../HomePage';

export const useBulletOneText = ({
  b2bForkResult,
  isB2BOutOfNetwork,
  isCouldNotBeVerified,
}: {
  b2bForkResult: string | undefined;
  isB2BOutOfNetwork: HomePageState['isB2BOutOfNetwork'];
  isCouldNotBeVerified: HomePageState['isCouldNotBeVerified'];
}) => {
  const textInNetwork = useStringTranslation(
    'Provide a few more details to see your plan',
    'quickmatch.common',
    'matchesStep.remainingStepAddDetailsText'
  );
  const textOONorOOP = useStringTranslation(
    'Choose a plan that works for you',
    'quickmatch.common',
    'matchesStep.remainingStepChoosePlanText'
  );
  const textMissingInfo = useStringTranslation(
    'Select your insurance or employer or choose to self-pay',
    'quickmatch.common',
    'matchesStep.remainingStepMissingInfoText'
  );

  return useMemo(() => {
    debug('Generating bullet one text', {
      b2bForkResult,
      isB2BOutOfNetwork,
      isCouldNotBeVerified,
    });
    if (b2bForkResult === 'b2b') {
      return isB2BOutOfNetwork || isCouldNotBeVerified ? textOONorOOP : textInNetwork;
    }
    if (b2bForkResult === 'b2c') {
      return textOONorOOP;
    }
    // Should never be the case
    return textMissingInfo;
  }, [
    b2bForkResult,
    isB2BOutOfNetwork,
    isCouldNotBeVerified,
    textInNetwork,
    textMissingInfo,
    textOONorOOP,
  ]);
};
