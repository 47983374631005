import { FunctionComponent, useEffect, useState } from 'react';
import { View, Spinner } from '@talkspace/react-toolkit';
import ReactFrameService from '../../utils/reactFrame/ReactFrameService';
import apiWrapper from '../../core/api/apiWrapper';
import { alias } from '../../utils/analytics/events';

const ReactFrameServiceWrapper: FunctionComponent = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    const reactFrameService = ReactFrameService.instance();
    const timeout = setTimeout(() => {
      if (ReactFrameService.isMobile()) {
        reactFrameService.closePopup();
      }
    }, 12 * 1000);
    if (reactFrameService.isInFrame()) {
      reactFrameService.addTokenListeners((token, userID) => {
        if (token) {
          apiWrapper.setToken(token, userID);
          alias(undefined, reactFrameService.currentUserID);
          setIsReady(true);
          clearTimeout(timeout);
        }
      });
    } else {
      setIsReady(true);
      clearTimeout(timeout);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (isReady) return <>{children}</>;
  return (
    <View style={{ height: '100vh' }}>
      <View flex={1}>
        <Spinner isLoading />
      </View>
    </View>
  );
};

export default ReactFrameServiceWrapper;
