import { useFlags } from 'launchDarkly/FlagsProvider';
import { FlowConfig } from '../Flows/types';

import { AccountType, B2BForkResult } from '../Components/HomePage/types';

/*
https://talktala.atlassian.net/browse/MEMBER-1676

OMMITED US Territories
Keeping this here incase we ever want to add them in the future

'AS', // AMERICAN SAMOA	
'GU', // GUAM	
'MP', // NORTHERN MARIANA IS	
'PR', // PUERTO RICO
'VI', // VIRGIN ISLANDS	
*/
const ELIGIBLE_STATES = [
  'AL', // ALABAMA
  'AK', // ALASKA
  'AR', // ARKANSAS
  'AZ', // ARIZONA
  'CA', // CALIFORNIA
  'CO', // COLORADO
  'CT', // CONNECTICUT
  'DE', // DELAWARE
  'DC', // DISTRICT OF COLUMBIA
  'FL', // FLORIDA
  'GA', // GEORGIA
  'HI', // HAWAII
  'ID', // IDAHO
  'IL', // ILLINOIS
  'IN', // INDIANA
  'IA', // IOWA
  'KS', // KANSAS
  'KY', // KENTUCKY
  'LA', // LOUISIANA
  'ME', // MAINE
  'MD', // MARYLAND
  'MA', // MASSACHUSETTS
  'MI', // MICHIGAN
  'MN', // MINNESOTA
  'MS', // MISSISSIPPI
  'MO', // MISSOURI
  'MT', // MONTANA
  'NE', // NEBRASKA
  'NV', // NEVADA
  'NH', // NEW HAMPSHIRE
  'NJ', // NEW JERSEY
  'NM', // NEW MEXICO
  'NY', // NEW YORK
  'NC', // NORTH CAROLINA
  'ND', // NORTH DAKOTA
  'OH', // OHIO
  'OK', // OKLAHOMA
  'OR', // OREGON
  'PA', // PENNSYLVANIA
  'RI', // RHODE ISLAND
  'SC', // SOUTH CAROLINA
  'SD', // SOUTH DAKOTA
  'TN', // TENNESSEE
  'TX', // TEXAS
  'UT', // UTAH
  'VT', // VERMONT
  'VA', // VIRGINIA
  'WA', // WASHINGTON
  'WV', // WEST VIRGINIA
  'WI', // WISCONSIN
  'WY', // WYOMING
];

const useRemoveMessagingNudge = (
  state: string | undefined,
  b2bForkResult: B2BForkResult | undefined,
  accountType: AccountType | null,
  flowConfig: FlowConfig | undefined = undefined
): boolean => {
  const isBH =
    (b2bForkResult === 'b2b' && accountType === AccountType.BH) ||
    (flowConfig?.isB2B && accountType === AccountType.BH);

  const { removeMessagingNudge } = useFlags<{ removeMessagingNudge: boolean }>();

  // Feature flag only affects BH users in certain states. NOTE: can't be filtered in LD for quickmatch
  const inEligibleStates = ELIGIBLE_STATES.includes(state || '');

  return isBH && inEligibleStates ? removeMessagingNudge : false;
};

export default useRemoveMessagingNudge;
