import { useQuery } from 'react-query';
import { getLocalSchoolList } from '../Helpers/apiService';
import { publicSchoolListQueryKey } from './queryKeys';

const useQueryPublicSchoolList = (enabled: boolean) =>
  useQuery({
    queryKey: publicSchoolListQueryKey(),
    queryFn: getLocalSchoolList,
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: true,
    enabled,
  });

export default useQueryPublicSchoolList;
