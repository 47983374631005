import { FC } from 'react';
import { useWindowWidthState, View } from '@talkspace/react-toolkit';
import styled from '@/core/styled';

interface StickyStepFooterProps {
  stickHeader?: boolean;
  showFooterBar?: boolean;
}

const HorizontalLine = styled(View)(({ theme: { colors } }) => {
  return {
    width: '100vh',
    height: 1,
    borderTop: `1px solid ${colors.permaMischkaNew}`,
  };
});

const FooterContainer = styled(View)<StickyStepFooterProps>(
  ({ theme: { window, colors }, stickHeader }) => {
    const { isMobile } = window;
    return {
      position: 'sticky',
      bottom: isMobile ? 0 : -22,
      marginLeft: isMobile ? 0 : -22,
      marginRight: isMobile ? 0 : -22,
      backgroundColor: colors.white,
      boxShadow: stickHeader ? '0 1px 0 0 rgba(0,0,0,0.06)' : 'none',
      zIndex: 3,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: 430,
      paddingBottom: isMobile ? 16 : 0,
    };
  }
);

const Footer: FC<StickyStepFooterProps> = ({ children, showFooterBar, stickHeader }) => {
  const { isMobile, isSmall } = useWindowWidthState();

  return (
    <FooterContainer stickHeader={stickHeader}>
      {isSmall && showFooterBar && <HorizontalLine />}
      <View style={{ width: isMobile ? 335 : 430 }}>{children}</View>
    </FooterContainer>
  );
};

const StepContainer: FC<{ footer?: JSX.Element }> = ({ children, footer }) => {
  const { isMobile } = useWindowWidthState();
  return (
    <View
      align="center"
      flex={isMobile ? 1 : undefined}
      style={{
        paddingTop: 32,
        minWidth: isMobile ? 335 : 375,
        maxWidth: 440,
        alignSelf: 'center',
        padding: isMobile ? '0px 20px' : undefined,
      }}
    >
      {children}
    </View>
  );
};

const StickyStepFooter = {
  StepContainer,
  Footer,
};

export default StickyStepFooter;
