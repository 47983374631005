import { QMFlow, EligibilityType } from './types';
import { initFlowConfig } from './utils';
import { FLOW_90_SELECT_SERVICE } from './Flow90';

/** Beacon EAP */
export const FLOW_98_B2B_EAP_BEACON = 98;

const flow: QMFlow = {
  flowId: FLOW_98_B2B_EAP_BEACON,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    isEAP: true,
    specificCountryCodesToSupport: ['US', 'CA'],
    isTeen: true,
    eligibilityType: EligibilityType.organizationWithAddress,
    collectEmployeeInfo: true,
  },
  steps: [
    {
      id: 1,
      analyticsId: 56,
      category: 'oneFormEligibility',
      response_category_id: 9,
      optionalOrganization: false,
      skipRedirectToFlow: FLOW_90_SELECT_SERVICE,
    },
  ],
};

export default flow;
