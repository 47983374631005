import { GoogleMapsProvider } from '@talkspace/react-toolkit';

const withGoogleMaps = (apiKey: string) => (Component) => (props) =>
  (
    <GoogleMapsProvider apiKey={apiKey}>
      <Component {...props} />
    </GoogleMapsProvider>
  );

export default withGoogleMaps;
