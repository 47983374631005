/* eslint-disable import/prefer-default-export */
import { EligibilityType } from '@/Flows/types';
import { useMoveCoverageEnabled } from '../../../hooks';

export const useCollectFullName = ({
  eligibilityType,
  isB2BOutOfNetwork,
}: {
  eligibilityType: EligibilityType | undefined;
  isB2BOutOfNetwork: boolean | undefined;
}) => {
  const isMoveCoverageEnabled = useMoveCoverageEnabled();
  if (isMoveCoverageEnabled && eligibilityType === EligibilityType.trizetto && !isB2BOutOfNetwork) {
    return false;
  }
  return true;
};
