import { useEffect, VoidFunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Spinner,
  ErrorScreen,
  View,
  Button,
  Big,
  useEmotionTheme,
  spacing,
} from '@talkspace/react-toolkit';
import { WebPageError } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import appConfigs from '@/utils/configs';
import styled from '@/core/styled';
import switchFlowHelper from '../../../Helpers/switchFlowHelper';
import { FLOW_90_SELECT_SERVICE } from '../../../Flows';
import { useRecoveredSessionActions } from '../../../hooks/recoveredSessionContext';

export interface Props {
  email: string;
  isLoading: boolean;
  errorMessage: string | undefined;
  handleSubmitSkipPayment: (userEmail: string) => void;
}

const StyledView = styled(View)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  };
});

const TryAgainButton = styled(Button)(() => {
  const { space500 } = spacing;

  return {
    alignSelf: 'center',
    width: '100%',
    fontWeight: 'bold',
    marginTop: space500,
  };
});

const LogInButton = styled(Button)(({ theme: { colors } }) => {
  return {
    color: colors.green,
    backgroundColor: 'transparent',
    border: 'none',
    width: 'auto',
    alignSelf: 'center',
    transition: 'all 200ms ease-in-out',
    '&:hover': {
      color: colors.permaTropicalRainForest,
    },
  };
});

const SkipPaymentDetails: VoidFunctionComponent<Props> = ({
  email,
  isLoading,
  errorMessage,
  handleSubmitSkipPayment,
}) => {
  const { colors } = useEmotionTheme();
  const { updateAndSave } = useRecoveredSessionActions();
  const history = useHistory();

  useEffect(() => {
    if (!isLoading && !errorMessage) {
      handleSubmitSkipPayment(email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRetryButtonPress = () => {
    switchFlowHelper(FLOW_90_SELECT_SERVICE, history, updateAndSave);
  };

  const handleLogInButtonPress = () => {
    window.location.href = `${appConfigs.endpoints.clientWebEndpoint}/login`;
  };

  if (isLoading) {
    return (
      <StyledView>
        <Spinner isLoading />
      </StyledView>
    );
  }

  return (
    <View style={{ width: '100%' }}>
      {errorMessage && (
        <ErrorScreen
          illustration={<WebPageError />}
          hasButton={false}
          hideHeader
          title="Something went wrong"
          description="Please try again, or log in if you’ve already signed up."
          safeAreaVariant="none"
        >
          <TryAgainButton
            dataQa="SkipPaymentDetailsTryAgainButton"
            text="Try again"
            roundedFocusStyle
            primaryColor={colors.green}
            onPress={handleRetryButtonPress}
          />
          <LogInButton
            dataQa="SkipPaymentDetailsLogInButton"
            primaryColor={colors.green}
            roundedFocusStyle
            onPress={handleLogInButtonPress}
          >
            <Big style={{ color: colors.green }}>Log in</Big>
          </LogInButton>
        </ErrorScreen>
      )}
    </View>
  );
};

export default SkipPaymentDetails;
