import { TimeBucket, Day } from './types';

export const parseTime = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes; // Convert time to minutes past midnight
};

export const SUGGESTED_DAYS: Day[] = [
  'Mondays',
  'Tuesdays',
  'Wednesdays',
  'Thursdays',
  'Fridays',
  'Saturdays',
  'Sundays',
];
export const SELECTIONS_REQUIRED = 1;
export const DAILY_TIME_BUCKETS: TimeBucket[] = [
  {
    name: 'Morning',
    timeRange: '7am - 12pm',
    availability: {
      startTime: '7:00',
      endTime: '12:00',
    },
  },
  {
    name: 'Afternoon',
    timeRange: '12pm - 5pm',
    availability: {
      startTime: '12:00',
      endTime: '17:00',
    },
  },
  {
    name: 'Evening',
    timeRange: '5pm - 9pm',
    availability: {
      startTime: '17:00',
      endTime: '21:00',
    },
  },
];
