import { FunctionComponent } from 'react';
import { EmotionStyle, Text } from '@talkspace/react-toolkit';
import styled from '../../core/styled';

const AlertBoxComponent = styled(Text)(({ theme: { colors } }) => {
  return {
    backgroundColor: colors.permaProvincialPink,
    color: colors.black,
    fontSize: 16,
    maxWidth: 407,
    borderRadius: 9,
    padding: 18,
    textAlign: 'left',
    marginTop: 20,
  };
});
const AlertBox: FunctionComponent<{
  isAlert?: boolean;
  id?: string;
  dataQa?: string;
  style?: EmotionStyle;
}> = ({ children, isAlert, id, dataQa, style, ...otherProps }) => (
  <AlertBoxComponent
    dataQa={dataQa}
    id={id}
    role={isAlert ? 'alert' : undefined}
    aria-live={isAlert ? undefined : 'polite'}
    style={style}
    {...otherProps}
  >
    {children}
  </AlertBoxComponent>
);

export default AlertBox;
