import { useCallback, useState, VFC } from 'react';
import {
  Big,
  Button,
  ExperimentalComponent,
  ExtraHuge,
  ExtraTiny,
  isPhoneNumberValid,
  Large,
  Link,
  RHFCheckbox,
  RHFInput,
  RHFPhoneInput,
  useEmotionTheme,
  useWindowWidthState,
  View,
} from '@talkspace/react-toolkit';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { AllLaunchDarklyExperiments } from 'ts-frontend/types';
import { upsertSession } from 'ts-frontend/utils';
import { getGDPRProps, storeGDPRProps } from 'ts-frontend/helpers/gdpr';
import GDPRConsentCheckbox from '@talkspace/react-toolkit/src/components/GDPRConsentCheckbox';
import styled from '@/core/styled';
import { LeadCaptureStep, UpdateStep } from '../../../Flows';
import { trackEvent, trackGTMEvent } from '../../../utils/analytics/events';
import { VWO } from '../../../utils/analytics/eventTracker';
import { putAnonymousUserDetails } from '../../../Helpers/apiService';
import { useRecoveredSessionActions } from '../../../hooks/recoveredSessionContext';

type LeadCaptureFormData = {
  email?: string | undefined;
  phone?: string | undefined;
  consent?: boolean | undefined;
  consentGDPRMarketing?: boolean | undefined;
};

enum LeadCaptureFieldNames {
  email = 'email',
  phone = 'phone',
  consent = 'consent',
  consentGDPRMarketing = 'consentGDPRMarketing',
}

type LeadCaptureFormBaseFields = {
  [LeadCaptureFieldNames.email]?: string;
  [LeadCaptureFieldNames.phone]?: string;
};
type LeadCaptureFormDefaultFields = LeadCaptureFormBaseFields & {
  [LeadCaptureFieldNames.consent]: boolean;
};
type LeadCaptureFormGDPRFields = LeadCaptureFormBaseFields & {
  [LeadCaptureFieldNames.consentGDPRMarketing]: boolean;
};

const baseFieldsSchema = {
  [LeadCaptureFieldNames.email]: yup.string().email('This email is invalid'),
  [LeadCaptureFieldNames.phone]: yup
    .string()
    .test(
      'is-valid-phone-number',
      'This phone number is invalid',
      (userInput: string | undefined) => (userInput ? isPhoneNumberValid(userInput) : true)
    ),
};
const leadCaptureDefaultSchema = (): yup.SchemaOf<LeadCaptureFormDefaultFields> =>
  yup.object().shape({
    ...baseFieldsSchema,
    [LeadCaptureFieldNames.consent]: yup.boolean().oneOf([true, false]).required(),
  });
const leadCaptureGDPRSchema = (): yup.SchemaOf<LeadCaptureFormGDPRFields> =>
  yup.object().shape({
    ...baseFieldsSchema,
    [LeadCaptureFieldNames.consentGDPRMarketing]: yup.boolean().oneOf([true, false]).required(),
  });

const SkipOptionButton = styled(Button)(({ theme: { colors } }) => {
  return {
    color: colors.green,
    backgroundColor: 'transparent',
    border: 'none',
    width: 'auto',
    alignSelf: 'center',
    transition: 'all 200ms ease-in-out',
    '&:hover': {
      color: colors.permaTropicalRainForest,
    },
  };
});

const StyledForm = styled.form(({ isMobile }: { isMobile: boolean }) => {
  return {
    width: isMobile ? 335 : 430,
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    margin: `${isMobile ? 0 : 30}px 10px 0px 10px`,
    alignSelf: 'center',
  };
});

const ConsentLabelDefault = () => {
  const { colors } = useEmotionTheme();
  return (
    <ExtraTiny style={{ color: colors.grey950 }}>
      I agree to receive email and text marketing (up to 5 texts/mo) and may opt out any time. Msg
      and data rates may apply. View
      <Link
        stopPropagation
        dataQa="termsOfUseLink"
        target="_blank"
        href="https://www.talkspace.com/public/terms"
        style={{ textDecoration: 'underline', fontWeight: 700 }}
        roundedFocusStyle
        primaryColor={colors.green}
      >
        Terms
      </Link>
      and
      <Link
        stopPropagation
        dataQa="privacyPolicyLink"
        target="_blank"
        href="https://www.talkspace.com/public/privacy-policy"
        style={{ textDecoration: 'underline', fontWeight: 700, marginRight: 0 }}
        roundedFocusStyle
        primaryColor={colors.green}
      >
        Privacy Policy
      </Link>
      .
    </ExtraTiny>
  );
};

const getPrompt = (variant: AllLaunchDarklyExperiments['emailLeadCaptureMove']['variant']) =>
  variant === 'moveCopy2'
    ? 'Your path toward mental wellness starts now'
    : 'Let’s start your account';

const FirstCopyVariant = () => <ExtraHuge>{getPrompt('moveCopy1')}</ExtraHuge>;
const SecondCopyVariant = () => {
  const { colors } = useEmotionTheme();
  return (
    <>
      <ExtraHuge>{getPrompt('moveCopy2')}</ExtraHuge>
      <Large style={{ marginTop: 12, color: colors.grey950 }}>
        Let’s begin your account setup, then we’ll guide you through the rest
      </Large>
    </>
  );
};

const LeadCapture: VFC<{ step: LeadCaptureStep; updateStep: UpdateStep }> = ({
  step,
  updateStep,
}) => {
  const { isMobile } = useWindowWidthState();
  const { isGDPR } = getGDPRProps();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setRecoveredField } = useRecoveredSessionActions();
  const { email: qmMemberDetailEmail } = JSON.parse(
    sessionStorage.getItem('TSQM_MemberDetails') || '{}'
  );
  const methods = useForm<LeadCaptureFormData>({
    resolver: yupResolver(isGDPR ? leadCaptureGDPRSchema() : leadCaptureDefaultSchema()),
    defaultValues: {
      email: qmMemberDetailEmail || '',
      phone: '',
      consent: false,
      consentGDPRMarketing: false,
    },
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const { colors } = useEmotionTheme();
  const { emailLeadCaptureMove } = useFlags();

  const trackAnswer = useCallback(
    async (email?: string, phone?: string, consent?: boolean, consentGDPRMarketing?: boolean) => {
      const infoCaptured = (() => {
        if (email && phone) {
          return 'email + phone number';
        }
        if (email) {
          return 'email';
        }
        if (phone) {
          return 'phone number';
        }
        return 'skipped';
      })();

      if (email) {
        upsertSession('TSQM_RegistrationInformation', {
          email,
          consent,
          consentGDPRMarketing,
          phone,
        });
        if (isGDPR) {
          storeGDPRProps({ consentGDPRMarketing });
        }
        setRecoveredField(
          'registrationInformation',
          sessionStorage.getItem('TSQM_RegistrationInformation')
        );

        upsertSession('TSQM_MemberDetails', { email });
        setRecoveredField('memberDetails', sessionStorage.getItem('TSQM_MemberDetails'));
      }

      if (phone) {
        upsertSession('TSQM_BasicInformation', { phone });
        setRecoveredField('basicInformation', sessionStorage.getItem('TSQM_BasicInformation'));
      }

      trackEvent('Enter Info on Lead Capture Screen', {
        'Funnel Name': 'QuickMatch',
        'Info Captured': infoCaptured,
        Consent: !!consent,
        ConsentGDPRMarketing: !!consentGDPRMarketing,
      });

      if (infoCaptured !== 'skipped') {
        trackGTMEvent('QM Lead Info Captured', {});
        VWO.trackQMLeadCapturedGoal();
      }
    },
    [isGDPR, setRecoveredField]
  );

  const skipButton = useCallback(() => {
    trackAnswer();
    updateStep(step.buttonTarget);
  }, [step.buttonTarget, updateStep, trackAnswer]);

  const onSubmit = async (data: LeadCaptureFormData) => {
    const isSkip = Object.values(data).every((value) => !value);
    const hasError = Object.keys(errors).length !== 0;
    if (isSkip && !hasError) {
      skipButton();
    } else if (!hasError) {
      const { email, phone, consent, consentGDPRMarketing } = data;
      trackAnswer(email, phone, consent, consentGDPRMarketing);

      setIsSubmitting(true);
      await putAnonymousUserDetails({ email, phone, consent, isGDPR, consentGDPRMarketing });

      updateStep(step.buttonTarget);
    }
  };

  return (
    <FormProvider {...methods}>
      <StyledForm isMobile={isMobile} onSubmit={handleSubmit(onSubmit)}>
        <View style={{ marginBottom: 24 }}>
          <ExperimentalComponent
            featureFlagBoolean={emailLeadCaptureMove.experimentActive}
            featureFlagVariant={emailLeadCaptureMove.variant}
            control={<FirstCopyVariant />}
            screenReplacement={<FirstCopyVariant />}
            moveCopy1={<FirstCopyVariant />}
            moveCopy2={<SecondCopyVariant />}
          />
        </View>
        <RHFInput
          fieldName={LeadCaptureFieldNames.email}
          label="Email"
          placeholder="Enter email"
          isRequired
          containerStyle={{ maxWidth: undefined }}
        />
        <RHFPhoneInput
          fieldName={LeadCaptureFieldNames.phone}
          label="Phone number"
          containerStyle={{ width: '100%' }}
          style={{ maxWidth: undefined }}
        />
        {isGDPR ? (
          <GDPRConsentCheckbox type="marketing" />
        ) : (
          <RHFCheckbox
            alignCenter={false}
            fieldName={LeadCaptureFieldNames.consent}
            label={<ConsentLabelDefault />}
            containerStyle={{ marginTop: 4, marginBottom: 12, textAlign: 'start' }}
            wrapperStyle={{ maxWidth: isMobile ? 335 : 430 }}
            checkboxStyle={{ width: 24, height: 24, marginRight: 14 }}
          />
        )}
        <Button
          dataQa="leadCaptureContinueButton"
          text="Continue"
          roundedFocusStyle
          disabled={isSubmitting}
          isLoading={isSubmitting}
          primaryColor={colors.green}
          style={{
            alignSelf: 'center',
            width: '100%',
            fontWeight: 'bold',
          }}
        />
        <SkipOptionButton
          dataQa="leadCaptureSkipButton"
          primaryColor={colors.green}
          roundedFocusStyle
          onPress={skipButton}
          disableSubmit
        >
          <Big style={{ color: colors.green }}>I'll do this later</Big>
        </SkipOptionButton>
      </StyledForm>
    </FormProvider>
  );
};

export default LeadCapture;
