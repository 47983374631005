import { FunctionComponent } from 'react';
import {
  View,
  Standard,
  useEmotionTheme,
  RedX,
  Button,
  useContainerFocus,
  Heading1,
  useUniqueID,
  styled,
} from '@talkspace/react-toolkit';

interface Props {
  description: string;
  goBack: () => void;
}

const Container = styled(View)({ '&:focus': { outline: 'none' } });

const PsychiatryUnavailable: FunctionComponent<Props> = ({ description, goBack }) => {
  const {
    colors: { green, permaWaikawaGrey },
  } = useEmotionTheme();
  const { containerRef } = useContainerFocus();
  const heading1ID = useUniqueID('heading1ID');
  return (
    <Container
      align="center"
      style={{ textAlign: 'left', width: '100%' }}
      ref={containerRef}
      tabIndex={-1}
      aria-labelledby={heading1ID}
    >
      <Heading1 id={heading1ID} style={{ marginBottom: 35, textAlign: 'center' }}>
        Psychiatry Unavailable
      </Heading1>
      <RedX style={{ marginBottom: 20 }} />
      <Standard
        style={{
          color: permaWaikawaGrey,
          marginBottom: 35,
          maxWidth: 360,
        }}
      >
        {description}
      </Standard>
      <Button
        onClick={goBack}
        roundedFocusStyle
        primaryColor={green}
        dataQa="PsychiatryUnavailableBack"
      >
        Go Back
      </Button>
    </Container>
  );
};

export default PsychiatryUnavailable;
