/* eslint-disable import/prefer-default-export */
import { EligibilityType } from '@/Flows/types';
import { useMoveCoverageEnabled } from '../../../hooks';

export const useCollectInsuranceDetails = ({
  eligibilityType,
  isB2BOutOfNetwork,
}: {
  eligibilityType: EligibilityType | undefined;
  isB2BOutOfNetwork: boolean | undefined;
}) => {
  const isMoveCoverageEnabled = useMoveCoverageEnabled();
  if (isMoveCoverageEnabled && !isB2BOutOfNetwork) {
    return false;
  }

  return (
    eligibilityType !== EligibilityType.organization &&
    eligibilityType !== EligibilityType.organizationWithAddress &&
    eligibilityType !== EligibilityType.zipCodeEligibility
  );
};
