import React, { useState, useMemo, useEffect } from 'react';
import {
  Huge,
  View,
  Large,
  Button,
  BaseButton,
  useUniqueID,
  SelectRounded,
  useContainerFocus,
  useEmotionTheme,
  useWindowWidthState,
  Label,
  Image,
} from '@talkspace/react-toolkit';
import { PayerOptionType } from 'ts-frontend/types';
import { getInsurancePayersOptions } from 'ts-frontend/helpers';
import { useSetCustomAttributes } from 'launchDarkly/hooks';
import {
  SESSION_STORAGE_MEMBER_DETAILS_KEY,
  getSessionStorageValueFromJson,
  upsertSession,
} from 'ts-frontend/utils';
import styled from '@/core/styled';
import {
  useHandleChange,
  useInsurancePayerOptions,
  useOnContinuePress,
  useOnOrganizationPress,
  usePayerWatcher,
} from './hooks';
import insuranceIconAetna from '@/Assets/insurance-aetna.png';
import insuranceIconCigna from '@/Assets/insurance-cigna.png';
import insuranceIconPremera from '@/Assets/insurance-premera.png';
import insuranceIconOptum from '@/Assets/insurance-optum.png';
import insuranceIconRegence from '@/Assets/insurance-regence.png';
import insuranceIconBeacon from '@/Assets/insurance-beacon.png';
import { getCoverageVerificationStyles } from './utils';
import { B2BForkProps } from './types';
import { useMoveCoverageEnabled } from '../../hooks';
import { useHomePageState } from '../HomePage';

const Styled = {
  Container: styled(View)({ '&:focus': { outline: 'none' } }),
  Button: styled(Button)(({ theme: { colors } }) => {
    return {
      transition: 'all 200ms ease-in-out',
      '&:not([disabled]):hover': {
        backgroundColor: colors.permaTropicalRainForest,
      },
    };
  }),
  DecoratorBar: styled(View)(({ theme: { colors } }) => {
    return {
      flex: 1,
      alignSelf: 'center',
      height: 1,
      backgroundColor: colors.periwinkleGrey,
    };
  }),
  BaseButton: styled(BaseButton)(({ theme: { colors } }) => {
    return {
      color: colors.green,
      backgroundColor: 'transparent',
      border: 'none',
      width: 'auto',
      alignSelf: 'center',
      fontWeight: 700,
      transition: 'all 200ms ease-in-out',
      '&:hover': {
        color: colors.permaTropicalRainForest,
      },
    };
  }),
};

const B2BFork = ({
  step,
  flowId,
  service,
  updateStep,
  clientCountry,
  clientState,
  insurancePayer: initialInsurancePayer,
  experimentsVariants,
}: B2BForkProps) => {
  // Do not default this to an initial value other than `null`. See `usePayerWatcher`.
  const [selectedPayer, setSelectedPayer] = useState<PayerOptionType | null>(null);
  const payerInputId = useUniqueID('payerInputId');
  const header1Id = useUniqueID('idHeader1');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { containerRef } = useContainerFocus();
  const { colors } = useEmotionTheme();
  const setCustomAttribute = useSetCustomAttributes();
  const { isSkipDirectToBH, isHideNonBhItems } = useHomePageState();
  const { allowAutoSelectInsurancePayerAndMemberIdFromSession } = step;

  let { shouldHideOutOfPocket, shouldHideOutOfNetwork } = step;

  if (isHideNonBhItems) {
    shouldHideOutOfPocket = true;
    shouldHideOutOfNetwork = true;
  }

  const { options: insurancePayerOptions, loading: isLoadingOptions } = useInsurancePayerOptions({
    service,
    shouldHideOutOfPocket,
    shouldHideOutOfNetwork,
  });

  useEffect(() => {
    if (allowAutoSelectInsurancePayerAndMemberIdFromSession || isSkipDirectToBH) {
      const insurancePayerID = getSessionStorageValueFromJson(
        SESSION_STORAGE_MEMBER_DETAILS_KEY,
        'insurancePayerID'
      );
      const option = insurancePayerOptions?.find((o) => o.id === insurancePayerID);
      if (option) {
        setSelectedPayer(option);
      }
    } else {
      // if we're not auto selecting insurance payer, we should also not use the memberId
      upsertSession('TSQM_MemberDetails', { memberId: null }, true);
    }
  }, [
    allowAutoSelectInsurancePayerAndMemberIdFromSession,
    insurancePayerOptions,
    isSkipDirectToBH,
  ]);

  const isMoveCoverageEnabled = useMoveCoverageEnabled();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showDropdownError, setShowDropdownError] = useState(false);
  const experimentalDropdownStyles = useMemo(() => getCoverageVerificationStyles(colors), [colors]);

  const shouldShowShortB2BForkForm =
    step.shouldShowShortB2BForkForm !== undefined
      ? step.shouldShowShortB2BForkForm
      : isMoveCoverageEnabled;

  const shortB2BForkForm = shouldShowShortB2BForkForm || isHideNonBhItems;

  const onContinuePress = useOnContinuePress({
    step,
    flowId,
    service,
    updateStep,
    clientCountry,
    clientState,
    insurancePayer: initialInsurancePayer,
    experimentsVariants,
    selectedPayer,
    shortB2BForkForm,
    setShowDropdownError,
    setCustomAttribute,
    setIsLoading,
  });

  const onOrganizationPress = useOnOrganizationPress({
    updateStep,
    step,
  });

  const handleChange = useHandleChange({
    setShowDropdownError,
    setSelectedPayer,
  });

  usePayerWatcher({
    selectedPayer,
    onContinuePress,
  });

  const { isMobile } = useWindowWidthState();

  return (
    <Styled.Container ref={containerRef} aria-labelledby={header1Id} align="center" tabIndex={-1}>
      {/* Control variant */}
      {!shortB2BForkForm && (
        <View style={{ marginBottom: 35 }}>
          <Huge
            as="h1"
            style={{ marginBottom: 10, fontSize: 29, lineHeight: '34px', fontWeight: 700 }}
          >
            How would you like to pay?
          </Huge>
          <Large as="h2" variant="largeDarkGrey">
            60 million Americans have Talkspace <br />
            covered by their insurance.
          </Large>
        </View>
      )}

      {/* Experiment treatment variant + show this when hiding non-bh items */}
      {shortB2BForkForm && (
        <View
          style={{
            marginTop: -8,
            marginBottom: 32,
            // animation for hiding this header upon opening the dropdown on mobile
            transition: 'margin 0.1s ease',
            ...(isMenuOpen && isMobile
              ? {
                  marginTop: -86,
                }
              : {}),
          }}
        >
          <Huge as="h1" style={{ fontSize: 29, lineHeight: '34px', fontWeight: 700 }}>
            Find your insurance
          </Huge>
        </View>
      )}

      {/* Shared between control and treatment variants */}
      <View align="stretch">
        <Label htmlFor={payerInputId}>
          {shortB2BForkForm ? 'Insurance' : 'How would you like to pay?'}
        </Label>
        <SelectRounded
          isAsync
          cacheOptions
          placeholder={shortB2BForkForm ? 'Find your insurance' : undefined}
          defaultOptions={insurancePayerOptions}
          inputId={payerInputId}
          dataQa={`${step.category || ''}Dropdown`}
          value={selectedPayer}
          onChange={handleChange}
          loadOptions={getInsurancePayersOptions(insurancePayerOptions, shortB2BForkForm)}
          isLoading={isLoadingOptions}
          // allows for the dropdown to be keyboard searchable
          isInputReadOnly={false}
          wrapperStyle={{
            marginTop: 0,
            width: shortB2BForkForm ? 335 : undefined,
          }}
          styles={shortB2BForkForm ? experimentalDropdownStyles : undefined}
          onMenuOpen={() => {
            setIsMenuOpen(true);
          }}
          onMenuClose={() => {
            setIsMenuOpen(false);
          }}
          isError={showDropdownError}
        />

        {showDropdownError && (
          <Label style={{ color: colors.permaErrorRed, marginTop: 4 }}>Make a selection</Label>
        )}

        <Styled.Button
          text="Continue"
          isLoading={isLoading || isLoadingOptions}
          disabled={shortB2BForkForm ? false : !selectedPayer || isLoading || isLoadingOptions}
          onPress={onContinuePress}
          style={{ width: '100%', marginTop: 15, fontWeight: 700 }}
          dataQa={`${step.category || ''}ContinueButton`}
          roundedFocusStyle
          primaryColor={colors.green}
        />
      </View>

      {/* Control variant */}
      {!shortB2BForkForm && (
        <View>
          <View
            row
            flex={1}
            style={{
              marginTop: 25,
              marginBottom: 25,
              alignItems: 'stretch',
              alignSelf: 'center',
              width: 320,
            }}
          >
            <Styled.DecoratorBar />
            <Large style={{ marginLeft: 18, marginRight: 18 }} variant="largeDarkGrey">
              OR
            </Large>
            <Styled.DecoratorBar />
          </View>

          <Styled.BaseButton
            onPress={onOrganizationPress}
            data-qa={`${step.category || ''}AlternativeButton`}
            roundedFocusStyle
          >
            I want to use my organization or EAP coverage
          </Styled.BaseButton>
        </View>
      )}

      {/* Experiment treatment variant + */}
      {shortB2BForkForm && (
        <View style={{ marginTop: 60 }}>
          <Label style={{ color: colors.darkBlue }}>
            We're in network with many insurers, including:
          </Label>
          <View row justify="space-between" style={{ marginTop: 24 }}>
            <Image alt="Aetna" title="Aetna" source={insuranceIconAetna} height={20} />
            <Image alt="Beacon" title="Beacon" source={insuranceIconBeacon} height={22} />
            <Image alt="Cigna" title="Cigna" source={insuranceIconCigna} height={30} />
          </View>
          <View row justify="space-between" style={{ marginTop: 16 }}>
            <Image alt="Optum" title="Optum" source={insuranceIconOptum} height={22} />
            <Image alt="Premera" title="Premera" source={insuranceIconPremera} height={24} />
            <Image alt="Regence" title="Regence" source={insuranceIconRegence} height={18} />
          </View>
        </View>
      )}
    </Styled.Container>
  );
};

export default B2BFork;
