// DO NOT IMPORT THIS FILE DIRECTLY (use eventTracker)
/* eslint-disable */
import configs from "../../configs";

const gtmConfig = configs.analytics.GTM;
const scope = {}; // Use window for now. Ideally we shouldn't add keys to window
const initGTMSDK = (readyCB) => {

  const hidden =
    "width: 1px ;height: 1px ;position: absolute;top: -2000px;left: 0px; ;pointer-events: none;";
  const f = document.createElement("iframe");
  f.src = `/gtm.html#${encodeURIComponent(JSON.stringify(gtmConfig))}`;
  f.setAttribute("style", hidden);
  f.setAttribute("tabindex", "-1");
  f.onload = () => {
    scope.dataLayer = f.contentWindow.dataLayer;

    if (gtmConfig.debugMode) {
      window.dataLayer = scope.dataLayer;

      window.gtmShow = () => {
        const show = "width: 100%;height: 500px;";

        f.setAttribute("style", show);
        const debuggerFrame = f.contentWindow.document.querySelector("iframe");
        if (debuggerFrame) debuggerFrame.style.height = "100%";
        else alert("Enable preview on GTM and reload page");
      };
    }
    readyCB();
  };
  document.body.append(f);
};

export const initGTM = (onFinishLoading) => {
  initGTMSDK(onFinishLoading);
};

export const getDataLayer = () => {
  return scope.dataLayer;
};