import { QMFlow, EligibilityType } from './types';
import { initFlowConfig } from './utils';
import { ServiceKeywords } from './Flow120';
import { FLOW_90_SELECT_SERVICE } from './Flow90';

export const FLOW_119_B2B_BH_MANUAL_TYSON = 119;

const flow: QMFlow = {
  flowId: FLOW_119_B2B_BH_MANUAL_TYSON,
  flowConfig: {
    ...initFlowConfig,
    isManualFlow: true,
    isB2B: true,
    isTeen: false,
    serviceKeywords: ServiceKeywords.manual,
    eligibilityType: EligibilityType.manual,
    serviceType: 'psychiatry',
  },
  steps: [
    {
      id: 1,
      analyticsId: 56,
      category: 'oneFormEligibility',
      response_category_id: 9,
      optionalOrganization: true,
      isGroupIdRequired: false,
      skipText: 'Continue without insurance',
      skipRedirectToFlow: FLOW_90_SELECT_SERVICE,
    },
  ],
};

export default flow;
