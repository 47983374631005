import { QMFlow, EligibilityType } from './types';
import { initFlowConfig } from './utils';

//* * Generic EAP - Employee Assistance Program */
export const FLOW_9_B2B_EAP_GENERIC = 9;

const flow: QMFlow = {
  flowId: FLOW_9_B2B_EAP_GENERIC,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    isEAP: true,
    eligibilityType: EligibilityType.organization,
  },
  steps: [
    {
      id: 1,
      analyticsId: 54,
      category: 'oneFormEligibility',
      progressCurrent: 2,
      response_category_id: 9,
      progressMax: 10,
      optionalOrganization: false,
    },
  ],
};

export default flow;
