import { useState } from 'react';
import {
  View,
  Huge,
  TextInputAuth,
  Button,
  BaseButton,
  Standard,
  Link,
  Label,
  useUniqueID,
  useErrorFocus,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { useHistory } from 'react-router-dom';
import useWindowWidth from '@/hooks/useWindowWidth';
import { HomePageState } from '@/Components/HomePage';
import { validateKeywordMetadata } from '@/Helpers/apiService';
import switchFlowHelper from '@/Helpers/switchFlowHelper';
import { LocationEligibilityManualAccessCodeStep } from '@/Flows';
import { trackEvent } from '@/utils/analytics/events';

interface LocationEligibilityManualAccessCodeProps {
  step: LocationEligibilityManualAccessCodeStep;
  flowId: number;
  updateAndSave: (state: Partial<RecoveredSessionState>) => void;
  responses: HomePageState['responses'];
}

const LocationEligibilityManualAccessCode = ({
  step,
  flowId,
  updateAndSave,
  responses,
}: LocationEligibilityManualAccessCodeProps) => {
  const history = useHistory();
  const { isSmall } = useWindowWidth();
  const { colors } = useEmotionTheme();

  const emailFromPreviousStep =
    // eslint-disable-next-line camelcase
    responses.find((response) => response.stepId === 1)?.response_value || '';

  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const [inputValue, setInputValue] = useState('');
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setValidationError(null);
  };

  const { formContainerRef, setShouldFocusFirstInvalidField } = useErrorFocus();

  const handleSubmit = async () => {
    setIsValidating(true);
    setValidationError(null);
    // Submit access code
    const params = {
      email: emailFromPreviousStep,
      keyword: inputValue,
      flowId,
    };
    try {
      const data = await validateKeywordMetadata(params);
      const { accessCode, accessCodeType, qmFlowID } = data;
      const newFlowSearch = `?${accessCodeType}=${accessCode}&redirectFrom=${flowId}`;
      switchFlowHelper(qmFlowID, history, updateAndSave, {
        stepId: 1, // target flow step id
        newFlowSearch,
      });
    } catch (e) {
      setIsValidating(false);
      setValidationError(e);
      setShouldFocusFirstInvalidField(true);
    }
    trackEvent('Enter Access Code', {
      'Code Entered': true,
      'Funnel Name': 'QuickMatch',
    });
  };

  const accessCodeEmpty = inputValue.length === 0;

  const onSubmitEditing = () => {
    if (accessCodeEmpty) return;
    handleSubmit();
  };

  const headerID = useUniqueID('headerID');
  const inputID = useUniqueID('inputID');

  return (
    <View style={{ display: 'flex' }}>
      <View
        align="center"
        style={{ minWidth: isSmall ? undefined : 375, maxWidth: 440, alignSelf: 'center' }}
        ref={formContainerRef}
      >
        <Huge as="h1" id={headerID} style={{ marginTop: 0, marginBottom: 40 }}>
          Enter the access code or keyword provided to gain access to Talkspace at no charge through
          your regional program
        </Huge>
        <View style={{ width: 340 }}>
          <Label htmlFor={inputID}>Manual access code or keyword input</Label>
        </View>
        <TextInputAuth
          id={inputID}
          style={{ margin: 20 }}
          autoFocus
          inputStyle={{ width: 320 }}
          placeholder="Enter code or keyword"
          onChange={handleInputChange}
          value={inputValue}
          onSubmitEditing={onSubmitEditing}
          errorMessage="We don't recognize this code"
          shouldDisplayError={!!validationError}
          errorStyle={{ textAlign: 'left' }}
          ariaDescribedBy={headerID}
          roundedFocusStyle
          primaryColor={colors.periwinkleGrey}
        />

        <Button
          style={{ margin: 20, width: 320 }}
          onPress={handleSubmit}
          isLoading={isValidating}
          roundedFocusStyle
          primaryColor={colors.green}
        >
          Continue
        </Button>

        <BaseButton roundedFocusStyle primaryColor={colors.osloGrey} style={{ borderRadius: 5 }}>
          <Standard variant="standardDarkGrey" style={{ marginBottom: 5 }}>
            <Link
              style={{ color: colors.osloGrey }}
              href={String(step.externalTarget)}
              onClick={(e) => {
                trackEvent('Enter Access Code', {
                  'Code Entered': false,
                  'Funnel Name': 'QuickMatch',
                });
              }}
              tabIndex={-1}
            >
              I don't have a code or keyword
            </Link>
          </Standard>
        </BaseButton>
      </View>
    </View>
  );
};

export default LocationEligibilityManualAccessCode;
