import { FunctionComponent } from 'react';
import { View, Tiny, Standard } from '@talkspace/react-toolkit';
import MagnifyingGlass from './MagnifyingGlass';
import { EmotionStyle } from '../../core/styled/styled';

export interface MagnifyingGlassTextBoxProps {
  text: string;
  color?: string;
  width?: number;
  iconColor?: string;
  headerColor?: string;
  headerText: string;
  style?: EmotionStyle;
}

const MagnifyingGlassTextBox: FunctionComponent<MagnifyingGlassTextBoxProps> = ({
  text,
  iconColor,
  headerText,
  headerColor,
  width = 320,
  style,
}) => (
  <View row style={{ width, textAlign: 'left', ...style }}>
    <View
      justify="center"
      align="center"
      style={{
        width: 70,
        height: 70,
        boxShadow: '0px 3px 10px rgba(16, 109, 149, 0.3)',
        borderRadius: 20,
      }}
    >
      <MagnifyingGlass color={iconColor} />
    </View>
    <View style={{ width: 'calc(100% - 82px)', marginLeft: 12 }}>
      <Standard variant="standardBoldBrightGreen" style={{ marginBottom: 5, color: headerColor }}>
        {headerText}
      </Standard>
      <Tiny>{text}</Tiny>
    </View>
  </View>
);

export default MagnifyingGlassTextBox;
