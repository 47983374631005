import { QMFlow } from './types';
import { initFlowConfig } from './utils';

/** Catch All (previously known as Dispatcher) */
export const FLOW_90_SELECT_SERVICE = 90;

const flow: QMFlow = {
  flowId: FLOW_90_SELECT_SERVICE,
  flowConfig: {
    ...initFlowConfig,
    isB2B: false,
    specialFlowName: 'Dispatcher',
  },
  steps: [
    {
      id: 1,
      category: 'serviceSelection',
      buttonTarget: 2,
      progressCurrent: 0,
      progressMax: 10,
    },
    {
      id: 2,
      category: 'teenBranching',
      isInterstitial: true,
    },
  ],
};

export default flow;
