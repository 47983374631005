/* eslint-disable camelcase */
import type { StepTipName, StepTipConfig } from '@talkspace/react-toolkit';
import {
  TranslationNamespaceQuickmatchFlow,
  TFKeysPrefixedQuickmatchCommon,
  TFKeysPrefixedQuickmatchFlow138,
  TFKeysQuickmatchFlow,
} from '@talkspace/i18n/types';
import { SessionModality, ServiceType, LDFlags } from 'ts-frontend/types';
import type {
  InsuranceEligibility,
  InsuranceEligibilityPayer,
} from 'ts-frontend/types/insuranceEligibility';
import type {
  AnswerParam,
  HomePageState,
  DynamicValueHomePageState,
  DynamicValueTranslators,
  Appointment,
  ResponseSelfServe,
  B2BForkResult,
  ChosenPlan,
  ChosenTherapist,
  AccountType,
  QuickEligibilityInfo,
  MapSelfServeFieldsState,
  MemberAvailability,
} from '../Components/HomePage/types';
import { LandingPageCTAClick } from '../utils/analytics/trackerTypes';
import {
  DataDependencyHandlerOptions,
  DataDependencyKeys,
} from '../Helpers/dataDependencyHandlers';

type ServiceKeywords = Partial<Record<ServiceType, string>>;

export type FlowServiceKeywords = {
  trizetto: ServiceKeywords;
  manual: ServiceKeywords;
};

export interface SecondaryInsuranceConfig {
  enabled: boolean;
  separatePage: boolean;
}

interface BaseFlowConfig {
  /** Flow version number, bump it if breaking changes with the recovered session */
  version: number;
  disabled?: boolean;
  isB2B: boolean;
  isEAP?: boolean;
  isBH?: boolean;
  isTeen?: boolean;
  funnelVariation?: string;
  isTrial?: boolean;
  specificCountryCodesToSupport?: string[]; // undefined/empty means it's not specific
  /** B2B: get keywords according to service types */
  serviceKeywords?: ServiceKeywords;
  serviceKeywordsManual?: ServiceKeywords;
  /** B2C: Service type */
  serviceType?: ServiceType;
  payer?: string;
  removeNavBar?: boolean;
  /** surveyID for assessment SurveyWizard */
  surveyID?: number;
  /** Manual flow, in contrast to automatic Trizetto flow */
  isManualFlow?: boolean;
  shouldHideServiceSelection?: boolean;
  partnerID?: number;
  isEligibilityFileFlow?: boolean;
  // this essentially replaces the need for isManualFlow or isTrizettoFlow
  eligibilityType?: EligibilityType;
  isDispatcherInQMFlow?: boolean;
  hasNumberOfSessions?: boolean;
  /** Optional Flow name sent to analytics */
  specialFlowName?: LandingPageCTAClick['Flow'];
  // when employeeRelation field is set to spouse or dependent, it will display the employee info to fill up
  collectEmployeeInfo?: boolean;
  /** Flow config for useMoveCoverageEnabled UI changes */
  moveCoverageEnabled?: boolean;
  /** Flow config for usePreRegisterBookingEnabled UI changes */
  preRegisterBookingEnabled?: boolean;
  /** Resource name for flow internationalization */
  i18nNamespace?: TranslationNamespaceQuickmatchFlow;
  /** Flow config for using secondary insurance, starting with medicare 03/24 */
  secondaryInsurance?: SecondaryInsuranceConfig;
}

interface GeneralFlowConfig extends BaseFlowConfig {
  isTrizetto?: never;
  isManualFlow?: never;
}

// flows 9, 11, 41, and 62 as of 10/2021
interface OrganizationFlowConfig extends BaseFlowConfig {
  eligibilityType?: EligibilityType.organization;
}

// any flow that formerly started with BasicInformationStep should collect address
// flows 19, 44, and 78 as of 10/2021
interface OrganizationWithAddressFlowConfig extends BaseFlowConfig {
  eligibilityType?: EligibilityType.organizationWithAddress;
}

// flows 28, 32, 59, 70, and 84 as of 10/2021
interface B2BTrizettoFlowConfig extends BaseFlowConfig {
  isTrizetto: true;
  /** serviceKeywordsManual should exist for Trizetto flow in case of fallback to manual */
  serviceKeywordsManual: ServiceKeywords;
  maximumCost?: Partial<Record<ServiceType, number>>;
  eligibilityType?: EligibilityType.trizetto;
}

// flows 24, 25, 43, 60, 71, and 85 as of 10/2021
interface B2BManualFlowConfig extends BaseFlowConfig {
  isManualFlow: true;
  /** serviceKeywordsManual should exist for manual flows */
  serviceKeywords: ServiceKeywords;
  eligibilityType?: EligibilityType.manual;
}

// flows 33, 76, 82, and 83 as of 10/2021
interface FileBasedFlowConfig extends BaseFlowConfig {
  eligibilityType?: EligibilityType.fileBased;
}

// flows 128
interface ZipCodeBasedFlowConfig extends BaseFlowConfig {
  eligibilityType?: EligibilityType.zipCodeEligibility;
}

export enum OneFormConfigSetType {
  nycTeen = 1,
}

export type OneFormConfigSet = Partial<OneFormEligibilityStep>;

export enum EligibilityType {
  organization = 1,
  organizationWithAddress = 2,
  manual = 3,
  fileBased = 4,
  trizetto = 5,
  zipCodeEligibility = 6,
}

export type FlowConfig =
  | GeneralFlowConfig
  | B2BTrizettoFlowConfig
  | B2BManualFlowConfig
  | OrganizationWithAddressFlowConfig
  | OrganizationFlowConfig
  | FileBasedFlowConfig
  | ZipCodeBasedFlowConfig;

export type RemoteFlowConfig = FlowConfig | null;

export interface UpdateStepObj {
  flowId?: number;
  discard_answer?: boolean;
  appointment?: Appointment;
  go_to_matches?: boolean;
  payfirst_step_id?: number;
  payfirst_step_prompt?: string;
  response_category_id?: number;
  response_prompt?: string;
  response_value?: string;
  stepId?: number;
  params?: AnswerParam;
  livesInTheUS?: boolean;
  response_self_serve?: ResponseSelfServe;
  isEligible?: boolean;
  eligibilityPromo?: number;
  insuranceEligibility?: InsuranceEligibility;
  b2bForkResult?: B2BForkResult;
  voucher?: string;
  copayCents?: string;
  invalidVoucher?: boolean;
  insuranceCode?: string;
  trizettoRequestId?: string;
  service?: ServiceType;
  trizettoErrorStatusCode?: number;
  isCouldNotBeVerified?: boolean;
  isNoMatches?: boolean;
  accessCode?: string;
  accessCodeType?: AccessCodeType;
  isVideoOnlyPlan?: boolean;
  organizationFlowID?: number;
  insurancePayer?: InsuranceEligibilityPayer;
  partnerFlowID?: number;
  isB2BOutOfNetwork?: boolean;
  collectReferralSourceOnSignUp?: boolean;
  allowedModalities?: Array<AllowedModality>;
  sessionModality?: SessionModality;
  totalSessions?: number;
  accountType?: AccountType | null;
  manualFlowID?: number;
  insuranceConfirmation?: InsuranceConfirmation;
  quickStatusAction?: QuickStatusAction;
  quickEligibilityInfo?: QuickEligibilityInfo;
  oneFormClientState?: string;
  oneFormClientAge?: string;
  memberAvailability?: MemberAvailability;
  memberAvailabilityVariant?: 'control' | 'treatment' | 'treatment-with-skip';
  skipCreditCard?: boolean;
}

export type QuickStatusAction =
  | 'continueWithInsurance'
  | 'continueWithoutInsurance'
  | 'resubmit'
  | 'orgEap'
  | 'fileUpload';

export type InsuranceConfirmation = 'check' | 'direct' | 'orgEap';

export type NextQuestionAction = (
  state: HomePageState,
  answer?: any,
  flags?: LDFlags,
  extraParams?: {
    urlFlowID: number | null;
  }
) => (number | undefined) | Promise<number | undefined>;

export type NextQuestionActionForTarget = { [key: string]: Array<NextQuestionAction> };

export type UpdateStep = (
  target: InternalTarget | ExternalTarget,
  answerValue?: UpdateStepObj,
  chosenTherapist?: ChosenTherapist,
  chosenPlan?: ChosenPlan,
  config?: {
    flowId?: number;
    stepId?: number;
    updateStateOnly?: boolean;
    removeStepFromHistory?: boolean;
  }
) => Promise<void>;

export type SelectButton = {
  text: string;
  translationKey?: TFKeysPrefixedQuickmatchCommon | TFKeysPrefixedQuickmatchFlow138;
  internalTarget: InternalTarget;
  externalTarget?: ExternalTarget;
  answerValue: string | number | null | undefined;
  hideInitially?: boolean;
};

type DynamicValueString =
  | string
  | ((
      state?: DynamicValueHomePageState,
      i18nTranslators?: DynamicValueTranslators
    ) => string | Promise<string>);
type DynamicValueButtonArray =
  | Array<SelectButton>
  | ((
      state?: DynamicValueHomePageState,
      i18nTranslators?: DynamicValueTranslators
    ) => Array<SelectButton> | Promise<Array<SelectButton>>);

export interface SelfServeField {
  field: string;
  type?: typeof Array;
  answerValue?: string;
}

type DynamicSelfServeField =
  | SelfServeField
  | ((
      state?: DynamicValueHomePageState | MapSelfServeFieldsState,
      i18nTranslators?: DynamicValueTranslators
    ) => SelfServeField);

export interface BaseStep {
  /** Step ID, must be unique */
  id: number;

  /** Step category */
  category: string;

  nextQuestionActions?: Array<NextQuestionAction> | NextQuestionActionForTarget;
  skipQuestionActions?: Array<NextQuestionAction>;

  /** Option to hide button back to previous step */
  hideBackButton?: boolean;

  /** Step-In-Flow Progress Related */
  progressCurrent?: number;
  progressMax?: number;
  response_category_id?: number;

  /** Headings */
  heading1?: string | null; // always display
  heading2?: string; // display when !hideComponent
  heading2TranslationKey?: TFKeysPrefixedQuickmatchCommon | TFKeysQuickmatchFlow;
  heading3?: string; // display when voucher && !invalidVoucher && !trizettoErrorStatusCode
  heading3TranslationKey?: TFKeysPrefixedQuickmatchCommon | TFKeysQuickmatchFlow;

  /** CTA text and Step id target */
  buttonText?: string;
  buttonTarget?: InternalTarget;

  /** V2 Heading prompt */
  prompt?: string;

  /** Analytics Related */
  analyticsId?: number;

  /** Option to skip current step */
  skipText?: string;
  skipInternalTarget?: number;
  skipExternalTarget?: number | null;
  skipRedirectToFlow?: number;

  selfServe?: SelfServeField;

  tip?: StepTipName;
  tipImage?: StepTipConfig['image'];
  tipTitle?: StepTipConfig['title'];
  tipContent?: StepTipConfig['content'];

  goToMatches?: boolean;
  dataDependencies?: Array<{ key: DataDependencyKeys; options?: DataDependencyHandlerOptions }>;
}

interface InterstitialStepBase extends BaseStep {
  isInterstitial: true;
}

export type InternalTarget = string | number | null | undefined;
export type ExternalTarget = number | string | null;

/**
 * A step with category `switch` does not actually render anything. It's sole purpose is to provide you
 * with an opportunity to run `skipQuestionActions` logic - allowing you to conditionally redirect the
 * user to one of multiple possible other steps.
 */
export interface SwitchStep extends BaseStep {
  category: 'switch';
}

export interface InsuranceConfirmationStep extends BaseStep {
  category: 'insuranceConfirmation';
  internalTarget: InternalTarget;
  flowId: number;
}

export interface MultiSelectStep extends BaseStep {
  category: 'multiselect';

  /** Multiselect, appears as a dropdown */
  multiselect?: Array<{
    label: string;
    value: string | number | null;
    addQuestionToQueue?: number;
  }>;

  internalTarget: InternalTarget;
  externalTarget: ExternalTarget;

  bubbles?: string[];
  submitText?: string;

  multiSelectLabelText?: string;
}

export interface IntroductionStep extends BaseStep {
  category: 'introduction1';
}

export interface MatchesStep extends BaseStep {
  category: 'matches1';

  matches?: any;
  zeroMatchTarget?: InternalTarget;
  showCustomerRegistrationModalWithoutPayment?: boolean;

  /** Modal to collect email before displaying match results */
  matchOverlay?: boolean;
  matchOverlayCampaignType?: string;
  canMatchOverlayBeSkipped?: boolean;

  // these are all referenced in MatchTab. Can't find any other reference, might be legacy code
  therapistAvailability?: any;
  traditionalTherapyWaitTime?: any;
  acceptingClients?: any;
}

export interface OneFormEligibilityStep extends BaseStep {
  category: 'oneFormEligibility';
  isGroupIdRequired?: boolean;
  optionalOrganization?: boolean;
  optionalVoucher?: boolean;
  removePhoneNumber?: boolean;
  removeReferralSource?: boolean;
  removeEmployeeRelation?: boolean;
  removeEmail?: boolean;
  removeOrganization?: boolean;
  removeAddress?: boolean;
  authCodeLabel?: string;
  authCodeTooltip?: string;
  authCodeMaxLength?: number;
  hasAuthCodeExpiration?: boolean;
  authCodeExpirationMaxDays?: number;
  authCodeExpirationTooltip?: string;
  hasEmployeeId?: boolean;
  hasNumberOfSessions?: boolean;
  numberOfSessionsTooltip?: string;
  hasSocialSecurity?: boolean;
  // NOTE: if a flow allows for skipping insurance details it must have redirectToFlow attribute
  skipText?: string;
  heading1?: never;
  heading2?: never;
  allowTeens?: boolean;
  ineligiblePromoCouponCode?: string;
  ineligiblePromo?: number;
  ineligiblePromoWeeks?: number;
  disableOrganization?: boolean;
  noCoverageButtonTarget?: number;
  noCoverageHideEAP?: boolean;
  noCoverageHideOutOfPocket?: boolean;
  onlyFirstName?: boolean;
  oneFormConfigSet?: OneFormConfigSetType;
  address1Label?: string;
  address1Hint?: string;
  address1ErrorMessage?: string;
  optionalTruthCheckbox?: boolean;
  address2Label?: string;
  address2Placeholder?: string;
  emailHint?: string;
  showMarketingConsent?: boolean;
}

export interface ValidateVoucherStep extends BaseStep {
  category: 'validateVoucher';

  behavioralHealth: boolean;
  supportEmail?: string;
  linkToFAQ?: string;
}

export interface ExplanationStep extends BaseStep {
  category: 'explanation';

  /** Category: explanation */
  explanationGroups: Array<{
    text: string;
    tooltipText: string;
  }>;
}
export interface SelectStep extends BaseStep {
  category: 'select1';

  buttons: Array<SelectButton>;
  content?: string[];

  /** Tooltip for V2 Layout */
  toolTip?: string;
  toolTipLink?: string;
}

// Uses same components as select1
export interface SelectMultiStep extends BaseStep {
  category: 'select1';

  buttons: Array<Omit<SelectButton, 'internalTarget'>>;
  content?: string[];
  // Button target is required
  buttonTarget: InternalTarget;

  /** Tooltip for V2 Layout */
  toolTip?: string;
  toolTipLink?: string;
  isMulti: true;
}

export interface SelectDynamicStep
  extends Omit<
    SelectStep,
    'category' | 'buttons' | 'heading1' | 'heading2' | 'heading3' | 'selfServe'
  > {
  category: 'selectDynamic';

  buttons?: DynamicValueButtonArray;
  content?: string[];
  selfServe?: DynamicSelfServeField;

  /** Tooltip for V2 Layout */
  toolTip?: string;
  toolTipLink?: string;

  heading1?: string | null | DynamicValueString; // always display
  heading2?: string | DynamicValueString; // display when !hideComponent
  heading3?: string | DynamicValueString; // display when voucher && !invalidVoucher && !trizettoErrorStatusCode
}

export interface SelectDynamicStepResolved extends Omit<SelectStep, 'category' | 'buttons'> {
  category: 'selectDynamic';
  buttons?: Array<SelectButton>;
}

export interface DateOfBirthStep extends BaseStep {
  category: 'dateOfBirth';

  targets?: {
    under13: InternalTarget | ExternalTarget;
    under18GDPR: InternalTarget | ExternalTarget;
    teen: InternalTarget | ExternalTarget;
    adult: InternalTarget | ExternalTarget;
  };
  content?: string[];
  bubbles?: string[];
  doNotAllowSearch?: boolean;
}

export interface NotAvailableInYourRegionStep extends BaseStep {
  category: 'notAvailableInYourRegion';
}

export interface DropdownOption {
  value: string | number | null;
  label: string;
}

export interface DropdownStep extends BaseStep {
  category: 'dropdown';
  response_category_id: 2 | 6 | 7;

  doNotAllowSearch?: boolean;
  selectPlaceholder: string;
  selectOptions: Array<DropdownOption>;
  payfirst_step_id?: number;
  bubbles?: string[];
  submitText?: string;
  externalTarget?: ExternalTarget;
  internalTarget?: InternalTarget;
  extraCTA?: string;

  toolTip?: string;

  selectLabelText?: string;
}

export interface LoadingStep extends BaseStep {
  category: 'loading1';

  buttonTarget: InternalTarget;
}

export interface InsuranceEligibilityStep extends BaseStep {
  category: 'insuranceEligibility';
  internalTarget: InternalTarget;
  ineligiblePromoCouponCode?: string;
  ineligiblePromo?: number;
}

export interface ChooseAndPayClientWebStep extends BaseStep {
  category: 'chooseAndPayClientWeb';
  offerID: number;
  couponInputMode?: 'default' | 'disabled' | 'hidden';
  previousStepsUntilMatches: number;
  hideProviderInfo?: boolean;
}

export interface PsychiatryUnavailableStep extends BaseStep {
  category: 'psychiatryUnavailable';
  description: string;
}

export interface RegisterWithVoucherStep extends BaseStep {
  category: 'registerWithVoucher1';
  isNYCTeen?: boolean;
}

export interface RegisterWithoutPaymentStep extends BaseStep {
  category: 'registerWithoutPayment';
}

export interface SchedulerStep extends BaseStep {
  category: 'scheduler';
  prompt: string;
  buttonTarget: InternalTarget;
}

export interface BHCopayStep extends BaseStep {
  category: 'BHCopay';
}

export interface UpdateNicknamePasswordStep extends BaseStep {
  category: 'updateNicknamePassword1';
  prompt: string;
}

export interface FreeTextStep extends BaseStep {
  category: 'freeText';

  bubbles?: string[];
  internalTarget: InternalTarget;
  submitText: string;
  textAreaPlaceholder?: string;
}

export interface SurveyIntroductionStep extends BaseStep {
  category: 'surveyIntroduction';
}

export interface SurveyWizardStep extends BaseStep {
  category: 'surveyWizard';
}

export interface SurveyResultsStep extends BaseStep {
  category: 'surveyResults';
}

export interface MatchConfirmationStep extends BaseStep {
  category: 'matchConfirmation';
  buttonTarget: InternalTarget;
}

export interface LocationEligibilityStep extends BaseStep {
  category: 'locationEligibility';
  internalTarget: InternalTarget;
}
export interface LocationEligibilityErrorStep extends BaseStep {
  category: 'locationEligibilityError';
  internalTarget: InternalTarget;
}
export interface LocationEligibilityManualAccessCodeStep extends BaseStep {
  category: 'locationEligibilityManualAccessCode';
  externalTarget: ExternalTarget;
}

export interface PresentingProblemsStep extends BaseStep {
  category: 'presentingProblems';
  internalTarget: InternalTarget;
}

export interface PresentingProblemsMultiStep extends BaseStep {
  category: 'presentingProblems';
  buttonTarget: InternalTarget;
  isMulti: true;
}

export interface B2BForkStep extends BaseStep {
  category: 'b2bFork';
  dispatcherStepTarget: InternalTarget;
  ctSplitTarget?: InternalTarget;
  shouldHideOutOfPocket?: boolean;
  shouldHideOutOfNetwork?: boolean;
  allowAutoSelectInsurancePayerAndMemberIdFromSession?: boolean;
  shouldShowShortB2BForkForm?: boolean;
}

export interface SecondaryInsuranceStep extends BaseStep {
  category: 'secondaryInsurance';
  dispatcherStepTarget: InternalTarget;
  ctSplitTarget?: InternalTarget;
}

export interface DispatcherInQMStep extends BaseStep {
  category: 'dispatcherInQM';
  internalTarget: InternalTarget;
  initialDispatcherStep: DispatcherStep;
}

export interface LastingPromotionStep extends BaseStep {
  category: 'lastingPromotion';
  externalTarget: ExternalTarget;
  internalTarget: InternalTarget;
}

export interface ServiceSelectionStep extends BaseStep {
  category: 'serviceSelection';
}

export interface CurrentProviderStep extends BaseStep {
  category: 'currentProvider';
  currentProviderTarget: InternalTarget;
}

export interface ReviewPlanStep extends BaseStep {
  category: 'reviewPlan';
  buttonTarget: InternalTarget;
}

export interface OutOfNetworkStep extends BaseStep {
  category: 'outOfNetwork';
  buttonTarget: InternalTarget;
  ineligiblePromoCouponCode: string;
  ineligiblePromo: number;
  ineligiblePromoWeeks: number;
}

export interface QuickEligibilityStep extends BaseStep {
  category: 'quickEligibility';
  inNetworkTarget: InternalTarget;
  enterMemberIDTarget: InternalTarget;
  timeoutTarget: InternalTarget;
}

export interface QuickEligibilityEnterMemberIDStep extends BaseStep {
  category: 'quickEligibilityEnterMemberID';
  inNetworkTarget: InternalTarget;
  timeoutTarget: InternalTarget;
}

export interface MemberAvailabilityStep extends BaseStep {
  category: 'memberAvailability';
  buttonTarget: InternalTarget;
}

export interface QuickEligibilityStatusStep extends BaseStep {
  category: 'quickEligibilityStatus';
}

export interface PreRegisterHoldExpiredStep extends BaseStep {
  category: 'preRegisterHoldExpired';
}

export interface PreRegisterJwtExpiredStep extends BaseStep {
  category: 'preRegisterJwtExpired';
}

export interface NoCoverageStep extends BaseStep {
  category: 'noCoverage';
}

export interface FileUploadStep extends BaseStep {
  category: 'fileUpload';
}

export interface ManualSubmitStep extends BaseStep {
  category: 'manualSubmit';
}

export interface RequestReceivedStep extends BaseStep {
  category: 'requestReceived';
}

export interface TeenBranchingStep extends InterstitialStepBase {
  category: 'teenBranching';
}

export interface TeenNYCSummaryStep extends InterstitialStepBase {
  category: 'teenNYCSummary';
}

export interface TeenLetsLearnAboutYouStep extends InterstitialStepBase {
  category: 'teenLetsLearnAboutYou';
}

export interface TeenYouAreNotAloneStep extends InterstitialStepBase {
  category: 'teenYouAreNotAlone';
}

export interface TeenConsentHeadsUpStep extends InterstitialStepBase {
  category: 'teenConsentHeadsUp';
}

export interface TeenThankYouForSharingStep extends InterstitialStepBase {
  category: 'teenThankYouForSharing';
}

export interface LeadCaptureStep extends BaseStep {
  category: 'leadCapture';
}

export interface TeensHereToHelpStep extends BaseStep {
  category: 'teensHereToHelp';
  isInterstitial: true;
}

export interface TeensEligibleFreeTherapyStep extends BaseStep {
  category: 'teensEligibleFreeTherapy';
  isInterstitial: true;
}

export interface SchoolSelectStep extends BaseStep {
  category: 'schoolSelect';
  response_category_id: 7;
  analyticsId: 160;
  prompt: string;
  heading1: string;
  heading2: string;
  buttonTarget: InternalTarget;
}

export interface TeenZipcodeEligibilityStep extends BaseStep {
  category: 'teenZipcodeEligibility';
  buttonTarget: InternalTarget;
  response_category_id: 9;
  analyticsId: 54;
}

export type FlowStepResolved =
  | IntroductionStep
  | MultiSelectStep
  | MatchesStep
  | ValidateVoucherStep
  | ExplanationStep
  | SelectStep
  | SelectMultiStep
  | SelectDynamicStepResolved
  | DateOfBirthStep
  | DropdownStep
  | LoadingStep
  | ChooseAndPayClientWebStep
  | PsychiatryUnavailableStep
  | RegisterWithVoucherStep
  | RegisterWithoutPaymentStep
  | SchedulerStep
  | BHCopayStep
  | UpdateNicknamePasswordStep
  | FreeTextStep
  | SurveyIntroductionStep
  | SurveyWizardStep
  | SurveyResultsStep
  | MatchConfirmationStep
  | InsuranceEligibilityStep
  | LocationEligibilityStep
  | LocationEligibilityErrorStep
  | LocationEligibilityManualAccessCodeStep
  | PresentingProblemsStep
  | PresentingProblemsMultiStep
  | LastingPromotionStep
  | OneFormEligibilityStep
  | B2BForkStep
  | SecondaryInsuranceStep
  | DispatcherInQMStep
  | LastingPromotionStep
  | ServiceSelectionStep
  | CurrentProviderStep
  | SessionModalityStep
  | ReviewPlanStep
  | InsuranceConfirmationStep
  | SwitchStep
  | OutOfNetworkStep
  | QuickEligibilityStep
  | MemberAvailabilityStep
  | QuickEligibilityEnterMemberIDStep
  | QuickEligibilityStatusStep
  | PreRegisterHoldExpiredStep
  | PreRegisterJwtExpiredStep
  | NoCoverageStep
  | FileUploadStep
  | ManualSubmitStep
  | RequestReceivedStep
  | TeenBranchingStep
  | TeenNYCSummaryStep
  | TeenLetsLearnAboutYouStep
  | TeenYouAreNotAloneStep
  | TeenConsentHeadsUpStep
  | TeenThankYouForSharingStep
  | LeadCaptureStep
  | TeensHereToHelpStep
  | TeensEligibleFreeTherapyStep
  | SchoolSelectStep
  | TeenZipcodeEligibilityStep
  | NotAvailableInYourRegionStep;

export type FlowStep = FlowStepResolved | SelectDynamicStep;

export interface QMFlow {
  flowId: number;
  category?: string;
  flowConfig: FlowConfig;
  steps: Array<FlowStep>;
}

export interface RecoveredFields {
  dateOfBirth?: string | null;
  freeText?: string | null;
  basicInformation?: string | null;
  memberDetails?: string | null;
  insuranceDetails?: string | null;
  registrationInformation?: string | null;
}

export interface SessionModalityStep extends BaseStep {
  category: 'sessionModality';
}
