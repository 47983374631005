import { OneFormConfigSet, OneFormConfigSetType } from '../../Flows/types';

const oneFormConfigSets: Record<OneFormConfigSetType, OneFormConfigSet> = {
  [OneFormConfigSetType.nycTeen]: {
    removePhoneNumber: true,
    removeReferralSource: true,
    removeEmployeeRelation: true,
    removeOrganization: true,
    allowTeens: true,
    removeEmail: false,
    onlyFirstName: true,
    address1Label: 'Address',
    address1Hint: '(e.g. home, shelter, or most recent address)',
    address1ErrorMessage: 'Home, shelter, or most recent address is required',
    address2Label: 'Apartment, suite, etc. (optional)',
    address2Placeholder: 'Apartment, suite, etc.',
    optionalTruthCheckbox: true,
    showMarketingConsent: true,
  },
};

export default oneFormConfigSets;
