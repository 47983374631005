import appConfigs from './configs';

/* eslint-disable no-underscore-dangle */
type InsuranceProvider = 'out-of-pocket' | 'in-network' | 'out-of-network';
interface VWOWindow {
  _ts?: {
    insuranceProvider?: InsuranceProvider;
    oonCode?: string;
    oonCodeValue?: number;
  };
  VWO?: Array<any>;
}

const vwoWindow: VWOWindow = window as VWOWindow;

export const setVWOInsuranceCoupon = (coupon: string, amount: number) => {
  vwoWindow._ts = vwoWindow._ts || {};
  vwoWindow._ts.oonCode = coupon;
  vwoWindow._ts.oonCodeValue = amount;
};

export const setVWOInsuranceProvider = (insuranceProvider: InsuranceProvider) => {
  vwoWindow._ts = vwoWindow._ts || {};
  vwoWindow._ts.insuranceProvider = insuranceProvider;
};

export const activateVwoOonExperiment = () => {
  if (vwoWindow._ts?.insuranceProvider !== 'out-of-network') return;
  vwoWindow.VWO = vwoWindow.VWO || [];
  vwoWindow.VWO.push(['activate', false, [appConfigs.analytics.VWO.oonExperimentCampaignID], true]);
};
