import { getClientFlow } from '../Flows';
import { setFlowSuperProperty } from '../utils/analytics/events';

const ALLOWED_SEARCH_PARAMS = [
  'coupon',
  'show_plans',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  'gclid',
  '_ga',
  'affiliate',
  'clientAge',
  'insurancePayer',
  'skipServiceSelection',
];

const removeQuestion = (search) => (search && search.indexOf('?') === 0 ? search.slice(1) : search);

export const combineSearch = (newFlowSearch, originalSearch) => {
  if (!newFlowSearch && !originalSearch) return '';
  if (!newFlowSearch) return `?${removeQuestion(originalSearch)}`;
  if (!originalSearch) return `?${removeQuestion(newFlowSearch)}`;
  return `?${removeQuestion(newFlowSearch)}&${removeQuestion(originalSearch)}`;
};

const getOriginalSearch = () => {
  const { search } = window.location;
  const params = Object.fromEntries(new URLSearchParams(search));
  const filteredParams = Object.entries(params).reduce(
    (prev, [k, v]) => (ALLOWED_SEARCH_PARAMS.includes(k) ? { ...prev, [k]: v } : prev),
    {}
  );
  const queryString = new URLSearchParams(filteredParams).toString();
  return queryString;
};

export default function switchFlowHelper(newFlowId, history, updateAndSave, config = {}) {
  const { newFlowSearch = '', stepId = 1 } = config;
  const flow = getClientFlow(newFlowId, false);
  let search = combineSearch(newFlowSearch, getOriginalSearch());
  if (stepId !== 1) {
    // Let InitialQuestionSkipper know the first step is not step 1
    search = combineSearch(search, `initialStepId=${stepId}`);
  }
  updateAndSave({
    flowId: newFlowId,
    stepId,
    flowVersion: flow ? flow.flowConfig.version : 0,
    search,
  });
  setFlowSuperProperty(newFlowId);
  history.push(`/flow/${newFlowId}/step/${stepId}${search}`);
}
