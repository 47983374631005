/**
 * shared/stripe/StripeContext.tsx expects these functions
 * They are used to open a stripe error modal in the event that stripe fails to boot up.
 * We should probably build out a non-trivial ModalsContextProvider for quickmatch-web sometime soon.
 */

const sendTrivialWarning = () =>
  // eslint-disable-next-line no-console
  console.warn(
    'This is a trivial call to an empty function of ModalsContentProvider, which has not been built out in quickmatch-web'
  );

export function useOpenModal() {
  sendTrivialWarning();
}

export function useCloseModal() {
  sendTrivialWarning();
  return (obj: object | undefined) => {};
}

export const getMemoryHistory = () => {
  sendTrivialWarning();
  return null;
};
