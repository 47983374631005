/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import { ServiceType } from 'ts-frontend/types';
import { useSetCustomAttributes } from 'launchDarkly/hooks';

export const useChangeServiceTypeInLD = () => {
  const setCustomAttributes = useSetCustomAttributes(true);

  return useCallback(
    async (serviceType: ServiceType, flowId?: number) => {
      const payload: {
        qmServiceType: ServiceType;
        qmFlowId?: number;
      } = {
        qmServiceType: serviceType,
      };
      if (flowId) {
        payload.qmFlowId = flowId;
      }
      const updatedFlags = await setCustomAttributes(payload);
      return { ...updatedFlags };
    },
    [setCustomAttributes]
  );
};
