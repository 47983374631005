import { Button, Large, Link, Small, SparklingCheckMark, View } from '@talkspace/react-toolkit';
import Title from '@/Components/Title';
import Description from '@/Components/Description';

interface Props {
  email: string;
  showBackToAccountCTA?: boolean;
  onBackToAccountCTAPress?: () => void;
}

const OneFormEligibilitySubmitManualRequestSuccess = ({
  email,
  showBackToAccountCTA,
  onBackToAccountCTAPress,
}: Props) => (
  <View align="center">
    <SparklingCheckMark />
    <Title
      style={{
        marginTop: 24,
      }}
    >
      We’ve received your request
    </Title>
    <Description>
      One of our agents will review your information. We’ll have an update for you in 3-5 business
      days. We’ll follow up by emailing you at{' '}
      <Large inline style={{ fontWeight: 'bold' }} dataQa="followUpEmail">
        {email}
      </Large>
      .
    </Description>
    <Link
      href="https://help.talkspace.com/hc/en-us/categories/360005701492-About-Talkspace"
      target="_blank"
      style={{ marginTop: 40 }}
    >
      <Small variant="smallBolderGreen" inline>
        Learn more about Talkspace
      </Small>
    </Link>
    {showBackToAccountCTA && (
      <Button
        dataQa="returnToMyAccountButton"
        style={{ marginTop: 40 }}
        text="Return to my account"
        onClick={onBackToAccountCTAPress}
      />
    )}
  </View>
);

export default OneFormEligibilitySubmitManualRequestSuccess;
