import { useCallback } from 'react';
import { useHistory } from '@/core/routerLib';

import { FlowStep } from '../../../Flows/types';
import { HomePageActions, useHomePageActions, useHomePageState } from '../../HomePage';

const useBackButton = (
  step: FlowStep
): {
  isHidden: boolean;
  onPress: () => void;
  setOverriddenBackButtonBehavior: HomePageActions['setOverriddenBackButtonBehavior'];
} => {
  const { category } = step;

  const history = useHistory();
  const { isDisabledPreviousButton = false, overriddenBackButtonBehavior } = useHomePageState();
  const { setOverriddenBackButtonBehavior } = useHomePageActions();

  const isHidden =
    ['loading', 'introduction', 'matches', 'validateVoucher', 'serviceSelection'].includes(
      category
    ) || isDisabledPreviousButton;

  const onPress = useCallback(() => {
    if (overriddenBackButtonBehavior) {
      overriddenBackButtonBehavior();
    } else {
      history.goBack();
    }
  }, [history, overriddenBackButtonBehavior]);

  return {
    isHidden,
    onPress,
    setOverriddenBackButtonBehavior,
  };
};

export default useBackButton;
