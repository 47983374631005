import { QMFlow } from './types';
import { initFlowConfig } from './utils';
// eslint-disable-next-line import/no-cycle
import flow100 from './Flow100';

export const FLOW_103_ELIGIBILITY_QUESTIONS_TEEN_THERAPY = 103;

const flow: QMFlow = {
  steps: flow100.steps,
  flowId: FLOW_103_ELIGIBILITY_QUESTIONS_TEEN_THERAPY,
  flowConfig: {
    ...initFlowConfig,
    ...flow100.flowConfig,
  },
};

export default flow;
