/* eslint-disable import/prefer-default-export */

import { ServiceType } from 'ts-frontend/types';

export const insurancePayersQueryKey = (service: ServiceType | null): Array<string> => [
  'insurancePayers',
  service || '',
];

export const liveSessionAvailabilityQueryKey = (therapistID: string): Array<string> => [
  'liveSessionAvailability',
  therapistID,
];
export const implicitBusinessHoursQueryKey = (providerID: number): Array<string> => [
  'implicitBusinessHours',
  providerID.toString(),
];

export const adminConfigQueryKey = (configKey: string): Array<string> => ['adminConfig', configKey];

export const publicSchoolListQueryKey = (): Array<string> => ['publicSchoolList'];

export const flowConfigQueryKey = (flowID?: number): Array<string> => [
  'flowConfig',
  flowID?.toString() || '',
];
