import { useQuery } from 'react-query';

import apiWrapper from '../core/api/apiWrapper';
import apiHelper from '../Helpers/apiHelper';
import { liveSessionAvailabilityQueryKey } from './queryKeys';

interface Availability {
  therapistTimezone: string;
  timeslots: Array<{
    day: number;
    timeslots: Array<{
      start: string;
      end: string;
    }>;
  }>;
}

const fetchAvailability =
  (therapistID: string, isPsych: boolean) => async (): Promise<Availability> =>
    apiWrapper.get(
      `${apiHelper()}therapists/${therapistID}/availability/${isPsych ? 'psychiatry' : 'therapy'}`
    );

const useQueryLiveSessionAvailability = (therapistID: string, isPsych: boolean) =>
  useQuery({
    queryKey: liveSessionAvailabilityQueryKey(therapistID),
    queryFn: fetchAvailability(therapistID, isPsych),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

export default useQueryLiveSessionAvailability;
