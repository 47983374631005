import {
  ExtraHuge,
  Large,
  Small,
  Tiny,
  View,
  HeartShield,
  TouchableView,
  CaretRight,
  useEmotionTheme,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { ReactElement } from 'react';
import { QuickEligiblityStatusProps } from '../types';
import styled from '@/core/styled';
import { trackEvent } from '@/utils/analytics/events';

const Wrapper = styled(View)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    alignItems: 'center',
    maxWidth: 430,
    marginTop: isMobile ? 20 : 100,
  };
});

const InsuranceInfoBanner = styled(View)<{ isMobile: boolean }>(
  ({ theme: { colors }, isMobile }) => {
    return {
      borderRadius: 12,
      width: '100%',
      maxWidth: isMobile ? 335 : 430,
      height: 62,
      background: colors.permaGreyed,
      marginBottom: 16,
      border: `solid 1px ${colors.permaLondonGray}`,
    };
  }
);

const DiscountBanner = styled(View)(({ theme: { colors } }) => {
  return {
    borderRadius: 8,
    padding: 8,
    marginTop: 8,
    background: colors.aquaSqueeze,
    color: colors.permaTalkspaceDarkGreen,
    width: 202,
    boxSizing: 'content-box',
  };
});

const MenuLinks = ({ items }) => {
  const { colors } = useEmotionTheme();
  return (
    <View style={{ borderTop: `1px ${colors.periwinkleGrey} solid`, marginTop: 20, width: '100%' }}>
      {items.map(({ key, Content, onPress }) => (
        <TouchableView
          row
          justify="space-between"
          key={key}
          dataQa={key}
          style={{
            textAlign: 'left',
            padding: 20,
            borderBottom: `1px ${colors.periwinkleGrey} solid`,
          }}
          onPress={onPress}
          align="center"
        >
          <View style={{ flex: 1 }}>
            <Content />
          </View>

          <CaretRight style={{ marginLeft: 24 }} />
        </TouchableView>
      ))}
    </View>
  );
};

interface MenuItems {
  key: string;
  shouldHide?: boolean;
  Content: () => ReactElement;
  onPress: () => void;
}

const NotVerified = ({
  step,
  updateStep,
  insurancePayer,
  quickEligibilityInfo,
}: QuickEligiblityStatusProps) => {
  const { firstName, lastName, dob, memberID } = quickEligibilityInfo;

  const fullName = `${firstName} ${lastName}`;

  const noCoverageHideEAP = false;
  const payerName = insurancePayer?.label;

  const { isMobile } = useWindowWidthState();
  const items: MenuItems[] = [
    {
      key: 'continueWithoutInsurancePress',
      Content: () => (
        <>
          <Large>Continue without insurance</Large>
          <DiscountBanner>
            <Small variant="smallBolderGreen">You’re eligible for a discount!</Small>
          </DiscountBanner>
        </>
      ),
      onPress: () => {
        // Align tracking with `OneFormEligibilityNoCoverage`
        trackEvent('No Coverage Options', {
          optionType: 'Continue without insurance',
          source: 'Could not verify',
        });

        updateStep(step.id, {
          quickStatusAction: 'continueWithoutInsurance',
          isCouldNotBeVerified: true,
          // clear insuranceEligibility for out-of-pocket, to support OOP promo and applying coupon
          insuranceEligibility: undefined,
          b2bForkResult: 'b2c',
          insurancePayer: undefined,
        });
      },
    },
    {
      key: 'checkInformationCorrectAndResubmitPress',
      Content: () => <Large>Check that my information is correct and resubmit</Large>,
      onPress: () => {
        // Align tracking with `OneFormEligibilityNoCoverage`
        trackEvent('No Coverage Options', {
          optionType: 'Check that my information is correct and resubmit',
          source: 'Could not verify',
        });

        updateStep(step.id, {
          quickStatusAction: 'resubmit',
          isCouldNotBeVerified: false,
        });
      },
    },
    {
      key: 'checkIfHaveCoveragePress',
      Content: () => (
        <Large>
          See if I have coverage through my employer, organization, or Employee Assistance Program
          (EAP)
        </Large>
      ),
      onPress: () => {
        // Align tracking with `OneFormEligibilityNoCoverage`
        trackEvent('No Coverage Options', {
          optionType: 'See if I have coverage through my employer, organization, or EAP',
          source: 'Could not verify',
        });

        updateStep(step.id, {
          quickStatusAction: 'orgEap',
          isCouldNotBeVerified: false,
          insurancePayer: undefined,
        });
      },
    },
    {
      key: 'surePlanCoversTalkspacePress',
      Content: () => <Large>I’m sure my plan covers Talkspace</Large>,
      onPress: () => {
        // Align tracking with `OneFormEligibilityNoCoverage`
        trackEvent('No Coverage Options', {
          optionType: 'I’m sure my plan covers Talkspace',
          source: 'Could not verify',
        });

        updateStep(step.id, {
          quickStatusAction: 'fileUpload',
          isCouldNotBeVerified: false,
        });
      },
    },
  ];

  return (
    <Wrapper isMobile={isMobile}>
      <InsuranceInfoBanner justify="center" isMobile={isMobile}>
        {payerName && (
          <View row justify="center">
            <HeartShield />
            <Small style={{ marginLeft: 8 }} variant="smallBolderGrey">
              {payerName}
            </Small>
          </View>
        )}
        <View style={{ marginTop: 4 }}>
          <Tiny>
            {fullName}, {dob}, {memberID || '-'}
          </Tiny>
        </View>
      </InsuranceInfoBanner>
      <ExtraHuge style={{ marginBottom: 12 }}>
        We couldn’t verify your coverage for Talkspace
      </ExtraHuge>
      <Large>
        If you typed everything correctly, then it appears that Talkspace may not be covered by your
        plan. Don't worry, we'll help you explore other options.
      </Large>
      <MenuLinks
        items={
          step?.skipRedirectToFlow || noCoverageHideEAP
            ? items.filter((value) => value.key !== 'checkIfHaveCoveragePress')
            : items
        }
      />
    </Wrapper>
  );
};

export default NotVerified;
