import { FunctionComponent } from 'react';
import {
  View,
  useUniqueID,
  Modal,
  ExtraBig,
  CloseButton,
  useObjectState,
  Button,
  BaseButton,
  Big,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { ClientMatchPresentingProblem } from 'ts-frontend/types';
import styled from '@/core/styled';
import Filters from './Filters';
import { FiltersState } from './types';

interface FiltersModalProps {
  loadingMatches: boolean;
  clientMatchPresentingProblems: ClientMatchPresentingProblem[];
  applyFilters: (
    clientState: string,
    specialties: ClientMatchPresentingProblem[],
    providerGender?: string,
    blockGetMatches?: boolean
  ) => void;
  hideFiltersModal: () => void;
  clientMatchGenderPreference?: string;
  clientState?: string;
}

const FullScreenWrapper = styled(View)(({ theme: { colors } }) => {
  return {
    backgroundColor: colors.white,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1000,
  };
});

const NavBar = styled(View)(({ theme: { colors } }) => {
  return {
    height: 66,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: colors.white,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.04)',
  };
});

const FiltersWrapper = ({ onClose, children }) => {
  const { colors } = useEmotionTheme();
  return (
    <FullScreenWrapper>
      <NavBar>
        <ExtraBig as="h1">Filters</ExtraBig>
        <CloseButton
          ariaLabel="exit filters"
          roundedFocusStyle
          primaryColor={colors.permaWaikawaGrey}
          onPress={onClose}
          style={{
            position: 'fixed',
            top: 16,
            right: 16,
            borderRadius: 10,
          }}
        />
      </NavBar>
      <View
        flex={1}
        style={{
          paddingTop: 40,
          width: '100%',
          maxWidth: 335,
          alignSelf: 'center',
        }}
      >
        {children}
      </View>
    </FullScreenWrapper>
  );
};

const FiltersModal: FunctionComponent<FiltersModalProps> = ({
  loadingMatches,
  clientMatchPresentingProblems,
  applyFilters,
  hideFiltersModal,
  clientMatchGenderPreference = undefined,
  clientState = undefined,
}) => {
  const { colors } = useEmotionTheme();
  const [filtersState, setFiltersState] = useObjectState<FiltersState>({
    state: '',
    specialties: [],
    providerGender: '',
  });
  const titleId = useUniqueID('titleId');

  const handleApplyFiltersPress = () => {
    applyFilters(filtersState.state, filtersState.specialties, filtersState.providerGender);
  };

  return (
    <Modal isVisible titleText="Filters" titleID={titleId}>
      <FiltersWrapper onClose={hideFiltersModal}>
        <Filters
          clientState={clientState}
          clientMatchPresentingProblems={clientMatchPresentingProblems}
          clientMatchGenderPreference={clientMatchGenderPreference}
          setFiltersState={setFiltersState}
        />
        <Button
          data-qa="applyFiltersButton"
          style={{ marginTop: 40, width: 335, fontWeight: 'bold' }}
          roundedFocusStyle
          primaryColor={colors.green}
          onPress={handleApplyFiltersPress}
          text="Apply filters"
          isLoading={loadingMatches}
        />
        <BaseButton
          roundedFocusStyle
          primaryColor={colors.permaWaikawaGrey}
          style={{ marginTop: 25, borderRadius: 5 }}
          data-qa="cancelFiltersButton"
          onPress={hideFiltersModal}
        >
          <Big variant="bigMedium">Cancel</Big>
        </BaseButton>
      </FiltersWrapper>
    </Modal>
  );
};

export default FiltersModal;
