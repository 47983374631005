/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import { LDFlags } from 'ts-frontend/types';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { HomePageState, RunSkipQuestionActions } from '../types';

export const useRunSkipQuestionActions = (stateRef?: React.RefObject<HomePageState>) => {
  const flags = useFlags();

  return useCallback<RunSkipQuestionActions>(
    async (skipQuestionActions = {}, currentQuestionId?, answer?) => {
      let nextQuestionId = currentQuestionId;
      const finalSkipQuestionActions = Array.isArray(skipQuestionActions)
        ? skipQuestionActions
        : (nextQuestionId && skipQuestionActions[nextQuestionId]) || undefined;

      if (Array.isArray(finalSkipQuestionActions)) {
        for (let i = 0; i < finalSkipQuestionActions.length; i += 1) {
          const action = finalSkipQuestionActions[i];
          if (typeof action === 'function') {
            // eslint-disable-next-line no-await-in-loop
            const retValue = await action(
              (stateRef?.current || {}) as HomePageState,
              answer,
              flags as LDFlags
            );
            if (retValue && !isNaN(retValue)) {
              nextQuestionId = retValue;
              break;
            }
          }
        }
        return nextQuestionId;
      }
      return nextQuestionId;
    },
    [flags, stateRef]
  );
};
