import * as yup from 'yup';
import moment from 'moment';
import { isPhoneNumberValid, isZipValid } from '@talkspace/react-toolkit';
import { ServiceType } from 'ts-frontend/types';
import {
  SESSION_STORAGE_MEMBER_DETAILS_KEY,
  getSessionStorageValuesFromJson,
} from 'ts-frontend/utils/sessionStorageService';
import { ageErrorCopy, isUnderage, isValidDate, isUnder18 } from '@/Helpers/ageHelper';
import sessionStorage from '@/core/storage/sessionStorage';
import { countries } from '@/Helpers/locales';
import {
  EligibilityType,
  FLOW_62_B2B_EAP_OPTUM,
  FLOW_78_B2B_EAP_CIGNA,
  FlowConfig,
  OneFormEligibilityStep,
} from '@/Flows';
import {
  OneFormEligibilityFieldNames,
  OneFormEligibilityFields,
  OneFormEligibilityYupContext,
} from './types';
import countriesHelper from '@/Helpers/countriesHelper';

const US = 'US';

export const SERVICE_OPTIONS = [
  {
    key: 'serviceType',
    value: 'psychotherapy',
    label: 'Therapy',
  },
  {
    key: 'serviceType',
    value: 'psychiatry',
    label: 'Psychiatry',
  },
  {
    key: 'serviceType',
    value: 'therapyCouples',
    label: 'Therapy - couples therapy',
  },
];

export const EMPLOYEE_OPTIONS = [
  {
    key: 'employeeRelationship',
    value: 'employee',
    label: 'Employee',
  },
  {
    key: 'employeeRelationship',
    value: 'spouse',
    label: 'Spouse/partner',
  },
  {
    key: 'employeeRelationship',
    value: 'dependent',
    label: 'Adult dependent/Member of household',
  },
];

export const DEFAULT_ERROR = 'Required field.';

export const PSYCH_TOO_YOUNG_ERROR = 'Talkspace does not offer psychiatry to individuals under 18.';

const sessionStorageToFieldNameDict = {
  firstName: 'firstName',
  lastName: 'lastName',
  dateOfBirth: 'dateOfBirth',
  phone: 'phone',
  email: 'email',
  employeeId: 'employeeID',
  whoAreYou: 'employeeRelation',
  serviceType: 'serviceType',
  referralSource: 'heardAbout',
  memberId: 'memberID',
  groupId: 'groupID',
  isChecked: 'verificationCheckbox',
  authCode: 'authorizationCode',
  address: 'addressLine1',
  unitAddress: 'addressLine2',
  city: 'city',
  state: 'state',
  zipCode: 'zipcode',
  country: 'country',
  keyword: 'organizationName',
  employeeFirstName: 'employeeFirstName',
  employeeLastName: 'employeeLastName',
  employeeSameAddressCheckbox: 'employeeSameAddressCheckbox',
  employeeAddressLine1: 'employeeAddressLine1',
  employeeAddressLine2: 'employeeAddressLine2',
  employeeCity: 'employeeCity',
  employeeState: 'employeeState',
  employeeZipcode: 'employeeZipcode',
  employeeCountry: 'employeeCountry',
  attendedSchool: 'attendedSchool',
  secondaryMemberID: 'secondaryMemberID',
  secondaryPayerID: 'secondaryPayerID',
};

export const countryTestFunction = (
  userInput?: string,
  testContext?: yup.TestContext<OneFormEligibilityYupContext>,
  isFieldRequired?: boolean
) => {
  const specificCountryCodesToSupport = testContext?.options.context
    ?.specificCountryCodesToSupport || ['US'];

  const valid =
    isFieldRequired && userInput
      ? countriesHelper
          .getRelevantCountryCodesToDisplay(specificCountryCodesToSupport)
          .includes(userInput)
      : !isFieldRequired;

  return valid
    ? true
    : !!testContext &&
        specificCountryCodesToSupport.length < 6 &&
        testContext.createError({
          message: `Services are covered for ${specificCountryCodesToSupport.join(
            ', '
          )} residents only.`,
        });
};

export const stateTestFunction = (
  userInput?: string,
  testContext?: yup.TestContext<OneFormEligibilityYupContext>
) => {
  if (testContext?.options.context?.isAddressRequired) {
    if (userInput && userInput.length > 0) {
      return true;
    }
    return false;
  }
  return true;
};

export const responsiveSchema: yup.SchemaOf<OneFormEligibilityFields> = yup.object().shape({
  // required for all non-organizational flows
  memberID: yup
    .string()
    .when(
      '$collectInsuranceDetails',
      (collectInsuranceDetails: boolean, schema: yup.StringSchema) =>
        collectInsuranceDetails
          ? schema.required('Member/Subscriber ID missing or invalid.')
          : schema.optional()
    ),
  // required for GDPR countries users
  consentGDPRProcessing: yup.boolean().when('$isGDPR', {
    is: true,
    then: yup.boolean().isTrue().required('Consent is required'),
  }),
  consentGDPRTransferring: yup.boolean().when('$isGDPR', {
    is: true,
    then: yup.boolean().isTrue().required('Consent is required'),
  }),
  // not required for any flows at this point but the validation context should be based on step attribute to allow it be required in the future
  groupID: yup
    .string()
    .when('$isGroupIdRequired', (isGroupIdRequired: boolean, schema: yup.StringSchema) =>
      isGroupIdRequired ? schema.required('Group/Plan ID missing or invalid.') : schema.optional()
    ),
  // always required
  firstName: yup.string().required('First name is required'),
  // always required
  lastName: yup.string().when('$onlyFirstName', {
    is: true,
    then: yup.string().ensure().optional(),
    otherwise: yup.string().required('Last name is required'),
  }) as ReturnType<yup.StringSchema<string>['required']>,
  // always required
  dateOfBirth: yup
    .string()
    .test(
      'is-under-age',
      (userInput?: string, testContext?: yup.TestContext<OneFormEligibilityYupContext>) => {
        const isUnderAge = isUnderage(userInput, testContext?.options?.context?.flowId);

        if (testContext && userInput && isUnderAge) {
          return testContext.createError({
            message: ageErrorCopy(testContext.options?.context?.flowId),
          });
        }

        return true;
      }
    )
    .test(
      'is-valid-date-format',
      'This is not a valid date',
      (userInput?: string, testContext?: yup.TestContext<OneFormEligibilityYupContext>) =>
        userInput
          ? isValidDate(userInput, testContext?.options?.context?.flowId, 'MM/DD/YYYY')
          : true
    )
    .required('Date of birth is required'),
  // always required
  phone: yup
    .string()
    .when('$removePhoneNumber', {
      is: true,
      then: yup.string().optional(),
      otherwise: yup.string().required(DEFAULT_ERROR),
    })
    .test(
      'is-valid-phone-number',
      'Phone number is missing or invalid.',
      (userInput: string | undefined, testContext) =>
        userInput ? isPhoneNumberValid(userInput) : !!testContext.options.context?.removePhoneNumber
    ),
  // address is required for certain organization flows and all file-based, manual, and trizetto eligibility flows
  addressLine1: yup.string().test({
    name: 'address-line-test',
    test: async (addressLine1Value, context) => {
      const errorMessage =
        context.options.context?.addressLine1ErrorMessage || 'Address is missing or invalid.';
      let schema = yup.string().optional();
      if (context.options.context?.isAddressRequired) {
        schema = schema.required();
      }
      return (
        (await schema.isValid(addressLine1Value)) ||
        context.createError({
          message: errorMessage,
        })
      );
    },
  }),
  // never required
  addressLine2: yup.string().optional(),
  city: yup
    .string()
    .when('$isAddressRequired', (isAddressRequired: boolean, schema: yup.StringSchema) =>
      isAddressRequired ? schema.required('City is required') : schema.optional()
    ),
  zipcode: yup
    .string()
    .test('is-required-zip-code', 'Zip code is required', (zipCodeValue, context) => {
      if (context.options.context?.isAddressRequired) {
        if (zipCodeValue && zipCodeValue.length > 0) {
          return true;
        }
        return false;
      }
      return true;
    })
    .test('is-valid-zip-code', 'Zip code is invalid', (zipCodeValue, context) =>
      zipCodeValue && context.parent.country
        ? isZipValid(zipCodeValue, context.parent.country)
        : !context.options.context?.isAddressRequired
    ),
  // required for some flows
  // some flows have configs that dictate whether or not the services are for specific countries residents only (flowConfig.specificCountryCodesToSupport)
  country: yup
    .string()
    .when('$isAddressRequired', (isAddressRequired: boolean, schema: yup.StringSchema) =>
      isAddressRequired ? schema.required('Country is required') : schema.optional()
    )
    .test(
      'is-valid-country',
      'Services are not covered for selected country',
      (userInput?: string, testContext?: yup.TestContext<OneFormEligibilityYupContext>) => {
        const { isAddressRequired } = testContext?.options.context || {};
        return countryTestFunction(userInput, testContext, isAddressRequired);
      }
    ),
  // Dropdown alternative to state
  clientState: yup
    .string()
    // Temporarily using the fact that `removeAddress === false` is the only case where this dropdown is rendered.
    .when('$removeAddress', (removeAddress: boolean, schema: yup.StringSchema) =>
      removeAddress ? schema.required(DEFAULT_ERROR) : schema.optional()
    )
    .when('isAddressRequired', (isAddressRequired: boolean, schema: yup.StringSchema) =>
      isAddressRequired ? schema.required(DEFAULT_ERROR) : schema.optional()
    )
    .test('is-required-state', 'State is required', stateTestFunction)
    .test(
      'is-valid-state',
      'State is invalid.',
      (
        userInput: OneFormEligibilityFields['clientState'],
        testContext: yup.TestContext<OneFormEligibilityYupContext>
      ) => {
        const hasSelectedUS = testContext.parent.country === 'US';
        const clientStateInput = testContext.parent.clientState;
        const stateRegex = /^[a-zA-Z]{2}$/g;
        if (hasSelectedUS) {
          if (userInput && userInput.length > 0) {
            return stateRegex.test(userInput);
          }
          if (clientStateInput.length > 0) {
            return stateRegex.test(clientStateInput);
          }
        }
        return true;
      }
    ),
  // api expects just the value string
  employeeRelation: yup
    .object({
      // Adding default(null) removes the `| undefined` from the final schema type
      value: yup.string().default(null).required(),
      label: yup.string().default(null).required(),
      key: yup.string().default(null).required(),
    })
    .default(null)
    .nullable()
    .when(
      '$removeEmployeeRelation',
      (
        removeEmployeeRelation: boolean,
        schema: yup.SchemaOf<OneFormEligibilityFields['employeeRelation']>
      ) => (removeEmployeeRelation ? schema.notRequired() : schema.required(DEFAULT_ERROR))
    ),
  employeeFirstName: yup
    .string()
    .when('$displayEmployeeInfo', (displayEmployeeInfo: boolean, schema: yup.StringSchema) =>
      displayEmployeeInfo ? schema.required(DEFAULT_ERROR) : schema.optional()
    ),
  employeeLastName: yup
    .string()
    .when('$displayEmployeeInfo', (displayEmployeeInfo: boolean, schema: yup.StringSchema) =>
      displayEmployeeInfo ? schema.required(DEFAULT_ERROR) : schema.optional()
    ),
  employeeSameAddressCheckbox: yup
    .bool()
    .when('$displayEmployeeInfo', (displayEmployeeInfo: boolean, schema: yup.BooleanSchema) =>
      displayEmployeeInfo ? schema.required(DEFAULT_ERROR) : schema.optional()
    ),
  employeeAddressLine1: yup.string().when(['$displayEmployeeInfo', '$employeeSameAddress'], {
    is: (displayEmployeeInfo: boolean, employeeSameAddress: boolean) =>
      displayEmployeeInfo && !employeeSameAddress,
    then: yup.string().required(DEFAULT_ERROR),
  }),
  employeeAddressLine2: yup.string().optional(),
  employeeCity: yup.string().when(['$displayEmployeeInfo', '$employeeSameAddress'], {
    is: (displayEmployeeInfo: boolean, employeeSameAddress: boolean) =>
      displayEmployeeInfo && !employeeSameAddress,
    then: yup.string().required(DEFAULT_ERROR),
  }),
  employeeState: yup
    .string()
    .when(['$displayEmployeeInfo', '$employeeSameAddress'], {
      is: (displayEmployeeInfo: boolean, employeeSameAddress: boolean) =>
        displayEmployeeInfo && !employeeSameAddress,
      then: yup.string().required(DEFAULT_ERROR),
    })
    .test(
      'is-valid-employee-state',
      'State missing or invalid.',
      (
        userInput?: OneFormEligibilityFields['clientState'],
        testContext?: yup.TestContext<OneFormEligibilityYupContext>
      ) => {
        const hasSelectedUS = testContext?.parent.employeeCountry === 'US';
        const stateRegex = /^[a-zA-Z]{2}$/g;
        if (!testContext?.options.context?.employeeSameAddress && !userInput && hasSelectedUS)
          return false;
        if (!testContext?.options.context?.employeeSameAddress && userInput && hasSelectedUS)
          return stateRegex.test(userInput);
        return true;
      }
    ),
  employeeZipcode: yup.string().when(['$displayEmployeeInfo', '$employeeSameAddress'], {
    is: (displayEmployeeInfo: boolean, employeeSameAddress: boolean) =>
      displayEmployeeInfo && !employeeSameAddress,
    then: yup.string().required(DEFAULT_ERROR),
  }),
  employeeCountry: yup
    .string()
    .when(['$displayEmployeeInfo', '$employeeSameAddress'], {
      is: (displayEmployeeInfo: boolean, employeeSameAddress: boolean) =>
        displayEmployeeInfo && !employeeSameAddress,
      then: yup.string().required(DEFAULT_ERROR),
    })
    .test(
      'is-valid-employee-country',
      'Services are not covered for selected country',
      (userInput?: string, testContext?: yup.TestContext<OneFormEligibilityYupContext>) => {
        const { employeeSameAddress, displayEmployeeInfo } = testContext?.options.context || {};
        return countryTestFunction(
          userInput,
          testContext,
          displayEmployeeInfo && !employeeSameAddress
        );
      }
    ),
  // required for all organization-based eLigibility flows except for one
  // optional for all other eligibility flows
  organizationName: yup
    .string()
    .when('$optionalOrganization', (optionalOrganization: boolean, schema: yup.StringSchema) =>
      optionalOrganization ? schema.optional() : schema.required(DEFAULT_ERROR)
    ),
  // api expects just the value string
  heardAbout: yup
    .object({
      value: yup.string().default(null).required(),
      label: yup.string().default(null).required(),
      key: yup.string().default(null).required(),
    })
    .default(null)
    .nullable()
    .when(
      '$removeReferralSource',
      (removeHeardAbout: boolean, schema: yup.SchemaOf<OneFormEligibilityFields['heardAbout']>) =>
        removeHeardAbout ? schema.notRequired() : schema.required('Referral source is missing.')
    ),
  email: yup
    .string()
    .test('is-email-required', 'Email address is required.', (emailValue, context) => {
      if (context.options.context?.removeEmail) {
        return true;
      }
      if (emailValue && emailValue.length > 0) {
        return true;
      }
      return false;
    })
    .test('is-valid-email', 'This is not a valid email address', (emailValue) =>
      yup.string().email().isValid(emailValue)
    ),
  // required for only one organization-based eligibility flow at the moment (flow 11)
  employeeID: yup
    .string()
    .when('$hasEmployeeId', (hasEmployeeId: boolean, schema: yup.StringSchema) =>
      hasEmployeeId ? schema.required(DEFAULT_ERROR) : schema.optional()
    )
    .test(
      'is-valid-employee-id',
      'Employee ID is invalid (up to 20 digits accepted).',
      (userInput?: string, testContext?: yup.TestContext<OneFormEligibilityYupContext>) =>
        testContext?.options.context?.hasEmployeeId || userInput
          ? !!(userInput && /^\d+$/g.test(userInput) && userInput.length <= 20)
          : true
    ),
  // required only for some organization-based eligibility flows
  authorizationCode: yup
    .string()
    .test(
      'is-valid-authorization-code',
      'Authorization code is missing or invalid.',
      (userInput?: string, testContext?: yup.TestContext<OneFormEligibilityYupContext>) => {
        const flow62Regex = /^[a-zA-Z0-9]{6}[-][0-9]{2}$/;
        const flow78OldAndNewerValidRegex = /^[1-9][0-9]{8,9}[*][O]$/;
        const flow78NewValidRegex = /^[O][P][0-9]{10}$/;
        const authCodeMaxLength = testContext?.options.context?.authCodeMaxLength;
        const flowId = testContext?.options.context?.flowId;
        const hasAuthCode = testContext?.options.context?.hasAuthCode;
        if (hasAuthCode && !userInput) return false;
        if (authCodeMaxLength && userInput && userInput.length > authCodeMaxLength) return false;
        if (hasAuthCode && userInput) {
          if (flowId === FLOW_62_B2B_EAP_OPTUM) {
            return flow62Regex.test(userInput);
          }
          if (flowId === FLOW_78_B2B_EAP_CIGNA) {
            return (
              flow78OldAndNewerValidRegex.test(userInput) || flow78NewValidRegex.test(userInput)
            );
          }
        }
        return true;
      }
    ),
  // required only for one organization-based eligibility flow at the moment
  numberOfSessions: yup
    .object({
      value: yup.string().default(null).required(),
      label: yup.string().default(null).required(),
    })
    .default(null)
    .nullable()
    .when(
      '$hasNumberOfSessions',
      (
        hasNumberOfSessions: boolean,
        schema: yup.SchemaOf<OneFormEligibilityFields['numberOfSessions']>
      ) => (hasNumberOfSessions ? schema.required(DEFAULT_ERROR) : schema)
    ),
  authorizationCodeExpiration: yup
    .string()
    .test(
      'is-valid-authorization-code-expiration-date',
      (userInput?: string, testContext?: yup.TestContext<OneFormEligibilityYupContext>) => {
        if (testContext) {
          const authCodeExpirationEnabled =
            !!testContext.options?.context?.authCodeExpirationEnabled;

          const authCodeExpirationMaxDays = testContext.options.context?.authCodeExpirationMaxDays;

          const error = () =>
            testContext.createError({
              message: `Please select a valid date in the future, within the next ${authCodeExpirationMaxDays} days.`,
            });

          if (authCodeExpirationEnabled && authCodeExpirationMaxDays && !userInput) return error();
          if (authCodeExpirationEnabled && authCodeExpirationMaxDays && !!userInput) {
            const userInputDate = moment(userInput, 'MM/DD/YYYY', true);
            if (!userInputDate.isValid()) return error();
            return userInputDate.isBetween(
              moment(),
              moment().add(authCodeExpirationMaxDays, 'days'),
              'days',
              '(]'
            )
              ? true
              : error();
          }
        }
        return true;
      }
    ),
  // required for certain file-based, manual, and trizetto flows. keyword is constructed based on selected or default service type for these flows
  serviceType: yup
    .object({
      value: (
        yup.string() as yup.StringSchema<
          ServiceType | undefined,
          Record<string, any>,
          ServiceType | undefined
        >
      )
        .oneOf<ServiceType>(['psychiatry', 'psychotherapy', 'therapyCouples'])
        .default(null)
        .required(),
      label: yup.string().default(null).required(),
    })
    .default(null)
    .nullable()
    .when(
      '$hasServiceType',
      (hasServiceType: boolean, schema: yup.SchemaOf<OneFormEligibilityFields['serviceType']>) =>
        hasServiceType ? schema.required('Please select a service.') : schema
    )
    .test(
      'is-under-age-psychiatry',
      PSYCH_TOO_YOUNG_ERROR,
      (
        userInput: OneFormEligibilityFields['serviceType'],
        testContext: yup.TestContext<OneFormEligibilityYupContext>
      ) =>
        userInput?.value === 'psychiatry' &&
        moment(testContext.parent.dateOfBirth, 'MM/DD/YYYY', true).isValid()
          ? !isUnder18(testContext.parent.dateOfBirth)
          : true
    ),
  verificationCheckbox: yup
    .bool()
    .when(
      '$optionalTruthCheckbox',
      (optionalTruthCheckbox: boolean | undefined, schema: yup.BooleanSchema) =>
        optionalTruthCheckbox ? schema.nullable().optional() : schema.oneOf([true]).required()
    ),
  attendedSchool: yup
    .object({
      // Since this is a `isCreatable` select, value could be nullable, string, or number
      value: yup.mixed().default(null).required(),
      // Adding default(null) removes the `| undefined` from the final schema type
      label: yup.string().default(null).required(),
    })
    .default(null)
    .nullable()
    .when('$collectTeenSchool', {
      is: true,
      then: (schema) => schema.optional(),
      otherwise: (schema) => schema.default(null).nullable(),
    }),
  marketingConsent: yup
    .bool()
    .when(
      '$showMarketingConsent',
      (showMarketingConsent: boolean | undefined, schema: yup.BooleanSchema) =>
        showMarketingConsent
          ? schema.oneOf([true, false]).default(false).required()
          : schema.nullable().optional()
    ),
  socialSecurity: yup
    .string()
    .test(
      'is-valid-social-security-number',
      (userInput?: string, testContext?: yup.TestContext<OneFormEligibilityYupContext>) => {
        if (testContext) {
          const socialSecurityEnabled = !!testContext.options.context?.socialSecurityNumberEnabled;
          if (!socialSecurityEnabled) return true;

          let errorMessage: string | undefined;

          const validRegexp = /^[0-9]{4}$/;

          if (!userInput || userInput.length === 0) {
            errorMessage = 'Last 4 digits of Social Security Number is required';
          } else if (!validRegexp.test(userInput)) {
            errorMessage = 'Must be 4 digits';
          }

          if (errorMessage) {
            const error = () =>
              testContext.createError({
                message: errorMessage,
              });

            return error();
          }
          return true;
        }
        return true;
      }
    ),
  secondaryMemberID: yup
    .string()
    .test(
      'is-valid-secondary-member-id',
      'Required if entering Supplement insurance',
      (userInput?: string, testContext?: yup.TestContext<OneFormEligibilityYupContext>) => {
        if (testContext) {
          return !!userInput || !testContext.parent.secondaryPayerID;
        }
        return true;
      }
    ),
  secondaryPayerID: yup
    .string()
    .test(
      'is-valid-secondary-payer-id',
      'Required if entering Supplement Insurance Member/Subscriber ID',
      (userInput?: string, testContext?: yup.TestContext<OneFormEligibilityYupContext>) => {
        if (testContext) {
          return !!userInput || !testContext.parent.secondaryMemberID;
        }
        return true;
      }
    ),
});

interface GetFormValuesFromSessionStorageParams {
  finalFlowConfig: FlowConfig;
  finalStepConfig: OneFormEligibilityStep;
}

interface ShouldGetFormValueParams extends GetFormValuesFromSessionStorageParams {
  fieldName: OneFormEligibilityFieldNames;
}

// Check if fieldName is relevant in the current flow. Prevents sending unwanted values to the server (such as 'memberID' in EAP flows)
const shouldGetFormValue = ({
  finalFlowConfig,
  finalStepConfig,
  fieldName,
}: ShouldGetFormValueParams) => {
  const bhOnlyFieldNames: OneFormEligibilityFieldNames[] = [
    OneFormEligibilityFieldNames.memberID,
    OneFormEligibilityFieldNames.groupID,
  ];
  const hasAuthCodeOnlyFieldNames: OneFormEligibilityFieldNames[] = [
    OneFormEligibilityFieldNames.authorizationCode,
    OneFormEligibilityFieldNames.authorizationCodeExpiration,
  ];
  const secondaryInsuranceFieldNames: OneFormEligibilityFieldNames[] = [
    OneFormEligibilityFieldNames.secondaryMemberID,
    OneFormEligibilityFieldNames.secondaryPayerID,
  ];

  const { eligibilityType, secondaryInsurance } = finalFlowConfig;

  const { authCodeLabel: hasAuthCode, hasNumberOfSessions, hasSocialSecurity } = finalStepConfig;

  if (hasAuthCodeOnlyFieldNames.includes(fieldName)) {
    return hasAuthCode;
  }
  if (fieldName === OneFormEligibilityFieldNames.numberOfSessions) {
    return hasNumberOfSessions;
  }
  if (secondaryInsuranceFieldNames.includes(fieldName)) {
    return !!secondaryInsurance?.enabled;
  }
  if (fieldName === OneFormEligibilityFieldNames.socialSecurity) {
    return hasSocialSecurity;
  }
  if (
    eligibilityType === EligibilityType.organization ||
    eligibilityType === EligibilityType.organizationWithAddress
  ) {
    return !bhOnlyFieldNames.includes(fieldName);
  }
  return true;
};

export const getFormValuesFromSessionStorage = ({
  finalFlowConfig,
  finalStepConfig,
}: Partial<GetFormValuesFromSessionStorageParams>) => {
  const finalObj: Partial<OneFormEligibilityFields> = {};
  const basicInformationData = sessionStorage.getItem('TSQM_BasicInformation');
  const parsedBasicInformationData = basicInformationData && JSON.parse(basicInformationData);
  const memberDetailsSessionData = sessionStorage.getItem(SESSION_STORAGE_MEMBER_DETAILS_KEY);
  const parsedMemberDetailsSessionData =
    memberDetailsSessionData && JSON.parse(memberDetailsSessionData);
  const combinedSessionData = {
    ...parsedMemberDetailsSessionData,
    ...parsedBasicInformationData,
  };

  Object.keys(combinedSessionData).forEach((key) => {
    const fieldName = sessionStorageToFieldNameDict[key];
    // only restore from session storage if meaningful values are stored in the session, otherwise should be set based on defaultValues
    if (fieldName && combinedSessionData[key]) {
      if (fieldName === 'dateOfBirth') {
        // some forms set the session key for date of birth in YYYY-MM-DD format while this one does it MM/DD/YYYY
        if (moment(combinedSessionData[key], 'YYYY-MM-DD', true).isValid()) {
          finalObj[fieldName] = moment(combinedSessionData[key], 'YYYY-MM-DD', true).format(
            'MM/DD/YYYY'
          );
        } else if (moment(combinedSessionData[key], 'MM/DD/YYYY', true).isValid()) {
          finalObj[fieldName] = combinedSessionData[key];
        }
      } else if (fieldName === 'state') {
        finalObj.clientState = combinedSessionData[key];
      } else if (
        finalFlowConfig &&
        finalStepConfig &&
        shouldGetFormValue({ finalFlowConfig, finalStepConfig, fieldName })
      ) {
        finalObj[fieldName] = combinedSessionData[key];
      }
    }
  });
  return finalObj;
};

export const setSessionMemberMemberDetails = (
  formValues: OneFormEligibilityFields,
  displayEmployeeInfo: boolean
) => {
  const {
    emailDisabled,
    preRegisterBookingID,
    insurancePayerID,
    bookedTherapistName,
    bookedTherapistLicense,
  } = getSessionStorageValuesFromJson(SESSION_STORAGE_MEMBER_DETAILS_KEY, [
    'emailDisabled',
    'preRegisterBookingID',
    'insurancePayerID',
    'bookedTherapistName',
    'bookedTherapistLicense',
  ]);

  sessionStorage.setItem(
    SESSION_STORAGE_MEMBER_DETAILS_KEY,
    JSON.stringify({
      email: formValues.email,
      emailDisabled,
      preRegisterBookingID,
      insurancePayerID,
      bookedTherapistName,
      bookedTherapistLicense,
      employeeId: formValues.employeeID,
      whoAreYou: formValues.employeeRelation,
      serviceType: formValues.serviceType,
      referralSource: formValues.heardAbout,
      memberId: formValues.memberID,
      groupId: formValues.groupID,
      authCode: formValues.authorizationCode,
      keyword: formValues.organizationName,
      attendedSchool: formValues.attendedSchool,
      ...(displayEmployeeInfo && {
        employeeFirstName: formValues.employeeFirstName,
        employeeLastName: formValues.employeeLastName,
        employeeSameAddressCheckbox: formValues.employeeSameAddressCheckbox,
        employeeAddressLine1: formValues.employeeSameAddressCheckbox
          ? formValues.addressLine1
          : formValues.employeeAddressLine1,
        employeeAddressLine2: formValues.employeeSameAddressCheckbox
          ? formValues.addressLine2
          : formValues.employeeAddressLine2,
        employeeCity: formValues.employeeSameAddressCheckbox
          ? formValues.city
          : formValues.employeeCity,
        employeeState: formValues.employeeSameAddressCheckbox
          ? formValues.clientState
          : formValues.employeeState,
        employeeZipcode: formValues.employeeSameAddressCheckbox
          ? formValues.zipcode
          : formValues.employeeZipcode,
        employeeCountry: formValues.employeeSameAddressCheckbox
          ? formValues.country
          : formValues.employeeCountry,
      }),
    })
  );
};

export const setSessionBasicInformation = (
  formValues: OneFormEligibilityFields,
  displayEmployeeInfo: boolean
) => {
  sessionStorage.setItem(
    'TSQM_BasicInformation',
    JSON.stringify({
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      dateOfBirth: formValues.dateOfBirth,
      phone: formValues.phone,
      address: formValues.addressLine1,
      unitAddress: formValues.addressLine2,
      city: formValues.city,
      state: formValues.clientState,
      zipCode: formValues.zipcode,
      country: formValues.country,
      ...(displayEmployeeInfo && {
        employeeFirstName: formValues.employeeFirstName,
        employeeLastName: formValues.employeeLastName,
        employeeSameAddressCheckbox: formValues.employeeSameAddressCheckbox,
        employeeAddressLine1: formValues.employeeSameAddressCheckbox
          ? formValues.addressLine1
          : formValues.employeeAddressLine1,
        employeeAddressLine2: formValues.employeeSameAddressCheckbox
          ? formValues.addressLine2
          : formValues.employeeAddressLine2,
        employeeCity: formValues.employeeSameAddressCheckbox
          ? formValues.city
          : formValues.employeeCity,
        employeeState: formValues.employeeSameAddressCheckbox
          ? formValues.clientState
          : formValues.employeeState,
        employeeZipcode: formValues.employeeSameAddressCheckbox
          ? formValues.zipcode
          : formValues.employeeZipcode,
        employeeCountry: formValues.employeeSameAddressCheckbox
          ? formValues.country
          : formValues.employeeCountry,
      }),
    })
  );
};

export const countriesDropdownOptions = [{ label: 'United States', value: US }, ...countries];
export const MAX_RETRIES = 2;
