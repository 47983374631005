import {
  Big,
  Button,
  ExtraHuge,
  Large,
  Link,
  View,
  useWindowWidthState,
} from '@talkspace/react-toolkit';

import { isLDExperimentTreatment } from 'launchDarkly/util';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { FLOW_147_TEENSPACE_OPTIMIZED, TeenConsentHeadsUpStep, UpdateStep } from '../../../Flows';
import styled from '@/core/styled';
import TeenConsentFormIllustration from '../../assets/TeenConsentFormIllustration';

const Styled = {
  Container: styled(View)(({ isMobile }: { isMobile: boolean }) => {
    return {
      maxWidth: 350,
      height: isMobile ? 'auto' : undefined,
      justifyContent: 'space-between',
    };
  }),
  Title: styled(ExtraHuge)({ margin: '32px 0 12px' }),
  ContinueButton: styled(Button)({
    marginTop: 40,
    width: '100%',
  }),
  Link: styled(Link)({
    margin: '8px 0 0',
    padding: '14px 0',
  }),
  LinkText: styled(Big)(({ theme: { colors } }) => {
    return {
      color: colors.accessibilityGreenDark,
    };
  }),
};

interface Props {
  step: TeenConsentHeadsUpStep;
  updateStep: UpdateStep;
  flowId: number;
}
const TeenConsentHeadsUp = ({ step, updateStep, flowId }: Props) => {
  const { isMobile } = useWindowWidthState();
  const flags = useFlags();
  const isTeenspaceTreatment = isLDExperimentTreatment(flags, 'teenspaceCrOptimization', {
    flowID: flowId,
    teenspaceFlowID: FLOW_147_TEENSPACE_OPTIMIZED,
  });
  return (
    <Styled.Container isMobile={isMobile}>
      <View align="center">
        {!isTeenspaceTreatment && <TeenConsentFormIllustration />}
        <Styled.Title style={isTeenspaceTreatment ? { margin: '0 0 12px' } : {}}>
          Heads up! You may need consent to start.
        </Styled.Title>
        <Large
          variant={isTeenspaceTreatment ? 'largeGrey950' : undefined}
          style={isTeenspaceTreatment ? { marginBottom: 32 } : {}}
        >
          Your Talkspace therapy will be confidential, but since you’re under 18 we need to ask you
          for parent or guardian consent unless you fall under one of the consent exceptions
        </Large>
        {isTeenspaceTreatment && <TeenConsentFormIllustration />}
      </View>
      <View align="center">
        <Styled.ContinueButton
          text="Continue"
          onPress={() => updateStep(step.buttonTarget)}
          dataQa="continueButton"
        />
        <Styled.Link
          dataQa="seeConsentExceptionsLink"
          stopPropagation
          target="_blank"
          href="https://help.talkspace.com/hc/en-us/articles/18167691776027"
          roundedFocusStyle
        >
          <Styled.LinkText inline>See consent exceptions</Styled.LinkText>
        </Styled.Link>
      </View>
    </Styled.Container>
  );
};
export default TeenConsentHeadsUp;
