/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import { SessionModality } from 'ts-frontend/types';
import { UseAvailableModalitiesOptions } from '../types';

export const useAvailableModalities = ({
  allowedModalities,
}: UseAvailableModalitiesOptions): Record<SessionModality, boolean> =>
  useMemo(() => {
    const availableModalities = allowedModalities
      ? allowedModalities.reduce((acc, currModality) => {
          return { ...acc, [currModality.name]: true };
        }, {} as Record<SessionModality, boolean>)
      : { video: false, chat: false, audio: false, messaging: false };

    return availableModalities;
  }, [allowedModalities]);
