import { useCallback } from 'react';
import SurveyWizard, { SurveyWizardProps } from 'surveyWizard';
import { AppSource } from 'ts-frontend/types';
import { View } from '@talkspace/react-toolkit';
import { MemoryRouter } from '@/core/routerLib';
import { FlowConfig, SurveyWizardStep, UpdateStep } from '@/Flows/types';
import { trackEvent } from '@/utils/analytics/events';

interface SurveyWizardWrapperProps {
  step: SurveyWizardStep;
  flowConfig: FlowConfig;
  updateStep: UpdateStep;
}

const NAV_BAR_HEIGHT = 80;
const FOOTER_HEIGHT = 40;
const FOOTER_MARGIN = 50 + 20; // Margin top and bottom

const SurveyWizardWrapper = ({ step, flowConfig, updateStep }: SurveyWizardWrapperProps) => {
  const handleCompletedSurvey = useCallback(() => {
    updateStep(step.buttonTarget);
  }, [step.buttonTarget, updateStep]);
  const onSurveyProgress = useCallback<NonNullable<SurveyWizardProps['onSurveyProgress']>>(
    (question, response, stepNumber, isCompleted) => {
      if (isCompleted) {
        trackEvent('Submit Assessment', {
          'Funnel Name': 'Assessments',
        });
      } else {
        trackEvent('Assessments Wizard Questionnaire', {
          'Funnel Name': 'Assessments',
          Question: question,
          Step: stepNumber,
        });
      }
    },
    []
  );
  if (!flowConfig.surveyID) return null;

  return (
    <MemoryRouter initialEntries={['/survey']}>
      <View
        style={{
          alignContent: 'center',
          height: `calc(100vh - ${NAV_BAR_HEIGHT + FOOTER_MARGIN + FOOTER_HEIGHT}px)`,
          paddingTop: 48,
        }}
      >
        <SurveyWizard
          handleSurveysError={handleCompletedSurvey}
          source={AppSource.qm}
          closeWizardAction={() => updateStep(step.buttonTarget)}
          surveyID={flowConfig.surveyID}
          onSurveyProgress={onSurveyProgress}
        />
      </View>
    </MemoryRouter>
  );
};

export default SurveyWizardWrapper;
