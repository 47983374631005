import {
  View,
  ExtraHuge,
  Large,
  useUniqueID,
  CouplesCircle,
  RxCircle,
  ChatCircle,
  useEmotionTheme,
  useContainerFocus,
  Spinner,
} from '@talkspace/react-toolkit';
import { useCallback, VFC } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import compose from 'lodash/fp/compose';
import styled from '@/core/styled';
import { redirectToClientWebSignup } from '@/Helpers/registrationHelpers';
import ChatWithConsultantButton from '@/Components/ChatWithConsultantButton';
import TherapyTypeCard from '@/Components/TherapyTypeCard/TherapyTypeCard';
import { useHandleServiceSelection, useRedirector } from './hooks';
import { ServiceSelectionProps } from './types';
import { getQMFunnelName } from '../../../Helpers/flowsHelper';
import { trackEvent } from '@/utils/analytics/events';
import { useHomePageState } from '@/Components/HomePage';

const Styled = {
  Container: styled(View)({ '&:focus': { outline: 'none' } }),
  CardWrapper: styled(View)({
    marginTop: 10,
  }),
  CardContainer: styled(View)<{ lastElement: boolean }>(({ lastElement = false }) => {
    return {
      marginTop: 20,
      marginBottom: lastElement ? 50 : 0,
    };
  }),
  TextWrapper: styled(View)({
    marginTop: 10,
  }),
  ConsultantWrapper: styled(View)({
    marginTop: 20,
    marginBottom: 50,
    display: 'block',
  }),
};

const ServiceSelection: VFC<ServiceSelectionProps> = ({
  step,
  hideExitToCT,
  isCreateRoomFlow,
  isReactivationFlow,
  isUpdateCoverageFlow,
  isMBHIneligibilityFlow,
  updateAndSave,
  serviceType,
  updateStep,
}) => {
  const { nycTeensQuickmatch, unifiedFlowForDirectB2B } = useFlags();
  const { partnerFlowID, isHideTeenServiceType } = useHomePageState();
  const isUnifiedFlowActiveAndTreatment =
    unifiedFlowForDirectB2B.experimentActive &&
    unifiedFlowForDirectB2B.variant === 'treatment' &&
    partnerFlowID;

  const handleServiceSelection = useHandleServiceSelection({
    isCreateRoomFlow,
    isReactivationFlow,
    isUpdateCoverageFlow,
    isMBHIneligibilityFlow,
    updateAndSave,
  });

  const { containerRef } = useContainerFocus();
  const heading1ID = useUniqueID('heading1ID');
  const {
    colors: {
      aquaSqueeze,
      blueStone,
      bizarre,
      redDamask,
      catskillWhite,
      matisse,
      green,
      spindle,
      permaVictoria,
      permaOrinoco,
      permaAtlantisNew,
      permaFlamePea,
      permaBlueStoneNew,
    },
  } = useEmotionTheme();

  const handleChatWithConsultant = useCallback(() => redirectToClientWebSignup(), []);

  const { isLoading } = useRedirector({
    handleServiceSelection,
    serviceType,
  });

  const handleTeensClick = useCallback(() => {
    if (nycTeensQuickmatch && !isUnifiedFlowActiveAndTreatment && step.buttonTarget) {
      updateStep(step.buttonTarget);
      trackEvent('Answer Questionnaire', {
        Question: 'selectService',
        Response: 'therapyTeen',
        // For backwards compatibility tracking
        Flow: 'Dispatcher',
        'Funnel Name': getQMFunnelName({
          isCreateRoomFlow,
          isReactivationFlow,
          isUpdateCoverageFlow,
          isMBHIneligibilityFlow,
        }),
      });
    } else {
      handleServiceSelection('therapyTeen');
    }
  }, [
    handleServiceSelection,
    isCreateRoomFlow,
    isMBHIneligibilityFlow,
    isReactivationFlow,
    isUpdateCoverageFlow,
    nycTeensQuickmatch,
    step.buttonTarget,
    updateStep,
    isUnifiedFlowActiveAndTreatment,
  ]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Styled.Container
      align="center"
      style={{ maxWidth: 440, alignSelf: 'center' }}
      ref={containerRef}
      tabIndex={-1}
      aria-labelledby={heading1ID}
    >
      <ExtraHuge id={heading1ID} as="h1">
        What type of service are you looking for?
      </ExtraHuge>
      <Styled.TextWrapper>
        <Large variant="largeDarkGrey" as="h2">
          {nycTeensQuickmatch
            ? 'Talkspace offers comprehensive options to meet all your mental health needs'
            : 'Talkspace offers comprehensive treatment plans to meet all your mental health needs.'}
        </Large>
      </Styled.TextWrapper>
      <Styled.CardContainer lastElement={hideExitToCT}>
        <Styled.CardWrapper>
          <TherapyTypeCard
            title={nycTeensQuickmatch ? 'Therapy' : 'Online Therapy'}
            description={
              nycTeensQuickmatch
                ? 'Individualized support from a licensed therapist for ages 18+'
                : 'On-going support from a licensed therapist.'
            }
            icon={
              <ChatCircle color={nycTeensQuickmatch ? permaBlueStoneNew : blueStone} width={47} />
            }
            bgColor={aquaSqueeze}
            focusColor={green}
            onClick={() => handleServiceSelection('psychotherapy')}
            dataQa="onlineTherapy"
          />
        </Styled.CardWrapper>
        {nycTeensQuickmatch ? (
          <>
            {!isHideTeenServiceType && (
              <Styled.CardWrapper>
                <TherapyTypeCard
                  title="Teen Therapy"
                  description="Specialized support designed for youth ages 13-17"
                  icon={<ChatCircle color={permaAtlantisNew} width={47} />}
                  bgColor={permaOrinoco}
                  focusColor={permaAtlantisNew}
                  onClick={handleTeensClick}
                  dataQa="teenTherapy"
                />
              </Styled.CardWrapper>
            )}
            <Styled.CardWrapper>
              <TherapyTypeCard
                title="Couples Therapy"
                description="Relationship support to improve your connection with your partner"
                icon={<CouplesCircle color={matisse} width={47} />}
                bgColor={catskillWhite}
                focusColor={matisse}
                onClick={() => handleServiceSelection('therapyCouples')}
                dataQa="couplesTherapy"
              />
            </Styled.CardWrapper>
          </>
        ) : (
          <>
            <Styled.CardWrapper>
              <TherapyTypeCard
                title="Couples Therapy"
                description="Relationship-centered therapy that connects you and your partner."
                icon={<CouplesCircle color={matisse} width={47} />}
                bgColor={catskillWhite}
                focusColor={matisse}
                onClick={() => handleServiceSelection('therapyCouples')}
                dataQa="couplesTherapy"
              />
            </Styled.CardWrapper>
            {!isHideTeenServiceType && (
              <Styled.CardWrapper>
                <TherapyTypeCard
                  title="Teen Therapy"
                  description="Specialized therapy for ages 13-17"
                  icon={<ChatCircle color={permaVictoria} width={47} />}
                  bgColor={spindle}
                  focusColor={permaVictoria}
                  onClick={() => handleServiceSelection('therapyTeen')}
                  dataQa="teenTherapy"
                />
              </Styled.CardWrapper>
            )}
          </>
        )}
        <Styled.CardWrapper>
          <TherapyTypeCard
            title="Psychiatry"
            description={
              nycTeensQuickmatch
                ? 'Psychiatric evaluations, diagnoses, and medication'
                : 'Evaluations and psychiatry medication management.'
            }
            icon={<RxCircle color={nycTeensQuickmatch ? permaFlamePea : redDamask} width={47} />}
            bgColor={bizarre}
            focusColor={redDamask}
            onClick={() => handleServiceSelection('psychiatry')}
            dataQa="psychiatry"
          />
        </Styled.CardWrapper>
      </Styled.CardContainer>
      {!hideExitToCT && (
        <Styled.ConsultantWrapper>
          <Large inline>Not quite sure? </Large>
          <ChatWithConsultantButton
            roundedFocusStyle
            primaryColor={green}
            onPress={handleChatWithConsultant}
            dataQa="chatWithConsultant"
          />
        </Styled.ConsultantWrapper>
      )}
    </Styled.Container>
  );
};

/**
 * Renders a spinner until we're certain that LaunchDarkly flags have been loaded.
 */
const withFlags = (Component) => (props) => {
  const flags = useFlags();
  if (!Object.keys(flags).length) {
    return <Spinner isLoading />;
  }
  return <Component {...props} />;
};

const Composed = compose(withFlags)(ServiceSelection);

export default Composed;
