import { Button, ExtraHuge, Large, View, useWindowWidthState } from '@talkspace/react-toolkit';
import sessionStorage from '@/core/storage/sessionStorage';
import styled from '@/core/styled';

import { TeenLetsLearnAboutYouStep, UpdateStep } from '../../../Flows';
import TeenThoughtsIllustration from '../../assets/TeenThoughtsIllustration';

const Styled = {
  Container: styled(View)(({ isMobile }: { isMobile: boolean }) => {
    return {
      maxWidth: 350,
      minHeight: isMobile ? 'calc(100vh - 280px)' : undefined,
      justifyContent: 'space-between',
    };
  }),
  Title: styled(ExtraHuge)({ margin: '32px 0 12px' }),
  ContinueButton: styled(Button)({
    marginBottom: 16,
    marginTop: 40,
    width: '100%',
  }),
};

interface Props {
  step: TeenLetsLearnAboutYouStep;
  updateStep: UpdateStep;
}
const TeenLetsLearnAboutYou = ({ step, updateStep }: Props) => {
  const { isMobile } = useWindowWidthState();
  const { firstName } = JSON.parse(sessionStorage.getItem('TSQM_BasicInformation') || '{}');
  return (
    <Styled.Container isMobile={isMobile}>
      <View align="center">
        <TeenThoughtsIllustration />
        <Styled.Title>
          Now let’s learn more about you{firstName ? `, ${firstName}` : ''}
        </Styled.Title>
        <Large variant="largeGrey950">
          The more we get to know you, the better we can match you with the right therapist. Your
          answers are confidential.
        </Large>
      </View>
      <Styled.ContinueButton
        text="Continue"
        onPress={() => updateStep(step.buttonTarget)}
        dataQa="continueButton"
      />
    </Styled.Container>
  );
};
export default TeenLetsLearnAboutYou;
