import { QMFlow } from './types';
import { findAndModifyStep, initFlowConfig } from './utils';
import flow48 from './Flow48';

/** Consumer Trial like flow 48 */
export const FLOW_46_CONSUMER_TRAIL = 46;

const flow: QMFlow = {
  ...flow48,
  steps: [
    ...findAndModifyStep(flow48.steps, ['category', 'chooseAndPayClientWeb'], {
      updatedStep: {
        offerID: 72,
        couponInputMode: 'hidden',
      },
    }),
  ],
  flowId: FLOW_46_CONSUMER_TRAIL,
  flowConfig: {
    ...initFlowConfig,
    ...flow48.flowConfig,
    version: 2,
    isB2B: false,
    isTeen: true,
    isTrial: true,
    funnelVariation: 'chatbot_trial',
    disabled: true,
  },
};

export default flow;
