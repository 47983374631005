import { FunctionComponent, useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  View,
  SelectRounded,
  Button as AccessibleButton,
  OptionType,
  useEmotionTheme,
  useUniqueID,
  Label,
} from '@talkspace/react-toolkit';
import { TranslationNamespaceQuickmatchFlow } from '@talkspace/i18n/types';
import useStringTranslation from '@talkspace/i18n/hooks/useStringTranslation';
import styled from '../../core/styled';
import {
  UpdateStepObj,
  ExternalTarget,
  InternalTarget,
  DropdownOption,
  DropdownStep,
} from '../../Flows/types';

const StyledButton = styled(AccessibleButton)(({ theme: { colors } }) => {
  return {
    transition: 'all 200ms ease-in-out',
    '&:not([disabled]):hover': {
      backgroundColor: colors.permaTropicalRainForest,
    },
  };
});

const StyledAccessibleButton = styled(AccessibleButton)(({ theme: { colors } }) => {
  return {
    color: colors.green,
    backgroundColor: 'transparent',
    border: 'none',
    width: 'auto',
    alignSelf: 'center',
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: colors.permaTropicalRainForest,
      color: colors.permaTropicalRainForest,
    },
  };
});

interface DropdownProps {
  step: DropdownStep;
  options: Array<OptionType>;
  value?: Array<OptionType>;
  shouldResetComponent?: boolean;
  placeholder?: string;
  updateStep: (target: InternalTarget | ExternalTarget, updateStepObj: UpdateStepObj) => void;
  resetCallback?: () => void;
  onChange?: (option: DropdownOption) => void;
  labelText?: string;
  i18nNamespace?: TranslationNamespaceQuickmatchFlow;
}

const Dropdown: FunctionComponent<DropdownProps> = ({
  step,
  value,
  options,
  placeholder,
  updateStep,
  shouldResetComponent,
  resetCallback,
  onChange,
  labelText,
  i18nNamespace,
}) => {
  const { colors } = useEmotionTheme();
  const [searchQuery, setSearchQuery] = useState<OptionType | null>(null);
  const inputID = useUniqueID('inputID');

  useEffect(() => {
    const valueIsValid =
      value &&
      value.length &&
      typeof value[0] !== 'undefined' &&
      !!value[0] &&
      !!options.filter((option) => option.label === value?.[0].label).length;
    if (valueIsValid) {
      setSearchQuery(value ? value[0] : null);
    } else {
      setSearchQuery(null);
    }
    if (shouldResetComponent) {
      setSearchQuery(null);
      if (resetCallback) {
        resetCallback();
      }
    }
  }, [value, shouldResetComponent, resetCallback, options]);

  const logChange = (val) => {
    setSearchQuery(val);
    if (onChange) {
      onChange(val);
    }
  };

  const extraCTA = 'extraCTA' in step ? step.extraCTA : null;
  const doNotAllowSearch = 'doNotAllowSearch' in step ? step.doNotAllowSearch : null;
  const buttonText = useStringTranslation(
    step.buttonText || 'Submit',
    i18nNamespace,
    `step${step.id}.buttonText`
  );
  return (
    <View>
      {!!labelText && <Label htmlFor={inputID}>{labelText}</Label>}
      <SelectRounded
        inputId={inputID}
        isInputReadOnly={doNotAllowSearch}
        value={searchQuery}
        options={options}
        onChange={logChange}
        placeholder={placeholder}
        wrapperStyle={{ marginTop: 0 }}
      />
      <View align="center">
        <StyledButton
          onClick={() => {
            updateStep(step.buttonTarget, {
              payfirst_step_id: step.analyticsId,
              payfirst_step_prompt: step.heading2 || step.prompt,
              response_category_id: step.response_category_id,
              response_prompt: searchQuery?.label,
              response_value: searchQuery?.value || undefined,
            });
          }}
          roundedFocusStyle
          primaryColor={colors.green}
          style={{ width: '100%', fontWeight: 700 }}
          disabled={!searchQuery}
          dataQa={`${step.category || ''}ContinueButton`}
        >
          {buttonText}
        </StyledButton>
      </View>
      {extraCTA && (
        <View>
          <StyledAccessibleButton
            isSecondary
            roundedFocusStyle
            primaryColor={colors.green}
            style={{ fontWeight: 700 }}
            onClick={() => {
              updateStep(step.buttonTarget, {
                payfirst_step_id: step.analyticsId,
                payfirst_step_prompt: step.heading2 || step.prompt,
                response_category_id: step.response_category_id,
                response_prompt: extraCTA,
                response_value: undefined,
              });
            }}
          >
            {extraCTA}
          </StyledAccessibleButton>
        </View>
      )}
    </View>
  );
};

export default Dropdown;
