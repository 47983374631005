/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import { InsuranceEligibilityPayer } from 'ts-frontend/types';
import { OUT_OF_POCKET_OPTION } from 'ts-frontend/helpers';
import { trackEvent } from '@/utils/analytics/events';
import { getInsuranceVerificationFlowID } from '@/Helpers/insurancePayersHelper';
import apiWrapper from '@/core/api/apiWrapper';
import { apiHelperV4 } from '@/Helpers/apiHelper';
import { FLOW_132_CONSUMER_THERAPY } from '@/Flows';
import { UseOnContinuePressOptions } from '../types';
import { setVWOInsuranceCoupon } from '../../../utils/vwoHelper';

export const useOnContinuePress = (options: UseOnContinuePressOptions) =>
  useCallback(async () => {
    const {
      selectedPayer,
      shortB2BForkForm,
      setShowDropdownError,
      setCustomAttribute,
      setIsLoading,
      service,
      flowId,
      experimentsVariants,
      step,
      clientCountry,
      clientState,
      updateStep,
    } = options;

    setShowDropdownError(false);
    if (!selectedPayer) {
      if (shortB2BForkForm) {
        setShowDropdownError(true);
      }
      return;
    }

    const { id, label, value, keyword, partnerID, flowID, isOutOfNetwork, serviceTypes } =
      selectedPayer;
    let forkInsuranceFlow: 'out-of-pocket' | 'in-network' | 'out-of-network';
    let shouldCollectReferralOnSignUp = false;
    if (id === OUT_OF_POCKET_OPTION.id) {
      forkInsuranceFlow = 'out-of-pocket';
      shouldCollectReferralOnSignUp = true;
    } else if (keyword) {
      forkInsuranceFlow = 'in-network';
      shouldCollectReferralOnSignUp = false;
    } else {
      forkInsuranceFlow = 'out-of-network';
      shouldCollectReferralOnSignUp = true;
    }

    setCustomAttribute({
      insuranceProvider: forkInsuranceFlow,
    }).then((newFlags) => {
      if (newFlags?.oonDiscount.experimentActive) {
        const { oonDiscount } = newFlags;
        trackEvent('TS Experiment Session', {
          experimentName: oonDiscount.experimentName,
          variantName: oonDiscount.variant,
        });
        if (oonDiscount.variant !== 'control' && oonDiscount.coupon && oonDiscount.couponValue) {
          setVWOInsuranceCoupon(oonDiscount.coupon, oonDiscount.couponValue);
        }
      }
    });

    trackEvent('Select Insurance Provider', {
      'Insurance Provider': selectedPayer.label,
      selection: forkInsuranceFlow,
    });

    const insurancePayer: InsuranceEligibilityPayer = {
      id,
      label,
      value,
      keyword,
      partnerID,
      flowID,
      isOutOfNetwork,
      serviceTypes,
    };

    let partnerFlowID: number | null = null;
    let b2bOutOfNetwork: boolean | undefined = false;
    setIsLoading(true);
    if (id) {
      // Having a keyword makes an API call
      ({ flowID: partnerFlowID, b2bOutOfNetwork } = await getInsuranceVerificationFlowID(
        insurancePayer,
        service
      ));
    }

    if (!partnerFlowID && !b2bOutOfNetwork) {
      if (
        flowId === FLOW_132_CONSUMER_THERAPY &&
        forkInsuranceFlow === 'out-of-pocket' &&
        experimentsVariants?.outOfPocketSplit === 'ct' &&
        step.ctSplitTarget
      ) {
        // for this ct out-of-pocket split, check ct capacity
        try {
          const response = await apiWrapper.post(
            `${apiHelperV4()}dispatcher/ct-registration-available`,
            {
              country: clientCountry,
              state: clientState,
            },
            { timeout: 5000 }
          );
          if (response === true) {
            await updateStep(step.ctSplitTarget, {
              b2bForkResult: 'b2c',
              insurancePayer,
              insuranceEligibility: undefined,
              partnerFlowID: undefined,
              isB2BOutOfNetwork: undefined,
              isCouldNotBeVerified: undefined,
              collectReferralSourceOnSignUp: shouldCollectReferralOnSignUp,
              manualFlowID: undefined,
              quickEligibilityInfo: undefined,
            });
            return;
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(
            'Failed to get CT availability information - Defaulting to matches',
            e.message
          );
        }
      }
      await updateStep(step.buttonTarget, {
        b2bForkResult: 'b2c',
        insurancePayer,
        insuranceEligibility: undefined,
        partnerFlowID: undefined,
        isB2BOutOfNetwork: undefined,
        isCouldNotBeVerified: undefined,
        collectReferralSourceOnSignUp: shouldCollectReferralOnSignUp,
        manualFlowID: undefined,
        quickEligibilityInfo: undefined,
      });
    } else {
      await updateStep(step.buttonTarget, {
        response_value: JSON.stringify(selectedPayer),
        b2bForkResult: 'b2b',
        insurancePayer,
        insuranceEligibility: undefined,
        isB2BOutOfNetwork: b2bOutOfNetwork,
        isCouldNotBeVerified: undefined,
        partnerFlowID: partnerFlowID || undefined,
        collectReferralSourceOnSignUp: shouldCollectReferralOnSignUp,
        manualFlowID: undefined,
        quickEligibilityInfo: undefined,
      });
    }
  }, [options]);
