import { Trans } from '@talkspace/i18n';
import {
  BaseButton,
  ExtraHuge,
  Large,
  Standard,
  Button,
  View,
  StuckAtHomeImagination,
} from '@talkspace/react-toolkit';
import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import useStringTranslation from '@talkspace/i18n/hooks/useStringTranslation';
import styled from '@/core/styled';

const BookLiveSessionButton = styled(BaseButton)(({ theme: { colors } }) => {
  return {
    color: colors.slateGrey,
    backgroundColor: colors.white,
    marginBottom: 80,
    marginTop: 20,
  };
});

const StartWithMessagingButton = styled(Button)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      marginTop: 50,
      fontWeight: 700,
      fontSize: 17,
      width: isMobile ? 335 : 430,
    };
  }
);

const ImageContainer = styled(View)(
  ({
    theme: {
      colors,
      window: { isMobile },
    },
  }) => {
    return {
      backgroundColor: colors.permaFeta,
      height: 200,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20 0',
      gap: 10,
      width: isMobile ? 335 : 430,
      borderRadius: 16,
    };
  }
);

const ContentWrapper = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      marginTop: 50,
      width: isMobile ? 335 : 430,
    };
  }
);

interface RecommendMessagingProps {
  onStartWithMessagingPress: () => void;
  onBookALiveSessionPress: () => void;
}

const RecommendMessaging = ({
  onStartWithMessagingPress,
  onBookALiveSessionPress,
}: RecommendMessagingProps) => {
  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');
  const startMessagingSessionButtonText = useStringTranslation(
    'Start with a messaging session',
    'quickmatch.common',
    'sessionModalityStepWeRecommend.startMessagingSessionButtonText'
  );
  return (
    <View flex={1} align="center" justify="center" style={{ marginTop: 67 }}>
      <ImageContainer>
        <StuckAtHomeImagination />
      </ImageContainer>
      <ContentWrapper>
        <ExtraHuge>
          <Trans t={tQuickmatchCommon} i18nKey="sessionModalityStepWeRecommend.titleText">
            We recommend Messaging therapy
          </Trans>
        </ExtraHuge>
        <Large variant="largeDarkGrey" style={{ marginTop: 12 }}>
          <Trans t={tQuickmatchCommon} i18nKey="sessionModalityStepWeRecommend.descriptionText">
            Due to increased demand for mental health services, the fastest way to make progress is
            with messaging therapy.
          </Trans>
        </Large>
      </ContentWrapper>
      <StartWithMessagingButton
        onPress={onStartWithMessagingPress}
        text={startMessagingSessionButtonText}
        dataQa="recommendMessagingStartWithMessagingButton"
      />
      <BookLiveSessionButton onPress={onBookALiveSessionPress}>
        <Standard
          variant="standardBoldDarkCyan"
          dataQa="recommendMessagingStartWithLiveSessionButton"
        >
          <Trans
            t={tQuickmatchCommon}
            i18nKey="sessionModalityStepWeRecommend.startLiveSessionButtonText"
          >
            Start with a live session
          </Trans>
        </Standard>
      </BookLiveSessionButton>
    </View>
  );
};

export default RecommendMessaging;
