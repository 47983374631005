import { useEffect, useRef, useState } from 'react';
import { useRouteMatch, useHistory, useLocation } from 'react-router';
import { getStepFromFlowIDByID } from '@/Flows';
import { useRunSkipQuestionActions } from './hooks';
import { HomePageState } from './types';

type RefSetter = (state: HomePageState) => void;

export type InitialQuestionSkipperProp = {
  questionSkipperStateRefCallback: RefSetter;
};

const InitialQuestionSkipper: React.VFC<{
  children(refSetter: RefSetter): JSX.Element;
}> = ({ children }) => {
  const { params } = useRouteMatch<{
    flowId: string;
    stepId: string;
  }>();
  const location = useLocation();

  const urlHashParams = new URLSearchParams(location.search.replace('#', '?'));
  const initialStepId = Number(urlHashParams.get('initialStepId')) || 1;
  const flowId = Number(params.flowId);
  const stepId = Number(params.stepId);
  const [ready, setReady] = useState(false);
  const history = useHistory();
  const step = getStepFromFlowIDByID(flowId, stepId);
  const homepageStateRef = useRef<HomePageState>({} as HomePageState);
  const runSkipQuestionActions = useRunSkipQuestionActions(homepageStateRef);

  const ref = useRef<{
    runSkipQuestionActions: ReturnType<typeof useRunSkipQuestionActions>;
    redirectedForFlow: number | null;
  }>({
    runSkipQuestionActions,
    redirectedForFlow: null,
  });
  useEffect(() => {
    const asyncEffect = async () => {
      const handleSkipQuestionActions = async () => {
        let skipToQuestionId;
        let currStep = step;
        let currStepId: number | undefined = stepId;
        do {
          // eslint-disable-next-line no-await-in-loop
          skipToQuestionId = await ref.current.runSkipQuestionActions(
            currStep?.skipQuestionActions,
            currStepId,
            null
          );
          if (skipToQuestionId === currStepId || !skipToQuestionId) break;
          if (!getStepFromFlowIDByID(flowId, skipToQuestionId)?.skipQuestionActions) break;
          currStep = getStepFromFlowIDByID(flowId, skipToQuestionId);
          currStepId = currStep?.id;
        } while (skipToQuestionId);
        return skipToQuestionId;
      };

      const checkAndRedirect = async () => {
        if (!step) {
          ref.current.redirectedForFlow = null;
          return;
        }
        if (flowId !== ref.current.redirectedForFlow) {
          ref.current.redirectedForFlow = null;
        }
        if (
          step.id !== initialStepId ||
          !step.skipQuestionActions ||
          ref.current.redirectedForFlow === flowId
        ) {
          setReady(true);
          return;
        }
        ref.current.redirectedForFlow = flowId;
        const skipToQuestionId = await handleSkipQuestionActions();
        if (skipToQuestionId !== step.id) {
          history.replace(`/flow/${flowId}/step/${skipToQuestionId}${location.search}`);
        } else {
          setReady(true);
        }
      };

      await checkAndRedirect();
    };

    asyncEffect();
  }, [step, history, flowId, initialStepId, stepId, location.search]);

  if (!ready) {
    return null;
  }

  return (
    <>
      {children((state) => {
        homepageStateRef.current = state;
      })}
    </>
  );
};

/**
 * This is a HOC intended to wrap the HomePage component.
 *
 * Every flow step has the option of exposing a `skipQuestionActions` property - an array of functions to be called
 * whenever the user enters that step. If any of those functions should return a numeric ID (i.e. a step ID), the
 * user should skip the current step and instead be redirected to the step that was returned.
 *
 * Most of the logic for handling this redirection exists within the `HomePage` component; however, it does not
 * currently support running that conditional logic on the *first* step within a flow. This HOC exists to address
 * that.
 *
 * Before the `HomePage` component is ever rendered, the HOC will check to see if the user is entering step one.
 * If they are, it will attempt to run the `skipQuestionActions` logic. If a reference to a different step is found,
 * the user will be redirected to it via `history.replace`, and the `HomePage` component will not render at all
 * until the user reaches the new step.
 */
const withInitialQuestionSkipper =
  <P extends InitialQuestionSkipperProp>(
    Component: React.ComponentType<P>
  ): React.ComponentType<Omit<P, keyof InitialQuestionSkipperProp>> =>
  (props: P) =>
    (
      <InitialQuestionSkipper>
        {(refSetter: RefSetter) => (
          <Component
            {...props}
            questionSkipperStateRefCallback={(state: HomePageState) => refSetter(state)}
          />
        )}
      </InitialQuestionSkipper>
    );

export default InitialQuestionSkipper;
export { withInitialQuestionSkipper };
