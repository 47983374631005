import { useFlags } from 'launchDarkly/FlagsProvider';
import { useState, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { FLOW_128_NYC_TEENS, FLOW_147_TEENSPACE_OPTIMIZED } from '../Flows';
import { trackEvent } from '../utils/analytics/eventTracker';

/**
 * This redirect is only for directing users with a direct link to the flow ID we want to replace
 * It does not work after the initial load of the page.
 */
const usePageLoadExperimentRedirect = () => {
  const { teenspaceCrOptimization } = useFlags();
  const [isLoadingRedirect, setIsLoadingRedirect] = useState(true);
  const history = useHistory();
  const { pathname } = useLocation();

  const experiments = useMemo(
    () =>
      ({
        teenspaceCrOptimization: {
          variant: teenspaceCrOptimization?.variant,
          experimentName: teenspaceCrOptimization?.experimentName,
          pathnameMatch: pathname.includes(`flow/${FLOW_128_NYC_TEENS}`),
          isExperimentActive: teenspaceCrOptimization?.experimentActive,
          isTreatmentVariant: teenspaceCrOptimization?.variant === 'treatment',
          newPath: `/flow/${FLOW_147_TEENSPACE_OPTIMIZED}/step/1`,
        },
      } as const),
    [pathname, teenspaceCrOptimization]
  );

  useEffect(() => {
    if (isLoadingRedirect === false) return;
    Object.keys(experiments).some((experiment: keyof typeof experiments) => {
      if (experiments[experiment].pathnameMatch) {
        const { isExperimentActive, isTreatmentVariant, newPath, experimentName, variant } =
          experiments[experiment];

        if (isExperimentActive) {
          trackEvent('TS Experiment Session', {
            experimentName,
            variantName: variant,
          });
        }

        if (isExperimentActive && isTreatmentVariant) {
          history.replace({
            pathname: newPath,
            search: history.location.search,
            hash: history.location.hash,
          });
          return true;
        }
      }
      return false;
    });

    setIsLoadingRedirect(false);
  }, [experiments, history, isLoadingRedirect]);

  return { isLoadingRedirect };
};

export default usePageLoadExperimentRedirect;
