import { useMemo } from 'react';

import { View, Spinner, useWindowWidthState } from '@talkspace/react-toolkit';
import { useLocation } from '@/core/routerLib';
import OneFormEligibilityError from '@/Components/OneFormEligibility/OneFormEligibilityError';
import { useDispatcherActions, useDispatcherState } from '../hooks/dispatcherContext';
import EligibilityOrg from '../Components/EligibilityDispatch/EligibilityOrg';
import EligibilityEmail from '../Components/EligibilityDispatch/EligibilityEmail';
import EligibilityVerifyEmail from '../Components/EligibilityDispatch/EligibilityVerifyEmail';
import EligibilityCode from '../Components/EligibilityDispatch/EligibilityCode';
import EligibilityKeywordCheck from '../Components/EligibilityDispatch/EligibityKeywordCheck';
import styled from '../core/styled';
import { ROUTES_TO_STEPS } from '../hooks/useDispatcherRouting';
import useQueryPublicAdminConfig from '../hooks/useQueryPublicAdminConfig';

const DispatchContainer = styled(View)<{ isMobile: boolean }>(({ isMobile }) => {
  return {
    paddingTop: 78,
    width: isMobile ? undefined : '100%',
    margin: isMobile ? '0 10px' : '0 auto',
    alignItems: 'center',
  };
});

const DispatcherContainer = () => {
  const location = useLocation();
  const {
    setHasAccessCode,
    submitAccessCode,
    setOrganizationName,
    setOrganizationEmail,
    resendVerificationCode,
    submitEmailVerificationCode,
  } = useDispatcherActions();
  const {
    isError,
    isReady,
    restoringState,
    isPartOfQMFlow,
    organizationName,
    organizationEmail,
    isCheckingEligibility,
  } = useDispatcherState();

  const { isSmall, isMobile } = useWindowWidthState();
  const { isLoading: cognitoActiveIsLoading } = useQueryPublicAdminConfig('cognito_user_migration');
  const currentStepName = ROUTES_TO_STEPS[location.pathname.replace(/\/$/, '')];

  const dispatcherComponent = useMemo(() => {
    switch (currentStepName) {
      case 'organizationName':
        return <EligibilityOrg orgName={organizationName || ''} onSubmit={setOrganizationName} />;
      case 'organizationEmail':
        return (
          <EligibilityEmail
            email={organizationEmail || ''}
            onSubmit={setOrganizationEmail}
            isLoading={isCheckingEligibility}
          />
        );
      case 'verifyEmail':
        return (
          <EligibilityVerifyEmail
            isError={isError}
            isLoading={isCheckingEligibility}
            email={organizationEmail || ''}
            resendVerificationCode={resendVerificationCode}
            onSubmit={submitEmailVerificationCode}
          />
        );
      case 'keywordCheck':
        return <EligibilityKeywordCheck setDoesHaveCode={setHasAccessCode} />;
      case 'redeemsReachedForEmailError':
        return (
          <OneFormEligibilityError
            error="redeemsReachedForEmailError"
            linkToFAQ="https://help.talkspace.com/hc/en-us"
          />
        );
      case 'eligibilityCode':
        return (
          <EligibilityCode
            isError={isError}
            isLoading={isCheckingEligibility}
            orgName={organizationName || ''}
            onSubmit={submitAccessCode}
          />
        );
      default:
        return null;
    }
  }, [
    currentStepName,
    organizationName,
    setOrganizationName,
    organizationEmail,
    setOrganizationEmail,
    isCheckingEligibility,
    isError,
    resendVerificationCode,
    submitEmailVerificationCode,
    setHasAccessCode,
    submitAccessCode,
  ]);

  if (!isReady || restoringState || cognitoActiveIsLoading)
    return (
      <View style={{ height: '100vh' }}>
        <View flex={1}>
          <Spinner isLoading />
        </View>
      </View>
    );

  return (
    <DispatchContainer isMobile={isMobile}>
      <View
        align="center"
        style={{ minWidth: isSmall ? undefined : 375, maxWidth: 440, alignSelf: 'center' }}
      >
        <View
          style={{
            width: isMobile ? undefined : '100%',
            textAlign: 'center',
            height: isPartOfQMFlow ? undefined : '100%',
          }}
        >
          {dispatcherComponent}
        </View>
      </View>
    </DispatchContainer>
  );
};

export default DispatcherContainer;
