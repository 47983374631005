/* eslint-disable no-console */
import * as VWOTool from 'ts-analytics/VWO/VWOSetup';
import createTSAnalyticsTracker from 'ts-analytics/trackers/tsAnalytics';

import { initMixpanel, getMixpanel } from './tools/mixpanelSetup';
import { getDataLayer, initGTM } from './tools/gtmSetup';

import { EventNameTypes, EventProperties, TrackerFunctions, TrackerTypes } from './trackerTypes';
import appConfig, { detectEnv } from '../configs';

(window as any).cypressEventLog = (window as any).cypressEventLog || [];

type DebugTypes = 'console' | 'console and remote' | 'remote';

const tsAnalyticsTracker = createTSAnalyticsTracker({
  endpoint: `${appConfig.analytics.mixpanel.tsAnalyticsEndpoint}/event`,
});

const NOOP =
  (tracker: string, method?: string) =>
  (...params) => {
    console.warn(
      `[${tracker}${
        method ? ` - ${method}` : ''
      }] - Invoked ${tracker} method without properly loading the tracker`,
      params
    );
  };

const DEBUG = detectEnv() !== 'prod';
/**
 * Only used when DEBUG is true
 */
const DEBUG_MODE: DebugTypes = 'console and remote';

const logEvent = (name: string, ...params: unknown[]) => {
  if (DEBUG && ['console', 'console and remote'].includes(DEBUG_MODE))
    console.log(`[Tracking][${name}]`, ...params);
};

/**
 * Assumes that callback is the last parameter of the function
 */
const promisifyWithTimeout =
  <F extends (...args: unknown[]) => any>(fun: F, scope: any, config: { timeout?: number } = {}) =>
  (...args: Parameters<F>) =>
    new Promise<void>((resolve) => {
      const { timeout = 1000 } = config;
      let timer: number | null = null;
      if (timeout) {
        timer = window.setTimeout(() => {
          timer = null;
          resolve();
        });
      }
      fun.apply(scope || this, [
        ...args,
        () => {
          if (timer) {
            window.clearTimeout(timer);
            resolve();
          }
        },
      ]);
    });

const swallowErrors =
  <T extends unknown[], U extends unknown>(fun: (...args: T) => Promise<U>) =>
  async (...args: T): Promise<U | void> => {
    try {
      const res = await fun(...args);
      return res;
    } catch (err) {
      if (DEBUG) {
        console.warn('[Tracking] - Error in function', fun.name, err);
      }
      return Promise.resolve();
    }
  };

interface Scope extends TrackerFunctions {
  /**
   * Note: userID is only set when one of the trackers is done loading
   * OR when debug mode is on
   */
  userID: string;
  calledSetUserID: boolean;
}

interface Scope extends TrackerFunctions {
  /**
   * Note: userID is only set when one of the trackers is done loading
   * OR when debug mode is on
   */
  userID: string;
  calledSetUserID: boolean;
}

const scope: Scope = {
  dataLayer: {
    push: NOOP('dataLayer'),
  },
  mixpanel: {
    track: NOOP('mixpanel', 'track'),
    identify: NOOP('mixpanel', 'identify'),
    people: {
      set: NOOP('mixpanel', 'people.set'),
    },
    alias: NOOP('mixpanel', 'alias'),
    get_distinct_id: NOOP('mixpanel', 'get_distinct_id'),
    register: NOOP('mixpanel', 'register'),
  },
  userID: '',
  calledSetUserID: false,
};

let resolveMixpanelPromise: (value?: unknown) => void;
const mixpanelLoadedPromise = new Promise<void>((resolve) => {
  let timeout: ReturnType<typeof setTimeout> | undefined;
  const sdkResolve = (timedOut?: boolean) => {
    if (timeout) clearTimeout(timeout);
    if (timedOut === true) console.error('Failed to load Mixpanel - Timed out');
    else scope.mixpanel = getMixpanel();
    resolve();
  };
  // Either resolves because resolveMixpanelPromise was invoked,
  resolveMixpanelPromise = sdkResolve;
  // Or a 12 second timeout
  timeout = setTimeout(() => sdkResolve(true), 12 * 1000);
});

let resolveGTMPromise: (value?: unknown) => void;
const GTMlLoadedPromise = new Promise<void>((resolve) => {
  const sdkResolve = () => {
    scope.dataLayer = getDataLayer();
    resolve();
  };
  resolveGTMPromise = sdkResolve;
});

const waitMixpanelReady = async (callback: Function) => {
  await mixpanelLoadedPromise;
  return callback();
};

const waitGTMReady = async (callback: Function) => {
  await GTMlLoadedPromise;
  return callback();
};

const initTracker = async (apiWrapper) => {
  logEvent('initTracker');

  if (DEBUG && !['remote', 'console and remote'].includes(DEBUG_MODE)) return;

  initMixpanel(resolveMixpanelPromise);
  initGTM(resolveGTMPromise);

  tsAnalyticsTracker.init(apiWrapper);

  VWOTool.initVWO();
};

const setTrackerUserID = async (userID: string | number) => {
  if (!userID) {
    console.warn('[Tracking] - Called setTrackerUserID with an invalid userID');
    return;
  }

  if (scope.calledSetUserID && scope.userID === String(userID)) return;
  scope.userID = String(userID);
  scope.calledSetUserID = true;
  tsAnalyticsTracker.setUserID(userID);
  logEvent('setTrackerUserID', userID);
  if (DEBUG && !['remote', 'console and remote'].includes(DEBUG_MODE)) {
    return;
  }

  waitMixpanelReady(() => {
    if (typeof userID === 'string') {
      scope.mixpanel.identify(userID);
    } else {
      scope.mixpanel.alias(String(userID));
    }
  });
};

const trackEvent = async (
  action: EventNameTypes,
  props: EventProperties,
  trackers?: TrackerTypes[],
  mpCB?: () => void
) => {
  logEvent('trackEvent', action, props, trackers);

  try {
    (window as any).cypressEventLog.push({
      name: action,
      properties: props,
    });
  } catch (err) {
    // Do nothing
  }

  if (DEBUG && !['remote', 'console and remote'].includes(DEBUG_MODE)) return;

  const {
    eventCategory,
    eventCategoryKey = 'Event Category',
    eventProperty = '',
    eventPropertyValue,
    ...otherProps
  } = props;

  if (!trackers || trackers.includes('mixpanel')) {
    waitMixpanelReady(() =>
      promisifyWithTimeout(scope.mixpanel.track, scope.mixpanel)(
        action,
        {
          [eventCategoryKey]: eventCategory,
          ...(eventProperty ? { [eventProperty]: eventPropertyValue } : {}),
          ...otherProps,
        },
        ['Complete Purchase', 'Register', 'Confirm Update Coverage'].includes(action)
          ? { send_immediately: true }
          : {}
      ).then(mpCB)
    );
  }

  if (!trackers || trackers.includes('GTM'))
    waitGTMReady(() =>
      scope.dataLayer.push({
        event: action,
        user_id: scope.userID,
        [eventCategoryKey]: eventCategory,
        ...(eventProperty ? { [eventProperty]: eventPropertyValue } : {}),
        ...otherProps,
      })
    );

  if (trackers && trackers.includes('tsAnalytics'))
    tsAnalyticsTracker.trackEvent(action, props).catch((err) => {
      if (DEBUG) {
        console.warn('[Tracking] - Error in function', 'tsAnalyticsTracker', err); // eslint-disable-line no-console
      }
    });
};

const setPeople = async (data: Record<string, any>, trackers?: TrackerTypes[]) => {
  logEvent('setPeople', data, trackers);
  if (DEBUG && !['remote', 'console and remote'].includes(DEBUG_MODE)) return;

  const promises: Promise<void>[] = [];

  if (!trackers || trackers.includes('mixpanel'))
    promises.push(
      waitMixpanelReady(() =>
        promisifyWithTimeout(scope.mixpanel.people.set, scope.mixpanel.people)(data)
      )
    );

  await Promise.all(promises);
};

const setAlias = async (newID?: string, existingID?: string, trackers?: TrackerTypes[]) => {
  logEvent('setAlias', newID, existingID, trackers);
  if (DEBUG && !['remote', 'console and remote'].includes(DEBUG_MODE)) return;

  if (!trackers || trackers.includes('mixpanel'))
    waitMixpanelReady(() =>
      scope.mixpanel.alias(newID || scope.mixpanel.get_distinct_id(), existingID)
    );
};

const setSuperProperty = async (metadata: object, trackers?: TrackerTypes[]) => {
  logEvent('setSuperProperty', metadata, trackers);
  if (DEBUG && !['remote', 'console and remote'].includes(DEBUG_MODE)) return;

  if (!trackers || trackers.includes('mixpanel'))
    waitMixpanelReady(() => scope.mixpanel.register(metadata));
};

const getMixpanelDistinctID = () => waitMixpanelReady(() => scope.mixpanel.get_distinct_id());

const VWO = {
  trackPurchaseGoal: VWOTool.trackPurchaseGoal,
  trackRegisterGoal: VWOTool.trackRegisterGoal,
  trackVWOPageView: VWOTool.trackVWOPageView,
  trackVWOConversionGoal: VWOTool.trackVWOConversionGoal,
  trackInitiatedQMGoal: VWOTool.trackInitiatedQMGoal,
  trackQMLeadCapturedGoal: VWOTool.trackQMLeadCapturedGoal,
  trackSelectedPlanGoal: VWOTool.trackSelectedPlanGoal,
  trackQMPurchaseGoal: VWOTool.trackQMPurchaseGoal,
};

const safeInitTracker = swallowErrors(initTracker);
const safeSetTrackerUserID = swallowErrors(setTrackerUserID);
const safeTrackEvent = swallowErrors(trackEvent);
const safeSetPeople = swallowErrors(setPeople);
const safeSetAlias = swallowErrors(setAlias);
const safeSetSuperProperty = swallowErrors(setSuperProperty);
const safeGetMixpanelDistinctID = swallowErrors(getMixpanelDistinctID);

export {
  tsAnalyticsTracker,
  safeInitTracker as initTracker,
  safeSetTrackerUserID as setTrackerUserID,
  safeTrackEvent as trackEvent,
  safeSetPeople as setPeople,
  safeSetAlias as setAlias,
  safeSetSuperProperty as setSuperProperty,
  safeGetMixpanelDistinctID as getMixpanelDistinctID,
  VWO,
};
