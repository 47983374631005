/* eslint-disable import/prefer-default-export */
import { useEffect, useRef } from 'react';
import { OUT_OF_POCKET_OPTION } from 'ts-frontend/helpers';
import { useMoveCoverageEnabled } from '../../../hooks';
import { UsePayerWatcherOptions } from '../types';

export const usePayerWatcher = ({ selectedPayer, onContinuePress }: UsePayerWatcherOptions) => {
  const ref = useRef({
    continuePressed: false,
  });

  const isMoveCoverageEnabled = useMoveCoverageEnabled();

  useEffect(() => {
    if (
      isMoveCoverageEnabled &&
      selectedPayer?.id === OUT_OF_POCKET_OPTION.id &&
      !ref.current.continuePressed
    ) {
      ref.current.continuePressed = true;
      onContinuePress();
    }
  }, [isMoveCoverageEnabled, selectedPayer, onContinuePress]);
};
