import { QMFlow, EligibilityType } from './types';
import { initFlowConfig } from './utils';

//* * HUMANA (Pilot EAP with teen support) */
export const FLOW_41_B2B_EAP_HUMANA_TEEN = 41;

const flow: QMFlow = {
  flowId: FLOW_41_B2B_EAP_HUMANA_TEEN,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    isEAP: true,
    isTeen: true,
    eligibilityType: EligibilityType.organization,
  },
  steps: [
    {
      id: 1,
      analyticsId: 54,
      category: 'oneFormEligibility',
      progressCurrent: 2,
      response_category_id: 9,
      progressMax: 10,
      optionalOrganization: false,
    },
  ],
};

export default flow;
