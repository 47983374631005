/**
 * Asks the user to enter memberID as Trizetto Discovery didn't find a match.
 * This will send a regular Trizetto request with memberID and the flow continues as usual.
 */
import { Button, RHFInput, useEmotionTheme } from '@talkspace/react-toolkit';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { compose } from 'lodash/fp';
import { yupResolver } from '@hookform/resolvers/yup';
import { EligibilityServiceFormData, useEligibilityService } from '@/hooks/useEligibilityService';

import { AA_TOOLTIP_STYLE, Styled } from '../QuickEligibility/EligibilityForm';
import { QuickEligibilityEnterMemberIDProps, EnterMemberIDFields } from './types';
import { formSchema } from './util';
import StatusModal from '../QuickEligibility/StatusModal';
import { useFormSaver, useContinue } from '../QuickEligibility/hooks';

const QuickEligibilityEnterMemberID = (props: QuickEligibilityEnterMemberIDProps) => {
  useFormSaver();

  const { insurancePayer, quickEligibilityInfo, step, updateStep } = props;
  const { colors } = useEmotionTheme();

  const { result, done, errorType, canRetry, attempts, isSubmitting, submit } =
    useEligibilityService({
      maxAttempts: 3,
      isDiscoveryScreen: true,
    });

  const shouldRetry = attempts > 0 && !isSubmitting && canRetry;
  const statusModalIsVisible = (isSubmitting || (attempts > 0 && canRetry)) && !done;

  const data = useWatch() as any as EligibilityServiceFormData;

  const formContext = useFormContext();
  const { handleSubmit, setValue } = formContext;

  useContinue({
    result,
    data,
    done,
    errorType,
    updateStep,
    step,
    insurancePayer,
  });

  const handleOnSubmit = (formData: EligibilityServiceFormData) => {
    const parsedFormData = JSON.parse(JSON.stringify(formData));
    const sendFormData = { ...quickEligibilityInfo, ...parsedFormData };

    return submit(sendFormData);
  };

  return (
    <Styled.View>
      <StatusModal
        visible={statusModalIsVisible}
        isCheckingCoverage={isSubmitting}
        showRetryButton={shouldRetry}
        onHandleRetry={() => submit(data)}
      />
      <Styled.Form onSubmit={handleSubmit(handleOnSubmit)}>
        <Styled.Heading>What’s your Member / Subscriber ID?</Styled.Heading>
        <Styled.Subheading>
          We’re having issues verifying your coverage for Talkspace. In most cases, providing your
          Member ID will resolve this issue.
        </Styled.Subheading>
        <RHFInput
          fieldName="memberID"
          label="Member / Subscriber ID"
          tooltip="Located on your Health Plan ID card. Type the full ID, including letters and numbers."
          isRequired
          data-qa="member-id-input"
          onChange={(e) => {
            const newValue = e.target.value.replace(/\s/gi, '').toLowerCase();
            if (newValue !== e.target.value) {
              setValue('memberID', newValue);
            }
          }}
          labelStyle={{ alignSelf: 'flex-end' }}
          tooltipStyle={AA_TOOLTIP_STYLE}
        />
        <Button
          text="Continue"
          roundedFocusStyle
          disabled={false}
          isLoading={false}
          primaryColor={colors.green}
          dataQa="enterMemberIDCTA"
          style={{ alignSelf: 'center', width: '100%', fontWeight: 'bold' }}
        />
      </Styled.Form>
    </Styled.View>
  );
};

export default compose((Component) => (props) => {
  const methods = useForm<EnterMemberIDFields>({
    resolver: yupResolver(formSchema),
    context: {},
    defaultValues: {
      memberID: '',
    } as EnterMemberIDFields,
  });

  return (
    <FormProvider {...methods}>
      <Component {...props} />
    </FormProvider>
  );
})(QuickEligibilityEnterMemberID) as typeof QuickEligibilityEnterMemberID;
