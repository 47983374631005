import {
  Button,
  ExtraHuge,
  Large,
  View,
  useWindowWidthState,
  ExperimentalComponent,
} from '@talkspace/react-toolkit';

import { isLDExperimentTreatment } from 'launchDarkly/util';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { FLOW_147_TEENSPACE_OPTIMIZED, TeenYouAreNotAloneStep, UpdateStep } from '../../../Flows';
import styled from '@/core/styled';
import TeensTogetherIllustration from '../../assets/TeensTogetherIllustration';

const Styled = {
  Container: styled(View)(({ isMobile }: { isMobile: boolean }) => {
    return {
      maxWidth: 350,
      minHeight: isMobile ? 'calc(100vh - 260px)' : undefined,
      justifyContent: 'space-between',
    };
  }),
  Title: styled(ExtraHuge)({ margin: '32px 0 12px' }),
  ContinueButton: styled(Button)({
    marginBottom: 16,
    marginTop: 40,
    width: '100%',
  }),
};

interface Props {
  step: TeenYouAreNotAloneStep;
  updateStep: UpdateStep;
  flowId: number;
}
const TeenYouAreNotAlone = ({ step, updateStep, flowId }: Props) => {
  const { isMobile } = useWindowWidthState();
  const flags = useFlags();
  const isTeenspaceTreatment = isLDExperimentTreatment(flags, 'teenspaceCrOptimization', {
    flowID: flowId,
    teenspaceFlowID: FLOW_147_TEENSPACE_OPTIMIZED,
  });
  return (
    <Styled.Container isMobile={isMobile}>
      <View align="center">
        <ExperimentalComponent
          featureFlagBoolean={isTeenspaceTreatment}
          featureFlagVariant={isTeenspaceTreatment ? 'treatment' : 'control'}
          control={
            <>
              <TeensTogetherIllustration />
              <Styled.Title>You’re not alone</Styled.Title>
              <Large>
                We’ve helped over{' '}
                <Large inline variant="largeBoldWide">
                  11,240
                </Large>{' '}
                other teens just like you get the support they need
              </Large>
            </>
          }
          treatment={
            <>
              <Styled.Title style={{ margin: '0 0 12px' }}>You’re not alone</Styled.Title>
              <Large variant="largeGrey950" style={{ marginBottom: 32 }}>
                We’ve helped thousands of teens with similar struggles to yours. Therapy is proven
                to help you feel make progress towards feeling better.
              </Large>
              <TeensTogetherIllustration />
            </>
          }
        />
      </View>
      <Styled.ContinueButton
        text="Continue"
        onPress={() => updateStep(step.buttonTarget)}
        dataQa="teenYouAreNotAloneContinueButton"
      />
    </Styled.Container>
  );
};
export default TeenYouAreNotAlone;
