import { useEffect, useState } from 'react';
import { View, HiddenLabel, TextAreaRounded, Button, useUniqueID } from '@talkspace/react-toolkit';
import { useSpring, animated } from 'react-spring';
import useWindowWidth from '@/hooks/useWindowWidth';
import {
  FreeTextStep,
  ExternalTarget,
  InternalTarget,
  UpdateStepObj,
  RecoveredFields,
} from '@/Flows/types';

type RecoveredField = keyof RecoveredFields;

interface FreeTextProps {
  question: FreeTextStep;
  updateStep: (
    target: ExternalTarget | InternalTarget,
    objectArg: UpdateStepObj
  ) => Promise<unknown>;
  setRecoveredField: <T extends RecoveredField>(field: T, data: RecoveredFields[T]) => void;
  label?: string;
  placeholder?: string;
}

const getWidthStyles = (isMobile: boolean) => {
  return { width: '100%', maxWidth: isMobile ? 355 : 450 };
};

const FreeText = ({
  updateStep,
  question,
  setRecoveredField,
  label = 'Enter your treatment goals',
  placeholder = 'Enter your goals here, like "Be less anxious in social settings"',
}: FreeTextProps) => {
  const { isMobile } = useWindowWidth();

  const [showSubmit, setShowSubmit] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    const sessionStateFromStorage = JSON.parse(sessionStorage.getItem('TSQM_FreeText') || '{}');

    if (sessionStateFromStorage.showSubmit) {
      setShowSubmit(sessionStateFromStorage.showSubmit);
    }
    if (sessionStateFromStorage.text) {
      setText(sessionStateFromStorage.text);
    }
  }, [setRecoveredField]);

  const updateText = (value) => {
    setText(value);
    setShowSubmit(!!value);
  };

  const handleSubmit = () => {
    sessionStorage.setItem(
      'TSQM_FreeText',
      JSON.stringify({
        showSubmit,
        text,
      })
    );
    setRecoveredField('freeText', sessionStorage.getItem('TSQM_FreeText'));

    updateStep(`${question.internalTarget}`, {
      payfirst_step_id: question.analyticsId,
      payfirst_step_prompt: question.prompt,
      response_category_id: question.response_category_id,
      response_prompt: text,
      response_value: text,
    });
  };

  const textAreaId = useUniqueID('textAreaId');

  return (
    <View style={getWidthStyles(isMobile)}>
      <HiddenLabel htmlFor={textAreaId}>{label}</HiddenLabel>
      <TextAreaRounded
        id={textAreaId}
        value={text}
        onChangeText={updateText}
        placeholder={placeholder}
        dataQa="describeGoalsTextArea"
        rows={10}
        style={{
          ...getWidthStyles(isMobile),
          height: 220,
          alignSelf: 'center',
        }}
        textAreaStyle={{
          padding: '10px 5px 10px 5px',
          margin: 0,
          height: 300,
        }}
        roundedFocusStyle
      />

      <animated.div
        style={useSpring({
          from: { opacity: 0 },
          to: { opacity: showSubmit ? 1 : 0 },
        })}
        hidden={!showSubmit}
      >
        <Button style={{ alignSelf: 'center' }} onClick={handleSubmit} disabled={!showSubmit}>
          {question.submitText || 'Submit'}
        </Button>
      </animated.div>
    </View>
  );
};

export default FreeText;
