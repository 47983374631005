import { useQuery } from 'react-query';
import { getPublicAdminConfigJsonValue } from '../Helpers/apiService';
import { adminConfigQueryKey } from './queryKeys';

const fetchAdminConfig = (configKey: string, json?: boolean) => async (): Promise<object> => {
  const res = await getPublicAdminConfigJsonValue(configKey, json);
  return res;
};

const useQueryPublicAdminConfigJson = (configKey: string, json?: boolean) =>
  useQuery<object>({
    queryKey: adminConfigQueryKey(configKey),
    queryFn: fetchAdminConfig(configKey, json),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: true,
  });

export default useQueryPublicAdminConfigJson;
