import { useEffect, useState, useMemo } from 'react';
import useTranslation from '@talkspace/i18n/hooks/useTranslation';

import { FlowStep, FlowStepResolved, SelectDynamicStepResolved } from '../Flows/types';
import { DynamicValueHomePageState, DynamicValueTranslators } from '../Components/HomePage/types';

import { CALLBACK_FIELDS, resolveField, getHasAnyCallbacks } from '../Helpers/dynamicStepHelpers';

interface UseDynamicStepValuesParams {
  step: FlowStep;
  homePageState: DynamicValueHomePageState;
}
interface UseDynamicStepValuesReturn {
  isLoading: boolean;
  stepDynamicResolved: SelectDynamicStepResolved | FlowStepResolved;
}

export default function useDynamicStepValues({
  step,
  homePageState,
}: UseDynamicStepValuesParams): UseDynamicStepValuesReturn {
  const { t: tQuickmatchCommon, i18n } = useTranslation('quickmatch.common');
  const { language } = i18n;
  const [callbackResolutions, setCallbackResolutions] =
    useState<Partial<SelectDynamicStepResolved> | null>(null);
  const [currentStepID, setCurrentStepID] = useState<number | null>(null);
  const i18nTranslators = useMemo<DynamicValueTranslators>(() => {
    return { tQuickmatchCommon };
  }, [tQuickmatchCommon]);
  const hasCallbacks = getHasAnyCallbacks(step);
  useEffect(() => {
    if (step.id !== currentStepID || callbackResolutions === null) {
      setCurrentStepID(step.id);
      if (hasCallbacks && step.category === 'selectDynamic') {
        const resolveAllFields = async () => {
          const resolvedList = await Promise.all(
            CALLBACK_FIELDS.map((fieldName) =>
              resolveField(step, fieldName, homePageState, i18nTranslators)
            )
          );
          const resolvedObj = CALLBACK_FIELDS.reduce(
            (prev, fieldName, index) =>
              resolvedList[index] ? { ...prev, [fieldName]: resolvedList[index] } : prev,
            {}
          );
          setCallbackResolutions(resolvedObj);
        };
        resolveAllFields();
      }
    }
  }, [step, homePageState, hasCallbacks, currentStepID, i18nTranslators, callbackResolutions]);

  // When language changes, reset resolved values for this step
  useEffect(() => {
    setCallbackResolutions(null);
  }, [i18nTranslators, language]);

  const stepDynamicResolved = {
    ...step,
    ...callbackResolutions,
  } as SelectDynamicStepResolved;

  const resolvedHasCallbacks = getHasAnyCallbacks(stepDynamicResolved);

  if (step.category === 'selectDynamic' && hasCallbacks) {
    if (!callbackResolutions || resolvedHasCallbacks) {
      return {
        isLoading: true,
        stepDynamicResolved: {
          ...stepDynamicResolved,
          buttons: [],
          heading1: '',
          heading2: '',
          heading3: '',
          prompt: '',
        } as SelectDynamicStepResolved,
      };
    }
    return {
      isLoading: false,
      stepDynamicResolved,
    };
  }
  return {
    isLoading: false,
    stepDynamicResolved: step as FlowStepResolved,
  };
}
