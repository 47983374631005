// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FunctionComponent, useRef, useState } from 'react';
import { Trans } from '@talkspace/i18n';
import useTranslation from '@talkspace/i18n/hooks/useTranslation';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { View, Button as AccessibleButton, StepTip } from '@talkspace/react-toolkit';
import styled from '../../core/styled';
import { HomePageState } from '../HomePage/types';

const StyledAccessibleButton = styled(AccessibleButton)(({ theme: { colors } }) => {
  return {
    color: colors.green,
    backgroundColor: 'transparent',
    border: 'none',
    width: 'auto',
    alignSelf: 'center',
    fontWeight: 700,
    transition: 'all 200ms ease-in-out',
    '&:hover': {
      color: colors.permaTropicalRainForest,
    },
  };
});

interface StateCountryToggleProps extends Pick<HomePageState, 'service' | 'livesInTheUS'> {
  onShowCountries: () => void;
  isForUSOnly?: boolean;
}

const StateCountryToggle: FunctionComponent<StateCountryToggleProps> = ({
  service,
  livesInTheUS,
  onShowCountries,
  isForUSOnly,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isMouseUser, setIsMouseUser] = useState(false);
  const onMouseEnter = () => {
    setIsMouseUser(true);
  };
  const onMouseLeave = () => {
    setIsMouseUser(false);
  };
  const handleClick = () => {
    onShowCountries();
    if (isMouseUser) {
      buttonRef?.current?.blur();
    }
  };
  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');
  if (service === 'psychiatry') {
    return (
      <StepTip
        tip="noPsychOutsideUS"
        style={{
          maxWidth: 335,
          marginTop: 35,
          marginLeft: 'auto',
          marginRight: 'auto',
          alignItems: 'center',
        }}
      />
    );
  }

  return (
    <View>
      <StyledAccessibleButton
        onClick={handleClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        roundedFocusStyle
        ref={buttonRef}
      >
        {livesInTheUS && isForUSOnly && (
          <Trans t={tQuickmatchCommon} i18nKey="stateCountrySelectionStep.liveInUSTerritoryText">
            I live in a US Territory
          </Trans>
        )}
        {livesInTheUS && !isForUSOnly && (
          <Trans
            t={tQuickmatchCommon}
            i18nKey="stateCountrySelectionStep.liveInUSTerritoryOrOutsideText"
          >
            I live in a US Territory or outside of the US
          </Trans>
        )}
        {!livesInTheUS && (
          <Trans t={tQuickmatchCommon} i18nKey="stateCountrySelectionStep.liveInUSText">
            I live in the US
          </Trans>
        )}
      </StyledAccessibleButton>
    </View>
  );
};
export default StateCountryToggle;
