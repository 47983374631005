import TSConfigs from '@talkspace/configs';

const therapistGender = {
  1: 'male',
  2: 'female',
  3: 'any',
};

const therapistPrompt = {
  3: 'I have no gender preference',
  2: 'Female',
  1: 'Male',
};

export const getTherapistGender = (index) => therapistGender[index] || therapistGender[3];

export const getTherapistPrompt = (index) => therapistPrompt[index] || therapistPrompt[3];

export const getTherapistAbilityPreferences = () => {
  const configKey = 'therapistAbilityPreferences';
  const otherOptions = [{ value: null, label: 'Not sure' }];
  return [
    ...Object.keys(TSConfigs[configKey] ? TSConfigs[configKey] : {}).map((key) => {
      return {
        value: key,
        label: TSConfigs[configKey][key],
      };
    }),
    ...otherOptions,
  ];
};
