import { getFieldsOfExpertise } from 'ts-frontend/helpers/presentingProblems';
import { getTherapistGender } from './therapistHelper';
import { MapSelfServeFieldsState } from '../Components/HomePage/types';
import { FlowStep, UpdateStepObj, SelfServeField } from '../Flows/types';

const getSelfServeField = (selfServe, state): SelfServeField => {
  if (typeof selfServe === 'function') {
    return selfServe(state);
  }
  return selfServe || {};
};

/**
 * Attach to response fields and values for self serve
 *
 * @param {string} field
 * @param {string|undefined} type
 * @param {{}} answer
 */
const mapSelfServeFields = (
  { selfServe }: Partial<FlowStep> = {},
  answer: UpdateStepObj = {},
  state: MapSelfServeFieldsState
) => {
  const { field, type, answerValue } = getSelfServeField(selfServe, state);
  if (!field) {
    return;
  }
  if (answerValue && Number(answerValue) && (field === 'languages' || field === 'languagesSoft')) {
    // eslint-disable-next-line no-param-reassign
    answer.response_self_serve = {
      field,
      value: [Number(answerValue)],
    };
    return;
  }
  const { response_value: responseValue } = answer;
  let response: string | string[] | undefined | (number | undefined)[] = responseValue;

  switch (type) {
    case Array:
      if (response) {
        response = response.split(',');
        response =
          field === 'fieldsOfExpertise' ? getFieldsOfExpertise(response.map(Number)) : response;
      }
      break;
    default:
      response = field === 'therapistGender' ? getTherapistGender(response) : response;
      break;
  }

  // eslint-disable-next-line no-param-reassign
  answer.response_self_serve = {
    field,
    value: response as string | number[],
  };
};

export default mapSelfServeFields;
