import {
  Large,
  Link,
  Standard,
  useEmotionTheme,
  View,
  useUniqueID,
} from '@talkspace/react-toolkit';
import { EmotionStyle } from '../../core/styled';
import appConfigs from '../../utils/configs';

const supportEmailValues = Object.values(appConfigs.supportEmailLinks);

const Assistance = ({
  style,
  assistanceTextStyle,
  supportEmailStyle,
  linkToFAQStyle,
  supportEmail = appConfigs.supportEmailLinks.partnersSupport,
  linkToFAQ = 'https://redemption.talkspace.com/faq-segments/bh',
}: {
  style?: EmotionStyle;
  assistanceTextStyle?: EmotionStyle;
  supportEmailStyle?: EmotionStyle;
  linkToFAQStyle?: EmotionStyle;
  supportEmail?: string;
  linkToFAQ?: string;
}) => {
  const furtherAssistanceTextID = useUniqueID('furtherAssistanceTextID');
  const { colors } = useEmotionTheme();

  const emailLinkCopy = supportEmailValues.includes(supportEmail)
    ? supportEmail
    : appConfigs.supportEmailLinks.partnersSupport;

  return (
    <View style={{ marginTop: 40, ...style }}>
      <Standard
        id={furtherAssistanceTextID}
        variant="standardDarkGrey"
        style={{ alignSelf: 'center', lineHeight: 1.8, assistanceTextStyle }}
      >
        Need further assistance?
      </Standard>
      <View row justify="space-around" style={{ alignSelf: 'center', marginBottom: 17 }}>
        <Link
          ariaDescribedBy={furtherAssistanceTextID}
          href={`mailto:${supportEmail}`}
          style={{ textDecoration: 'none' }}
          target="_blank"
          stopPropagation
          roundedFocusStyle
          primaryColor={colors.green}
          dataQa="partnersSupportEmailLink"
        >
          <Large
            variant="largeBoldWideGreen"
            style={{
              borderRight: `1px solid ${colors.a11yLinkWaterGrey}`,
              paddingRight: 12,
              whiteSpace: 'nowrap',
              ...supportEmailStyle,
            }}
          >
            {emailLinkCopy}
          </Large>
        </Link>
        <Link
          ariaDescribedBy={furtherAssistanceTextID}
          href={linkToFAQ}
          style={{ textDecoration: 'none' }}
          target="_blank"
          stopPropagation
          roundedFocusStyle
          primaryColor={colors.green}
          dataQa="faqLink"
        >
          <Large
            variant="largeBoldWideGreen"
            style={{ paddingLeft: 3, whiteSpace: 'nowrap', ...linkToFAQStyle }}
          >
            Visit the FAQ
          </Large>
        </Link>
      </View>
    </View>
  );
};

export default Assistance;
