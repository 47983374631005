import React, { FunctionComponent, useState, useEffect, SetStateAction, Dispatch } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import {
  Big,
  Button,
  ExtraHuge,
  Large,
  ExtraBig,
  View,
  Spinner,
  TimeslotCarousel,
  BackNextButton,
  useEmotionTheme,
  Image,
  useUniqueID,
  useContainerFocus,
} from '@talkspace/react-toolkit';
import { ServiceType, TherapistTimeslot } from 'ts-frontend/types';
import { trackVisitTherapistScheduler, trackEvent } from '@/utils/analytics/events';
import { EventValueTypes } from '@/utils/analytics/trackerTypes';
import apiWrapper from '@/core/api/apiWrapper';
import user from '@/Assets/user.png';
import apiHelper from '@/Helpers/apiHelper';
import appConfigs from '@/utils/configs';
import styled from '@/core/styled';
import { UpdateStepObj, InternalTarget, ExternalTarget, SchedulerStep } from '@/Flows/types';

const REACT_APP_CDN_ENDPOINT = appConfigs.endpoints.cdnEndpoint;
interface SchedulerProps {
  providerID: number;
  providerFirstName: string;
  updateStep: (target: InternalTarget | ExternalTarget, updateStepObj?: UpdateStepObj) => void;
  step: SchedulerStep;
  service: ServiceType | null;
  setIsConfirmScheduler: Dispatch<SetStateAction<boolean>>;
}

type TimeslotsByDay = { day: number; timeslots: TherapistTimeslot[] }[];

const StyledContainer = styled(View)({
  display: 'flex',
  flexDirection: 'column',
  '&:focus': {
    outline: 'none',
  },
});

const TimeslotColumnsContainer = styled(View)({
  width: 'fit-content',
  margin: 'auto',
  // giving padding-bottom to account for the combined height of the Continue and Reserve Later buttons (plus a bit extra) prevents the bottom timeslot cells from being cut off
  paddingBottom: 165,
});

const Header1 = styled(ExtraBig)(({ theme: { window, colors } }) => {
  const { isMobile } = window;
  return {
    maxWidth: isMobile ? 332 : 350,
    paddingBottom: isMobile ? 7 : 14,
    paddingTop: 40,
    fontSize: isMobile ? 14 : undefined,
    margin: 'auto',
    color: colors.permaRiverBed,
    fontWeight: 400,
  };
});

const ShowConfirmContainer = styled(View)(({ theme: { window } }) => {
  const { isMobile } = window;
  return {
    display: 'flex',
    flexDirection: 'column',
    height: '75vh',
    width: '100vw',
    alignItems: 'center',
    justifyContent: isMobile ? 'space-between' : undefined,
    paddingLeft: 15,
    paddingRight: 15,
    '&:focus': { outline: 'none' },
  };
});

const PrimaryButton = ({
  onPress,
  text,
  dataQa,
  ariaDescribedBy,
}: {
  onPress: () => void;
  text: string;
  dataQa: string;
  ariaDescribedBy?: string;
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Button
      aria-describedby={ariaDescribedBy}
      data-qa={dataQa}
      roundedFocusStyle
      primaryColor={colors.green}
      text={text}
      onPress={onPress}
    />
  );
};

const ScrollAnchorContainer = styled(View)(({ theme: { colors } }) => {
  return {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 19,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 50,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: colors.white,
  };
});

const ForwardBackButton = ({
  onPress,
  page,
  dataQa,
  isRight,
  lastPage,
}: {
  onPress: () => void;
  page: number;
  dataQa: string;
  lastPage?: number;
  isRight?: boolean;
}) => {
  let visibility = 'visible';
  if (isRight && lastPage && page === lastPage) visibility = 'hidden';
  if (!isRight && page === 0) visibility = 'hidden';
  const { colors } = useEmotionTheme();
  return (
    <BackNextButton
      aria-hidden={visibility === 'hidden'}
      isRight={isRight}
      onPress={onPress}
      dataQa={dataQa}
      style={{
        visibility,
        height: 'fit-content',
        marginTop: 20,
        marginLeft: isRight ? 2 : 0,
        marginRight: isRight ? 0 : 2,
        borderRadius: 5,
      }}
      primaryColor={colors.green}
      roundedFocusStyle
    />
  );
};

const ScheduleLaterButton = ({
  onPress,
  dataQa,
}: {
  onPress: (event: React.KeyboardEvent | React.MouseEvent) => void;
  dataQa: string;
}) => {
  const { colors } = useEmotionTheme();
  return (
    <Button
      data-qa={dataQa}
      primaryColor={colors.permaWaikawaGrey}
      roundedFocusStyle
      isSecondary
      removeBorder
      style={{ marginTop: 20, color: colors.permaWaikawaGrey }}
      onPress={onPress}
      text="I'll reserve this session later"
      size="small"
    />
  );
};

const TherapistUserIllustration = ({
  providerID,
  providerFirstName,
}: {
  providerID: number;
  providerFirstName: string;
}) => {
  const outlineSize = 6;
  const circleSize = 85;
  const outerCircleSize = 2 * outlineSize + circleSize;
  return (
    <View
      style={{ display: 'flex', justifyContent: 'center', height: outerCircleSize, marginTop: 50 }}
    >
      <View
        style={{
          position: 'relative',
          left: -circleSize,
        }}
      >
        <View
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            borderRadius: '50%',
            width: outerCircleSize,
            height: outerCircleSize,
          }}
        >
          <Image
            dataQa="proiderProfileImage"
            src={`${REACT_APP_CDN_ENDPOINT}images/application/therapist/440/${providerID}.png`}
            alt={`${providerFirstName}'s profile picture`}
            style={{
              position: 'absolute',
              top: outlineSize,
              left: outlineSize,
              width: circleSize,
              height: circleSize,
              borderRadius: '50%',
            }}
          />
        </View>
        <Image
          dataQa="userAvatarImage"
          src={user}
          className="scheduler-therapist-image  user-img"
          alt="User avatar"
          style={{
            width: circleSize,
            height: circleSize,
            float: 'none',
            position: 'absolute',
            left: circleSize,
            top: outlineSize,
            zIndex: -1,
          }}
        />
      </View>
    </View>
  );
};

// the api returns timeslots grouped by day according to the therapist's timezone
// we should display the data to the user in the user's timezone while
// preserving the same structure
const reorganizeTimeslots = (localTimezone: string, timeslotsByDay: TimeslotsByDay) => {
  // convert all timeslots to a single array
  const therapistTimezoneTimeslots = timeslotsByDay.reduce(
    (acc, dayTimeslots) => acc.concat(dayTimeslots.timeslots),
    [] as TherapistTimeslot[]
  );

  // group the timeslots into arrays by the date in the user's timezone
  const localTimezoneTimeslots = therapistTimezoneTimeslots.reduce((acc, { start, end }) => {
    const localStart = momentTz.tz(start, localTimezone).format();
    const localEnd = momentTz.tz(end, localTimezone).format();
    const startDate = momentTz.tz(start, localTimezone).format('YYYY-MM-DD');
    const dayTimeslotList = acc[startDate] || [];

    dayTimeslotList.push({ start: localStart, end: localEnd });

    return { ...acc, [startDate]: dayTimeslotList };
  }, {});

  // return the timeslots with the structure in which they were received
  return Object.values(localTimezoneTimeslots).map((localTimeslotsByDay, index) => {
    return { day: index + 1, timeslots: localTimeslotsByDay as TherapistTimeslot[] };
  });
};

const Scheduler: FunctionComponent<SchedulerProps> = ({
  providerID,
  providerFirstName,
  updateStep,
  step,
  service,
  setIsConfirmScheduler,
}) => {
  const [localTimezoneTimeslots, setLocalTimezoneTimeslots] = useState<TimeslotsByDay>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [page, setPage] = useState(0);
  const [selectedTimeslot, setSelectedTimeslot] = useState({ start: '', end: '' });
  const [city, setCity] = useState('');

  // programmatically focuses the containers on load as content changes, thereby allowing screen readers to announce the texts that they are aria-labelledby
  const { containerRef } = useContainerFocus(!isLoading);
  const { containerRef: confirmContainerRef } = useContainerFocus(showConfirm);
  const header1ID = useUniqueID('header1ID');
  const confirmHeder1ID = useUniqueID('confirmHeder1ID');
  const confirmTextID = useUniqueID('confirmTextID');

  useEffect(() => {
    setIsConfirmScheduler(showConfirm);
  }, [showConfirm, setIsConfirmScheduler]);

  useEffect(() => {
    trackVisitTherapistScheduler(providerID);

    apiWrapper
      // Include (optional) service type in request to get on the hour availabilities for psychiatry; on the half-hour availabilities for therapy
      .get(`${apiHelper()}therapists/${providerID}/availability/${service}`)
      .then((responseData) => {
        const { timeslots } = responseData;
        const localTimezone = momentTz.tz.guess();
        let userCity = localTimezone;
        if (userCity.split('/')[1]) {
          userCity = localTimezone.split('/')[1].includes('_')
            ? localTimezone.split('/')[1].replace('_', ' ')
            : localTimezone.split('/')[1];
        }

        const timeslotsReorganizedToLocalTimezone = reorganizeTimeslots(localTimezone, timeslots);
        setLocalTimezoneTimeslots(timeslotsReorganizedToLocalTimezone);
        setIsLoading(false);
        setSelectedTimeslot({
          start: timeslotsReorganizedToLocalTimezone[0].timeslots[0].start,
          end: timeslotsReorganizedToLocalTimezone[0].timeslots[0].end,
        });
        setCity(userCity);
      })
      .catch(() => {
        // on any error with this page timeslots we skip this step and progress with the flow
        updateStep(step.buttonTarget);
      });
  }, [service, step.buttonTarget, providerID, updateStep]);

  const handleScheduleLater = (
    screen: EventValueTypes['Schedule Live Session Later']['screen'],
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    event.preventDefault();

    trackEvent('Schedule Live Session Later', {
      therapistID: providerID,
      screen,
      funnelName: 'QuickMatch',
    });

    updateStep(step.buttonTarget);
  };

  const confirmSchedule = () => {
    updateStep(step.buttonTarget, {
      appointment: { start: selectedTimeslot.start, end: selectedTimeslot.end },
    });
  };

  const lastPage = Math.ceil(localTimezoneTimeslots.length / 3) - 1;
  const isPsych = service === 'psychiatry';
  return isLoading ? (
    <Spinner />
  ) : (
    <StyledContainer tabIndex={-1} ref={containerRef} aria-labelledby={header1ID}>
      <View row style={{ alignSelf: 'center' }}>
        {showConfirm ? (
          <ShowConfirmContainer
            ref={confirmContainerRef}
            tabIndex={-1}
            aria-labelledby={confirmHeder1ID}
          >
            <TherapistUserIllustration
              providerID={providerID}
              providerFirstName={providerFirstName}
            />
            <ExtraHuge id={confirmHeder1ID} as="h1" style={{ marginTop: 35 }}>
              {moment(selectedTimeslot.start).format('dddd, MMM D')}
            </ExtraHuge>
            <Big as="h2" variant="bigMedium" style={{ marginTop: 8 }}>{`${moment(
              selectedTimeslot.start
            ).format('h:mm A')} - ${moment(selectedTimeslot.start)
              .add(isPsych ? 60 : 10, 'minutes')
              .format('h:mm A')}`}</Big>
            <Large as="h3" id={confirmTextID} variant="largeDarkGrey" style={{ marginTop: 14 }}>
              {isPsych ? (
                <>
                  You are about to reserve your <br /> Initial evaluation.
                </>
              ) : (
                'You are about to reserve a 10 minute Live Video Intro Session.'
              )}
            </Large>
            <View style={{ marginTop: 40 }}>
              <PrimaryButton
                dataQa="confirmTimeslotButton"
                ariaDescribedBy={confirmTextID}
                onPress={confirmSchedule}
                text="Reserve session"
              />
              <ScheduleLaterButton
                dataQa="scheduleLaterButton1"
                onPress={(event) => handleScheduleLater('Confirmation', event)}
              />
            </View>
          </ShowConfirmContainer>
        ) : (
          <Header1 as="h1" id={header1ID}>
            Please pick a time that suits your schedule. Availability is shown in {city} time.
          </Header1>
        )}
      </View>
      {!showConfirm && (
        <View>
          <TimeslotColumnsContainer row>
            <ForwardBackButton
              dataQa="schduleBackButton"
              onPress={() => {
                setPage(page - 1);
              }}
              page={page}
            />
            <TimeslotCarousel
              containerStyle={{ alignSelf: 'center' }}
              timeslotsByDay={localTimezoneTimeslots.slice(page * 3, page * 3 + 3)}
              selectedTimeslot={selectedTimeslot}
              setSelectedTimeslot={setSelectedTimeslot}
              roundedFocusStyle
              isTherapist={false}
              // allows a longer list of timeslots to render as per the QM designs
              isMobile
            />
            <ForwardBackButton
              dataQa="scheduleForwardButton"
              isRight
              lastPage={lastPage}
              onPress={() => {
                setPage(page + 1);
              }}
              page={page}
            />
          </TimeslotColumnsContainer>
          <ScrollAnchorContainer>
            <PrimaryButton
              dataQa="continueButton"
              onPress={() => {
                setShowConfirm(true);
              }}
              text="Continue"
            />
            <ScheduleLaterButton
              dataQa="schedulerLaterButton2"
              onPress={(event) => handleScheduleLater('Scheduler', event)}
            />
          </ScrollAnchorContainer>
        </View>
      )}
    </StyledContainer>
  );
};

export default Scheduler;
