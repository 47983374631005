import { FunctionComponent, useEffect, RefObject, Dispatch, SetStateAction } from 'react';
import { Player, ControlBar } from 'video-react';
import { useEmotionTheme, Modal, CloseButton } from '@talkspace/react-toolkit';
import { Match } from '../HomePage/types';
import appConfigs from '../../utils/configs';
import './IntroVideoPlayer.css';

const REACT_APP_CDN_ENDPOINT = appConfigs.endpoints.cdnEndpoint;

interface IntroVidePlayerProps {
  match: Match;
  videoPlayerRef: RefObject<Player>;
  showVideo: boolean;
  setShowVideo: Dispatch<SetStateAction<boolean>>;
  videoProgress: number;
  setVideoProgress: Dispatch<SetStateAction<number>>;
}

const IntroVideoPlayer: FunctionComponent<IntroVidePlayerProps> = ({
  match,
  videoPlayerRef,
  showVideo,
  setShowVideo,
  videoProgress,
  setVideoProgress,
}) => {
  const { id: matchId, firstName, lastName } = match;
  const { colors } = useEmotionTheme();
  const onBackdropPress = () => {
    videoPlayerRef.current?.pause();
    // save the video progress
    setVideoProgress(videoPlayerRef.current?.getState().player.currentTime);
    setShowVideo(false);
  };

  // set the player's currentTime state to that of the saved video progress
  useEffect(() => {
    const currentVideoPlayerRef = videoPlayerRef.current;
    if (videoProgress && currentVideoPlayerRef) {
      currentVideoPlayerRef.setState({ currentTime: videoProgress });
    }
  }, [videoProgress, videoPlayerRef]);

  useEffect(() => {
    const currentVideoPlayerRef = videoPlayerRef.current;
    if (showVideo && currentVideoPlayerRef) {
      if (videoProgress) {
        // resume playing from where user left off
        currentVideoPlayerRef.forward(videoProgress);
      }
      currentVideoPlayerRef.play();
    }
  }, [videoPlayerRef, showVideo, videoProgress]);

  return (
    <Modal
      titleText={`${firstName} ${lastName}'s intro video`}
      isVisible={showVideo}
      onBackdropPress={onBackdropPress}
      dialogStyle={{ height: '100vh', width: '100%' }}
      underlayStyle={{ zIndex: 1100 }}
    >
      <CloseButton
        ariaLabel="exit video"
        dataQa="closeIntroVideoButton"
        style={{ position: 'absolute', top: 32, right: 32, borderRadius: 10, zIndex: 10000 }}
        roundedFocusStyle
        primaryColor={colors.white}
        onPress={onBackdropPress}
      />
      <Player
        ref={videoPlayerRef}
        fluid={false}
        width="100%"
        autoplay
        playsInline
        src={`${REACT_APP_CDN_ENDPOINT}therapist-intro-videos/${matchId}.mp4`}
      >
        <ControlBar autoHide={false} />
      </Player>
    </Modal>
  );
};

export default IntroVideoPlayer;
