// eslint-disable-next-line import/no-cycle
import { getClientFlow } from '../Flows';
import { FunnelName } from '../utils/analytics/trackerTypes';

export const isTeensFlow = (flowId: number): boolean => {
  function teenSupportEnabledViaParam() {
    const urlParams = new URLSearchParams(window.location.search);
    return Number(urlParams.get('ts'));
  }
  const flow = getClientFlow(flowId);
  return !!teenSupportEnabledViaParam() || !!(flow && flow.flowConfig.isTeen);
};

export const isB2BFlow = (flowId: number): boolean => {
  const flow = getClientFlow(flowId);
  return !!(flow && flow.flowConfig.isB2B);
};

export const getQMFunnelName = ({
  isCreateRoomFlow,
  isReactivationFlow,
  isUpdateCoverageFlow,
  isMBHIneligibilityFlow,
  isB2B,
}: {
  isCreateRoomFlow: boolean;
  isReactivationFlow: boolean;
  isUpdateCoverageFlow: boolean;
  isMBHIneligibilityFlow: boolean;
  isB2B?: boolean;
}): FunnelName => {
  if (isCreateRoomFlow) return 'Eligibility';
  if (isReactivationFlow) return 'Reactivation';
  if (isUpdateCoverageFlow) return 'Update Coverage';
  if (isMBHIneligibilityFlow) return 'MBH Ineligibility';
  if (isB2B) return 'B2B QuickMatch';
  return 'QuickMatch';
};
