import { QMFlow, EligibilityType } from './types';
import { initFlowConfig } from './utils';

/** Optum EAP */
export const FLOW_62_B2B_EAP_OPTUM = 62;

const flow: QMFlow = {
  flowId: FLOW_62_B2B_EAP_OPTUM,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    isEAP: true,
    isTeen: true,
    eligibilityType: EligibilityType.organizationWithAddress,
  },
  steps: [
    {
      id: 1,
      analyticsId: 54,
      category: 'oneFormEligibility',
      progressCurrent: 2,
      response_category_id: 9,
      progressMax: 10,
      removeOrganization: true,
      authCodeLabel: 'Authorization Code',
      authCodeTooltip:
        'This is the approval number or authorization code received verbally or in the Employee Wellbeing Solution Authorization letter. Enter the full value of the code including all characters.',
      authCodeMaxLength: 30,
      hasNumberOfSessions: true,
      numberOfSessionsTooltip:
        "This is the number of remaining sessions you have on your Employee Wellbeing Solution plan. If you're not sure, contact your provider for more details.",
      hasAuthCodeExpiration: true,
      authCodeExpirationMaxDays: 365,
      authCodeExpirationTooltip:
        "This is the expiration date for your authorization code. If you're not sure, contact your provider for more details.",
    },
  ],
};

export default flow;
