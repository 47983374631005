import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useUniqueID, SelectRounded, OptionType, Label, View } from '@talkspace/react-toolkit';
import { states } from '@talkspace/configs';
import { OptionsType, ValueType } from 'react-select';
import { formatStateOptions, sortOptionsAlphabetically } from 'ts-frontend/helpers/optionsHelpers';
import {
  getPresentingProblemsByIds,
  presentingProblemsList,
} from 'ts-frontend/helpers/presentingProblems';
import { ClientMatchPresentingProblem } from 'ts-frontend/types';
import styled from '@/core/styled';
import { FiltersState } from './types';

const PROVIDER_GENDER_OPTIONS: OptionType<string>[] = [
  { label: 'Male', value: '1' },
  { label: 'Female', value: '2' },
  { label: 'Any', value: '3' },
];

interface FiltersProps {
  clientMatchPresentingProblems: ClientMatchPresentingProblem[];
  setFiltersState: ({ state, specialties, providerGender }: FiltersState) => void;
  clientMatchGenderPreference?: string;
  clientState?: string;
}

const StyledLabel = styled(Label)({ marginBottom: -16, marginTop: 12 });

const findOptionValue = (optionsArr: OptionType[], targetValue: string | number | undefined) =>
  optionsArr.find((option) => option.value === targetValue);

const findOptionsByValues = (optionsArr: OptionType[], targetValues: string[]) =>
  optionsArr.filter((option) => targetValues.includes(option.value));

const Filters: FunctionComponent<FiltersProps> = ({
  clientMatchPresentingProblems,
  setFiltersState,
  clientState = undefined,
  clientMatchGenderPreference = undefined,
}) => {
  const presentingProblems = clientMatchPresentingProblems.map((p) => String(p.id));
  const stateOptions = useMemo(() => formatStateOptions(states), []);
  const specialtiesOptions = useMemo(
    () =>
      presentingProblemsList
        .filter((x) => x.id && x.expertiseName && x.expertiseID)
        .filter((x) => x.id !== 5) // Bipolar Disorder - Depression
        .filter((x) => x.id !== 6) // Bipolar Disorder - Mania
        .map((x) => {
          return {
            label: x.name,
            value: String(x.id),
          };
        })
        .sort(sortOptionsAlphabetically),
    []
  );
  const [selectedState, setSelectedState] = useState<OptionType<string> | null>(
    () => findOptionValue(stateOptions, clientState) || null
  );
  const [selectedGender, setSelectedGender] = useState<OptionType<string> | null>(
    () => findOptionValue(PROVIDER_GENDER_OPTIONS, clientMatchGenderPreference) || null
  );
  const [selectedSpecialties, setSelectedSpecialties] = useState<OptionType<string>[]>(
    () => findOptionsByValues(specialtiesOptions, presentingProblems) || []
  );

  const handleOnChangeState = (value: ValueType<OptionType<string>, boolean>) => {
    setSelectedState(value as OptionType);
  };

  const handleOnChangeSpecialties = (value: ValueType<OptionType<string>, boolean>) => {
    setSelectedSpecialties(value as OptionType<string>[]);
  };

  const handleOnChangeProviderGender = (value: ValueType<OptionType<string>, boolean>) => {
    setSelectedGender(value as OptionType);
  };

  useEffect(() => {
    const presentingProblemIds: number[] = selectedSpecialties?.map((s) => Number(s.value)) || [];
    const specialties = getPresentingProblemsByIds(presentingProblemIds);
    setFiltersState({
      state: selectedState?.value || '',
      specialties,
      providerGender: selectedGender?.value || undefined,
    });
  }, [selectedState, selectedSpecialties, selectedGender, setFiltersState]);

  const stateInputId = useUniqueID('stateInputId');
  const specialtiesInputId = useUniqueID('specialtiesInputId');
  const providerGenderInputId = useUniqueID('providerGenderInputId');

  return (
    <View style={{ width: '100%', maxWidth: 335 }}>
      <StyledLabel htmlFor={stateInputId} style={{ marginTop: 0 }} isDefaultStyle>
        State of residence
      </StyledLabel>
      <SelectRounded
        ariaLabelledBy={stateInputId}
        dataQa="stateSelectFilterDropdown"
        options={stateOptions}
        value={selectedState}
        onChange={handleOnChangeState}
        placeholder="Select state"
        width="100%"
      />
      <StyledLabel htmlFor={specialtiesInputId} isDefaultStyle>
        Specialties
      </StyledLabel>
      <SelectRounded
        ariaLabelledBy={specialtiesInputId}
        dataQa="specialtiesFilterDropdown"
        options={specialtiesOptions as OptionsType<OptionType<string>>}
        value={selectedSpecialties}
        onChange={handleOnChangeSpecialties}
        placeholder="Select specialties"
        width="100%"
        isMulti
      />
      <StyledLabel htmlFor={providerGenderInputId} isDefaultStyle>
        Provider Gender
      </StyledLabel>
      <SelectRounded
        ariaLabelledBy={providerGenderInputId}
        dataQa="providerGenderFilterDropdown"
        options={PROVIDER_GENDER_OPTIONS}
        value={selectedGender}
        onChange={handleOnChangeProviderGender}
        placeholder="Select gender"
        width="100%"
      />
    </View>
  );
};

export default Filters;
