import { useReducer } from 'react';
import InsuranceCardImages from './InsuranceCardImages';
import IDCardImages from './IDCardImages';
import { FileUploadProps, ReducerState } from './types';

const FileUpload = ({ updateStep, step, setEligibilityFiles }: FileUploadProps) => {
  const [reducerState, dispatch] = useReducer(
    (state: ReducerState, { type, payload }) => {
      switch (type) {
        case 'setInsuranceFront':
          return {
            ...state,
            insuranceFront: payload.file,
          };
        case 'setInsuranceBack':
          return {
            ...state,
            insuranceBack: payload.file,
          };
        case 'setIDFront':
          return {
            ...state,
            idFront: payload.file,
          };
        case 'setIDBack':
          return {
            ...state,
            idBack: payload.file,
          };
        default:
          return state;
      }
    },
    {
      insuranceFront: null,
      insuranceBack: null,
      idFront: null,
      idBack: null,
    }
  );

  if (!reducerState.insuranceFront || !reducerState.insuranceBack) {
    return (
      <InsuranceCardImages
        onUploadFront={(image: File) => {
          dispatch({
            type: 'setInsuranceFront',
            payload: {
              file: image,
            },
          });
        }}
        onUploadBack={(image: File) => {
          dispatch({
            type: 'setInsuranceBack',
            payload: {
              file: image,
            },
          });
        }}
        onDeleteFront={() => {
          dispatch({
            type: 'setInsuranceFront',
            payload: {
              file: null,
            },
          });
        }}
        onDeleteBack={() => {
          dispatch({
            type: 'setInsuranceBack',
            payload: {
              file: null,
            },
          });
        }}
      />
    );
  }

  return (
    <IDCardImages
      onUploadFront={(image: File) => {
        dispatch({
          type: 'setIDFront',
          payload: {
            file: image,
          },
        });
      }}
      onUploadBack={(image: File) => {
        dispatch({
          type: 'setIDBack',
          payload: {
            file: image,
          },
        });
      }}
      onDeleteFront={() => {
        dispatch({
          type: 'setIDFront',
          payload: {
            file: null,
          },
        });
      }}
      onDeleteBack={() => {
        dispatch({
          type: 'setIDBack',
          payload: {
            file: null,
          },
        });
      }}
      onContinue={() => {
        if (!reducerState.insuranceFront || !reducerState.insuranceBack || !reducerState.idFront) {
          return;
        }
        setEligibilityFiles({
          insuranceFront: reducerState.insuranceFront,
          insuranceBack: reducerState.insuranceBack,
          idFront: reducerState.idFront,
          idBack: reducerState.idBack,
        });
        updateStep(step.id);
      }}
    />
  );
};

export default FileUpload;
