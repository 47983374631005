import { QMFlow, EligibilityType } from './types';
import { initFlowConfig } from './utils';

/** Generic EAP */
export const FLOW_89_B2B_EAP = 89;

const flow: QMFlow = {
  flowId: FLOW_89_B2B_EAP,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    isEAP: true,
    isTeen: true,
    eligibilityType: EligibilityType.organizationWithAddress,
  },
  steps: [
    {
      id: 1,
      analyticsId: 54,
      category: 'oneFormEligibility',
      progressCurrent: 2,
      response_category_id: 9,
      progressMax: 10,
      optionalOrganization: false,
      authCodeLabel: 'Authorization Code',
      authCodeTooltip:
        'This is the approval number or authorization code received verbally or in the EAP authorization letter. Enter the full value of the code including all characters.',
    },
  ],
};

export default flow;
