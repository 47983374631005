import ReactFrameServiceWrapper from '@/Components/ReactFrameServiceWrapper';

function withFrameService<P extends object>(WrappedComponent: React.ComponentType<P>) {
  return function FrameServiceWrapper(props: P) {
    return (
      <ReactFrameServiceWrapper>
        <WrappedComponent {...(props as P)} />
      </ReactFrameServiceWrapper>
    );
  };
}

export default withFrameService;
