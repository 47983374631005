import { useState } from 'react';
import {
  View,
  Button,
  SelectRounded,
  useUniqueID,
  Label,
  OptionType,
  useEmotionTheme,
  selectLoadOptions,
} from '@talkspace/react-toolkit';
import { ValueType } from 'react-select';
import useQueryPublicSchoolList from '../../hooks/useQueryPublicSchoolList';
import styled from '@/core/styled';
import { SchoolSelectStep, UpdateStep } from '../../Flows';

const StyledButton = styled(Button)(({ theme: { colors } }) => {
  return {
    transition: 'all 200ms ease-in-out',
    '&:not([disabled]):hover': {
      backgroundColor: colors.permaTropicalRainForest,
    },
  };
});

type SchoolSelectProps = {
  step: SchoolSelectStep;
  updateStep: UpdateStep;
};

const SchoolSelect = ({ step, updateStep }: SchoolSelectProps) => {
  const [searchQuery, setSearchQuery] = useState<OptionType<string> | null>(null);

  const { data: schoolList = [], isLoading: isLoadingSchoolList } = useQueryPublicSchoolList(true);
  const inputID = useUniqueID('inputID');
  const onChange = (val: ValueType<OptionType<string>, false>) => {
    setSearchQuery(val || null);
  };
  const { buttonText } = step;
  const { colors } = useEmotionTheme();
  return (
    <View>
      <Label htmlFor={inputID}>School</Label>
      <SelectRounded
        inputId={inputID}
        isInputReadOnly={false}
        value={searchQuery}
        defaultOptions={schoolList}
        loadOptions={selectLoadOptions(schoolList)}
        isAsync
        cacheOptions
        isCreatable
        isLoading={isLoadingSchoolList}
        onChange={onChange}
        placeholder="Select school"
        wrapperStyle={{ marginTop: 0 }}
      />
      {searchQuery && (
        <StyledButton
          onClick={() => {
            updateStep(step.buttonTarget, {
              payfirst_step_id: step.analyticsId,
              payfirst_step_prompt: step.prompt,
              response_category_id: step.response_category_id,
              response_prompt: searchQuery?.label,
              response_value: searchQuery?.value || undefined,
            });
          }}
          roundedFocusStyle
          primaryColor={colors.green}
          style={{ width: '100%', fontWeight: 700 }}
          disabled={!searchQuery}
          dataQa={`${step.category || ''}ContinueButton`}
        >
          {buttonText}
        </StyledButton>
      )}
    </View>
  );
};

export default SchoolSelect;
