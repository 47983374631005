/* eslint-disable import/prefer-default-export */
import { CommonServiceType } from 'ts-frontend/types';
import { apiHelperV4 } from '@/Helpers/apiHelper';
import { getFileExtensionFromFileType } from '@/utils/files';
import { QuickEligibilityInfo } from '@/Components/HomePage/types';
import apiWrapper from '@/core/api/apiWrapper';

const FILE_NAME_SUFFIXES = ['insurance-front', 'insurance-back', 'id-front', 'id-back'] as const;

type FileNameSuffix = typeof FILE_NAME_SUFFIXES[number];

interface SignManualCoverageImageUploadURLProps {
  nameSuffix: FileNameSuffix;
  fileType: string;
}

interface SignedUrlResponse {
  signedRequest: string;
  url: string;
}

const signManualCoverageImageUploadURL = ({
  nameSuffix,
  fileType,
}: SignManualCoverageImageUploadURLProps): Promise<SignedUrlResponse> => {
  if (!fileType.startsWith('image/')) throw new Error('fileType must be image/*');
  const extension = getFileExtensionFromFileType(fileType);
  return apiWrapper.post(`${apiHelperV4()}eligibility/manual-coverage-request/sign-image-url`, {
    fileName: `${nameSuffix}.${extension}`, // NOTE: this will be prepended with QM session ID within qm-api
    fileType,
  });
};

type SubmitManualCoverageRequestProps = QuickEligibilityInfo & {
  email: string;
  uploadedFiles: { nameSuffix: FileNameSuffix; extension: string }[];
  partnerID?: number;
  serviceType?: CommonServiceType;
};
const submitManualCoverageRequest = ({
  email,
  memberID,
  firstName,
  lastName,
  dob,
  state,
  partnerID,
  serviceType,
  uploadedFiles,
}: SubmitManualCoverageRequestProps): Promise<{}> =>
  apiWrapper.post(`${apiHelperV4()}eligibility/manual-coverage-request/submit`, {
    email,
    memberID,
    firstName,
    lastName,
    dateOfBirth: dob,
    state,
    partnerID,
    serviceType,
    uploadedFiles,
  });

export { submitManualCoverageRequest, signManualCoverageImageUploadURL };
