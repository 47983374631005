import {
  ExtraHuge,
  SimpleQuestions,
  SimpleSurveyQuestionsProps,
  View,
} from '@talkspace/react-toolkit';
import { useCallback } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { isLDExperimentTreatment } from 'launchDarkly/util';
import styled from '@/core/styled';
import NYCHealthBanner from '../../assets/NYCHealthBanner';
import switchFlowHelper from '../../../Helpers/switchFlowHelper';
import { useHistory } from '@/core/routerLib';
import { FLOW_128_NYC_TEENS, FLOW_140_TEEN_THERAPY } from '../../../Flows';
import { trackEvent } from '../../../utils/analytics/events';
import { FLOW_147_TEENSPACE_OPTIMIZED } from '../../../Flows/Flow147';

const COVERED_ID = 'covered' as const;
const NOT_COVERED_ID = 'not-covered' as const;
const BRANCHING_OPTIONS = [
  {
    value: COVERED_ID,
    label: 'Yes, I think so',
    description:
      'We’ll check your eligibility—some cities like New York City offer free therapy to their teens',
  },
  {
    value: NOT_COVERED_ID,
    label: 'I’ll use a different form of coverage',
    description: 'You can use insurance, company benefits, or self-pay options',
  },
];

type TeenBranchingProps = {
  updateAndSave: (state: Partial<RecoveredSessionState>) => void;
};

const Styled = {
  Container: styled(View)({
    maxWidth: 350,
    alignItems: 'center',
    justifyContent: 'center',
    '&:focus': { outline: 'none' },
  }),
  BannerContainer: styled(View)({
    marginTop: 24,
    width: 349,
  }),
};

const TeenBranching = ({ updateAndSave }: TeenBranchingProps) => {
  const history = useHistory();
  const flags = useFlags();

  const onPress: SimpleSurveyQuestionsProps['onPress'] = useCallback(
    (response) => {
      const isCovered = !Array.isArray(response) && response.value === COVERED_ID;
      trackEvent('Teen Branching CTA', {
        Choice: isCovered ? 'School or City' : 'Default',
      });
      if (isCovered) {
        const isTeenspaceTreatment = isLDExperimentTreatment(flags, 'teenspaceCrOptimization');

        if (flags.teenspaceCrOptimization.experimentActive) {
          trackEvent('TS Experiment Session', {
            experimentName: flags.teenspaceCrOptimization.experimentName,
            variantName: flags.teenspaceCrOptimization.variant,
          });
        }
        switchFlowHelper(
          isTeenspaceTreatment ? FLOW_147_TEENSPACE_OPTIMIZED : FLOW_128_NYC_TEENS,
          history,
          updateAndSave,
          {
            stepId: 1,
          }
        );
      } else {
        switchFlowHelper(FLOW_140_TEEN_THERAPY, history, updateAndSave, {
          stepId: 1,
        });
      }
    },
    [history, flags, updateAndSave]
  );

  return (
    <Styled.Container>
      <ExtraHuge>Does your school or city offer Talkspace for free?</ExtraHuge>
      <SimpleQuestions
        wrapperStyle={{ marginTop: 24 }}
        fieldSetStyle={{ padding: 5 }}
        questions={BRANCHING_OPTIONS}
        onPress={onPress}
      />
      <Styled.BannerContainer>
        <NYCHealthBanner />
      </Styled.BannerContainer>
    </Styled.Container>
  );
};

export default TeenBranching;
