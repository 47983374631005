import { QMFlow, EligibilityType } from './types';
import { initFlowConfig } from './utils';
import { FLOW_90_SELECT_SERVICE } from './Flow90';
import appConfigs from '../utils/configs';

export const FLOW_82_DTE_FILE_FAMILY_MEDICAL_CARE = 82;

const flow: QMFlow = {
  flowId: FLOW_82_DTE_FILE_FAMILY_MEDICAL_CARE,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    isEligibilityFileFlow: true,
    shouldHideServiceSelection: true,
    serviceKeywords: {
      psychotherapy: 'FMCPeligibilityfileDTEkeyword',
    },
    partnerID: 117,
    eligibilityType: EligibilityType.fileBased,
  },
  steps: [
    {
      id: 1,
      analyticsId: 56,
      category: 'oneFormEligibility',
      response_category_id: 9,
      isGroupIdRequired: false,
      optionalOrganization: true,
      buttonTarget: 2,
      skipText: 'Continue without insurance',
      skipRedirectToFlow: FLOW_90_SELECT_SERVICE,
    },
    {
      id: 2,
      prompt: 'Validate Voucher',
      analyticsId: 58,
      category: 'validateVoucher',
      response_category_id: 8,
      heading1: null,
      heading2: 'Thank you.',
      heading3: `If you have not received an email within 5 minutes, please check your spam/junk email folders and if still missing contact ${appConfigs.supportEmailLinks.gatorcareSupport}`,
      behavioralHealth: true,
      buttonText: 'Next',
      hideBackButton: true,
      supportEmail: appConfigs.supportEmailLinks.gatorcareSupport,
      linkToFAQ: 'https://www.talkspace.com/faq/bh',
    },
  ],
};

export default flow;
