import { FunctionComponent } from 'react';
import { ChatBubbleWithBadge, Large, BaseButton } from '@talkspace/react-toolkit';
import styled from '../../core/styled';

const ConsultantButtonText = styled(Large)(
  ({
    theme: {
      colors: { blueStone },
    },
  }) => {
    return {
      color: blueStone,
      fontWeight: 700,
    };
  }
);

const ChatWithConsultantButton: FunctionComponent<{
  onPress: () => void;
  roundedFocusStyle: boolean;
  primaryColor: string;
  dataQa: string;
}> = ({ onPress, roundedFocusStyle, primaryColor, dataQa }) => (
  <BaseButton
    onPress={onPress}
    roundedFocusStyle={roundedFocusStyle}
    primaryColor={primaryColor}
    style={{ borderRadius: 5 }}
    data-qa={dataQa}
  >
    <ConsultantButtonText inline variant="largeBoldBrightGreen">
      Chat with a live consultant <ChatBubbleWithBadge width={20} />
    </ConsultantButtonText>
  </BaseButton>
);
export default ChatWithConsultantButton;
