/**
 * This module exports a HOC (`withCypressHooks`) that makes *no* functional changes
 * to the application itself. It exists to support Cypress testing. It attaches a
 * `cypress.setFlag` function to the window object that is used by the test suite.
 */
import { useEffect } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';

const setFlagCallbacks: ((any) => void)[] = [];
const win = window as any;
win.cypress = {};

const withCypressHooks = (Component) => (props) => {
  const { setFlag, ...flags } = useFlags();

  useEffect(() => {
    win.cypress.setFlag = (key: string, value: any, cb?: (any) => void) => {
      if (cb) {
        setFlagCallbacks.push(cb);
      }
      setFlag(key, value);
    };
  }, [setFlag]);

  useEffect(() => {
    setFlagCallbacks.forEach((cb) => {
      cb(flags);
    });
    setFlagCallbacks.splice(0, setFlagCallbacks.length);
  }, [flags]);

  return <Component {...props} />;
};

export default withCypressHooks;
