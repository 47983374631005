import { ExtraHuge } from '@talkspace/react-toolkit';
import Page from '../Page/Page';

export default function NotFoundPage() {
  return (
    <Page isLoggedIn={false}>
      <ExtraHuge style={{ marginTop: 80, fontSize: 40, alignSelf: 'center' }}>Not found</ExtraHuge>
    </Page>
  );
}
