/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react';
import { ValueType } from 'react-select';
import { PayerOptionType } from 'ts-frontend/types';
import { OptionType } from '@talkspace/react-toolkit';

import { SESSION_STORAGE_MEMBER_DETAILS_KEY, upsertSession } from 'ts-frontend/utils';
import { UseHandleChangeOptions } from '../types';

export const useHandleChange = ({
  setShowDropdownError,
  setSelectedPayer,
}: UseHandleChangeOptions) =>
  useCallback(
    (value: ValueType<OptionType, boolean>) => {
      const option = value as PayerOptionType;
      const insurancePayerID = option.id;
      setShowDropdownError(false);
      setSelectedPayer(option);
      upsertSession(SESSION_STORAGE_MEMBER_DETAILS_KEY, { insurancePayerID });
    },
    [setShowDropdownError, setSelectedPayer]
  );
