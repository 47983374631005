import { useEffect, useState, useMemo, VoidFunctionComponent } from 'react';
import {
  Button,
  View,
  Carousel,
  CancellationPolicy,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import { Move } from '@talkspace/react-toolkit/src/components/Carousel';
import useStringTranslation from '@talkspace/i18n/hooks/useStringTranslation';
import styled from '@/core/styled';
import AsyncSessionCarouselStep from './AsyncSessionCarouselStep';
import CancellationPolicyMessage from './CancellationPolicyMessage';
import {
  trackAsyncConfirmSessionClick,
  trackAsyncSessionInfoCancellationPolicyClick,
  trackAsyncSessionInfoNextClick,
} from '@/utils/analytics/events';
import useBackButton from '../../StepLayout/hooks/useBackButton';
import { SessionModalityStep } from '../../../Flows';
import { useHomePageState } from '@/Components/HomePage';

const StyledButton = styled(Button)({
  marginTop: 18,
  fontWeight: 700,
  fontSize: 17,
  width: 335,
});

interface AsyncSessionCarouselProps {
  onConfirmSessionButtonPress: () => void;
  goBackToSelectSessionModality: () => void;
  step: SessionModalityStep;
}

const AsyncSessionCarousel: VoidFunctionComponent<AsyncSessionCarouselProps> = ({
  step,
  onConfirmSessionButtonPress,
  goBackToSelectSessionModality,
}) => {
  const { accountType } = useHomePageState();
  const isEAP = accountType === 'eap';

  const { setOverriddenBackButtonBehavior } = useBackButton(step);
  const { isMobile } = useWindowWidthState();
  const [move, setMove] = useState<Move>(null);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isCancellationPolicyOpen, setIsCancellationPolicyOpen] = useState(false);
  const [jumpTo, setJumpTo] = useState<number | null>(null);

  const steps = useMemo(
    () => [
      <AsyncSessionCarouselStep key={1} step={1} isEAP={isEAP} />,
      <AsyncSessionCarouselStep key={2} step={2} isEAP={isEAP} />,
    ],
    [isEAP]
  );

  const handleStepChange = (changedStep: number) => {
    setIsLastStep(changedStep === steps.length - 1);
  };

  const handleForwardButtonPress = () => {
    setMove('forward');
    if (isLastStep) {
      trackAsyncConfirmSessionClick();
    } else {
      trackAsyncSessionInfoNextClick();
    }
  };

  useEffect(() => {
    // set custom behavior to back button in HomePage
    if (isCancellationPolicyOpen) {
      setOverriddenBackButtonBehavior(() => {
        setIsCancellationPolicyOpen(false);
        setJumpTo(1);
      });
    } else if (isLastStep) {
      setOverriddenBackButtonBehavior(() => {
        setMove('backward');
      });
    } else {
      setOverriddenBackButtonBehavior(() => {
        goBackToSelectSessionModality();
      });
    }
  }, [
    goBackToSelectSessionModality,
    isCancellationPolicyOpen,
    isLastStep,
    setOverriddenBackButtonBehavior,
  ]);

  const handleCancellationPolicyPress = () => {
    setIsCancellationPolicyOpen(true);
    trackAsyncSessionInfoCancellationPolicyClick();
  };

  const lastButtonText = useStringTranslation(
    'Confirm session',
    'quickmatch.common',
    'sessionModalityStepAsyncSessionCarousel.lastButtonText'
  );
  const nextButtonText = useStringTranslation(
    'Next',
    'quickmatch.common',
    'sessionModalityStepAsyncSessionCarousel.nextButtonText'
  );

  return (
    <View
      flex={1}
      align="center"
      justify="center"
      style={{ marginTop: 37, width: isMobile ? 335 : 430, overflowX: 'hidden' }}
    >
      {isCancellationPolicyOpen && (
        <View flex={1} style={{ textAlign: 'left' }}>
          <CancellationPolicy variant="async" />
        </View>
      )}
      {!isCancellationPolicyOpen && (
        <>
          <Carousel
            hideArrows
            move={move}
            resetMove={() => {
              setIsLastStep(false);
              setMove(null);
              setJumpTo(null);
            }}
            lastStepOneTimeAction={onConfirmSessionButtonPress}
            reactivateLastStepAction
            onStepChange={handleStepChange}
            jumpTo={jumpTo}
            carouselIndicatorsStyles={{ marginTop: 0, marginBottom: 0 }}
            containerStyle={{ maxWidth: 680, overflowX: 'hidden' }}
          >
            {steps}
          </Carousel>
          <StyledButton
            onPress={handleForwardButtonPress}
            text={isLastStep ? lastButtonText : nextButtonText}
            dataQa={
              isLastStep
                ? 'messagingInformationConfirmSessionButton'
                : 'messagingInformationNextButton'
            }
          />
          {isLastStep && (
            <CancellationPolicyMessage onCancellationPolicyPress={handleCancellationPolicyPress} />
          )}
        </>
      )}
    </View>
  );
};

export default AsyncSessionCarousel;
