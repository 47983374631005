// eslint-disable-next-line import/prefer-default-export
export const QM_COPY = {
  state: {
    continue: 'Continue',
    placeholder: 'Select a state',
    prompt: 'What is your state?',
    submit: 'Submit',
    subtitle: 'We’ll find you a licensed provider in that state',
    title: 'What state do you live in most of the year?',
  },
  country: {
    title: 'Select your country of residence.',
  },
};
