/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';
import sessionStorage from '@/core/storage/sessionStorage';
import { setSessionBasicInformation, setSessionMemberMemberDetails } from '../util';
import { OneFormEligibilityFields, RecoveredField } from '../types';

// sets the appropriate keys in session storage so that the form can be prefilled with data if user navigates away and then returns
export const useSessionStorage = (
  getValues: (payload?: string | string[]) => OneFormEligibilityFields,
  setRecoveredField: <T extends RecoveredField>(field: T, data: RecoveredFields[T]) => void,
  displayEmployeeInfo: boolean
) =>
  useEffect(
    () => () => {
      const formValues = getValues();

      setSessionBasicInformation(formValues, displayEmployeeInfo);
      setRecoveredField('basicInformation', sessionStorage.getItem('TSQM_BasicInformation'));
      setSessionMemberMemberDetails(formValues, displayEmployeeInfo);
      setRecoveredField('memberDetails', sessionStorage.getItem('TSQM_MemberDetails'));
    },
    [getValues, setRecoveredField, displayEmployeeInfo]
  );
