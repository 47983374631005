/* eslint camelcase: off */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FunctionComponent, useState, useEffect, useCallback, useMemo } from 'react';
// import ReactDOMServer from 'react-dom/server';
import {
  useUniqueID,
  StepTip,
  View,
  OptionType,
  useWindowWidthState,
  Spinner,
} from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useTranslationActions } from '@talkspace/i18n/hooks/translationContext';
import useStringTranslation from '@talkspace/i18n/hooks/useStringTranslation';
import SelectSessionModality from '@/Components/Steps/SelectSessionModality';
import sessionStorage from '../../core/storage/sessionStorage';
import { countries } from '../../Helpers/locales';
import { getQMFunnelName } from '../../Helpers/flowsHelper';
import Explainer from '@/Components/Steps/Explainer';
import Buttons from '../Buttons/Buttons';
import MultiSelect from '../MultiSelect/MultiSelect';
import Content from '../Content/Content';
import Loading from '@/Components/Steps/Loading';
import Scheduler from '@/Components/Steps/Scheduler';
import Matches from '../Matches/Matches';
import SkipOption from '../SkipOption/SkipOption';
import StateCountryToggle from '../StateCountryToggle/StateCountryToggle';
import Dropdown from '../Dropdown/Dropdown';
import NoMatches from '../Payment/NoMatches';
import RegisterWithVoucher from '../Payment/RegisterWithVoucher';
import RegisterWithoutPayment from '../Payment/RegisterWithoutPayment';
import ValidateVoucher from '../Payment/ValidateVoucher';
import DateOfBirth from '@/Components/Steps/DateOfBirth';
import NotAvailableInYourRegion from '@/Components/Steps/NotAvailableInYourRegion';
import RegisterWithBHCopay from '@/Components/Steps/RegisterWithBHCopay';
import PsychiatryUnavailable from '@/Components/Steps/PsychiatryUnavailable';
import Nav from '../Nav/Nav';
import { FLOW_19_B2B_EAP_HUMANA } from '../../Flows';
import { useRecoveredSessionActions } from '../../hooks/recoveredSessionContext';
import useDynamicStepValues from '../../hooks/useDynamicStepValues';
import EligibilityStartTherapyContainer from '../../containers/EligibilityStartTherapyContainer';
import TherapistSelectedConfirmation from '../TherapistSelectedConfirmation/TherapistSelectedConfirmation';
import ReactFrameService from '../../utils/reactFrame/ReactFrameService';
import appConfigs from '../../utils/configs';
import RegisterOutOfPocket from '../Payment/RegisterOutOfPocket';
import { QuickEligibilityInfo, EligibilityFiles } from '../HomePage/types';
import {
  DropdownStep,
  SelectButton,
  EligibilityType,
  FlowStepResolved,
  FlowStep,
} from '../../Flows/types';
import {
  isSelectStep,
  isMultiSelectStep,
  isChooseAndPayClientWebStep,
  isMatchesStep,
  isExplanationStep,
  isDropdownStep,
  isLoadingStep,
  isBHCopayStep,
  isRegisterWithVoucherStep,
  isRegisterWithoutPaymentStep,
  isFreeTextStep,
  isValidateVoucherStep,
  isPsychiatryUnavailableStep,
  isSchedulerStep,
  isDateOfBirthStep,
  isNotAvailableInYourRegionStep,
  isSurveyWizardStep,
  isSurveyIntroductionStep,
  isSurveyResultsStep,
  isMatchConfirmationStep,
  isLocationEligibilityStep,
  isLocationEligibilityErrorStep,
  isLocationEligibilityManualAccessCodeStep,
  isPresentingProblemsStep,
  isB2BForkStep,
  isSecondaryInsuranceStep,
  isDispatcherInQMStep,
  isLastingPromotionStep,
  isOneFormEligibilityStep,
  isStateCountrySelectionStep,
  isServiceSelectionStep,
  isCurrentProviderStep,
  isSessionModalityStep,
  isReviewPlanStep,
  isInsuranceConfirmationStep,
  isOutOfNetworkStep,
  isFileUploadStep,
  isQuickEligibilityStep,
  isMemberAvailabilityStep,
  isQuickEligibilityEnterMemberIDStep,
  isQuickEligibilityStatusStep,
  isPreRegisterHoldExpiredStep,
  isPreRegisterJwtExpiredStep,
  isManualSubmit,
  isRequestReceivedStep,
  isTeenBranchingStep,
  isTeenNYCSummaryStep,
  isTeenLetsLearnAboutYouStep,
  isTeenYouAreNotAloneStep,
  isTeenConsentHeadsUpStep,
  isTeenThankYouForSharingStep,
  isSelectDynamicStepResolved,
  isLeadCaptureStep,
  isTeensHereToHelpStep,
  isTeensEligibleFreeTherapyStep,
  isSchoolSelectStep,
  isTeenZipcodeEligibilityStep,
} from '../../Helpers/stepTypeHelper';
import ManualSubmit from '@/Components/Steps/ManualSubmit';
import FileUpload from '@/Components/Steps/FileUpload';
import SurveyIntroduction from '@/Components/Steps/SurveyIntroduction';
import SurveyWizardWrapper from '@/Components/Steps/SurveyWizardWrapper';
import SurveyResults from '@/Components/Steps/SurveyResults';
import MatchConfirmation from '../MatchConfirmation/MatchConfirmation';
import LocationEligibility, {
  LocationEligibilityError,
  LocationEligibilityManualAccessCode,
} from '@/Components/Steps/LocationEligibility';
import InsuranceCheckConfirmation from '../InsuranceCheckConfirmation';
import LastingPromotion from '@/Components/Steps/LastingPromotion';
import MainContentWrapper, { StepLayoutButton } from './Components/MainContentWrapper';
import OneFormEligibility from '../OneFormEligibility/OneFormEligibility';
import FiltersModal from '../FiltersModal/FiltersModal';
import B2BFork from '../B2BFork';
import RequestReceived from '@/Components/Steps/RequestReceived';
import DispatcherInQM from '../DispatcherInQM';
import FreeText from '@/Components/Steps/FreeText';
import ServiceSelection from '@/Components/Steps/ServiceSelection';
import CurrentProvider from '@/Components/Steps/CurrentProvider';
import ReviewPlansBHOrEAP from '@/Components/Steps/ReviewPlansBHOrEAP';
import OutOfNetwork from '@/Components/Steps/OutOfNetwork';
import QuickEligibility from '@/Components/Steps/QuickEligibility';
import QuickEligibilityEnterMemberID from '@/Components/Steps/QuickEligibilityEnterMemberID';
import QuickEligibilityStatus from '@/Components/Steps/QuickStatus';
import PreRegisterHoldExpired from '@/Components/Steps/PreRegisterExpired/PreRegisterHoldExpired';
import PreRegisterJwtExpired from '@/Components/Steps/PreRegisterExpired/PreRegisterJwtExpired';
import { useVWOToggler } from './hooks';
import { FinalStepLayoutProps } from './types';
import countriesHelper from '@/Helpers/countriesHelper';
import TeenBranching from '../Steps/TeenBranching';
import TeenNYCSummary from '../Steps/TeenNYCSummary';
import TeenLetsLearnAboutYou from '../Steps/TeenLetsLearnAboutYou';
import TeenYouAreNotAlone from '../Steps/TeenYouAreNotAlone';
import TeenConsentHeadsUp from '../Steps/TeenConsentHeadsUp';
import TeenThankYouForSharing from '../Steps/TeenThankYouForSharing';
import LeadCapture from '../Steps/LeadCapture';
import { getPresentingProblemList } from '../../Helpers/presentingProblemsHelper';
import SecondaryInsurance from '../SecondaryInsurance';
import { QM_COPY } from '../../utils/qmCopy';
import TeensHereToHelp from '../Steps/TeensHereToHelp';
import TeensEligibleFreeTherapy from '../Steps/TeensEligibleFreeTherapy';
import SchoolSelect from '../SchoolSelect';
import TeenZipcodeEligibility from '../Steps/TeenZipcodeEligibility';
import MemberAvailability from '../Steps/MemberAvailability';

const getButtonOptions = (
  step: FlowStep,
  stepDynamicResolved: FlowStepResolved,
  stepPresentingProblemsButtons: Array<SelectButton>
): SelectButton[] => {
  if (isSelectDynamicStepResolved(stepDynamicResolved)) {
    return stepDynamicResolved.buttons || [];
  }
  return isSelectStep(step) ? step.buttons : stepPresentingProblemsButtons;
};

const StepLayout: FunctionComponent<FinalStepLayoutProps> = ({
  isLoggedInUser,
  matchesReceived,
  clientUnderAge,
  hasCoupon,
  onHideFiltersModal,
  handleShowFiltersModal,
  handleSetIsMatchesOverlayDismissed,
  goBack,
  setDiscardMatches,
  updateStep,
  onShowCountries,
  getAnalyticsPaymentInfo,
  onRetargetingSubmit,
  handleClientDateOfBirth,
  handleClientAge,
  onChangeTier,
  resetLivingInfo,
  setRoomID,
  setQMFlowDone,
  applyFilters,
  removeGenderFilter,
  setCreateRoomReturnProps,
  setIsCouldNotBeVerified,
  step,
  flowId,
  redirectFrom,
  livesInTheUS,
  responses,
  clientMatchPresentingProblems,
  isFiltersModalVisible,
  opacity,
  matches,
  exactMatch,
  clientState,
  qmPartnerCode,
  therapistId,
  therapistFirstName,
  therapistLastName,
  therapistTitle,
  therapistLicenses,
  chosenPlan,
  cpPartnerCode,
  appointment,
  answerParams,
  invalidVoucher,
  voucher,
  copayCents,
  insuranceCode,
  isDisabledPreviousButton,
  funnelVariation,
  service,
  clientDateOfBirth,
  currentTier,
  trizettoErrorStatusCode,
  coupon,
  trizettoRequest,
  shouldResetLivingInfo,
  keepProvider,
  isCreateRoomFlow,
  isReactivationFlow,
  isUpdateCoverageFlow,
  isMBHIneligibilityFlow,
  roomID,
  existingUserEmail,
  insuranceEligibility,
  flowConfig,
  redirectFromFlowConfig,
  roomType,
  clientCountry,
  setBlurParentContent,
  clientMatchGenderPreference,
  selectedMatch,
  clientGender,
  scrollPage,
  isNoMatches,
  loadingMatches,
  isMatchesOverlayDismissed,
  isVideoOnlyPlan,
  organizationFlowID,
  b2bForkResult,
  insurancePayer,
  partnerFlowID,
  isB2BOutOfNetwork,
  cognitoActive,
  experimentsVariants,
  setIsConfirmScheduler,
  collectReferralSourceOnSignUp,
  handleReferralSource,
  referralSource,
  currentProvidersInfo,
  hasOnlyPlaceholderProviders,
  allowedModalities,
  hideVWOBar,
  reviewPlanDetails,
  accountType,
  totalSessions,
  manualFlowID,
  updateCoverageRoomID,
  oldRoomHasSessions,
  sessionsCanceled,
  sessionsTransferred,
  isCouldNotBeVerified,
  quickEligibilityInfo,
  memberAvailability,
  setEligibilityFiles,
  eligibilityFiles,
  setManualVerificationEmail,
  manualVerificationEmail,
  previousTherapistId,
  buttonsFieldStyle,
  stepAnswers,
  matchingLanguages,
  headerRef,
  therapistIDFromCreateRoom,
  setInsuranceDetails,
  insuranceDetails,
  isGDPR,
  skipCreditCard,
}) => {
  const [dropdownValue, setDropdownValue] = useState([]);
  const { setRecoveredField, updateAndSave } = useRecoveredSessionActions();
  const heading1ID = useUniqueID('heading1ID');
  const heading2ID = useUniqueID('heading2ID');

  const { i18nNamespace } = flowConfig || {};
  const quickmatchCommonNamespace = 'quickmatch.common';

  const {
    id: stepId,
    category,
    progressCurrent,
    progressMax,
    buttonText,
    heading2TranslationKey,
    heading3TranslationKey,
  } = step;

  const teenEligibility =
    flowConfig?.serviceType === 'therapyTeen' &&
    flowConfig?.eligibilityType === EligibilityType.zipCodeEligibility;

  const { isLoading: stepDynamicIsLoading, stepDynamicResolved } = useDynamicStepValues({
    step,
    homePageState: {
      clientState,
      stepAnswers: stepAnswers || [],
      responses: responses || [],
      qmPartnerCode,
      cpPartnerCode,
      insurancePayer,
      b2bForkResult,
      matchingLanguages,
    },
  });

  const { selfServe } = stepDynamicResolved;

  useVWOToggler({
    isCouldNotBeVerified,
    hideVWOBar,
    b2bForkResult,
    service,
    isLoggedInUser,
    isB2BOutOfNetwork,
    teenEligibility,
    flowId,
    stepId,
  });

  const { localizationFlow138: isLocalizationFlow138Enabled } = useFlags();
  const { setIsTranslationEnabled } = useTranslationActions();
  const translatedFlows = useMemo(
    () => [...(isLocalizationFlow138Enabled ? [138] : [])],
    [isLocalizationFlow138Enabled]
  );

  useEffect(() => {
    if (translatedFlows.includes(flowId)) {
      setIsTranslationEnabled(true);
    } else {
      setIsTranslationEnabled(false);
    }
  }, [flowId, setIsTranslationEnabled, translatedFlows]);

  const REACT_APP_CDN_ENDPOINT = appConfigs.endpoints.cdnEndpoint;

  const hideComponent =
    isLoadingStep(step) ||
    isMatchesStep(step) ||
    isRegisterWithVoucherStep(step) ||
    isChooseAndPayClientWebStep(step) ||
    isValidateVoucherStep(step);

  const statesDropdownOptions = useMemo(() => {
    if (isDropdownStep(step)) {
      if (redirectFrom === FLOW_19_B2B_EAP_HUMANA) {
        // Return list without CA
        return step.selectOptions.filter((option) => option && option.value !== 'CA');
      }
      return step.selectOptions;
    }
    return [];
  }, [redirectFrom, step]);

  const countriesDropdownOptions = useMemo(
    () =>
      redirectFromFlowConfig?.specificCountryCodesToSupport?.length
        ? countries.filter((el) =>
            countriesHelper
              .getRelevantCountryCodesToDisplay(
                redirectFromFlowConfig?.specificCountryCodesToSupport || []
              )
              .includes(el.value)
          )
        : countries,
    [redirectFromFlowConfig?.specificCountryCodesToSupport]
  );
  const selectOptions =
    isStateCountrySelectionStep(step) && !livesInTheUS
      ? countriesDropdownOptions
      : statesDropdownOptions;

  const isSelectCountry = isStateCountrySelectionStep(step) && !livesInTheUS;
  const isSelectState = isStateCountrySelectionStep(step) && livesInTheUS;
  const dropdownPlaceholderCountryTranslated = useStringTranslation(
    'Select a country',
    'quickmatch.common',
    `stateCountrySelectionStep.selectCountryPlaceholder`
  );
  let dropdownPlaceholder = (step as DropdownStep).selectPlaceholder;
  const dropdownPlaceholderStateTranslated = useStringTranslation(
    dropdownPlaceholder,
    i18nNamespace,
    `step${stepId}.selectPlaceholder`
  );

  if (isSelectCountry) {
    dropdownPlaceholder = dropdownPlaceholderCountryTranslated;
  } else if (isSelectState) {
    dropdownPlaceholder = dropdownPlaceholderStateTranslated;
  }

  const countryLabel = useStringTranslation(
    'Country',
    quickmatchCommonNamespace,
    'stateCountrySelectionStep.selectCountryLabel'
  );
  const stateLabel = useStringTranslation(
    'State',
    quickmatchCommonNamespace,
    'stateCountrySelectionStep.selectStateLabel'
  );
  const stateCountryLabel =
    isStateCountrySelectionStep(step) && !livesInTheUS ? countryLabel : stateLabel;

  let { heading2 } = stepDynamicResolved;
  if (isStateCountrySelectionStep(step) && !livesInTheUS) {
    heading2 = QM_COPY.country.title;
  }

  const stepPresentingProblemsButtons: Array<SelectButton> = useMemo(() => {
    if (isPresentingProblemsStep(step)) {
      return getPresentingProblemList({
        serviceType: flowConfig?.serviceType,
        eligibilityType: flowConfig?.eligibilityType,
        secondaryServiceSource: service,
        flowId,
        clientDateOfBirth,
        step,
      }).map(({ id, name, translationKey }) => {
        return {
          text: name,
          translationKey,
          answerValue: id,
          internalTarget: step.internalTarget,
        };
      });
    }
    return [];
  }, [
    step,
    flowId,
    service,
    flowConfig?.serviceType,
    flowConfig?.eligibilityType,
    clientDateOfBirth,
  ]);

  const selectedButton = () => {
    const selected = responses.find((response) => response.stepId === stepId);
    return selected ? selected.response_prompt : '';
  };

  useEffect(() => {
    let selected;
    if (selfServe && selfServe.field === 'fieldsOfExpertise') {
      selected = clientMatchPresentingProblems.map((problem) => {
        return {
          value: problem.id,
          label: problem.name,
        };
      });
      setDropdownValue(selected);
      return;
    }

    // Set selected value when it is coming from direct flows
    if (isStateCountrySelectionStep(step) && livesInTheUS) {
      const { state: memberState, country: memberCountry } = JSON.parse(
        sessionStorage.getItem('TSQM_BasicInformation') || '{}'
      );
      if (!memberState || (memberCountry !== '' && memberCountry !== 'US')) {
        return;
      }
      selected = statesDropdownOptions.filter((stateOption) => stateOption.value === memberState);
      setDropdownValue(selected);
      return;
    }

    if (isStateCountrySelectionStep(step) && !livesInTheUS) {
      const { country: memberCountry } = JSON.parse(
        sessionStorage.getItem('TSQM_BasicInformation') || '{}'
      );
      if (!memberCountry) {
        return;
      }
      selected = countriesDropdownOptions.filter(
        (countryOption) => countryOption.value === memberCountry
      );
      setDropdownValue(selected);
      return;
    }

    // Set selected value based on state from HomePage
    if (isStateCountrySelectionStep(step) && clientCountry && !livesInTheUS) {
      selected = countries.filter((el) => el.value === clientCountry).length
        ? countries.filter((el) => el.value === clientCountry)
        : [];
      setDropdownValue(selected);
      return;
    }
    // Set selected value based on recorded responses
    selected = responses.find((response) => {
      if (response.flowId && response.flowId !== flowId) {
        return false;
      }
      return response.stepId === stepId;
    });
    if (!selected || !selected.response_prompt || !selected.response_value) {
      setDropdownValue([]);
      return;
    }
    const labels = selected.response_prompt.split(',');
    const values = selected.response_value.split(',');
    selected = labels.map((label, index) => {
      return { label, value: values[index] };
    });
    setDropdownValue(selected || []);
  }, [
    selfServe,
    responses,
    clientMatchPresentingProblems,
    clientCountry,
    livesInTheUS,
    flowId,
    step,
    stepId,
    statesDropdownOptions,
    countriesDropdownOptions,
  ]);

  const progressHelpers = {
    progressCurrent,
    progressMax,
    isProgressHidden: hideComponent,
  };

  const showFiltersButton = isMatchesStep(step) && !isNoMatches && matchesReceived;

  const shouldShowBackButtonAndOrProgressBar =
    !isLoadingStep(step) && !(flowConfig && flowConfig.removeNavBar);

  const buttonOptions = getButtonOptions(step, stepDynamicResolved, stepPresentingProblemsButtons);

  const { isMobile } = useWindowWidthState();
  if (stepDynamicIsLoading) {
    return (
      <View style={{ marginTop: 200 }}>
        <Spinner />
      </View>
    );
  }
  return (
    <View>
      {isMatchesStep(step) && isFiltersModalVisible ? (
        <FiltersModal
          hideFiltersModal={onHideFiltersModal}
          clientState={clientState}
          clientMatchGenderPreference={clientMatchGenderPreference}
          clientMatchPresentingProblems={clientMatchPresentingProblems}
          applyFilters={applyFilters}
          loadingMatches={loadingMatches}
        />
      ) : null}
      {/* Back button and progress bar */}
      {shouldShowBackButtonAndOrProgressBar && (
        <Nav
          step={step}
          progressHelpers={progressHelpers}
          showFiltersButton={showFiltersButton}
          isLoggedInUser={isLoggedInUser}
          handleShowFiltersModal={handleShowFiltersModal}
        />
      )}
      {/* Main page content */}
      <MainContentWrapper
        hasBackButtonOrProgress={shouldShowBackButtonAndOrProgressBar}
        stepCategory={category}
        progressHelpers={progressHelpers}
        stepId={stepId}
        opacity={opacity}
        hideComponent={hideComponent}
        heading1={stepDynamicResolved.heading1}
        heading2={heading2}
        heading2TranslationKey={heading2TranslationKey}
        heading1ID={heading1ID}
        heading2ID={heading2ID}
        heading3={stepDynamicResolved.heading3}
        heading3TranslationKey={heading3TranslationKey}
        i18nNamespace={i18nNamespace}
      >
        {/* Survey Wizard */}
        {isSurveyWizardStep(step) && flowConfig && flowConfig.surveyID && (
          <SurveyWizardWrapper step={step} updateStep={updateStep} flowConfig={flowConfig} />
        )}
        {/* Survey Results */}
        {isSurveyResultsStep(step) && flowConfig && flowConfig.surveyID && (
          <SurveyResults
            flowId={flowId}
            flowConfig={flowConfig}
            setBlurParentContent={setBlurParentContent}
          />
        )}
        {/* Content */}
        {(isSelectStep(step) || isDateOfBirthStep(step)) && step.content && (
          <Content paragraphs={step.content} />
        )}
        {/* Textarea */}
        {isFreeTextStep(step) && (
          <FreeText
            question={step}
            updateStep={updateStep}
            setRecoveredField={setRecoveredField}
            placeholder={step.textAreaPlaceholder}
          />
        )}
        {/* Date of Birth */}
        {isDateOfBirthStep(step) && (
          <DateOfBirth
            step={step}
            updateStep={updateStep}
            flowConfig={flowConfig}
            handleClientDateOfBirth={handleClientDateOfBirth}
            redirectFrom={redirectFrom}
            qmPartnerCode={qmPartnerCode}
            cpPartnerCode={cpPartnerCode}
            flowId={flowId}
            setRecoveredField={setRecoveredField}
            service={service}
          />
        )}
        {/* NotAvailableInYourRegion */}
        {isNotAvailableInYourRegionStep(step) && <NotAvailableInYourRegion />}
        {/* B2BFork */}
        {isB2BForkStep(step) && (
          <B2BFork
            flowId={flowId}
            step={step}
            service={service}
            updateStep={updateStep}
            clientCountry={clientCountry}
            clientState={clientState}
            insurancePayer={insurancePayer}
            experimentsVariants={experimentsVariants}
          />
        )}
        {/* SecondaryInsurance */}
        {isSecondaryInsuranceStep(step) && (
          <SecondaryInsurance
            step={step}
            service={service}
            updateStep={updateStep}
            primaryInsurancePayer={insurancePayer}
          />
        )}
        {/* Dispatcher in QM */}
        {isDispatcherInQMStep(step) && (
          <DispatcherInQM step={step} updateStep={updateStep} service={service} />
        )}
        {/* Eligibility by geolocation */}
        {isLocationEligibilityStep(step) && (
          <LocationEligibility
            step={step}
            flowId={flowId}
            updateStep={updateStep}
            updateAndSave={updateAndSave}
          />
        )}
        {isLocationEligibilityErrorStep(step) && (
          <LocationEligibilityError step={step} updateStep={updateStep} />
        )}
        {isFileUploadStep(step) && (
          <FileUpload
            step={step}
            updateStep={updateStep}
            service={service}
            setEligibilityFiles={setEligibilityFiles}
          />
        )}
        {isManualSubmit(step) && (
          <ManualSubmit
            step={step}
            updateStep={updateStep}
            service={service}
            eligibilityFiles={eligibilityFiles as EligibilityFiles}
            quickEligibilityInfo={quickEligibilityInfo as QuickEligibilityInfo}
            setManualVerificationEmail={setManualVerificationEmail}
          />
        )}
        {isRequestReceivedStep(step) && (
          <RequestReceived
            step={step}
            updateStep={updateStep}
            service={service}
            quickEligibilityInfo={quickEligibilityInfo as QuickEligibilityInfo}
            manualVerificationEmail={manualVerificationEmail}
            showBackToAccountCTA={
              isCreateRoomFlow ||
              isReactivationFlow ||
              isUpdateCoverageFlow ||
              isMBHIneligibilityFlow
            }
            onBackToAccountCTAPress={() => {
              window.onbeforeunload = null;
              ReactFrameService.instance().closePopup();
            }}
          />
        )}
        {isLocationEligibilityManualAccessCodeStep(step) && (
          <LocationEligibilityManualAccessCode
            step={step}
            flowId={flowId}
            updateAndSave={updateAndSave}
            responses={responses}
          />
        )}
        {isQuickEligibilityStep(step) && (
          <QuickEligibility
            updateStep={updateStep}
            service={service}
            step={step}
            quickEligibilityInfo={quickEligibilityInfo}
            insurancePayer={insurancePayer}
            flowConfig={flowConfig}
            setReferralSource={handleReferralSource}
            referralSource={referralSource}
            partnerFlowID={partnerFlowID}
          />
        )}
        {isMemberAvailabilityStep(step) && (
          <MemberAvailability
            updateStep={updateStep}
            step={step}
            memberAvailability={memberAvailability}
          />
        )}
        {isQuickEligibilityEnterMemberIDStep(step) && (
          <QuickEligibilityEnterMemberID
            updateStep={updateStep}
            service={service}
            step={step}
            quickEligibilityInfo={quickEligibilityInfo as QuickEligibilityInfo}
            insurancePayer={insurancePayer}
            redirectFrom={redirectFrom}
            organizationFlowID={organizationFlowID}
            partnerFlowID={partnerFlowID}
          />
        )}
        {isQuickEligibilityStatusStep(step) && (
          <QuickEligibilityStatus
            updateStep={updateStep}
            service={service}
            step={step}
            quickEligibilityInfo={quickEligibilityInfo as QuickEligibilityInfo}
            insurancePayer={insurancePayer}
            copayCents={copayCents}
            redirectFrom={redirectFrom}
            organizationFlowID={organizationFlowID}
            partnerFlowID={partnerFlowID}
            insuranceDetails={insuranceDetails}
          />
        )}
        {isPreRegisterHoldExpiredStep(step) && <PreRegisterHoldExpired />}
        {isPreRegisterJwtExpiredStep(step) && <PreRegisterJwtExpired />}
        {/* Lasting Promotion */}
        {isLastingPromotionStep(step) && <LastingPromotion step={step} updateStep={updateStep} />}
        {/* Button */}
        {/* Category: introduction */}
        {buttonText &&
          category !== 'explanation' &&
          category !== 'dropdown' &&
          category !== 'schoolSelect' &&
          category !== 'validateVoucher' && (
            <StepLayoutButton
              step={step}
              onClick={() => updateStep(step.buttonTarget)}
              aria-describedby={heading2 ? heading2ID : undefined}
              i18nNamespace={i18nNamespace}
            />
          )}
        {isSurveyIntroductionStep(step) && (
          <SurveyIntroduction onPress={() => updateStep(step.buttonTarget)} />
        )}
        {/* Button List */}
        {/* Presenting Problems */}
        {((isSelectStep(stepDynamicResolved) && stepDynamicResolved.buttons) ||
          isSelectDynamicStepResolved(stepDynamicResolved) ||
          isPresentingProblemsStep(stepDynamicResolved)) && (
          <Buttons
            value={selectedButton()}
            updateStep={updateStep}
            step={stepDynamicResolved}
            buttons={buttonOptions}
            isLoggedIn={isLoggedInUser}
            fieldStyle={buttonsFieldStyle}
          />
        )}
        {/* Multi Select Dropdown */}
        {isMultiSelectStep(step) && step.multiselect && (
          <MultiSelect
            question={step}
            updateStep={updateStep}
            key={`${stepId}`}
            value={dropdownValue}
            heading1ID={heading1ID}
            heading2ID={heading2ID}
            labelText={step.multiSelectLabelText}
          />
        )}
        {/* Explanation */}
        {isExplanationStep(step) && (
          <Explainer step={step} updateStep={updateStep} i18nNamespace={i18nNamespace} />
        )}
        {/* Dropdown */}
        {isDropdownStep(step) && (
          <Dropdown
            step={step}
            updateStep={updateStep}
            options={selectOptions as Array<OptionType>}
            placeholder={dropdownPlaceholder}
            key={`${stepId}`}
            value={dropdownValue}
            resetCallback={resetLivingInfo}
            shouldResetComponent={shouldResetLivingInfo}
            labelText={
              isStateCountrySelectionStep(step)
                ? stateCountryLabel
                : (step as DropdownStep).selectLabelText
            }
            i18nNamespace={i18nNamespace}
          />
        )}
        {/* State/Country Toggle */}
        {isStateCountrySelectionStep(step) && (
          <StateCountryToggle
            service={service}
            livesInTheUS={livesInTheUS}
            onShowCountries={onShowCountries}
            isForUSOnly={
              redirectFromFlowConfig?.specificCountryCodesToSupport?.length === 1 &&
              redirectFromFlowConfig?.specificCountryCodesToSupport[0] === 'US'
            }
          />
        )}
        {/* Loading screen */}
        {isLoadingStep(step) || (isMatchesStep(step) && !matchesReceived) ? (
          <Loading
            step={step}
            updateStep={updateStep}
            loadingMatches={loadingMatches}
            isNoMatches={isNoMatches}
          />
        ) : null}
        {/* Matches screen */}
        {isMatchesStep(step) && matches && matchesReceived && matches?.length > 0 ? (
          <Matches
            removeGenderFilter={removeGenderFilter}
            step={step}
            updateStep={updateStep}
            matches={matches}
            loadingMatches={loadingMatches}
            exactMatch={exactMatch}
            flowId={flowId}
            clientMatchGenderPreference={clientMatchGenderPreference}
            clientMatchPresentingProblems={clientMatchPresentingProblems}
            clientCountry={clientCountry}
            insurancePayer={insurancePayer}
          />
        ) : null}
        {/* No matches screen - thanks for sharing your matching preferences */}
        {isMatchesStep(step) && (!matches || matches.length === 0) && (
          <NoMatches
            step={step}
            insurancePayer={insurancePayer}
            updateStep={updateStep}
            setBlurParentContent={setBlurParentContent}
            isNoMatches={isNoMatches}
            handleSetIsMatchesOverlayDismissed={handleSetIsMatchesOverlayDismissed}
            isLoggedInUser={isLoggedInUser}
            b2bForkResult={b2bForkResult}
            isMatchesOverlayDismissed={isMatchesOverlayDismissed}
            isB2BOutOfNetwork={isB2BOutOfNetwork}
            isCouldNotBeVerified={isCouldNotBeVerified}
          />
        )}
        {/* Match confirmation */}
        {isMatchConfirmationStep(step) && selectedMatch && (
          <MatchConfirmation
            step={step}
            updateStep={updateStep}
            selectedMatch={selectedMatch}
            setDiscardMatches={setDiscardMatches}
            setBlurParentContent={setBlurParentContent}
          />
        )}
        {isInsuranceConfirmationStep(step) && (
          <InsuranceCheckConfirmation
            updateStep={updateStep}
            step={step}
            isUpdateCoverageFlow={isUpdateCoverageFlow}
          />
        )}
        {/* Register screen */}
        {isRegisterWithVoucherStep(step) &&
          (isLoggedInUser ? (
            <EligibilityStartTherapyContainer
              funnelVariation={funnelVariation}
              voucherCode={qmPartnerCode}
              quickmatchResponses={responses}
              therapistId={therapistId}
              updateCoverageRoomID={updateCoverageRoomID}
              therapistFirstName={therapistFirstName}
              presentingProblems={clientMatchPresentingProblems}
              isCreateRoomFlow={isCreateRoomFlow}
              isReactivationFlow={isReactivationFlow}
              isUpdateCoverageFlow={isUpdateCoverageFlow}
              isMBHIneligibilityFlow={isMBHIneligibilityFlow}
              sessionsCanceled={sessionsCanceled}
              sessionsTransferred={sessionsTransferred}
              clientDateOfBirth={clientDateOfBirth}
              appointment={appointment}
              clientGender={clientGender}
              isNoMatches={isNoMatches}
              redirectFrom={redirectFrom}
              hasRemainingSessions={oldRoomHasSessions}
              setCreateRoomReturnProps={setCreateRoomReturnProps}
              providerAvailable={Boolean(therapistId)}
              oldRoomID={updateCoverageRoomID}
              previousTherapistId={previousTherapistId}
              existingUserEmail={existingUserEmail}
              therapistIDFromCreateRoom={therapistIDFromCreateRoom}
            />
          ) : (
            <RegisterWithVoucher
              flowId={flowId}
              funnelVariation={funnelVariation}
              qmPartnerCode={qmPartnerCode}
              responses={responses}
              therapistId={therapistId}
              clientMatchPresentingProblems={clientMatchPresentingProblems}
              redirectFrom={redirectFrom}
              clientDateOfBirth={clientDateOfBirth}
              setQMFlowDone={setQMFlowDone}
              appointment={appointment}
              roomType={roomType}
              clientGender={clientGender}
              isNoMatches={isNoMatches}
              isNYCTeen={step.isNYCTeen}
              clientCountry={clientCountry}
              clientState={clientState}
              cognitoActive={cognitoActive}
              referralSource={referralSource}
            />
          ))}
        {/* Register without payment screen */}
        {isRegisterWithoutPaymentStep(step) && (
          <RegisterWithoutPayment
            funnelVariation={funnelVariation}
            responses={responses}
            clientMatchPresentingProblems={clientMatchPresentingProblems}
            redirectFrom={redirectFrom}
            clientDateOfBirth={clientDateOfBirth}
            setQMFlowDone={setQMFlowDone}
            roomType={roomType}
            clientGender={clientGender}
            clientCountry={clientCountry}
            clientState={clientState}
            cognitoActive={cognitoActive}
          />
        )}
        {/* Scheduler */}
        {isSchedulerStep(step) && (
          <Scheduler
            providerID={therapistId}
            providerFirstName={therapistFirstName}
            updateStep={updateStep}
            step={step}
            service={service}
            setIsConfirmScheduler={setIsConfirmScheduler}
          />
        )}
        {/* Register with BH Copay */}
        {isBHCopayStep(step) && !roomID && (
          <RegisterWithBHCopay
            funnelVariation={funnelVariation}
            plan={chosenPlan}
            copayCents={copayCents}
            cpPartnerCode={cpPartnerCode}
            insuranceCode={insuranceCode}
            responses={responses}
            providerId={therapistId}
            updateCoverageRoomID={updateCoverageRoomID}
            firstName={therapistFirstName}
            lastName={therapistLastName}
            title={therapistTitle}
            clientMatchPresentingProblems={clientMatchPresentingProblems}
            service={service}
            redirectFrom={redirectFrom}
            clientDateOfBirth={clientDateOfBirth}
            step={step}
            trizettoRequest={trizettoRequest}
            setRoomID={setRoomID}
            existingUserEmail={existingUserEmail}
            setQMFlowDone={setQMFlowDone}
            appointment={appointment}
            roomType={roomType}
            flowConfig={flowConfig}
            clientGender={clientGender}
            isNoMatches={isNoMatches}
            isVideoOnlyPlan={isVideoOnlyPlan}
            organizationFlowID={organizationFlowID}
            partnerFlowID={partnerFlowID}
            cognitoActive={cognitoActive}
            insurancePayer={insurancePayer}
            collectReferralSourceOnSignUp={collectReferralSourceOnSignUp}
            allowedModalities={allowedModalities}
            referralSource={referralSource}
            flowId={flowId}
            setCreateRoomReturnProps={setCreateRoomReturnProps}
            headerRef={headerRef}
            memberAvailability={memberAvailability}
            skipCreditCard={skipCreditCard && !isLoggedInUser}
            isLoggedInUser={isLoggedInUser}
          />
        )}
        {isChooseAndPayClientWebStep(step) && !roomID && (
          <RegisterOutOfPocket
            flowId={flowId}
            coupon={coupon}
            offerID={step.offerID}
            setRoomID={setRoomID}
            responses={responses}
            appointment={appointment}
            therapistId={therapistId}
            setDiscardMatches={setDiscardMatches}
            therapistFirstName={therapistFirstName}
            therapistLastName={therapistLastName}
            therapistLicenses={therapistLicenses}
            funnelVariation={funnelVariation}
            couponInputMode={step.couponInputMode}
            isCreateRoomFlow={isCreateRoomFlow}
            isReactivationFlow={isReactivationFlow}
            isUpdateCoverageFlow={isUpdateCoverageFlow}
            isMBHIneligibilityFlow={isMBHIneligibilityFlow}
            isCouldNotBeVerified={isCouldNotBeVerified}
            clientDateOfBirth={clientDateOfBirth}
            previousStepsUntilMatches={step.previousStepsUntilMatches}
            hideProviderInfo={step.hideProviderInfo}
            clientMatchPresentingProblems={clientMatchPresentingProblems}
            clientCountry={clientCountry}
            clientState={clientState}
            insuranceEligibility={insuranceEligibility}
            roomType={roomType}
            clientGender={clientGender}
            isNoMatches={isNoMatches}
            cognitoActive={cognitoActive}
            insurancePayer={insurancePayer}
            collectReferralSourceOnSignUp={collectReferralSourceOnSignUp}
            setCreateRoomReturnProps={setCreateRoomReturnProps}
            updateCoverageRoomID={updateCoverageRoomID}
          />
        )}
        {(isBHCopayStep(step) || isChooseAndPayClientWebStep(step)) && isLoggedInUser && roomID && (
          <TherapistSelectedConfirmation
            buttonHandler={() => {
              ReactFrameService.instance().closePopup({
                navigateTo: 'room',
                metadata: {
                  roomID,
                  source: getQMFunnelName({
                    isCreateRoomFlow,
                    isReactivationFlow,
                    isUpdateCoverageFlow,
                    isMBHIneligibilityFlow,
                  }),
                },
              });
            }}
            therapistImageURL={`${REACT_APP_CDN_ENDPOINT}images/application/therapist/440/${therapistIDFromCreateRoom}.png`}
            previousTherapistImageURL={`${REACT_APP_CDN_ENDPOINT}images/application/therapist/440/${previousTherapistId}.png`}
            defaultImageURL={`${REACT_APP_CDN_ENDPOINT}images/application/therapist/440/1.png`}
            buttonText="Continue to room"
            title="Your new room has been created."
            subtitleFirstLine={
              therapistIDFromCreateRoom !== previousTherapistId
                ? ''
                : `${therapistFirstName} is ready to help you on your journey towards feeling better.`
            }
            subtitleSecondLine="If you had previously matched with a therapist, that room and subscription will remain active alongside your new room unless you cancel it."
            hasRemainingSessions={oldRoomHasSessions}
            providerAvailable={therapistIDFromCreateRoom === previousTherapistId}
            newRoomID={roomID}
            oldRoomID={updateCoverageRoomID}
            isNewProvider={therapistIDFromCreateRoom !== previousTherapistId}
            isUpdateCoverageFlow={isUpdateCoverageFlow}
            isMBHIneligibilityFlow={isMBHIneligibilityFlow}
            isReactivationFlow={isReactivationFlow}
            isCreateRoomFlow={isCreateRoomFlow}
            sessionsCanceled={sessionsCanceled}
            sessionsTransferred={sessionsTransferred}
          />
        )}
        {/* Service Selection */}
        {isServiceSelectionStep(step) && (
          <ServiceSelection
            step={step}
            updateAndSave={updateAndSave}
            isCreateRoomFlow={isCreateRoomFlow}
            isReactivationFlow={isReactivationFlow}
            isUpdateCoverageFlow={isUpdateCoverageFlow}
            isMBHIneligibilityFlow={isMBHIneligibilityFlow}
            updateStep={updateStep}
            serviceType={service}
            hideExitToCT
          />
        )}
        {isTeenBranchingStep(step) && <TeenBranching updateAndSave={updateAndSave} />}
        {isTeenNYCSummaryStep(step) && <TeenNYCSummary step={step} updateStep={updateStep} />}
        {isTeenLetsLearnAboutYouStep(step) && (
          <TeenLetsLearnAboutYou step={step} updateStep={updateStep} />
        )}
        {isTeenYouAreNotAloneStep(step) && (
          <TeenYouAreNotAlone step={step} updateStep={updateStep} flowId={flowId} />
        )}
        {isTeenConsentHeadsUpStep(step) && (
          <TeenConsentHeadsUp step={step} updateStep={updateStep} flowId={flowId} />
        )}
        {isTeenThankYouForSharingStep(step) && (
          <TeenThankYouForSharing step={step} updateStep={updateStep} flowId={flowId} />
        )}
        {isSchoolSelectStep(step) && <SchoolSelect step={step} updateStep={updateStep} />}
        {isLeadCaptureStep(step) && <LeadCapture step={step} updateStep={updateStep} />}
        {/* OneFormEligibility */}
        {isOneFormEligibilityStep(step) && flowConfig && (
          <OneFormEligibility
            flowId={flowId}
            flowConfig={flowConfig}
            step={step}
            updateStep={updateStep}
            setRecoveredField={setRecoveredField}
            payer={insurancePayer}
            service={service}
            organizationFlowID={organizationFlowID}
            isCreateRoomFlow={isCreateRoomFlow}
            isReactivationFlow={isReactivationFlow}
            isUpdateCoverageFlow={isUpdateCoverageFlow}
            isMBHIneligibilityFlow={isMBHIneligibilityFlow}
            b2bForkResult={b2bForkResult}
            partnerFlowID={partnerFlowID}
            isB2BOutOfNetwork={isB2BOutOfNetwork}
            isCouldNotBeVerified={isCouldNotBeVerified}
            setIsCouldNotBeVerified={setIsCouldNotBeVerified}
            scrollPage={scrollPage}
            setClientDateOfBirth={handleClientDateOfBirth}
            setBlurParentContent={setBlurParentContent}
            setReferralSource={handleReferralSource}
            updateAndSave={updateAndSave}
            setInsuranceDetails={setInsuranceDetails}
            insuranceDetails={insuranceDetails}
          />
        )}
        {/* Validate Voucher */}
        {isValidateVoucherStep(step) && (
          <ValidateVoucher
            updateAndSave={updateAndSave}
            clientDateOfBirth={clientDateOfBirth}
            invalidVoucher={invalidVoucher}
            flowId={flowId}
            flowConfig={flowConfig}
            step={step}
            goBack={goBack}
            voucher={voucher}
            insuranceCode={insuranceCode}
            trizettoRequest={trizettoRequest}
            copayCents={copayCents}
            service={service}
            trizettoErrorStatusCode={trizettoErrorStatusCode}
            isVideoOnlyPlan={isVideoOnlyPlan}
            totalSessions={totalSessions}
            accountType={accountType}
            manualFlowID={manualFlowID}
          />
        )}
        {isCurrentProviderStep(step) && (
          <CurrentProvider
            updateStep={updateStep}
            step={step}
            currentProvidersInfo={currentProvidersInfo}
            hasOnlyPlaceholderProviders={hasOnlyPlaceholderProviders}
            isReactivationFlow={isReactivationFlow}
            isUpdateCoverageFlow={isUpdateCoverageFlow}
            keepProvider={keepProvider}
          />
        )}
        {isPsychiatryUnavailableStep(step) && (
          <PsychiatryUnavailable description={step.description} goBack={goBack} />
        )}
        {isReviewPlanStep(step) && (
          <ReviewPlansBHOrEAP
            reviewPlanDetails={reviewPlanDetails}
            step={step}
            updateStep={updateStep}
            service={service}
            redirectFrom={redirectFrom}
            organizationFlowID={organizationFlowID}
            partnerFlowID={partnerFlowID}
            copayCents={copayCents}
            totalSessions={totalSessions}
            insuranceDetails={insuranceDetails}
          />
        )}
        {isSessionModalityStep(step) && (
          <SelectSessionModality
            clientState={clientState}
            b2bForkResult={b2bForkResult}
            accountType={accountType}
            flowConfig={redirectFromFlowConfig}
            allowedModalities={allowedModalities}
            step={step}
            updateStep={updateStep}
            isLoggedInUser={isLoggedInUser}
          />
        )}
        {isOutOfNetworkStep(step) && (
          <OutOfNetwork step={step} insurancePayer={insurancePayer} updateStep={updateStep} />
        )}
        {isTeensHereToHelpStep(step) && <TeensHereToHelp step={step} updateStep={updateStep} />}
        {isTeensEligibleFreeTherapyStep(step) && (
          <TeensEligibleFreeTherapy step={step} updateStep={updateStep} />
        )}
        {isTeenZipcodeEligibilityStep(step) && (
          <TeenZipcodeEligibility
            step={step}
            updateStep={updateStep}
            flowId={flowId}
            updateAndSave={updateAndSave}
            setClientAge={handleClientAge}
            setRecoveredField={setRecoveredField}
            isCreateRoomFlow={isCreateRoomFlow}
            isReactivationFlow={isReactivationFlow}
            isUpdateCoverageFlow={isUpdateCoverageFlow}
            isMBHIneligibilityFlow={isMBHIneligibilityFlow}
          />
        )}
        {/* Skip Button */}
        {/* - OneFormEligibility will deal with the button by itself (above the privacy text in it) */}
        {!hideComponent && !!step.skipText && !isOneFormEligibilityStep(step) && (
          <SkipOption
            step={stepDynamicResolved}
            updateStep={updateStep}
            i18nNamespace={i18nNamespace}
          />
        )}
        {/* Tip for steps */}
        {step.tip && (
          <StepTip
            tip={step.tip}
            style={{
              maxWidth: 430,
              marginTop: 35,
              marginBottom: isMobile ? 90 : 60,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            tipImageStyle={{ marginLeft: -8 }}
            customImage={step.tipImage}
            customTitle={step.tipTitle}
            customContent={step.tipContent}
          />
        )}
      </MainContentWrapper>
    </View>
  );
};

export default StepLayout;
