import {
  Button,
  Caption,
  Heading1,
  Notepad,
  RadioButton,
  Spinner,
  TabRadioGroup,
  TherapistCardSmall,
  useContainerFocus,
  useEmotionTheme,
  useUniqueID,
  View,
} from '@talkspace/react-toolkit';
import { useEffect, useMemo, useState, useCallback } from 'react';
import styled from '@/core/styled';
import { CurrentProviderStep, UpdateStep } from '@/Flows/types';
import { trackEvent } from '@/utils/analytics/eventTracker';
import { CurrentProvidersInfo, HomePageState } from '@/Components/HomePage/types';

const DESCRIPTION = `You're taking an important step prioritizing your mental health, and we feel honored to continue supporting you on your journey. You can continue with a previous provider or match with a new one.`;
const NO_PROVIDER_DESCRIPTION = `Unfortunately, your previous provider is no longer available. The good news is that we have other experienced providers that we can match you with.`;

interface CurrentProviderProps {
  updateStep: UpdateStep;
  step: CurrentProviderStep;
  currentProvidersInfo: HomePageState['currentProvidersInfo'];
  hasOnlyPlaceholderProviders: HomePageState['hasOnlyPlaceholderProviders'];
  keepProvider: boolean | undefined;
  isReactivationFlow: boolean;
  isUpdateCoverageFlow: boolean;
}

const StyledButton = styled(Button)(({ theme: { colors } }) => {
  return {
    transition: 'all 200ms ease-in-out',
    '&:not([disabled]):hover': {
      backgroundColor: colors.permaTropicalRainForest,
    },
  };
});

const Description = styled(Caption)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      marginTop: 10,
      textAlign: 'center',
      paddingLeft: isMobile ? 10 : 0,
      paddingRight: isMobile ? 10 : 0,
    };
  }
);

const TherapistsList = styled(View)({
  marginTop: 50,
  width: 345,
});

const Title = styled(Heading1)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      textAlign: 'center',
      fontSize: 38,
      lineHeight: 'inherit',
      ...(isMobile
        ? {
            fontSize: 32,
            fontWeight: 500,
            letterSpacing: -0.4,
            lineHeight: '30px',
            width: 'auto',
          }
        : {}),
    };
  }
);

const Container = styled(View)({ '&:focus': { outline: 'none' } });

const CurrentProvider = ({
  updateStep,
  step,
  currentProvidersInfo,
  hasOnlyPlaceholderProviders,
  keepProvider,
  isReactivationFlow,
  isUpdateCoverageFlow,
}: CurrentProviderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { colors } = useEmotionTheme();
  const { containerRef } = useContainerFocus();
  const header1Id = useUniqueID('idHeader1');
  const availableProviders = useMemo(
    () => currentProvidersInfo?.filter((provider) => provider.isAvailable) || [],
    [currentProvidersInfo]
  );
  const [selectedProvider, setSelectedProvider] = useState<CurrentProvidersInfo[number] | null>(
    availableProviders[0] || null
  );
  const hasMultipleProviders = availableProviders.length > 1;
  const noProvidersAvailable = availableProviders.length === 0;
  const keepCurrentProvider =
    (keepProvider || isUpdateCoverageFlow) &&
    availableProviders.length > 0 &&
    !hasOnlyPlaceholderProviders;
  const shouldSkipStep = keepCurrentProvider || !isReactivationFlow || hasOnlyPlaceholderProviders;
  const updateStepKeepProvider = useCallback(() => {
    if (!selectedProvider) return Promise.resolve();
    const { therapistID, therapistLicenses, firstName, lastName, title } = selectedProvider;
    setIsLoading(true);
    trackEvent('Reactivation - Use Current Provider', {
      therapistID,
      choicePosition: availableProviders.findIndex((p) => p.therapistID) + 1,
    });
    return updateStep(
      step.currentProviderTarget,
      {
        // TBD: Send provider info?
      },
      {
        id: therapistID,
        title,
        firstName,
        lastName,
        licenses: therapistLicenses.map((license) => {
          return { type: license };
        }),
      }
    );
  }, [step, updateStep, selectedProvider, availableProviders]);

  useEffect(() => {
    // Skip this step if no provider is available or if we are updating coverage
    if (shouldSkipStep) {
      if (keepCurrentProvider) {
        updateStepKeepProvider();
      } else {
        updateStep(step.buttonTarget, undefined, undefined, undefined, {
          removeStepFromHistory: true,
        });
      }
    }
  }, [step, updateStep, shouldSkipStep, keepCurrentProvider, updateStepKeepProvider]);

  const onContinuePress = async () => {
    await updateStepKeepProvider();
  };
  const onMatchNewTherapistPress = async () => {
    const { therapistID = null } = selectedProvider || {};
    setIsLoading(true);
    trackEvent('Reactivation - Use New Provider', {
      therapistID,
    });
    await updateStep(step.buttonTarget);
  };

  if (shouldSkipStep) return <Spinner isLoading />;

  return (
    <Container
      style={{
        maxWidth: 345,
      }}
      ref={containerRef}
      aria-labelledby={header1Id}
      align="center"
      tabIndex={-1}
    >
      <Notepad />
      <Title id={header1Id}>
        {!noProvidersAvailable ? 'Thanks for answering' : 'We appreciate your answers'}
      </Title>
      <Description as="h2">
        {!noProvidersAvailable ? DESCRIPTION : NO_PROVIDER_DESCRIPTION}
      </Description>
      <TherapistsList>
        <TabRadioGroup legendText="Select a provider you would like to continue working with">
          {availableProviders.map((therapist) => {
            const { therapistID, therapistImage, firstName, lastName, therapistLicenses, status } =
              therapist;
            return (
              <TherapistCardSmall
                wrapperProps={{
                  onClick: () => setSelectedProvider(therapist),
                  style: {
                    cursor: 'pointer',
                  },
                }}
                key={therapistID}
                image={therapistImage}
                name={`${firstName} ${lastName}`}
                licenses={therapistLicenses}
                status={status}
                rightController={
                  hasMultipleProviders && (
                    <RadioButton
                      isActive={selectedProvider?.therapistID === therapistID}
                      onPress={() => setSelectedProvider(therapist)}
                      ariaLabelledBy={firstName}
                    />
                  )
                }
              />
            );
          })}
        </TabRadioGroup>
      </TherapistsList>

      {!noProvidersAvailable && (
        <StyledButton
          text="Continue with current provider"
          dataQa={`${step.category || ''}ContinueButton`}
          onPress={onContinuePress}
          disabled={isLoading}
          style={{ width: '100%', marginTop: 15, fontWeight: 700 }}
          roundedFocusStyle
          primaryColor={colors.green}
        />
      )}

      <StyledButton
        text="Match me with a new provider"
        onPress={onMatchNewTherapistPress}
        disabled={isLoading}
        style={{ width: '100%', marginTop: 15, fontWeight: 700 }}
        dataQa={`${step.category || ''}NewProviderButton`}
        roundedFocusStyle
        primaryColor={colors.green}
      />
    </Container>
  );
};

export default CurrentProvider;
