import {
  View,
  Small,
  ScrollView,
  Big,
  Button,
  useWindowWidthState,
  Checkbox,
  TextDS,
  CheckV2,
} from '@talkspace/react-toolkit';
import { useState, useCallback } from 'react';
import { isApple } from 'ts-frontend/utils/device';
import { useFlags } from 'launchDarkly/FlagsProvider';
import ButtonV2 from '@talkspace/react-toolkit/src/designSystems/components/Button/ButtonV2';
import { SELECTIONS_REQUIRED, parseTime, SUGGESTED_DAYS, DAILY_TIME_BUCKETS } from './utils';
import { SelectedOption, Day, TimeBucket } from './types';
import { UpdateStep, MemberAvailabilityStep } from '@/Flows/types';
import { DayOfWeek, HomePageState, useHomePageState } from '@/Components/HomePage';
import styled from '../../../core/styled';
import { trackEvent } from '@/utils/analytics/events';
import { getQMFunnelName } from '../../../Helpers/flowsHelper';

const ScrollViewStyled = styled(ScrollView)({
  maxHeight: 'calc(100vh - 420px)',
  width: 355,
  overflowX: 'clip',
  paddingBottom: 40,
  paddingLeft: 5,
  alignItems: 'center',
});

const BorderLine = styled(View)({
  width: '100vw',
  borderBottom: '1px solid #D1D6E1',
  marginBottom: 16,
});

const Subtitle = styled(Small)(() => {
  return {
    textAlign: 'center',
    margin: `0 15px 35px`,
    maxWidth: 280,
  };
});

const DayTitle = styled(Big)(({ theme: { colorRoles } }) => {
  return {
    width: 35,
    textAlign: 'left',
    color: colorRoles.typography.textSubtle,
  };
});

const TableRow = styled(View)(() => {
  return {
    width: '100%',
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  };
});

const checkboxContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  marginLeft: -7,
  padding: 0,
};
interface MemberAvailabilityProps {
  updateStep: UpdateStep;
  step: MemberAvailabilityStep;
  memberAvailability: HomePageState['memberAvailability'];
}

const MemberAvailability = (props: MemberAvailabilityProps) => {
  const { updateStep, step, memberAvailability } = props;
  const { isCreateRoomFlow, isReactivationFlow, isUpdateCoverageFlow, isMBHIneligibilityFlow } =
    useHomePageState();
  const {
    clientAvailabilityPreferencesExperiment: { variant },
  } = useFlags();

  const deparseMemberAvailability = (
    memberAvailabilityData: HomePageState['memberAvailability']
  ): Array<SelectedOption> => {
    const previouslySelected: Array<SelectedOption> = [];

    memberAvailabilityData?.forEach((it) => {
      it.availability.forEach((availability) => {
        DAILY_TIME_BUCKETS.forEach((bucket) => {
          if (
            parseTime(availability.startTime) <= parseTime(bucket.availability.startTime) &&
            parseTime(availability.endTime) >= parseTime(bucket.availability.endTime)
          ) {
            previouslySelected.push({
              day: `${it.dayOfWeek}s` as Day,
              timeName: bucket.name,
              timeRange: bucket.timeRange,
            });
          }
        });
      });
    });

    return previouslySelected;
  };

  const [selectedOptions, setSelectedOptions] = useState<Array<SelectedOption>>(
    deparseMemberAvailability(memberAvailability)
  );
  const { isMobile } = useWindowWidthState();

  const selectedCount = selectedOptions.length;
  const leftToSelect = SELECTIONS_REQUIRED - selectedCount;
  const canProceed = leftToSelect <= 0;

  const parseMemberAvailability = (
    selectedOptionsData: Array<SelectedOption>
  ): HomePageState['memberAvailability'] => {
    const memberAvailabilityData: HomePageState['memberAvailability'] = [];
    selectedOptionsData.forEach((selectedOptionsItem) => {
      const dayIndex = memberAvailabilityData.findIndex(
        (memberAvailabilityItem) =>
          `${memberAvailabilityItem.dayOfWeek}s` === selectedOptionsItem.day
      );
      const dailyTimeBucket = DAILY_TIME_BUCKETS.find(
        (bucket) => bucket.name === selectedOptionsItem.timeName
      );
      if (dayIndex >= 0) {
        memberAvailabilityData[dayIndex].availability.push({
          startTime: dailyTimeBucket!.availability.startTime,
          endTime: dailyTimeBucket!.availability.endTime,
        });

        if (memberAvailabilityData[dayIndex].availability.length === DAILY_TIME_BUCKETS.length) {
          memberAvailabilityData[dayIndex].alwaysAvailable = true;
        }
      } else {
        memberAvailabilityData?.push({
          dayOfWeek: selectedOptionsItem.day.slice(0, -1) as DayOfWeek,
          availability: [
            {
              startTime: dailyTimeBucket!.availability.startTime,
              endTime: dailyTimeBucket!.availability.endTime,
            },
          ],
          alwaysAvailable: false,
        });
      }
    });
    return memberAvailabilityData;
  };

  const trackAnsweredQuestion = ({
    buttonClicked,
    availability,
  }: {
    buttonClicked: 'Continue' | 'Available anytime' | 'Skip';
    availability: Array<string>;
  }) => {
    trackEvent('Answer Questionnaire', {
      Question: step.prompt || 'member availability preferences',
      actionClicked: buttonClicked,
      availability,
      Flow: 'Dispatcher',
      'Funnel Name': getQMFunnelName({
        isCreateRoomFlow,
        isReactivationFlow,
        isUpdateCoverageFlow,
        isMBHIneligibilityFlow,
      }),
    });
  };

  const updateMemberAvailabilityStep = (responseData: HomePageState['memberAvailability']) => {
    const stringResponse = JSON.stringify(responseData);
    updateStep(step.buttonTarget, {
      payfirst_step_id: 135,
      payfirst_step_prompt: step.prompt,
      response_category_id: 7,
      response_prompt: stringResponse,
      response_value: stringResponse,
    });
  };

  const onPressContinue = () => {
    const memberAvailabilityData = parseMemberAvailability(selectedOptions);
    updateMemberAvailabilityStep(memberAvailabilityData);

    trackAnsweredQuestion({
      buttonClicked: 'Continue',
      availability: selectedOptions.map((it) => `${it.day} ${it.timeName} ${it.timeRange}`),
    });
  };

  const onPressSkip = () => {
    updateMemberAvailabilityStep([]);

    trackAnsweredQuestion({
      buttonClicked: 'Skip',
      availability: [],
    });
  };

  const onPressAvailableAnyTime = () => {
    const memberAvailabilityData: HomePageState['memberAvailability'] = [];

    SUGGESTED_DAYS.forEach((day) => {
      memberAvailabilityData.push({
        dayOfWeek: day.slice(0, -1) as DayOfWeek,
        availability: [],
        alwaysAvailable: true,
      });
      DAILY_TIME_BUCKETS.forEach((bucket) => {
        memberAvailabilityData[memberAvailabilityData.length - 1].availability.push({
          startTime: bucket.availability.startTime,
          endTime: bucket.availability.endTime,
        });
      });
    });
    updateMemberAvailabilityStep(memberAvailabilityData);

    trackAnsweredQuestion({
      buttonClicked: 'Available anytime',
      availability: deparseMemberAvailability(memberAvailabilityData).map(
        (it) => `${it.day} ${it.timeName} ${it.timeRange}`
      ),
    });
  };

  const checkIfSelected = useCallback(
    (timeBucket: TimeBucket, day: Day) =>
      selectedOptions.some((option) => option.day === day && option.timeName === timeBucket.name),
    [selectedOptions]
  );

  const toggleOption = useCallback(
    (timeBucket: TimeBucket, day: Day) => {
      const isSelected = checkIfSelected(timeBucket, day);

      if (isSelected) {
        const updatedOptions = selectedOptions.filter(
          (option) => !(option.day === day && option.timeName === timeBucket.name)
        );
        setSelectedOptions(updatedOptions);
      } else {
        const newOption = {
          day,
          timeName: timeBucket.name,
          timeRange: timeBucket.timeRange,
        };
        setSelectedOptions([...selectedOptions, newOption]);
      }
    },
    [selectedOptions, checkIfSelected]
  );

  const mobileContentHeight = isApple ? '78vh' : '88vh';

  return (
    <View align="center" style={{ height: isMobile ? mobileContentHeight : undefined }}>
      <Subtitle variant="smallDarkGrey">
        You'll be able to adjust and choose your exact session times later
      </Subtitle>
      <ScrollViewStyled>
        <View row style={{ marginLeft: 40, marginBottom: 12 }}>
          {DAILY_TIME_BUCKETS.map((timeBucket) => (
            <View key={timeBucket.name} style={{ width: 106, padding: 8 }}>
              <TextDS variant="headingXs">{timeBucket.name}</TextDS>
              <TextDS variant="bodyXs">{timeBucket.timeRange}</TextDS>
            </View>
          ))}
        </View>

        {SUGGESTED_DAYS.map((day) => (
          <TableRow key={day}>
            <DayTitle>{day.slice(0, 3)}</DayTitle>
            <View row justify="space-around" flex={1}>
              {DAILY_TIME_BUCKETS.map((timeBucket) => (
                <Checkbox
                  key={timeBucket.name + day}
                  containerStyle={checkboxContainerStyle}
                  isChecked={checkIfSelected(timeBucket, day)}
                  label=""
                  setIsChecked={() => toggleOption(timeBucket, day)}
                  checkboxStyle={{ width: 30, height: 30 }}
                  checkComponent={<CheckV2 height={19} width={19} />}
                  dataQa={`selectTimeslotPreferredTimesTimeRange${day}${timeBucket.timeRange.replace(
                    /\s/g,
                    ''
                  )}`}
                />
              ))}
            </View>
          </TableRow>
        ))}
      </ScrollViewStyled>
      <BorderLine />
      <Button
        text={canProceed ? 'Continue' : "I'm available any time"}
        onPress={canProceed ? onPressContinue : onPressAvailableAnyTime}
        style={{ marginTop: 4, maxWidth: 355 }}
        stretch
        dataQa={
          canProceed
            ? 'selectTimeslotPreferredTimesContinue'
            : 'selectTimeslotPreferredTimesAvailableAnyTime'
        }
      />
      {variant === 'treatment-with-skip' && (
        <ButtonV2
          text="Skip"
          variant="tertiary"
          onPress={onPressSkip}
          dataQa="selectTimeslotPreferredTimesAvailableSkip"
        />
      )}
    </View>
  );
};

export default MemberAvailability;
